<template>
  <div class="layout cloudCanal-header">
    <Layout>
      <Header>
        <Menu mode="horizontal" theme="dark" :active-name="activeName" ref="menu">
          <div class="layout-logo" @click="handleHome">
            <i class="iconfont iconlogo"></i>
          </div>
          <div class="layout-nav">
            <MenuItem name="/data/job/list" to="/data/job/list">
              任务管理
            </MenuItem>
            <MenuItem name="/data/dataSource" to="/data/dataSource">
              数据源管理
            </MenuItem>
            <MenuItem name="/system/resource" to="/system/resource"
                      v-if="menuList.indexOf('/system/resource')>-1">
              机器管理
            </MenuItem>
            <Submenu name="monitor">
              <template slot="title">
                监控管理
              </template>
              <MenuItem name="/monitor/job/dashboard" to="/monitor/job/dashboard"
                        v-if="menuList.indexOf('/monitor/job/dashboard')>-1">监控管理
              </MenuItem>
              <MenuItem name="/monitor/exception" to="/monitor/exception"
                        v-if="menuList.indexOf('/monitor/exception')>-1">异常监控
              </MenuItem>
              <MenuItem name="/alarm/log" to="/alarm/log">
                告警日志
              </MenuItem>
            </Submenu>
            <Submenu name="system"
                     v-if="menuList.indexOf('/system/user')>-1||menuList.indexOf('/system/resource')>-1||menuList.indexOf('/system/fsm')>-1||menuList.indexOf('/system/state/task')>-1">
              <template slot="title">
                系统管理
              </template>
              <MenuItem name="/system/config" to="/system/config"
                        v-if="menuList.indexOf('/system/config')>-1">系统配置
              </MenuItem>
              <MenuItem name="/system/user" to="/system/user"
                        v-if="menuList.indexOf('/system/user')>-1">用户管理
              </MenuItem>
              <MenuItem name="/system/fsm" to="/system/fsm"
                        v-if="menuList.indexOf('/system/fsm')>-1">状态机管理
              </MenuItem>
              <MenuItem name="/system/state/task" to="/system/state/task"
                        v-if="menuList.indexOf('/system/state/task')>-1">异步任务管理
              </MenuItem>
              <MenuItem name="/system/operationLog" to="/system/operationLog"
                        v-if="menuList.indexOf('/system/operationLog')>-1">操作审计
              </MenuItem>
              <MenuItem name="/system/license" to="/system/license"
                        v-if="menuList.indexOf('/system/license')>-1">资源中心
              </MenuItem>
            </Submenu>
          </div>
          <div class="navbar-collapse clearfix">
            <div class="navbar-nav-nb" style="margin-left: 30px">
              <Dropdown>
                <a class="dropdown-arrow-a">
                  {{ username }}
                  <svg style="font-size: 28px;vertical-align: middle;margin-left: 10px" class="icon"
                       aria-hidden="true">
                    <use xlink:href="#icon-headimg"></use>
                  </svg>
                </a>
                <DropdownMenu slot="list">
                  <DropdownItem>
                    <div
                      style="height: 60px;line-height: 60px;border-bottom: 1px solid #dadada;padding: 0 20px;vertical-align: middle">
                      <svg style="font-size: 36px;vertical-align: middle" class="icon"
                           aria-hidden="true">
                        <use xlink:href="#icon-headimg"></use>
                      </svg>
                      <span
                        style="font-size: 16px;font-family: PingFangSC-Semibold,serif;font-weight: 500;margin: 0 10px">{{
                          username
                        }}</span>
                      <span>{{ phone }}</span>
                    </div>
                  </DropdownItem>
                  <DropdownItem>
                    <div class="header-menu-item" @click="handleGoUrl('/system/userCenter')"><i
                      class="iconfont iconzhanghu"
                      style="font-size:12px;margin-right: 8px;vertical-align: middle"></i>账户信息
                    </div>
                  </DropdownItem>
                  <!--                  <DropdownItem>-->
                  <!--                    <div class="header-menu-item" @click="handleGoUrl('/system/alertSetting')"><i-->
                  <!--                      class="iconfont icongaojing"-->
                  <!--                      style="font-size:12px;margin-right: 8px;vertical-align: middle"></i>告警设置-->
                  <!--                    </div>-->
                  <!--                  </DropdownItem>-->
                  <DropdownItem>
                    <div class="header-menu-item" @click="handleGoUrl('/system/stTokenSetting')"><i
                      class="iconfont iconfangwe"
                      style="font-size:12px;margin-right: 8px;vertical-align: middle"></i>阿里云访问权限
                    </div>
                  </DropdownItem>
                  <DropdownItem>
                    <div
                      style="display: block;width: 240px;height: 40px;line-height: 40px;text-align: center;background-color: #ECECEC;color: #FF6E0D;vertical-align: middle"
                      @click="handleLogout">
                      <i class="iconfont icontuichu"
                         style="margin-right: 4px;vertical-align: middle"></i>
                      退出登录
                    </div>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            <div class="navbar-nav-nb">
              <Poptip placement="bottom-end">
                <a style="margin-left: 10px;font-size: 14px">寻求帮助</a>
                <div slot="content">
                  <div style="padding: 10px 30px 30px 30px">
                    <p
                      style="margin-bottom: 20px;text-align: center;font-size: 16px;color: #333333">
                      添加微信寻求帮助</p>
                    <div style="padding: 40px;background-color: #DEEFFF">
                      <img style="width: 204px;height: 204px" src="@asset/Wechat1.jpg" alt="官微">
                    </div>
                  </div>
                </div>
              </Poptip>
              <Tooltip content="帮助文档" placement="bottom"
                       style="padding-right: 20px;margin-left:20px;" transfer>
                <a class="dropdown-arrow-a"
                   href="https://www.clougence.com/cc-doc/intro/product_intro/"
                   target="_blank">
                  <icon class="iconfont iconnav_help"></icon>
                </a>
              </Tooltip>
              <!--              <a style="vertical-align: middle;"><i class="iconfont iconmessage"></i></a>-->
            </div>
            <div class="navbar-nav-nb" style="font-size: 14px;">
              <a href="https://www.clougence.com/#price"
                 target="_blank">{{ EDITIONS_I18N[store.state.globalConfig.product_auth_type] }}&nbsp;</a>
              <span>v{{
                  store.state.globalConfig.productVersions && store.state.globalConfig.productVersions.buildInfo.mainVersion
                }}</span>
              <span class="license-status-span"
                    v-if="!store.state.licenseStatus.success&&!store.state.globalConfig.saas">
                (<span @click="handleshowInactiveReason"
                       style="color: rgb(255, 24, 21)">未激活</span>)</span>
              <span class="license-status-span"
                    v-if="store.state.licenseStatus.success&&!store.state.licenseStatus.saas">
                (<span @click="handleShowResourceInfo"><span
                style="color: rgb(82, 196, 26)">已激活</span>，
                还剩<strong>{{
                    Math.floor(store.state.licenseStatus.remainingTimeMs / 1000 / 60 / 60 / 24)
                  }}</strong>天<strong>{{
                    Math.floor(store.state.licenseStatus.remainingTimeMs / 1000 / 60 / 60 % 24)
                  }}</strong>小时)</span></span>
            </div>
          </div>
        </Menu>
      </Header>
    </Layout>
    <StToken ref="stToken"></StToken>
<!--    <Modal v-model="showActiveLicense"-->
<!--           title="请先激活您的CloudCanal方可正常使用功能"-->
<!--           width="1000px"-->
<!--           footer-hide>-->
<!--      <div>-->
<!--        <LicenseActive :handle-cancel="handleCancel" :applyCode="applyCode"-->
<!--                       :handle-active-license="handleActiveLicense"-->
<!--                       :license-data="licenseData" :macAddress="macAddress"-->
<!--                       :cueAuthCode="cueAuthCode"></LicenseActive>-->
<!--      </div>-->
<!--    </Modal>-->
    <!--    <Modal v-model="showInactiveReason"-->
    <!--           title="未激活原因"-->
    <!--           width="1000px"-->
    <!--           footer-hide>-->
    <!--      <div>-->
    <!--        <div>-->
    <!--          <h3 style="margin-bottom: 10px">当前原因为</h3>-->
    <!--          <p style="color: rgb(255, 24, 21)">{{inactiveReason}}</p>-->
    <!--          <h3 style="margin: 10px 0">以下情况会导致License无效</h3>-->
    <!--          <div>-->
    <!--            <p>-->
    <!--              1. 从未进行过License申请和激活-->
    <!--            </p>-->
    <!--            <p>-->
    <!--              2. 容量超过License限定的额度(删除多余的任务后可自行重新激活)-->
    <!--            </p>-->
    <!--            <p>-->
    <!--              3. License超过时长，社区版可以在官网重新申请社区版License激活，延长使用时间-->
    <!--            </p>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div slot="footer" class="modal-footer" style="margin-top: 20px">-->
    <!--          <pd-button type="primary" @click="handleShowActiveLicense">去激活</pd-button>-->
    <!--          <pd-button @click="handleCancel">关闭</pd-button>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </Modal>-->
    <!--    <Modal v-model="showResourceInfo"-->
    <!--           title="已授权资源和时间"-->
    <!--           width="1000px"-->
    <!--           footer-hide>-->
    <!--      <div>-->
    <!--        <Table size="small" border :columns="resourceColumns" :data="resourceData">-->
    <!--          <template slot="timeRange" slot-scope="{row}">-->
    <!--            {{ fecha.format(new Date(row.startTimeMs), 'YYYY-MM-DD HH:mm:ss') }} - -->
    <!--            {{ fecha.format(new Date(row.endTimeMs), 'YYYY-MM-DD HH:mm:ss') }}-->
    <!--          </template>-->
    <!--        </Table>-->
    <!--        <p style="margin-top: 20px">*如需增加资源或延长授权时间，请点击重新激活获取License。</p>-->
    <!--        <div slot="footer" class="modal-footer" style="margin-top: 20px">-->
    <!--          <pd-button type="primary" @click="handleShowActiveLicense">重新激活</pd-button>-->
    <!--          <pd-button @click="handleCancel">关闭</pd-button>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </Modal>-->
    <Modal v-model="showResourceInfo"
           title="私有版部署详情"
           width="1000px"
           footer-hide @on-cancel="handleCloseModal">
      <div class="renew-license-modal">
        <Table size="small" border :columns="resourceColumns" :data="resourceData" style="margin-bottom: 25px;">
          <template slot="authedTaskCount" slot-scope="{row}">
            <span style="color: #0052D9">{{ row.authedTaskCount }}</span>
          </template>
          <template slot="authedWorkerCount" slot-scope="{row}">
            <span style="color: #0052D9">{{ row.authedWorkerCount }}</span>
          </template>
          <template slot="timeRange" slot-scope="{row}">
            {{ fecha.format(new Date(row.startTimeMs), 'YYYY-MM-DD HH:mm:ss') }} -
            {{ fecha.format(new Date(row.endTimeMs), 'YYYY-MM-DD HH:mm:ss') }}
            <span style="margin-left: 20px">
              剩余可用时常长
              <strong style="background: #FF8813;border-radius: 2px;color: #fff;padding: 1px 3px;">{{
                  Math.floor(store.state.licenseStatus.remainingTimeMs / 1000 / 60 / 60 / 24)
                }}</strong>天
              <strong style="background: #FF8813;border-radius: 2px;color: #fff;padding: 1px 3px;">{{
                  Math.floor(store.state.licenseStatus.remainingTimeMs / 1000 / 60 / 60 % 24)
                }}</strong>小时
            </span>
          </template>
        </Table>
        <div class="section" style="margin-bottom: 15px;">
          <div class="title">复制申请码</div>
          <div class="sub-title">
            复制下面的申请码，将该申请码填入对应的地方
            <div style="color:#0052D9;" @click="handleCopyApplyCode">
              <img src="@asset/download.svg"/>
              复制
            </div>
          </div>
          <div class="apply-code">
            {{ applyCode }}
          </div>
        </div>
        <div class="section" style="margin-bottom: 16px;" v-if="renewLicense">
          <div class="title">输入许可证</div>
          <div class="sub-title">将官网授权页面生成的许可证填写到下面输入框，点击激活完成激活</div>
          <div class="license-code">
            <Input v-model="licenseData.authCode" type="textarea"
                   placeholder="请输入许可证"/>
          </div>
        </div>
        <div class="section operation">
          <div class="left">
            激活遇到问题？请
            <Poptip placement="right">
              <span style="color: #075DDF;cursor:pointer;">联系客服</span>
              <div slot="content">
                <img src="@asset/wechat.png" width="200" height="200">
              </div>
            </Poptip>
          </div>
          <div class="right">
            <pd-button type="primary" v-if="!renewLicense" @click="handleRenewLicense">重新激活</pd-button>
            <pd-button type="primary" v-else @click="handleActiveLicense">立即激活</pd-button>
          </div>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showRemind"
      title="许可证到期提醒"
      footer-hide
      width="500"
    >
      <div>
        <div style="text-align: center;font-weight: 400;font-size: 14px;line-height: 20px;color: #667085;">
          您的许可证将于
          <strong style="color: #FF360C">{{store.state.licenseStatus.endTime ? fecha.format(new Date(store.state.licenseStatus.endTime), 'YYYY-MM-DD HH:mm:ss') : '' }}</strong>到期，
          还剩
          <strong style="color: #FF360C">{{Math.floor(store.state.licenseStatus.remainingTimeMs / 1000 / 60 / 60 / 24) }}</strong>
          天
          <strong style="color: #FF360C">{{Math.floor(store.state.licenseStatus.remainingTimeMs / 1000 / 60 / 60 % 24) }}</strong>
          小时。
          许可证时间到期后，CloudCanal控制台将无法执行正常运维操作，<strong style="color: #FF360C">正在运行的任务也会被全部停止</strong>，请注意！！！
        </div>
<!--        <p style="margin-top: 10px">-->
<!--          <Checkbox v-model="confirmRemind">已确认，不再提示</Checkbox>-->
<!--        </p>-->
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button @click="handleCancel">关闭</pd-button>
        </div>
      </div>
    </Modal>
    <Modal v-model="showInactiveReason" width="916" title="许可证申请" footer-hide>
      <div class="apply-license-modal">
          <div class="inactive-reason" v-if="inactiveReason" style="font-size:18px;margin-bottom: 20px;margin-left: 40px;">
            <span class="title" style="font-weight: bold">未激活原因：</span>
            <span class="title" style="font-weight: bold;color: red">{{inactiveReason}}</span>
          </div>
        <div class="body">
          <div class="steps">
            <div class="circle">1</div>
            <div class="line1"></div>
            <div class="circle">2</div>
            <div class="line2"></div>
            <div class="circle">3</div>
          </div>
          <div class="content">
            <div class="section" style="margin-bottom: 28px">
              <div class="title">私有部署版申请</div>
              <div class="sub-title" style="display: inline-block;">
                私有部署版请到<a href="https://www.clougence.com/" target="_blank">Clougence官网</a>直接点击获取许可证，商业版请联系CloudCanal工作人员获取许可证
              </div>
            </div>
            <div class="section" style="margin-bottom: 15px;">
              <div class="title">复制申请码</div>
              <div class="sub-title">
                复制下面的申请码，将该申请码填入对应的地方
                <div style="color:#0052D9;" @click="handleCopyApplyCode">
                  <img src="@asset/download.svg"/>
                  复制
                </div>
              </div>
              <div class="apply-code">
                {{ applyCode }}
              </div>
            </div>
            <div class="section">
              <div class="title">输入许可证</div>
              <div class="sub-title">将官网授权页面生成的许可证填写到下面输入框，点击激活完成激活
              </div>
              <div class="license-code">
                <Input v-model="licenseData.authCode" type="textarea" style="width: 825px;"
                       placeholder="请输入许可证"/>
              </div>
            </div>
            <div class="section tip">
              <div>1. 从未进行过许可证申请和激活</div>
              <div>2. 容量超过许可证限定的额度(删除多余的任务后可自行重新激活)</div>
              <div>3. 许可证超过时长，社区版可以在官网重新申请社区版许可证激活，延长使用时间</div>
              <div class="link">
                <a href="https://www.clougence.com/cc-doc/license/license_use" target="_blank">激活手册</a>
              </div>
            </div>
            <div class="section" style="display: flex;justify-content: space-between;align-items: center;">
              <div class="left">
                激活遇到问题？请
                <Poptip placement="right">
                  <span style="color: #075DDF;cursor:pointer;">联系客服</span>
                  <div slot="content">
                    <img src="@asset/wechat.png" width="200" height="200">
                  </div>
                </Poptip>
              </div>
              <div class="right">
                <pd-button type="primary" @click="handleActiveLicense" style="margin-right: 20px;">激活</pd-button>
                <pd-button @click="handleCancel">取消</pd-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import store from '@store';
import { listcatalog } from '@services/api/authority';
import { logout } from '@services/api/auth';
import { invalidStsToken } from '@services/api/stToken';
import { getGlobalSystemConfig } from '@services/api/constant';
import {
  activateLicense,
  queryValidity,
  offRemind,
  getApplyCode,
  getConsoleMac,
  getInactiveResult,
  getAuthedResourceInfo,
  getCurAuthCode
} from '@services/api/authCode';
import fecha from 'fecha';
import { handleCopy } from '@components/function/dataJob/createJob/util';
import { EDITIONS_I18N } from '@consts';
import StToken from './ApplyStToken';

export default {
  components: {
    StToken
  },
  data() {
    return {
      renewLicense: false,
      EDITIONS_I18N,
      store,
      username: '',
      phone: '',
      activeName: '/',
      openName: ['data'],
      menuList: [],
      showActiveLicense: false,
      confirmRemind: false,
      showRemind: false,
      showInactiveReason: false,
      showResourceInfo: false,
      inactiveReason: '',
      resourceColumns: [
        {
          title: '已授权任务数',
          slot: 'authedTaskCount',
          width: 120
        },
        {
          title: '已授权机器数',
          slot: 'authedWorkerCount',
          width: 120
        },
        {
          title: '已授权时间',
          key: '',
          slot: 'timeRange'
        }
      ],
      resourceData: [],
      resourceInfo: '',
      licenseData: {},
      applyCode: '',
      macAddress: '',
      cueAuthCode: '',
      fecha
    };
  },
  created() {
    window.$bus.on('showActiveLicenseModal', this.handleshowInactiveReason);
  },
  destroyed() {
    window.$bus.off('showActiveLicenseModal', this.handleshowInactiveReason);
  },
  mounted() {
    this.getUserInfo();
    listcatalog()
      .then((res) => {
        if (res.data.code === '1') {
          this.menuList = res.data.data;
          const { path } = this.$route;
          this.activeName = this.$route.path;
          if (path) {
            if (path.includes('/data/job/list')) {
              this.activeName = '/data/job/list';
            }
            if (path.includes('/data/dataSource')) {
              this.activeName = '/data/dataSource';
            }
            if (path.includes('/system/resource')) {
              this.activeName = '/system/resource';
            }
          }
          if (this.$route.path === '/') {
            this.activeName = '/';
            // this.openName = 'data';
          }
          const that = this;

          this.$nextTick(() => {
            that.$refs.menu.updateActiveName();
            that.$refs.menu.updateOpened();
          });
        }
      });
    getGlobalSystemConfig()
      .then((res2) => {
        if (res2.data.code === '1') {
          this.globalSystemConfig = res2.data.data;
          this.$store.commit('getGlobalConfig', res2.data.data);
          if (!store.state.globalConfig.saas) {
            queryValidity()
              .then((res) => {
                if (res.data.code === '1') {
                  this.$store.commit('getLicenseStatus', res.data.data);
                  this.showRemind = !!store.state.licenseStatus.needRemind;
                  // this.showRemind = true;
                }
              });
          }
        }
      });
  },
  methods: {
    handleRenewLicense() {
      this.renewLicense = true;
    },
    handleCopyApplyCode() {
      handleCopy(this.applyCode);
      this.$Message.success('复制成功！');
    },
    getUserInfo() {
      if (localStorage.getItem('userInfo')) {
        this.username = JSON.parse(localStorage.getItem('userInfo')).username;
        this.phone = JSON.parse(localStorage.getItem('userInfo')).phone;
      }
    },
    handleUserCenter() {
      this.$router.push({ path: '/system/userCenter' });
    },
    handleGoUrl(url) {
      this.$router.push({ path: url });
    },
    handleLogout() {
      logout()
        .then((res) => {
          if (res.data.code !== '1') {
            this.$Modal.error({
              title: 'error',
              content: '登出错误'
            });
          } else {
            this.$router.push({ path: '/login' });
          }
        });
    },
    handleRefreshStToken() {
      this.$refs.stToken.handleShowAkSk();
    },
    handleCleanStToken() {
      invalidStsToken()
        .then((res) => {
          if (res.data.code === '1') {
            this.$Message.success('操作成功！');
          }
        });
    },
    handleHome() {
      window.location.href = '/data/job/list';
    },
    handleShowActiveLicense() {
      this.licenseData = {};
      // this.showActiveLicense = true;
      // this.showInactiveReason = false;
      // this.showResourceInfo = false;
      this.applyCode = '';
      getApplyCode()
        .then((res) => {
          if (res.data.code === '1') {
            this.applyCode = res.data.data;
          }
        });
      getConsoleMac()
        .then((res) => {
          if (res.data.code === '1') {
            this.macAddress = res.data.data;
          }
        });
      getCurAuthCode()
        .then((res) => {
          if (res.data.code === '1') {
            this.cueAuthCode = res.data.data;
          }
        });
    },
    handleActiveLicense() {
      activateLicense({
        authCode: this.licenseData.authCode.split('CloudCanal')[0],
        secondAuthCode: this.licenseData.authCode.split('CloudCanal')[1]
      })
        .then((res) => {
          console.log('res', res);
          if (res.data.code === '1') {
            this.$Message.success('激活成功！');
            this.handleCloseModal();
            queryValidity()
              .then((res1) => {
                if (res1.data.code === '1') {
                  console.log('data', res1.data);
                  this.$store.commit('getLicenseStatus', res1.data.data);
                }
              });
          }
        });
    },
    handleCloseModal() {
      this.showActiveLicense = false;
      this.showRemind = false;
      this.showInactiveReason = false;
      this.showResourceInfo = false;
      this.renewLicense = false;
    },
    handleCancel() {
      this.handleCloseModal();
      if (this.confirmRemind) {
        offRemind()
          .then((res) => {
            if (res.data.code === '1') {
              queryValidity()
                .then((res1) => {
                  if (res1.data.code === '1') {
                    console.log('data', res1.data);
                    this.$store.commit('getLicenseStatus', res1.data.data);
                  }
                });
            }
          });
      }
    },
    handleshowInactiveReason() {
      getInactiveResult()
        .then((res) => {
          console.log('res', res);
          this.inactiveReason = res.data.data.msg;
          this.showInactiveReason = true;
        });

      this.handleShowActiveLicense();
    },
    handleShowResourceInfo() {
      getAuthedResourceInfo()
        .then((res) => {
          console.log('res', res);
          this.resourceData = [];
          this.resourceData.push(res.data.data);
          this.resourceInfo = res.data.data;
          this.showResourceInfo = true;
        });

      this.handleShowActiveLicense();
    },
    handleCopyData(data) {
      handleCopy(data);
      this.$Message.success('复制成功！');
    }
  },
  watch: {
    menuList() {
      this.getUserInfo();
    }
  }
};
</script>

<style lang="less" scoped>
.renew-license-modal {
  padding: 14px;

  ::v-deep textarea {
    height: 80px;
    width: 100%;
  }

  .operation {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .section {
    .title {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: rgba(0, 0, 0, .9);
      margin-bottom: 8px;
    }

    .sub-title {
      color: rgba(0, 0, 0, .6);
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        img {
          margin-right: 4px;
        }
      }
    }

    .apply-code {
      width: 100%;
      border: 1px solid #DFDFDF;
      word-wrap: break-word;
      padding: 10px;
    }
  }
}

.apply-license-modal {
  width: 100%;
  padding: 14px;

  .body {
    display: flex;

    ::v-deep textarea {
      height: 80px;
    }

    .steps {
      margin-right: 16px;

      .circle {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        border: 1px solid #0052D9;
        font-size: 16px;
        text-align: center;
        line-height: 25px;
        color: #0052D9;
      }

      .line1,
      .line2 {
        width: 1px;
        background: #0052D9;
        margin: 12px;
      }

      .line1 {
        height: 28px;
      }

      .line2 {
        height: 100px;
      }
    }

    .content {
      .tip {
        color: #666666;
        margin-top: 24px;
        width: 825px;
        padding: 15px;
        background: #FAFAFA;
        line-height: 18px;
        margin-bottom: 30px;

        .link {
          color: #075DDF;
          margin-top: 10px;
        }
      }

      .section {
        .title {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: rgba(0, 0, 0, .9);
          margin-bottom: 8px;
        }

        .sub-title {
          color: rgba(0, 0, 0, .6);
          margin-bottom: 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            img {
              margin-right: 4px;
            }
          }
        }

        .apply-code {
          width: 825px;
          border: 1px solid #DFDFDF;
          word-wrap: break-word;
          padding: 10px;
        }
      }
    }
  }

}

.layout {
  /*overflow: hidden;*/
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  border: none;
}

.layout-logo {
  width: 180px;
  height: 48px;
  background-image: url('~@asset/logo-BG.png');
  float: left;
  position: relative;
  color: #ffffff;
  padding-left: 20px;
  cursor: pointer;

  .iconfont {
    font-size: 22px;
  }
}

.layout-nav {
  width: 820px;
  /*margin: 0 auto;*/
  /*margin-right: 20px;*/
}

.layout-footer-center {
  text-align: center;
}

.app-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1001;
  min-height: 48px;

  .iconfont {
    margin-right: 5px;
  }

  .icon-database {
    font-size: 12px;
  }

  .icon-aliyun {
    font-size: 14px;
  }
}

.navbar-brand {
  float: none;
  height: auto;
  padding: 0 20px;
  font-size: 16px;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
  color: #ffffff;
  display: flex;
}

.navbar-brand-mini {
  display: none;
  float: none;
  height: auto;
  padding: 0 25px;
  font-size: 20px;
  font-weight: 700;
  line-height: 64px;
  text-align: center;
  color: #7793a7;
}

.navbar-header {
  position: fixed;
  width: 200px;
  float: left;
  box-shadow: 0 1px 4px rgba(0, 21, 41, .08);
  /*background-color: #10121A;*/
  text-align: center;
  height: 48px;
}

.navbar-collapse {
  /*background-color: #10121A;*/
  padding-right: 15px;
  position: relative;
  height: auto;
  overflow: visible;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(0, 0, 0, 0.05);
  float: right;
  /*top: 15px;*/
  /*right: 20px;*/
  /*background-image: url("../../assets/logo-BG.png");*/
}

.navbar-switch {
  float: left;

  .navbar-btn {
    display: block;
    line-height: 48px;
    color: #58666e;
    font-size: 12px;
  }
}

.navbar-form {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
  float: left;

  input {
    height: 30px;
    padding: 5px 10px;
    padding-right: 15px;
    padding-left: 15px;
    font-size: 12px;
    line-height: 1.5;
    border-color: transparent;
    border-width: 0;
    color: #58666e;
    background-color: #edf1f2;
    display: inline-block;
    vertical-align: middle;
  }
}

.menu-icon {
  transition: all .3s;
}

.rotate-icon {
  transform: rotate(-90deg) translate(12px, -80px);
}

.dropdown-arrow {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
  -webkit-transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
}

.dropdown-arrow-a:hover .dropdown-arrow {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
}

.ivu-select-dropdown {
  padding: 0 !important;
}

.ivu-dropdown-item {
  padding: 0 !important;
}

.header-menu-item {
  padding: 12px 20px;
}

.navbar-nav-nb {
  a {
    font-size: 14px;
  }

  .iconfont {
    font-size: 20px;
  }
}

.navbar-nav-nb {
  a {
    color: #ffffff;
    opacity: 0.85;

    &:hover {
      opacity: 1;
      color: #ffffff;
    }
  }

  .license-status-span {
    margin-left: 10px;
  }

  float: right;
  position: relative;

  display: block;
  height: 48px;
  line-height: 48px;
  color: #ffffff;
  font-size: 12px;
  text-decoration: none;
  margin-left: 10px;

  &:hover {
    /*background-color: #f8f8f9;*/
    cursor: pointer;
  }

  .menu-dropdown {
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 2px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, .1);
    position: absolute;
    width: 200px;
    background: #fff;
    right: 0;

    .info {
      border-bottom: 1px solid #dee5e7;
      color: #58666e;
      background-color: #edf1f2;
      padding: 15px;
      margin-bottom: 10px;
    }

    .divider {
      height: 1px;
      margin: 9px 0;
      overflow: hidden;
      background-color: #e5e5e5;
    }

    a {
      height: 30px;
      line-height: 30px;
      display: block;
      padding: 0 10px;
      color: #58666e;

      &:hover {
        background-color: #edf1f2;
      }
    }
  }
}
</style>
