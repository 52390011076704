<template>
  <Modal
    v-model="visible"
    title="任务告警配置"
    footer-hide
    @on-cancel="handleCloseModal"
  >
    <div style="font-size: 14px;margin-bottom: 10px">
      告警人员:
      <div style="width: 100%;border: 1px solid #ccc;border-radius: 3px;padding: 5px;margin-top: 5px;background: #eee">
        {{ Object.values(exceptionConfig.alertReceivers).length ? Object.values(exceptionConfig.alertReceivers).join(',') : '无' }}
      </div>
    </div>
    <div>
      <div style="font-size: 14px;margin-bottom: 10px">
        异常告警
        <Tooltip placement="right-start">
          <Icon type="ios-help-circle" style="font-size: 14px"/>
          <div slot="content">
            <P>当实例出现异常时，系统会发送报警。</P>
          </div>
        </Tooltip>
      </div>
      <Form label-position="right" :model="selectedRule" :label-width="90">
        <div style="display: flex;">
          <FormItem label="告警方式：">
            <CheckboxGroup v-model="taskException.methods">
              <Checkbox label="sms">短信</Checkbox>
              <Checkbox label="dingding">IM</Checkbox>
            </CheckboxGroup>
          </FormItem>
          <FormItem label="告警等级：">
            <Select v-model="taskException.level" style="width: 100px;" size="small">
              <Option v-for="level in alarmLevels" :value="level.alarmLevel" :key="level.alarmLevel">{{level.i18nName}}</Option>
            </Select>
          </FormItem>
        </div>
        <FormItem label="特殊配置：">
          <Checkbox label="phone" v-model="taskException.sendAdmin">发送给管理员</Checkbox>
          <Tooltip placement="right-start">
            <Icon type="ios-help-circle" style="font-size: 14px"/>
            <div slot="content">
              <P>勾选此配置，当用户收到报警时，该报警会以同样的方式发送给所有管理员。</P>
            </div>
          </Tooltip>
        </FormItem>
      </Form>
      <div style="font-size: 14px;margin-bottom: 10px">
        延迟告警
        <Tooltip placement="right-start">
          <Icon type="ios-help-circle" style="font-size: 14px"/>
          <div slot="content">
            <P>当同步任务出现延迟时，系统会发送告警。</P>
          </div>
        </Tooltip>
      </div>
      <Form label-position="right" :model="selectedRule" :label-width="90">
        <FormItem label="指标：">
          <span
            style="width: 240px;margin-right: 5px">{{
              MappingUtil.alarmIndicator[addRule.indicators[0].indicator]
            }}</span>
          <span style="width: 70px;margin-right: 5px">{{ addRule.indicators[0].symbol }}</span>
          <Input v-model="addRule.indicators[0].value" style="width: 100px;margin-right: 5px" size="small"/>
          <span>（单位：分钟）</span>
          <Tooltip placement="right-start">
            <Icon type="ios-help-circle" style="font-size: 14px"/>
            <div slot="content">
              <P>最小1分钟</P>
            </div>
          </Tooltip>
        </FormItem>
        <div style="display: flex;">
          <FormItem label="告警方式：">
            <CheckboxGroup v-model="addRule.methods">
              <Checkbox label="sms">短信</Checkbox>
              <Checkbox label="dingding">IM</Checkbox>
            </CheckboxGroup>
          </FormItem>
          <FormItem label="告警等级：">
            <Select v-model="addRule.level" style="width: 100px;" size="small">
              <Option v-for="level in alarmLevels" :value="level.alarmLevel" :key="level.alarmLevel">{{level.i18nName}}</Option>
            </Select>
          </FormItem>
        </div>
        <FormItem label="特殊配置：">
          <Checkbox label="phone" v-model="addRule.sendAdmin">发送给管理员</Checkbox>
          <Tooltip placement="right-start">
            <Icon type="ios-help-circle" style="font-size: 14px"/>
            <div slot="content">
              <P>勾选此配置，当用户收到报警时，该报警会以同样的方式发送给所有管理员。</P>
            </div>
          </Tooltip>
        </FormItem>
      </Form>
      <div style="font-size: 14px;margin-bottom: 10px">
        事件告警
        <Tooltip placement="right-start">
          <Icon type="ios-help-circle" style="font-size: 14px"/>
          <div slot="content">
            <P>当主备切换等事件时，系统会发送报警。</P>
          </div>
        </Tooltip>
      </div>
      <Form label-position="right" :model="selectedRule" :label-width="90">
        <div style="display: flex">
          <FormItem label="告警方式：">
            <CheckboxGroup v-model="eventException.methods">
              <Checkbox label="sms">短信</Checkbox>
              <Checkbox label="dingding">IM</Checkbox>
            </CheckboxGroup>
          </FormItem>
          <FormItem label="告警等级：">
            <Select v-model="eventException.level" style="width: 100px;" size="small">
              <Option v-for="level in alarmLevels" :value="level.alarmLevel" :key="level.alarmLevel">{{level.i18nName}}</Option>
            </Select>
          </FormItem>
        </div>
        <FormItem label="特殊配置：">
          <Checkbox label="phone" v-model="eventException.sendAdmin">发送给管理员</Checkbox>
          <Tooltip placement="right-start">
            <Icon type="ios-help-circle" style="font-size: 14px"/>
            <div slot="content">
              <P>勾选此配置，当用户收到报警时，该报警会以同样的方式发送给所有管理员。</P>
            </div>
          </Tooltip>
        </FormItem>
      </Form>
      <div slot="footer" class="modal-footer" style="margin-top: 20px">
        <pd-button v-if="exceptionConfig.alertReceivers[uid]" @click="handleAddAlarmReceiver('REMOVE')">取消关注</pd-button>
        <pd-button v-else @click="handleAddAlarmReceiver('ADD')">关注告警</pd-button>
        <pd-button type="primary" @click="handleEditRule">确认</pd-button>
        <pd-button @click="handleCloseModal">取消</pd-button>
      </div>
    </div>
  </Modal>
</template>

<script>
import {
  listAlertAlarmLevel,
  listAlertConfig,
  listAlertIndicators, modifyJobAlertReceiver,
  updateAlertConfigs
} from '@services/api/alert';
import MappingUtil from '../../util';

export default {
  name: 'AlarmRuleModal',
  props: {
    visible: Boolean,
    handleCloseModal: Function
  },
  data() {
    return {
      uid: '',
      jobId: 0,
      MappingUtil,
      selectedRule: {
        id: 0,
        ruleName: '',
        expression: '',
        phone: false,
        email: false,
        dingding: false,
        sms: false,
        duplicated: false,
        sendAdmin: false,
        sendSystem: false
      },
      taskException: {
        methods: [],
        sendAdmin: false,
        express: '',
        level: ''
      },
      eventException: {
        methods: [],
        sendAdmin: false,
        express: '',
        level: ''
      },
      alarmLevels: [],
      indicators: [],
      addRule: {
        indicators: [{
          indicator: '',
          value: '',
          symbol: ''
        }],
        methods: ['sms', 'dingding'],
        sendAdmin: false,
        level: ''
      },
      exceptionConfig: {
        alertReceivers: {}
      },
      eventExceptionConfig: {}
    };
  },
  created() {
    this.jobId = Number(this.$route.params.id);
    this.handleAlarmConfig();
    this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      if (localStorage.getItem('userInfo')) {
        this.uid = JSON.parse(localStorage.getItem('userInfo')).uid;
      }
    },
    verifyExpression(expression) {
      const expressionForm = [];

      if (expression.indexOf('>=') > -1) {
        expressionForm[0] = expression.split('>=')[0];
        expressionForm[1] = '>=';
        expressionForm[2] = expression.split('>=')[1];
      } else if (expression.indexOf('<=') > -1) {
        expressionForm[0] = expression.split('<=')[0];
        expressionForm[1] = '<=';
        expressionForm[2] = expression.split('<=')[1];
      } else if (expression.indexOf('!=') > -1) {
        expressionForm[0] = expression.split('!=')[0];
        expressionForm[1] = '!=';
        expressionForm[2] = expression.split('!=')[1];
      } else if (expression.indexOf('=') > -1) {
        expressionForm[0] = expression.split('=')[0];
        expressionForm[1] = '=';
        expressionForm[2] = expression.split('=')[1];
      } else if (expression.indexOf('>') > -1) {
        expressionForm[0] = expression.split('>')[0];
        expressionForm[1] = '>';
        expressionForm[2] = expression.split('>')[1];
      } else if (expression.indexOf('<') > -1) {
        expressionForm[0] = expression.split('<')[0];
        expressionForm[1] = '<';
        expressionForm[2] = expression.split('<')[1];
      }

      return expressionForm;
    },
    handleAlarmConfig() {
      listAlertAlarmLevel().then((res) => {
        if (res.data.success) {
          this.alarmLevels = res.data.data;
        }
      });
      listAlertIndicators().then((res) => {
        if (res.data.code === '1') {
          this.indicators = res.data.data;
        }
      });
      listAlertConfig({ dataJobId: this.jobId }).then((res) => {
        if (res.data.code === '1') {
          this.selectedRule = res.data.data[0];
          const expressionForm = this.verifyExpression(this.selectedRule.expression);

          this.addRule.indicators[0] = {
            indicator: expressionForm[0],
            value: expressionForm[2].split('*')[0],
            symbol: expressionForm[1]
          };
          this.addRule.sendAdmin = this.selectedRule.sendAdmin;
          this.addRule.level = this.selectedRule.alarmLevel;
          this.addRule.methods = [];
          if (this.selectedRule.dingding) {
            this.addRule.methods.push('dingding');
          }
          if (this.selectedRule.sms) {
            this.addRule.methods.push('sms');
          }
          this.taskException.methods = [];
          this.exceptionConfig = res.data.data[1];
          if (res.data.data[1].dingding) {
            this.taskException.methods.push('dingding');
          }
          if (res.data.data[1].sms) {
            this.taskException.methods.push('sms');
          }
          this.taskException.express = res.data.data[1].expression;
          this.taskException.sendAdmin = res.data.data[1].sendAdmin;
          this.taskException.level = res.data.data[1].alarmLevel;

          this.eventException.methods = [];
          this.eventExceptionConfig = res.data.data[2];
          if (res.data.data[2].dingding) {
            this.eventException.methods.push('dingding');
          }
          if (res.data.data[2].sms) {
            this.eventException.methods.push('sms');
          }
          this.eventException.express = res.data.data[2].expression;
          this.eventException.sendAdmin = res.data.data[2].sendAdmin;
          this.eventException.level = res.data.data[2].alarmLevel;
        }
      });
    },
    handleEditRule() {
      this.showEditAlarmRule = false;
      if (this.addRule.indicators[0].value < 1) {
        this.$Modal.warning({
          title: '告警配置失败',
          content: '延迟时间最小设置1分钟'
        });
      } else {
        const data1 = {};
        const data2 = {};
        const data3 = {};
        const alertConfigVOList = [];

        data1.expression = this.addRule.indicators[0].indicator + this.addRule.indicators[0].symbol + this.addRule.indicators[0].value;
        data1.phone = this.addRule.methods.indexOf('phone') > -1;
        data1.email = this.addRule.methods.indexOf('email') > -1;
        data1.sms = this.addRule.methods.indexOf('sms') > -1;
        data1.dingding = this.addRule.methods.indexOf('dingding') > -1;
        data1.sendAdmin = this.addRule.sendAdmin;
        data1.alarmLevel = this.addRule.level;
        data1.id = this.selectedRule.id;
        alertConfigVOList.push(data1);

        data2.id = this.exceptionConfig.id;
        data2.sendAdmin = this.taskException.sendAdmin;
        data2.dingding = this.taskException.methods.indexOf('dingding') > -1;
        data2.sms = this.taskException.methods.indexOf('sms') > -1;
        data2.phone = this.taskException.methods.indexOf('phone') > -1;
        data2.email = this.taskException.methods.indexOf('email') > -1;
        data2.alarmLevel = this.taskException.level;
        data2.expression = this.taskException.express;
        alertConfigVOList.push(data2);

        data3.id = this.eventExceptionConfig.id;
        data3.sendAdmin = this.eventException.sendAdmin;
        data3.dingding = this.eventException.methods.indexOf('dingding') > -1;
        data3.sms = this.eventException.methods.indexOf('sms') > -1;
        data3.phone = this.eventException.methods.indexOf('phone') > -1;
        data3.email = this.eventException.methods.indexOf('email') > -1;
        data3.alarmLevel = this.eventException.level;
        data3.expression = this.eventException.express;
        alertConfigVOList.push(data3);
        updateAlertConfigs(alertConfigVOList)
          .then((res) => {
            if (res.data.code === '1') {
              this.$Message.success('修改告警配置成功');
              this.handleCloseModal();
            }
          });
      }
    },
    async handleAddAlarmReceiver(type) {
      const res = await modifyJobAlertReceiver({ dataJobId: this.jobId, operation: type });
      if (res.data.success) {
        this.$Message.success(type === 'REMOVE' ? '取消关注成功' : '关注成功');
        this.handleAlarmConfig();
      }
    }
  }
};
</script>
