<template>
  <div class="job-detail-state-container">
    <div>
      <div class="job-detail-state-progress">
        <p class="job-detail-state-progress-title"><span class="status-point"></span>数据订正
          <a @click="handleShowLog(task.dataTaskId)">查看日志</a>
        </p>
        <div class="job-detail-state-count">
          <div class="circle-background"></div>
          <i-circle :stroke-width="5" :stroke-color="getCurrentStateColor('REVISE')"
                    :percent="parseInt(parseFloat(reviseProgress.revisePosDetailObj.percentage) * 100, 10)" :size="200">
            <span class="detail-Circle-inner"
                  style="font-size:50px">{{ parseInt(parseFloat(reviseProgress.revisePosDetailObj.percentage) * 100, 10) }}%</span>
          </i-circle>
          <p style="margin-top: 32px">
            已订正
            <span style="font-weight: 600">
              {{ formatCount(reviseProgress.revisePosDetailObj.revisedDiffCount + reviseProgress.revisePosDetailObj.revisedLossCount) }}
            </span>
            行
          </p>
        </div>
      </div>
      <div class="job-detail-state-detail">
        <p class="job-detail-state-progress-title"><span class="status-point"></span>订正详情</p>
        <div class="job-detail-position">
          <div>
            校验文件md5值：{{reviseProgress.revisePosDetailObj.md5}}
          </div>
          <div>
            当前订正扫描的校验文件行号：{{reviseProgress.revisePosDetailObj.lineNumber}}
          </div>
          <div>
            已订正的不一致数据：{{reviseProgress.revisePosDetailObj.revisedDiffCount}}
          </div>
          <div>
            已订正的丢失数据(相对于源端)：{{reviseProgress.revisePosDetailObj.revisedLossCount}}
          </div>
          <div>
            总共需要订正的行数：{{reviseProgress.revisePosDetailObj.totalReviseRows}}
          </div>
        </div>
      </div>
    </div>
    <div class="job-detail-metric">
      <p class="job-detail-state-progress-title"><span class="status-point"></span>订正速率
        <a @click="handleMoreMonitor(taskList['REVISE'].dataTaskId,'REVISE')">更多</a></p>
      <div style="margin-top:20px">
        <div class="panel">
          <div class="panel-header">
            <div style="float: right">
            </div>

            {{ metric.title }}
          </div>
          <div class="panel-body">
            <div style="height: 200px">
              <Metric ref="metric4" :model="metric" :time="time" :resourceType="resourceType"
                      :dataJobId="jobId.toString()"
                      :filters="filters" :selected="metric.selected"
                      :dataTaskId="taskList['REVISE'].dataTaskId.toString()"
                      :filterOthers="filterOthers"></Metric>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { formatCount } from '../../../util';
import Metric from '../../monitor/metric/metric';

export default {
  components: { Metric },
  props: {
    getCurrentStateColor: Function,
    reviseProgress: Object,
    handleFilterFullTable: Function,
    showFullTransData: Array,
    getProgressStatus: Function,
    taskList: Object,
    fullTotal: Number,
    fullSize: Number,
    handleFullPageChange: Function,
    handleMoreMonitor: Function,
    resourceType: String,
    filters: Object,
    filterOthers: Object,
    metric: Object,
    time: Object,
    jobId: Number,
    filterJobStatus: Array,
    filterTableName: String,
    handleShowLog: Function,
    task: Object
  },
  data() {
    return {
      theFilterJobStatus: this.filterJobStatus,
      theFilterTableName: this.filterTableName,
      formatCount,
      fullTransColumns: [
        {
          title: '数据库',
          key: 'schema',
          minWidth: 150
        },
        {
          title: '表名',
          key: 'table',
          minWidth: 220
        },
        {
          title: ' ',
          slot: 'progress',
          width: 160
        },
        {
          title: ' ',
          slot: 'progress',
          width: 200,
          render: (h, params) => h('div', {}, `已迁移${formatCount(params.row.trans)}行`)
        }
      ]
    };
  },
  methods: {
    handleRender() {
      const that = this;

      setTimeout(() => {
        console.log('12312312');
        if (that.$refs.metric4) {
          that.$refs.metric4.$emit('render');
        }
      }, 100);
    }
  },
  watch: {
    filterJobStatus(val) {
      this.theFilterJobStatus = val;
    },
    filterTableName(val) {
      this.theFilterTableName = val;
    }
  }
};
</script>
