`
<template>
  <div>
    <div style="padding-bottom: 74px">
      <Breadcrumb style="margin-bottom: 14px">
        <BreadcrumbItem to="/system/userCenter">个人中心</BreadcrumbItem>
        <BreadcrumbItem>兑换记录</BreadcrumbItem>
      </Breadcrumb>
      <div class="page-header-container">
        <Form label-position="left" :label-width="80" inline>
          <FormItem label="兑换时间：">
            <DatePicker :editable="false" v-model="searchInfo.dataRange" :options="dateRangeOptions"
                        type="datetimerange" format="yyyy-MM-dd HH:mm" placeholder="请选择开始时间和结束时间"
                        style="width: 250px"></DatePicker>
            <pd-button style="margin-left: 6px" type="primary" @click="handleSearch">查询</pd-button>
          </FormItem>
        </Form>
        <div class="page-header-function">
          <pd-button type="default" style="margin-right: 6px" @click="handleSearch"
                     :loading="refreshLoading">
            刷新
          </pd-button>
        </div>
      </div>
      <Table style="margin-top: 20px" size="small" :columns="alarmColumn" :data="alarmData" border>
        <template slot="licenseContent" slot-scope="{row}">
          <p v-for="(item,index) in JSON.parse(row.content)" :key="index"
             class="license-set-detail-item">
            <span>{{ Mapping.licenseTypeDefaultMeta[item.split(':')[0]] }}</span>
            <span>{{ item.split(':')[1] }}</span>
          </p>
        </template>
      </Table>
      <div class="page-footer-container">
        <div class="page-footer-paging">
          <pd-button :disabled="page===1" style="font-size: 16px;padding: 0 16px 0 10px"
                     @click="handlePre">
            <Icon type="ios-arrow-back" style="font-size: 16px"/>
            上一页
          </pd-button>
          <span style="margin: 0 10px">第{{ page }}页</span>
          <pd-button :disabled="noMoreData"
                     style="font-size: 16px;padding: 0 16px 0 10px;margin-left: 5px"
                     @click="handleNext">
            <Icon type="ios-arrow-forward" style="font-size: 16px"/>
            下一页
          </pd-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import fecha from 'fecha';
import { listExchangeHistory } from '@services/api/userLicense';
import Mapping from '../util';

export default {
  components: {},
  mounted() {
    this.handleSearch();
  },
  data() {
    return {
      Mapping,
      refreshLoading: false,
      dateRangeOptions: {
        shortcuts: [
          {
            text: '1 hour',
            value() {
              const end = new Date();
              const start = new Date();

              start.setTime(start.getTime() - 3600 * 1000);
              return [start, end];
            }
          },
          {
            text: '1 day',
            value() {
              const end = new Date();
              const start = new Date();

              start.setTime(start.getTime() - 3600 * 1000 * 24);
              return [start, end];
            }
          },
          {
            text: '1 week',
            value() {
              const end = new Date();
              const start = new Date();

              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              return [start, end];
            }
          },
          {
            text: '1 month',
            value() {
              const end = new Date();
              const start = new Date();

              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              return [start, end];
            }
          },
          {
            text: '3 months',
            value() {
              const end = new Date();
              const start = new Date();

              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              return [start, end];
            }
          }
        ]
      },
      firstId: 0,
      lastId: 0,
      prevFirst: [],
      startId: 0,
      total: 0,
      page: 1,
      searchInfo: {
        pageSize: 20,
        dataRange: [fecha.format(new Date(new Date().getTime() - 30 * 24 * 3600 * 1000), 'YYYY-MM-DD HH:mm:ss'), fecha.format(new Date(), 'YYYY-MM-DD HH:mm:ss')],
        status: ''
      },
      noMoreData: false,
      alarmColumn: [
        {
          title: '兑换码',
          key: 'licenseKey',
          width: 600
        },
        {
          title: '套餐名称',
          key: 'licenseSetMeta',
          width: 200
        },
        {
          title: '套餐内容',
          key: 'licenseContent',
          slot: 'licenseContent'
        },
        {
          title: '兑换时间',
          key: 'exchangeDate',
          width: 200,
          sortable: true,
          render: (h, params) => h('div', {}, fecha.format(new Date(params.row.exchangeDate), 'YYYY-MM-DD HH:mm:ss'))
        }
      ],
      alarmData: []
    };
  },
  methods: {
    handleSearch(type) {
      if (type !== 'next' && type !== 'prev') {
        this.page = 1;
        this.startId = 0;
        this.firstId = 0;
        this.lastId = 0;
        this.prevFirst = [];
      }
      this.refreshLoading = true;
      listExchangeHistory({
        startId: this.startId,
        pageSize: this.searchInfo.pageSize,
        startDate: new Date(this.searchInfo.dataRange[0]),
        endDate: new Date(this.searchInfo.dataRange[1])
      })
        .then((res) => {
          this.refreshLoading = false;
          if (res.data.code === '1') {
            this.alarmData = res.data.data;
            if (type === 'next') {
              if (!this.prevFirst[this.page - 1]) {
                this.prevFirst.push(this.firstId);
              }
            }
            if (this.alarmData.length > 0) {
              this.firstId = this.alarmData[0].id;
              this.lastId = this.alarmData[this.alarmData.length - 1].id;
            }
            this.noMoreData = this.alarmData.length < this.searchInfo.pageSize;
          }
        });
    },
    handlePre() {
      this.page--;
      let startId = this.prevFirst[this.page - 1] + 1;

      if (startId < 0) {
        startId = 0;
      }
      this.startId = startId;
      this.handleSearch('prev');
    },
    handleNext() {
      this.startId = this.lastId;
      this.handleSearch('next');
      this.page++;
    },
    handleChangeSize(size) {
      this.searchInfo.pageSize = size;
      this.handleSearch();
    },
    handlePageChange(page) {
      this.searchInfo.pageNo = page;
      this.handleSearch();
    }
  }
};
</script>
<style lang="less">
.worker-monitor-count {
  text-align: center;

  .worker-monitor-count-number {
    font-size: 40px;
  }

  .worker-monitor-count-title {
    display: block;
  }

  .worker-monitor-count-error {
    color: #ed4014;
  }
}
</style>
