<template>
  <div>
    <div class="job-detail-state-container job-detail-no-metric">
      <div class="job-detail-state-progress">
        <p class="job-detail-state-progress-title"><span class="status-point"></span>结构迁移
          <a @click="handleShowLog(task.dataTaskId)">查看日志</a>
          <Tooltip placement="bottom" class="alarm-icon" transfer content="存在异常的后台任务，请点击处理"
                   v-if="jobData.consoleTaskState==='FAILED'">
            <span style="display: block" @click="handleGoConsoleJob"><i
              class="iconfont iconyibuforce"></i></span>
          </Tooltip>
        </p>
        <div class="job-detail-state-count">
          <div class="circle-background"></div>
          <i-circle :stroke-width="5" :stroke-color="getCurrentStateColor('BUILD_STRUCT')"
                    :percent="parseInt(parseFloat(JSON.parse(taskList['BUILD_STRUCT'].taskPosition).builtPercent) * 100, 10)"
                    :size="200">
            <span class="detail-Circle-inner"
                  style="font-size:50px">{{
                parseInt(parseFloat(JSON.parse(taskList['BUILD_STRUCT'].taskPosition).builtPercent) * 100, 10)
              }}%</span>
          </i-circle>
          <p style="margin-top: 32px">已创建 <span
            style="font-weight: 600">{{
              JSON.parse(taskList['BUILD_STRUCT'].taskPosition).built
            }}</span>张表
          </p>
          <p style="margin-top: 8px">共<span
            style="font-weight: 600">{{
              JSON.parse(taskList['BUILD_STRUCT'].taskPosition).totalToBuild
            }}</span>张表
          </p>
        </div>
      </div>
      <div class="job-detail-state-detail">
        <p class="job-detail-state-progress-title"><span class="status-point"></span>所有迁移库表</p>
        <Table stripe style="margin-top: 20px" :border="false" size="small"
               :columns="structColumns" :data="showStructData"></Table>
        <Page size="small" style="margin-top:20px;text-align: center" :total="structData.length"
              show-total @on-change="handleStructPageChange"/>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    task: Object,
    handleShowLog: Function,
    jobData: Object,
    getCurrentStateColor: Function,
    taskList: Object,
    showStructData: Array,
    structData: Array,
    handleStructPageChange: Function
  },
  data() {
    return {
      structColumns: [
        {
          title: '数据库',
          key: 'schema'
        },
        {
          title: '表',
          key: 'table'
        }
      ]
    };
  },
  methods: {
    handleGoConsoleJob() {
      this.$router.push({ path: `/system/state/task/${this.jobData.consoleJobId}` });
    }
  }
};
</script>
