export const permissionList = {
  blackUris: {
    jarPackage: [
      '/cloudcanal/console/api/v1/inner/jarpackage/list',
      '/cloudcanal/console/api/v1/inner/jarpackage/active',
      '/cloudcanal/console/api/v1/inner/jarpackage/delete',
      '/cloudcanal/console/api/v1/inner/jarpackage/update',
      '/cloudcanal/console/api/v1/inner/jarpackage/upload',
      '/cloudcanal/console/api/v1/inner/jarpackage/download'
    ],
    dataJob: [
      '/cloudcanal/console/api/v1/inner/datajob/replay',
      '/cloudcanal/console/api/v1/inner/datajob/detachincretask',
      '/cloudcanal/console/api/v1/inner/datajob/attachincretask'
    ]
  },
  common: []
};
