<template>
  <div>
    <!--        <h4 style="margin-top: 30px">{{DataSourceGroup.mq.includes(taskInfo.sourceType)?'选择目标库':'选择源库'}}-->
    <!--            <Tooltip content="请确认数据库已对您的账号授权" placement="right" style="font-family: PingFangSC-Regular">-->
    <!--                <a style="color: #888888;font-size: 12px;font-weight:400;margin-left: 10px">查询不到库？</a>-->
    <!--            </Tooltip>-->
    <!--        </h4>-->
    <RadioGroup v-if="!DataSourceGroup.pg.includes(taskInfo.sourceType)&&!DataSourceGroup.oracle.includes(taskInfo.sourceType)&&
          !DataSourceGroup.pg.includes(taskInfo.sinkType)&&!DataSourceGroup.oracle.includes(taskInfo.sinkType)"
                v-model="taskInfo.mappingMode"
                @on-change="handleChangeMode"
                style="margin-left: 10px;font-weight: 400;font-size: 12px;margin-top: 12px">
      <Radio label="advanced">默认模式</Radio>
      <Radio label="easy">树形模式</Radio>
    </RadioGroup>
    <div v-if="taskInfo.mappingMode === 'advanced'">
      <div v-for="(db,index) in taskInfo.dbMap" :key="index" style="margin: 20px 0">
        <FormItem prop="hasDb"
                  :label="isMQ(taskInfo.sourceType)?'选择目标库':'选择源库'">
          <div v-if="type==='source'" style="display: inline-block">
            <Select filterable :disabled="!taskInfo.sourceConnection" v-model="db.sourceDb"
                    placeholder="选择源数据库"
                    :transfer="true"
                    style="width: 280px" @on-change="handleDbChange('source',index,$event)">
              <Option :disabled="hasTheDb(item)" v-for="(item,i) in taskInfo.sourceDbList"
                      :value="item.dbName"
                      :key="i">{{ item.dbName }}
              </Option>
            </Select>
            <Select filterable :disabled="!taskInfo.sourceConnection"
                    v-if="DataSourceGroup.hasSchema.indexOf(taskInfo.sourceType)>-1"
                    v-model="db.sourceSchema"
                    placeholder="选择schema"
                    :transfer="true" style="width: 100px;margin: 0 5px">
              <Option v-for="(item,i) in getSchemaList(taskInfo.sourceDbList,db.sourceDb)"
                      :value="item" :key="i">{{ item }}
              </Option>
              <Option value="public"
                      v-if="(taskInfo.sourceInstanceType!=='ALIBABA_CLOUD_HOSTED'||
                            (taskInfo.sourceInstanceType==='ALIBABA_CLOUD_HOSTED'&&taskInfo.sourceType==='Greenplum'))&&
                            db.sourceDb&&!containsDb(taskInfo.sourceDbList,db.sourceDb)">public
              </Option>
            </Select>
          </div>
          <div v-if="type==='sink'" style="display: inline-block">
            <Select filterable :disabled="!taskInfo.sinkConnection" v-model="db.sinkDb"
                    placeholder="选择目标数据库"
                    :transfer="true"
                    style="width: 280px" @on-change="handleDbChange('sink',index,$event)">
              <Option :disabled="hasTheDb(item)" v-for="(item,i) in taskInfo.sinkDbList"
                      :value="item.dbName"
                      :key="i">{{ item.dbName }}
              </Option>
            </Select>
            <Select filterable :disabled="!taskInfo.sinkConnection"
                    v-if="DataSourceGroup.hasSchema.indexOf(taskInfo.sinkType)>-1"
                    v-model="db.targetSchema"
                    placeholder="选择schema"
                    :transfer="true" style="width: 100px;margin: 0 5px">
              <Option v-for="(item,i) in getSchemaList(taskInfo.sinkDbList,db.sinkDb)"
                      :value="item" :key="i">{{ item }}
              </Option>
              <Option value="public"
                      v-if="(taskInfo.sinkInstanceType!=='ALIBABA_CLOUD_HOSTED'||
                            (taskInfo.sinkInstanceType==='ALIBABA_CLOUD_HOSTED'&&taskInfo.sinkType==='Greenplum'))&&
                            db.sinkDb&&!containsDb(taskInfo.sinkDbList,db.sinkDb)">public
              </Option>
            </Select>
          </div>
          <Icon class="add-item-btn add-item-btn-remove" @click="handleRemoveMap(index)"
                v-if="taskInfo.dbMap.length>1&&type!=='sink'" type="ios-remove-circle-outline"/>
          <Icon class="add-item-btn add-item-btn-add" @click="handleAddMap"
                v-if="index===taskInfo.dbMap.length-1&&type!=='sink'" type="ios-add-circle-outline"/>
        </FormItem>
      </div>
    </div>
    <div v-if="taskInfo.mappingMode === 'easy'">
      <div style="margin-top: 16px">
        <Row>
          <Col :span="16">
            <Input v-model="filterkey" @on-change="handleFilterDb" suffix="ios-search"
                   placeholder="搜索库名" style="width: 100%"/>
            <div class="db-tree-container">
              <div class="select-all">
                <Checkbox @on-change="handleSelectAll">全选</Checkbox>
              </div>
              <div class="db-tree-body">
                <Tree :data="filteredData" show-checkbox
                      @on-check-change="handleChangeCheck"></Tree>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import { isMQ, isTunnel } from '@utils';
import DataSourceGroup from '../../../../../views/dataSourceGroup';

export default {
  props: {
    dbMap: Array,
    handleDbChange: Function,
    hasTheDb: Function,
    taskInfo: Object,
    getSchemaList: Function,
    store: Object,
    handleRemoveMap: Function,
    handleAddMap: Function,
    containsDb: Function,
    type: String
  },
  data() {
    return {
      DataSourceGroup,
      filterkey: '',
      showEditMapping: false,
      currentSinkDb: '',
      currentSourceDb: '',
      batchlyEdit: false,
      filteredData: []
    };
  },
  mounted() {
    this.filteredData = _.cloneDeep(this.taskInfo.treeData);
    this.taskInfo.mappingMode = 'advanced';
  },
  methods: {
    isMQ,
    isTunnel,
    handleFilterDb() {
      if (this.filterkey) {
        this.filteredData = [];
        this.taskInfo.treeData.forEach((db) => {
          if (db.title.indexOf(this.filterkey) > -1) {
            this.filteredData.push(db);
          }
        });
      } else {
        this.getTreeData(this.taskInfo.treeData);
      }
    },
    handleSelectAll(data) {
      this.filteredData.forEach((db) => {
        db.checked = data;
        this.taskInfo.treeData.forEach((treedata) => {
          if (treedata.title === db.title) {
            treedata.checked = data;
          }
        });

        if (data) {
          this.taskInfo.dbMap.push({
            sourceDb: db.title,
            sinkDb: db.title,
            targetSchema: 'public',
            sourceSchema: 'public'
          });
        }
      });
      if (!data) {
        this.taskInfo.dbMap.splice(0, this.taskInfo.dbMap.length);
      }
    },
    handleChangeCheck(selection, current) {
      this.filteredData.forEach((db) => {
        if (current.title === db.title) {
          db.checked = current.checked;
          this.taskInfo.treeData.forEach((treedata) => {
            if (treedata.title === db.title) {
              treedata.checked = current.checked;
            }
          });
          if (current.checked) {
            this.taskInfo.dbMap.push({
              sourceDb: current.title,
              sinkDb: current.title,
              targetSchema: 'public',
              sourceSchema: 'public',
              needAutoCreated: false
            });
          } else {
            this.taskInfo.dbMap.forEach((item, i) => {
              if (item.sourceDb === current.title) {
                this.taskInfo.dbMap.splice(i, 1);
              }
            });
          }
        }
      });
    },
    getTreeData(data) {
      this.filteredData = [];
      data.forEach((db) => {
        this.filteredData.push(db);
      });
    },
    handleChangeMode(data) {
      if (data === 'easy') {
        this.taskInfo.treeData.forEach((treeData) => {
          treeData.checked = false;
        });

        this.taskInfo.dbMap.forEach((item, i) => {
          if (!item.sourceDb) {
            this.taskInfo.dbMap.splice(i, 1);
          } else {
            this.taskInfo.treeData.forEach((treeData) => {
              if (treeData.title === item.sourceDb) {
                treeData.checked = true;
                if (item.sourceDb !== item.sinkDb) {
                  treeData.sinkDb = item.sinkDb;
                }
              }
            });
            this.filteredData = _.cloneDeep(this.taskInfo.treeData);
            this.filterkey = '';
          }
        });
      } else {
        if (this.taskInfo.dbMap.length < 1) {
          this.taskInfo.dbMap.push({
            sourceDb: '',
            sinkDb: '',
            targetSchema: 'public',
            sourceSchema: 'public'
          });
        }
      }
    },
    handleEditMapping() {
      this.batchlyEdit = true;
    }
  },
  watch: {
    'taskInfo.treeData': {
      handler() {
        this.filteredData = _.clone(this.taskInfo.treeData);
      },
      deep: true
    }
  }
};
</script>
<style lang="less" scoped>
</style>
