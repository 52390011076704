<template>
  <div class="function-config-container">
    <Form ref="taskInfo" :model="taskInfo" label-position="left" :label-width="120"
          :rules="ruleValidate">
      <FormItem label="任务类型" key="type" prop="type">
        <RadioGroup v-model="taskInfo.type" type="button" @on-change="handleChangeType">
          <Radio label="MIGRATION" :disabled="!taskInfo.jobTypeList.MIGRATION || isCreateFromCheck">
            {{ Mapping.jobType.MIGRATION }}
          </Radio>
          <Radio label="SYNC" :disabled="!taskInfo.jobTypeList.SYNC || isCreateFromCheck">
            {{ Mapping.jobType.SYNC }}
          </Radio>
          <Radio label="CHECK" :disabled="!taskInfo.jobTypeList.CHECK || isCreateFromCheck">
            {{ Mapping.jobType.CHECK }}
          </Radio>
          <Radio label="STRUCT_MIGRATION" :disabled="!taskInfo.jobTypeList.STRUCT_MIGRATION || isCreateFromCheck">
            {{ Mapping.jobType.STRUCT_MIGRATION }}
          </Radio>
          <Radio label="REVISE" :disabled="!taskInfo.jobTypeList.REVISE">
            {{ Mapping.jobType.REVISE }}
          </Radio>
          <!--                    <Radio label="SUBSCRIBE">数据订阅-->
          <!--                    </Radio>-->
        </RadioGroup>
        <Poptip trigger="hover" placement="right" class="create-job-tip" style="margin-left: 12px;">
          <i class="iconfont icontips"></i>
          <div slot="content">
            <div style="margin-bottom: 20px">
              <p class="tip-title">全量迁移</p>[table.sourceDb][table.sourceTable]
              <div class="tip-content">以数据迁移为主，适合数据的全量搬迁及短期的增量同步任务。</div>
              <p class="tip-title">增量同步</p>
              <div class="tip-content">以数据同步为主，适合长期的增量同步任务。</div>
              <p class="tip-title">数据校验</p>
              <div class="tip-content">对比源端与目标端的数据，一次性或定时多次校验数据迁移的准确性。</div>
              <p class="tip-title">结构迁移</p>
              <div class="tip-content">根据所选数据库、表自动创建对应的数据库、表。</div>
              <p class="tip-title">数据订正</p>
              <div class="tip-content">对比源端与目标端的数据，将不一致的数据自动覆盖成和源端一致。</div>
            </div>
          </div>
        </Poptip>
        <div style="display: flex">
          <div class="function-extra" style="margin-right: 20px;"
               v-if="taskInfo.jobTypeList.MIGRATION&&taskInfo.jobTypeList.MIGRATION.Incremental&&taskInfo.type==='MIGRATION'">
            <Checkbox v-model="taskInfo.mode.synchronize" style="margin-right: 0"
                      @on-change="handleSynchronize">增量同步
            </Checkbox>
            <Select v-model="taskInfo.mode.shortTermNum" :disabled="!taskInfo.mode.synchronize"
                    style="width: 100px;margin-left: 10px">
              <Option :value="1">1天</Option>
              <Option :value="7">7天</Option>
              <Option :value="15">15天</Option>
              <Option :value="30">30天</Option>
            </Select>
          </div>
          <div class="function-extra" v-if="taskInfo.jobTypeList[taskInfo.type]&&taskInfo.jobTypeList[taskInfo.type].Scheduling">
            <Checkbox v-model="taskInfo.fullPeriod" style="margin-right: 0"
                      @on-change="handleChangeFullPeriod">定时迁移
            </Checkbox>
            <div v-if="taskInfo.fullPeriod" style="display: inline-block;margin-left: 10px">
              <span style="margin-right: 5px">每</span>
              <Select v-model="taskInfo.fullPeriodDate.dayType" style="width: 100px;margin-right: 10px">
                <Option v-for="type in taskInfo.loopRunTypes" :value="type.loopRunType" :key="type.loopRunType">{{type.i18nName}}</Option>
              </Select>
              <Select v-model="taskInfo.fullPeriodDate.month" placeholder="select month"
                      v-if="taskInfo.fullPeriodDate.dayType==='YEAR'" style="width: 100px;margin-right: 10px">
                <Option v-for="(month,index) of monthList" :key="index" :value="month">
                  {{month}}
                </Option>
              </Select>
              <Select v-model="taskInfo.fullPeriodDate.date" placeholder="select date"
                      v-if="taskInfo.fullPeriodDate.dayType==='MONTH'||taskInfo.fullPeriodDate.dayType==='YEAR'" style="width: 100px;margin-right: 10px">
                <Option v-for="(date,index) of dateList" :key="index" :value="date">
                  {{date}}
                </Option>
              </Select>
              <TimePicker v-if="taskInfo.fullPeriodDate.dayType!=='HOUR'"
                          v-model="taskInfo.fullPeriodDate.time" format="HH:mm"
                          placeholder="Select time" style="width: 112px"></TimePicker>
              <Select v-model="taskInfo.fullPeriodDate.hour"
                      v-if="taskInfo.fullPeriodDate.dayType==='HOUR'" style="width: 100px">
                <Option v-for="(minute,index) of minutes" :key="index" :value="minute">
                  {{ minute &lt; 10 ? '0' + minute : minute }}
                </Option>
              </Select>
              <span v-if="taskInfo.fullPeriodDate.dayType==='HOUR'" style="margin-left: 4px">分</span>
            </div>
          </div>
          <div class="function-extra" v-if="taskInfo.jobTypeList[taskInfo.type]&&taskInfo.jobTypeList[taskInfo.type].Incremental&&taskInfo.type==='REVISE'">
            关联的校验任务：
            <Select v-model="taskInfo.reviseBindCheckTaskId" style="width: 200px;" :disabled="isCreateFromCheck">
              <Option v-for="checkJob in checkTaskList" :value="checkJob.reviseTaskId" :key="checkJob.reviseTaskId">{{checkJob.reviseTaskId}}({{checkJob.reviseJobDesc || checkJob.reviseTaskName }})</Option>
            </Select>
          </div>
        </div>
        <Checkbox class="function-extra"
                  v-if="taskInfo.jobTypeList.SYNC&&taskInfo.jobTypeList.SYNC.FullAmount&&taskInfo.type==='SYNC'"
                  v-model="taskInfo.mode.init">全量初始化
        </Checkbox>
        <span v-if="hasNoResource().SYNC"><span style="color: #FF6E0C">
                  <i style="margin-left: 10px;margin-right: 8px" class="iconfont iconTIP"></i>
                  您的增量同步时长不足，</span><a @click="handleGoTB">去充值</a></span>
        <span v-if="hasNoResource().MIGRATION"><span style="color: #FF6E0C">
                  <i style="margin-left: 10px;margin-right: 8px" class="iconfont iconTIP"></i>
                  您的数据迁移次数不足，</span><a @click="handleGoTB">去充值</a></span>
        <span v-if="hasNoResource().CHECK"><span style="color: #FF6E0C">
                  <i style="margin-left: 10px;margin-right: 8px" class="iconfont iconTIP"></i>
                  您的数据校验次数不足，</span><a @click="handleGoTB">去充值</a></span>
        <span v-if="hasNoResource().STRUCT_MIGRATION"><span style="color: #FF6E0C">
                  <i style="margin-left: 10px;margin-right: 8px" class="iconfont iconTIP"></i>
                  您的结构迁移次数不足，</span><a @click="handleGoTB">去充值</a></span>
        <span v-if="hasNoOtherResource().SYNC"><span style="color: #FF6E0C">
                  <i style="margin-left: 10px;margin-right: 8px" class="iconfont iconTIP"></i>
                  您的增量同步时长不足，</span><a @click="handleGoTB">去充值</a></span>
        <span v-if="hasNoOtherResource().MIGRATION"><span style="color: #FF6E0C">
                  <i style="margin-left: 10px;margin-right: 8px" class="iconfont iconTIP"></i>
                  您的数据迁移次数不足，</span><a @click="handleGoTB">去充值</a></span>
      </FormItem>
      <FormItem label="任务规格" key="fullJVMHeapMB" prop="fullJVMHeapMB" v-if="taskInfo.type!=='STRUCT_MIGRATION'">
        <RadioGroup v-model="taskInfo.specKind" type="button" @on-change="handleChangeSpecKind">
          <Radio v-if="taskInfo.specsMap.IncreEnhance" label="IncreEnhance">增量增强型</Radio>
          <Radio v-if="taskInfo.specsMap.FullEnhance" label="FullEnhance">全量增强型</Radio>
          <Radio v-if="taskInfo.specsMap.Balance" label="Balance">平衡型</Radio>
        </RadioGroup>
        <Table style="width: 760px;margin-top: 10px" size="small" stripe border
               :columns="standardColumns" :data="taskInfo.specsMap[taskInfo.specKind]"
               @on-row-click="handleSelectStandard">
          <template slot-scope="{row,index}" slot="selectRadio">
            <div>
              <Radio @on-change="handleSelectStandard(row)"
                     v-model="taskInfo.specsMap[taskInfo.specKind][index].selected"></Radio>
            </div>
          </template>
        </Table>
      </FormItem>
      <FormItem v-if="taskInfo.type!=='STRUCT_MIGRATION'" label="已选择的规格" key="desc1" prop="desc">
        <p v-if="taskInfo.spec" style="font-weight: 500">
          全量内存：{{ taskInfo.spec.fullMemoryMb / 1024 }}G，增量内存：{{
            taskInfo.spec.increMemoryMb / 1024
          }}G，校验内存{{ taskInfo.spec.checkMemoryMb / 1024 }}G，描述：{{ taskInfo.spec.description }}
        </p>
      </FormItem>
      <FormItem label="任务描述" key="desc2" prop="desc">
        <Input v-model="taskInfo.desc" type="textarea" maxlength="300" style="width: 300px"/>
      </FormItem>
      <FormItem
        v-if="taskInfo.sourceType!=='Oracle'&&taskInfo.sinkType!=='ElasticSearch'&&(taskInfo.mode.synchronize||taskInfo.type==='SYNC')
        &&taskInfo.type!=='CHECK'&&taskInfo.type!=='REVISE'&&taskInfo.type!=='STRUCT_MIGRATION'"
        label="是否同步 DDL">
        <RadioGroup v-model="taskInfo.ddl">
          <Radio v-if="!(this.taskInfo.jobTypeList.SYNC && this.taskInfo.jobTypeList.SYNC.DisableDdlSync)" label="false">全部同步
          </Radio>
          <Radio label="true">不同步
          </Radio>
        </RadioGroup>
      </FormItem>
      <!--            <FormItem label="迁移白名单">-->
      <!--                <CheckboxGroup v-model="taskInfo.whiteList" style="display: inline-block">-->
      <!--                    <Checkbox label="db">新增库</Checkbox>-->
      <!--                    <Checkbox label="table">新增表</Checkbox>-->
      <!--                    <Checkbox label="column">新增列</Checkbox>-->
      <!--                </CheckboxGroup>-->
      <!--                <Tooltip placement="right-start" style="display: inline-block">-->
      <!--                    <Icon type="ios-help-circle" style="font-size: 14px;"/>-->
      <!--                    <div slot="content">-->
      <!--                        <P>选择迁移白名单后，迁移或同步过程中有新增的库表列系统将自动为其进行迁移</P>-->
      <!--                    </div>-->
      <!--                </Tooltip>-->
      <!--            </FormItem>-->
      <FormItem label="是否开启校验" key="mode" prop="mode" v-if="taskInfo.jobTypeList.CHECK">
        <RadioGroup v-model="taskInfo.checkMode"
                    style="display: inline-block;vertical-align: middle">
          <Radio label="noCheck" v-if="taskInfo.type!=='CHECK'">不开启校验</Radio>
          <Radio label="checkOnce"
                 v-if="taskInfo.type !== 'STRUCT_MIGRATION'&&taskInfo.type !== 'REVISE'">开启一次性校验
          </Radio>
          <Radio label="checkPeriod"
                 v-if="taskInfo.type!=='MIGRATION'&&taskInfo.type !== 'STRUCT_MIGRATION'&&taskInfo.type !== 'REVISE'">
            开启周期性校验
          </Radio>
        </RadioGroup>
        <div v-if="taskInfo.checkMode==='checkPeriod'" style="display: inline-block;margin-left: 10px">
          <span style="margin-right: 5px">每</span>
          <Select v-model="taskInfo.checkPeriodDate.dayType" style="width: 100px;margin-right: 10px">
            <Option v-for="type in taskInfo.loopRunTypes" :value="type.loopRunType" :key="type.loopRunType">{{type.i18nName}}</Option>
          </Select>
          <Select v-model="taskInfo.checkPeriodDate.month" placeholder="select month"
                  v-if="taskInfo.checkPeriodDate.dayType==='YEAR'" style="width: 100px;margin-right: 10px">
            <Option v-for="(month,index) of monthList" :key="index" :value="month">
              {{month}}
            </Option>
          </Select>
          <Select v-model="taskInfo.checkPeriodDate.date" placeholder="select date"
                  v-if="taskInfo.checkPeriodDate.dayType==='MONTH'||taskInfo.checkPeriodDate.dayType==='YEAR'" style="width: 100px;margin-right: 10px">
            <Option v-for="(date,index) of dateList" :key="index" :value="date">
              {{date}}
            </Option>
          </Select>
          <TimePicker v-if="taskInfo.checkPeriodDate.dayType!=='HOUR'"
                      v-model="taskInfo.checkPeriodDate.time" format="HH:mm"
                      placeholder="Select time" style="width: 112px"></TimePicker>
          <Select v-model="taskInfo.checkPeriodDate.hour"
                  v-if="taskInfo.checkPeriodDate.dayType==='HOUR'" style="width: 100px">
            <Option v-for="(minute,index) of minutes" :key="index" :value="minute">
              {{ minute &lt; 10 ? '0' + minute : minute }}
            </Option>
          </Select>
          <span v-if="taskInfo.checkPeriodDate.dayType==='HOUR'" style="margin-right: 4px">分</span>
        </div>
        <span v-if="taskInfo.checkMode!=='noCheck'&&hasNoCheckResource().CHECK"><span
          style="color: #FF6E0C">
                  <i style="margin-left: 10px;margin-right: 8px" class="iconfont iconTIP"></i>
                  您的数据校验次数不足，</span><a @click="handleGoTB">去充值</a></span>
      </FormItem>
      <FormItem label="自动启动任务">
        <i-switch true-color="#52C41A" v-model="taskInfo.autoStart"></i-switch>
      </FormItem>
    </Form>
    <div>
      <Modal
        v-model="showPreCheck"
        :closable="false"
        :footer-hide="true"
        :mask-closable="false"
        width="1000px"
        title="预检失败">
        <div>
          <div class="preCheck-title">
            <span class="preCheck-title-desc">{{ preCheckStatusDesc[preCheckStatus].desc }}</span>
            共检查<span class="preCheck-title-count">{{ preCheckItems.length }}</span
          >项，已检查<span class="preCheck-title-count">{{ preCheckItemsCheckedCount }}</span>项，失败
            <span class="preCheck-title-count">{{ preCheckItemsErrorCount }}</span>项。
          </div>
          <div style="max-height: 400px;overflow: auto">
            <Table stripe border size="small" :columns="preCheckColumns" :data="preCheckItems">
              <template slot-scope="{ row }" slot="preCheckResult">
                <div style="font-size: 18px">
                  <Icon type="md-checkmark" style="color: #19be6b" v-if="row.success&&row.checked"/>
                  <Icon type="md-close" style="color: #ed4014" v-if="!row.success&&row.checked"/>
                  <span v-if="!row.checked" style="font-size: 12px;color: #808695;">待检查</span>
                </div>
              </template>
            </Table>
          </div>
          <p style="margin: 20px 0;text-align: center">
            <pd-button style="width: 120px;height: 32px;line-height: 30px" type="default"
                       @click="handleHidePrecheck(false)">关闭
            </pd-button>
          </p>
        </div>
      </Modal>
    </div>
  </div>
</template>
<script>
import { listCheckTasks, listSpecifications, preCheckStepOne } from '@services/api/job';
import { listUserLicense } from '@services/api/userLicense';
import { transformJobType, listLoopRunTypes } from '@services/api/constant';
import Mapping from '../../../../views/util';
import DataSourceGroup from '../../../../views/dataSourceGroup';
import CreateJobMixin from '../../mixin/createJobMixin';
import store from '../../../../store/index';

const preCheckStatusDesc = {
  processing: {
    desc: '预检中',
    color: ''
  },
  success: {
    desc: '预检成功',
    color: '#19be6b'
  },
  error: {
    desc: '预检失败',
    color: '#ed4014'
  }
};

export default {
  name: 'functionConfig',
  mixins: [CreateJobMixin],
  props: {
    updateStep: Function,
    taskInfo: Object,
    updateShowAddDataSource: Function,
    updateShowAddCluster: Function,
    deployType: String,
    updateLoading: Function,
    updateNextDisabled: Function,
    handleCreateTask: Function
  },
  data() {
    return {
      checkTaskList: [],
      store,
      DataSourceGroup,
      jobTypeList: '',
      specsList: [],
      resourceData: [],
      loopRunTypes: [],
      dateList: [],
      monthList: [],
      standardColumns: [
        {
          title: ' ',
          key: '',
          width: 60,
          slot: 'selectRadio'
        },
        {
          title: '全量内存',
          key: 'fullMemoryMb',
          minWidth: 100,
          render: (h, params) => h('div', {}, `${params.row.fullMemoryMb / 1024}G`)
        },
        {
          title: '增量内存',
          key: 'increMemoryMb',
          minWidth: 100,
          render: (h, params) => h('div', {}, `${params.row.increMemoryMb / 1024}G`)
        },
        {
          title: '校验内存',
          key: 'checkMemoryMb',
          minWidth: 100,
          render: (h, params) => h('div', {}, `${params.row.checkMemoryMb / 1024}G`)
        },
        {
          title: '描述',
          key: 'description',
          minWidth: 400
        }
      ],
      showPreCheck: false,
      preCheckStatusDesc,
      preCheckItems: [],
      preCheckItemsMap: {},
      preCheckItemsSuccessCount: 0,
      preCheckItemsErrorCount: 0,
      preCheckItemsCheckedCount: 0,
      preCheckStatus: 'error',
      preCheckColumns: [
        {
          title: '类型',
          key: 'preCheckType',
          width: 200
        },
        {
          title: '标题',
          key: 'title'
        },
        {
          title: '措施',
          key: 'passRequirement'
        },
        {
          title: '失败目标',
          render: (h, params) => {
            let value = '';

            if (params.row.contextDbName) {
              if (params.row.contextTableName) {
                if (params.row.contextColumnName) {
                  if (params.row.varActualValue) {
                    value = `${params.row.contextDbName}.${params.row.contextTableName}.${params.row.contextColumnName}:${params.row.varActualValue}`;
                  } else {
                    value = `${params.row.contextDbName}.${params.row.contextTableName}.${params.row.contextColumnName}`;
                  }
                } else {
                  value = `${params.row.contextDbName}.${params.row.contextTableName}`;
                }
              } else {
                value = `${params.row.contextDbName}`;
              }
            }
            return h('div', {}, params.row.checked && !params.row.success ? value : '');
          }
        },
        {
          title: '检查结果',
          slot: 'preCheckResult',
          width: 100
        }
      ],
      minutes: [],
      Mapping,
      ruleValidate: {
        type: [
          {
            required: true,
            message: '请选择任务类型',
            trigger: 'change'
          }
        ],
        fullJVMHeapMB: [
          {
            required: true,
            type: 'number',
            message: '请选择任务规格',
            trigger: 'change'
          }
        ]
      }
    };
  },
  computed: {
    isCreateFromCheck() {
      return store.state.jobData && store.state.jobData.createReviseTask;
    }
  },
  mounted() {
    this.updateNextDisabled(false);
    this.getCheckTaskList();
    if (!this.taskInfo.functionalConfigHistory) {
      if (DataSourceGroup.mq.indexOf(this.taskInfo.sourceType) > -1) {
        this.taskInfo.type = 'SYNC';
        this.taskInfo.mode.init = false;
      }
      transformJobType({
        sourceType: this.taskInfo.sourceType,
        targetType: this.taskInfo.sinkType
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.taskInfo.jobTypeList = res.data.data.optionType;
            if (!store.state.jobData) {
              Object.keys(this.taskInfo.jobTypeList)
                .map((jobType) => {
                  if (this.taskInfo.jobTypeList[jobType].defaultCheck) {
                    this.taskInfo.type = jobType;
                  }
                  return null;
                });
            }
            if (this.taskInfo.jobTypeList.SYNC && this.taskInfo.jobTypeList.SYNC.DisableDdlSync) {
              this.taskInfo.ddl = (this.taskInfo.jobTypeList.SYNC.DisableDdlSync).toString();
            } else {
              this.taskInfo.ddl = 'false';
            }

            this.taskInfo.mode.init = Boolean(this.taskInfo.jobTypeList.SYNC && this.taskInfo.jobTypeList.SYNC.FullAmount);
            this.taskInfo.mode.synchronize = Boolean(this.taskInfo.jobTypeList.MIGRATION && this.taskInfo.jobTypeList.MIGRATION.Incremental);
            this.listSpecifications();
          }
        });
      listUserLicense()
        .then((res) => {
          if (res.data.code === '1') {
            this.taskInfo.resourceData = res.data.data;
          }
        });
      listLoopRunTypes().then((res) => {
        if (res.data.code === '1') {
          this.taskInfo.loopRunTypes = res.data.data;
        }
      });
    }

    for (let i = 0; i < 60; i++) {
      this.minutes.push(i);
    }
    for (let i = 1; i < 32; i++) {
      this.dateList.push(i);
    }
    for (let i = 1; i < 13; i++) {
      this.monthList.push(i);
    }
  },
  methods: {
    handleChangeType(value) {
      this.taskInfo.firstToTableFilter = false;
      this.taskInfo.firstToCleanData = false;
      if (value === 'CHECK') {
        this.taskInfo.checkMode = 'checkOnce';
      } else {
        this.taskInfo.checkMode = 'noCheck';
      }
      this.taskInfo.fullPeriod = false;
      this.listSpecifications();
      if (value === 'MIGRATION') {
        this.taskInfo.mode.synchronize = Boolean(this.taskInfo.jobTypeList.MIGRATION && this.taskInfo.jobTypeList.MIGRATION.Incremental);
      } else if (value === 'SYNC') {
        this.taskInfo.mode.init = Boolean(this.taskInfo.jobTypeList.SYNC && this.taskInfo.jobTypeList.SYNC.FullAmount);
      } else if (value === 'REVISE') {
        this.getCheckTaskList();
      }
    },
    async getCheckTaskList() {
      const res = await listCheckTasks();
      if (res.data.success) {
        this.checkTaskList = res.data.data;
      }
    },
    handleSynchronize(checked) {
      if (checked) {
        this.taskInfo.fullPeriod = false;
      }
      this.listSpecifications();
    },
    handleChangeFullPeriod(checked) {
      if (checked && this.taskInfo.type === 'MIGRATION') {
        this.taskInfo.mode.synchronize = false;
      }
      this.listSpecifications();
    },
    handleHidePrecheck() {
      this.showPreCheck = false;
    },
    handleShowPreCheck() {
      const noResource = this.hasNoResource();

      Object.keys(this.hasNoOtherResource())
        .map((item) => {
          noResource[item] = this.hasNoOtherResource()[item];
          return null;
        });
      Object.keys(this.hasNoCheckResource())
        .map((item) => {
          noResource[item] = this.hasNoCheckResource()[item];
          return null;
        });
      if (Object.keys(noResource).length < 1) {
        this.updateLoading(true);
        // 第一次预检
        const data = this.getDataJobData();

        preCheckStepOne(data)
          .then((res) => {
            let preCheckStatus = true;

            this.preCheckItemsErrorCount = 0;
            this.preCheckItemsSuccessCount = 0;
            this.preCheckItemsCheckedCount = 0;
            if (res.data.code === '1') {
              if (res.data.data) {
                res.data.data.map((item) => {
                  if (item.checked) {
                    this.preCheckItemsCheckedCount++;
                    if (!item.success) {
                      preCheckStatus = false;
                      this.preCheckItemsErrorCount++;
                    } else {
                      this.preCheckItemsSuccessCount++;
                    }
                  }
                  return null;
                });
              }
              this.preCheckItems = res.data.data || [];
              if (preCheckStatus) {
                this.updateStep(2);
              } else {
                this.showPreCheck = true;
              }
            } else {
              preCheckStatus = false;
            }
            this.updateLoading(false);
          })
          .catch(() => {
            this.updateLoading(false);
          });
      } else {
        this.$Modal.warning({
          title: '资源不足',
          content: '您当前的资源不足，请去<a href="/#/system/userCenter">个人中心</a>充值后再创建任务。'
        });
      }
    },
    listSpecifications() {
      if (Object.keys(this.taskInfo.specsMap).length < 1) {
        listSpecifications({
          dataJobType: this.taskInfo.type,
          initialSync: this.taskInfo.mode.init,
          shortTermSync: this.taskInfo.mode.synchronize
        })
          .then((res) => {
            if (res.data.code === '1') {
              this.taskInfo.specsMap = {};
              res.data.data.map((item) => {
                if (this.taskInfo.specsMap[item.specKind]) {
                  item.selected = false;
                  this.taskInfo.specsMap[item.specKind].push(item);
                } else {
                  this.taskInfo.specsMap[item.specKind] = [];
                  item.selected = false;
                  this.taskInfo.specsMap[item.specKind].push(item);
                }
                return null;
              });
              this.taskInfo.specsMap = { ...this.taskInfo.specsMap };
              if (!this.taskInfo.specKind) {
                this.taskInfo.specKind = 'Balance';
                this.handleChangeSpecKind('Balance');
              } else {
                this.handleChangeSpecKind(this.taskInfo.specKind, 'useOld');
              }
            }
          });
      }
    },
    handleSelectStandard(row) {
      this.taskInfo.specsMap[row.specKind].map((item) => {
        item.selected = item.id === row.id;
        return null;
      });
      this.taskInfo.specsMap = { ...this.taskInfo.specsMap };
      this.taskInfo.spec = row;
    },
    handleChangeSpecKind(kind) {
      if (this.taskInfo.specsMap[kind]) {
        this.taskInfo.specsMap[kind].map((item) => {
          item.selected = false;
          return null;
        });
        this.taskInfo.specsMap[kind][1].selected = true;
        this.taskInfo.specsMap = { ...this.taskInfo.specsMap };
        this.taskInfo.spec = this.taskInfo.specsMap[kind][1];
      }
    },
    hasNoResource() {
      let data = {};

      if (this.taskInfo.type === 'SYNC') {
        this.taskInfo.resourceData.map((item) => {
          if (item.licenseType === 'INCREMENT_SYNC_DURATION') {
            if (item.amount < 1 && item.amount !== -10000) {
              data = { SYNC: true };
            }
          }
          return null;
        });
      } else if (this.taskInfo.type === 'CHECK') {
        this.taskInfo.resourceData.map((item) => {
          if (item.licenseType === 'FULL_CHECK_COUNT') {
            if (item.amount < 1 && item.amount !== -10000) {
              data = { CHECK: true };
            }
          }
          return null;
        });
      } else if (this.taskInfo.type === 'STRUCT_MIGRATION') {
        this.taskInfo.resourceData.map((item) => {
          if (item.licenseType === 'STRUCT_TRANSFER_COUNT') {
            if (item.amount < 1 && item.amount !== -10000) {
              data = { STRUCT_MIGRATION: true };
            }
          }
          return null;
        });
      } else if (this.taskInfo.type === 'MIGRATION') {
        this.taskInfo.resourceData.map((item) => {
          if (item.licenseType === 'FULL_TRANSFER_COUNT') {
            if (item.amount < 1 && item.amount !== -10000) {
              data = { MIGRATION: true };
            }
          }
          return null;
        });
      }
      return data;
    },
    hasNoOtherResource() {
      let data = {};

      if (this.taskInfo.type === 'SYNC') {
        if (this.taskInfo.mode.init) {
          this.taskInfo.resourceData.map((item) => {
            if (item.licenseType === 'INCREMENT_SYNC_DURATION') {
              if (item.amount < 1 && item.amount !== -10000) {
                data = { MIGRATION: true };
              }
            }
            return null;
          });
        }
      } else if (this.taskInfo.type === 'MIGRATION') {
        if (this.taskInfo.mode.synchronize) {
          this.taskInfo.resourceData.map((item) => {
            if (item.licenseType === 'INCREMENT_SYNC_DURATION') {
              if (item.amount < 1 && item.amount !== -10000) {
                data = { SYNC: true };
              }
            }
            return null;
          });
        }
      }
      return data;
    },
    hasNoCheckResource() {
      let data = {};

      if (this.taskInfo.checkMode !== 'noCheck') {
        this.taskInfo.resourceData.map((item) => {
          if (item.licenseType === 'FULL_CHECK_COUNT') {
            if (item.amount < 1 && item.amount !== -10000) {
              data = { CHECK: true };
            }
          }
          return null;
        });
      }
      return data;
    },
    handleUserCenter() {
      this.$router.push({ path: '/system/userCenter' });
    },
    handleGoTB() {
      this.handleUserCenter();
    }
  }
};
</script>
<style lang="less">
.function-extra {
  margin-top: 20px;
  display: block!important;
}

.function-config-container {
  padding: 20px;

  .ivu-table-header thead tr th {
    padding: 3px 0;
  }
}

.create-job-tip {
  cursor: pointer;
  color: #999999;

  &:hover {
    color: #555555;
  }
}

.tip-title {
  font-family: PingFangSC-Semibold, serif;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 20px;
}

.tip-content {
  color: #555555;
  width: 480px;
  word-break: break-all;
  white-space: pre-wrap;
}
</style>
