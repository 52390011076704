<template>
  <div>
    <div v-if="type==='sink'">
      <p class="show-manual-account" v-if="showSinkAdvancedSettings">
        <Checkbox v-model="taskInfo.sinkExtraSetting"></Checkbox>
        <span>高级配置</span>
      </p>
      <div v-if="taskInfo.sinkExtraSetting && showSinkAdvancedSettings" class="advance-setting">
        <div v-if="DataSourceGroup.starrocks.includes(taskInfo.sinkType)">
          结构迁移待创建表默认桶（bucket）数量：
          <Input type="number" v-model="taskInfo.migrationBucketNumber" size="small"
                 style="margin-bottom: 10px;"/>
          结构迁移待创建表默认属性（properties）配置：
          <Input type="textarea" v-model="taskInfo.migrationPropertiesConfig" size="small"/>
        </div>
        <div v-if="(DataSourceGroup.pg.indexOf(this.taskInfo.sinkType)>-1 || DataSourceGroup.tidb.includes(this.taskInfo.sinkType))
              &&DataSourceGroup.sqlServer.indexOf(this.taskInfo.sinkType) === -1">
          <span style="margin-right: 10px">大小写敏感</span>
          <RadioGroup style="position: relative;top:-2px;margin-right: 56px;"
                      v-model="taskInfo.targetCaseSensitive">
            <Radio label="true">是</Radio>
            <Radio label="false">否</Radio>
          </RadioGroup>
        </div>
        <div v-if="DataSourceGroup.es.indexOf(taskInfo.sinkType)>-1">
          <span style="margin-right: 6px">时区选择</span>
          <Tooltip content="选择对应的时区后，时间类型字段会在原时间值上增加对应时区的偏移量。" placement="right">
            <Select filterable v-model="taskInfo.globalTimeZone" style="width: 100px">
              <Option v-for="(timezone,index) of timeZoneList" :value="timezone" :key="index">
                {{ timezone }}
              </Option>
            </Select>
          </Tooltip>
        </div>
        <div v-if="DataSourceGroup.mq.indexOf(this.taskInfo.sinkType)>-1">
          <span style="margin-right: 10px">消息格式</span>
          <RadioGroup style="position: relative;top:-2px;margin-right: 56px;"
                      v-model="taskInfo.dstSchemaLessFormat">
            <Radio v-for="schema in taskInfo.targetSchemaTypeList" :label="schema.format"
                   :key="schema.format">
              {{ schema.i18nName }}
            </Radio>
          </RadioGroup>
        </div>
        <div v-if="isMQ(this.taskInfo.sinkType)">
          <span style="margin-right: 10px">DDL Topic</span>
          <Select style="width: 280px" filterable v-model="taskInfo.dstMqDdlTopic"
                  @on-change="handleChangeDdlTopic">
            <Option v-for="topic in sinkTableList" :key="topic.tableName" :value="topic.tableName">
              {{ topic.tableName }}
            </Option>
          </Select>
        </div>
        <div v-if="DataSourceGroup.redis.indexOf(this.taskInfo.sinkType)>-1">
          <span style="margin-right: 10px">缓存格式</span>
          <RadioGroup style="position: relative;top:-2px;margin-right: 56px;"
                      v-model="taskInfo.dstSchemaLessFormat">
            <Radio v-for="schema in taskInfo.schemaTypeList" :label="schema.format"
                   :key="schema.format">
              {{ schema.i18nName }}
            </Radio>
          </RadioGroup>
        </div>
        <div v-if="DataSourceGroup.ck.indexOf(this.taskInfo.sinkType)>-1">
          <span style="margin-right: 10px">表引擎</span>
          <RadioGroup style="position: relative;top:-2px;margin-right: 56px;"
                      v-model="taskInfo.dstCkTableEngine">
            <Radio v-for="schema in taskInfo.engineTypeList" :label="schema.ckTableEngine"
                   :key="schema.ckTableEngine">{{ schema.ckTableEngine }}
            </Radio>
          </RadioGroup>
        </div>
        <div v-if="DataSourceGroup.starrocks.indexOf(this.taskInfo.sinkType)>-1">
          <span style="margin-right: 10px">引擎</span>
          <RadioGroup style="position: relative;top:-2px;margin-right: 56px;"
                      v-model="taskInfo.dstSrOrDorisTableModel">
            <Radio v-for="schema in taskInfo.dstSrOrDorisTableModelList" :label="schema.tableModel"
                   :key="schema.tableModel">{{ schema.tableModel }}
            </Radio>
          </RadioGroup>
        </div>
        <div v-if="DataSourceGroup.kudu.includes(taskInfo.sinkType)">
          <div>
            <Checkbox v-model="taskInfo.kuduNumReplicasSelected"/>
            <span style="margin-right: 10px">副本数</span>
            <Input style="width: 200px" v-model="taskInfo.kuduNumReplicas"
                   v-if="taskInfo.kuduNumReplicasSelected"/>
          </div>
          <div style="margin-top: 10px;">
            <Checkbox v-model="taskInfo.kuduNumBucketsSelected"/>
            <span style="margin-right: 10px">Buckets</span>
            <Input style="width: 200px" v-model="taskInfo.kuduNumBuckets"
                   v-if="taskInfo.kuduNumBucketsSelected"/>
          </div>
        </div>
        <div v-if="isMySQL(taskInfo.sinkType) || isPG(taskInfo.sinkType) || isPolar(taskInfo.sinkType)">
          <span style="margin-right: 10px">默认时间策略</span>
          <Select v-model="taskInfo.targetTimeDefaultStrategy" style="width: 280px">
            <Option v-for="strategy in targetTimeDefaultStrategyOptions" :value="strategy.value" :key="strategy.value">{{strategy.label}}</Option>
          </Select>
        </div>
      </div>
    </div>
    <div v-if="type==='source'">
      <p class="show-manual-account"
         v-if="showSourceAdvancedSettings">
        <Checkbox v-model="taskInfo.sourceExtraSetting"></Checkbox>
        <span>高级配置</span>
      </p>
      <div v-if="taskInfo.sourceExtraSetting&&showSourceAdvancedSettings"
        class="advance-setting">
        <div v-if="DataSourceGroup.mq.indexOf(this.taskInfo.sourceType)>-1">
          <span style="margin-right: 10px">消息格式</span>
          <RadioGroup style="position: relative;top:-2px;margin-right: 56px;" v-model="taskInfo.srcSchemaLessFormat">
            <Radio v-for="schema in taskInfo.sourceSchemaTypeList" :label="schema.format"
                   :key="schema.format">
              {{ schema.i18nName }}
            </Radio>
          </RadioGroup>
        </div>
        <div
          v-if="(DataSourceGroup.mq.indexOf(this.taskInfo.sinkType)>-1 && (DataSourceGroup.mysql.indexOf(this.taskInfo.sourceType)>-1||DataSourceGroup.polar.indexOf(this.taskInfo.sourceType)>-1))
          ||DataSourceGroup.mysql.indexOf(this.taskInfo.sourceType)>-1||DataSourceGroup.polar.indexOf(this.taskInfo.sourceType)>-1">
          <span style="margin-right: 10px">迁移模式</span>
          <RadioGroup v-model="taskInfo.schemaWhiteListLevel">
            <Radio label="">白名单模式（默认）</Radio>
            <Radio label="DB">全库同步</Radio>
          </RadioGroup>
        </div>
        <div v-if="isOracle(this.taskInfo.sourceType)">
          <span style="margin-right: 10px">增量模式</span>
          <RadioGroup style="position: relative;top:-2px;margin-right: 56px;" v-model="taskInfo.oraIncrMode">
            <Radio v-for="schema in taskInfo.oraIncrModeList" :label="schema.oraInceMode"
                   :key="schema.oraInceMode">
              {{ schema.oraInceMode }}
            </Radio>
          </RadioGroup>
        </div>
        <div v-if="isOracle(this.taskInfo.sourceType) && taskInfo.oraIncrMode === 'redo'">
          <div>
            <span style="margin-right: 10px">初始化字典</span>
            <Checkbox v-model="taskInfo.oraBuildRedoDicWhenCreate"/>
          </div>
        </div>
        <div v-if="isOb(taskInfo.sourceType)">
          租户：
          <Input style="width: 200px" v-model="taskInfo.obTenant" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  cacheSchemaLessValueFormats,
  ckTableEngine,
  mqSchemaLessValueFormats, srOrDorisTableModel, tunnelValueFormats
} from '@services/api/constant';
import {
  isCk,
  isES,
  isKudu,
  isMQ,
  isMySQL, isOb,
  isOracle,
  isPG,
  isPolar,
  isRedis,
  isSQLServer,
  isStarRocks,
  isTidDB
} from '@utils';
import { MYSQL_DEFAULT_STRATEGY, PG_DEFAULT_STRATEGY } from '@consts';
import DataSourceGroup from '@views/dataSourceGroup';

export default {
  props: {
    taskInfo: Object,
    type: String,
    sinkTableList: Array
  },
  computed: {
    showSinkAdvancedSettings() {
      const { sinkType } = this.taskInfo;
      return (isPG(sinkType) && !isSQLServer(sinkType))
        || isES(sinkType)
        || isMQ(sinkType)
        || isKudu(sinkType)
        || isRedis(sinkType)
        || isCk(sinkType)
        || isStarRocks(sinkType)
        || isTidDB(sinkType)
        || isMySQL(sinkType)
        || isPolar(sinkType);
    },
    showSourceAdvancedSettings() {
      const { sourceType, sinkType } = this.taskInfo;
      return (DataSourceGroup.mysql.indexOf(sourceType) > -1 || DataSourceGroup.polar.indexOf(sourceType) > -1)
        && (DataSourceGroup.mq.indexOf(sinkType) > -1 || DataSourceGroup.mysql.indexOf(sinkType) > -1 || DataSourceGroup.polar.indexOf(sinkType) > -1)
        || DataSourceGroup.mq.indexOf(sourceType) > -1 || isOracle(sourceType) || isOb(sourceType);
    },
    targetTimeDefaultStrategyOptions() {
      const { sinkType } = this.taskInfo;
      return isMySQL(sinkType) || isPolar(sinkType) ? MYSQL_DEFAULT_STRATEGY : PG_DEFAULT_STRATEGY;
    }
  },
  data() {
    return {
      DataSourceGroup,
      timeZoneList: ['-12:00', '-11:00', '-10:00', '-09:00', '-08:00', '-07:00', '-06:00',
        '-05:00', '-04:00', '-03:00', '-02:00', '-01:00', '+00:00', '+01:00', '+02:00',
        '+03:00', '+04:00', '+05:00', '+06:00', '+07:00', '+08:00', '+09:00', '+10:00', '+11:00', '+12:00'],
      schemaTypeList: [],
      engineTypeList: [],
      srOrDorisTableModelList: []
    };
  },
  mounted() {
    // if (!this.taskInfo.originalConfigHistory) {
    this.getAdvancedSettingList(this.taskInfo.sourceType);
    this.getAdvancedSettingList(this.taskInfo.sinkType);
    // }
  },
  methods: {
    isMQ,
    isMySQL,
    isPG,
    isOracle,
    isPolar,
    isOb,
    handleChangeDdlTopic(data) {
      this.sinkTableList.forEach((topic) => {
        if (topic.tableName === data) {
          this.taskInfo.dstMqDdlTopicPartitions = topic.mqTopicPartitions;
          this.taskInfo.dstMqDdlTopic = topic.tableName;
        }
      });
    },
    mqSchemaLessValueFormats(val) {
      mqSchemaLessValueFormats({
        dataSourceType: val,
        endPointType: this.type === 'source' ? 'SOURCE' : 'TARGET'
      })
        .then((res) => {
          if (res.data.code === '1') {
            if (this.type === 'source') {
              this.taskInfo.sourceSchemaTypeList = res.data.data.result;
              this.taskInfo.srcSchemaLessFormat = res.data.data.defaultName;
            } else {
              this.taskInfo.targetSchemaTypeList = res.data.data.result;
              this.taskInfo.dstSchemaLessFormat = res.data.data.defaultName;
            }
          }
        });
    },
    cacheSchemaLessValueFormats() {
      cacheSchemaLessValueFormats()
        .then((res) => {
          if (res.data.code === '1') {
            this.taskInfo.schemaTypeList = res.data.data;
            this.taskInfo.dstSchemaLessFormat = this.taskInfo.schemaTypeList[0].format;
          }
        });
    },
    tunnelValueFormats(val) {
      tunnelValueFormats({
        dataSourceType: val,
        endPointType: this.type === 'source' ? 'SOURCE' : 'TARGET'
      })
        .then((res) => {
          if (res.data.code === '1') {
            if (this.type === 'source') {
              this.taskInfo.sourceSchemaTypeList = res.data.data;
              this.taskInfo.srcSchemaLessFormat = this.taskInfo.sourceSchemaTypeList[0].format;
            } else {
              this.taskInfo.targetSchemaTypeList = res.data.data;
              this.taskInfo.dstSchemaLessFormat = this.taskInfo.targetSchemaTypeList[0].format;
            }
          }
        });
    },
    ckTableEngine() {
      ckTableEngine()
        .then((res) => {
          if (res.data.code === '1') {
            this.taskInfo.engineTypeList = res.data.data;
            this.taskInfo.engineTypeList.forEach((item) => {
              if (item.defaultCheck) {
                this.taskInfo.dstCkTableEngine = item.ckTableEngine;
              }
            });
          }
        });
    },
    srOrDorisTableModel(dataSourceType) {
      srOrDorisTableModel({
        dataSourceType
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.taskInfo.dstSrOrDorisTableModelList = res.data.data;
            this.taskInfo.dstSrOrDorisTableModelList.forEach((item) => {
              if (item.defaultCheck) {
                this.taskInfo.dstSrOrDorisTableModel = item.tableModel;
              }
            });
          }
        });
    },
    getAdvancedSettingList(val) {
      if (DataSourceGroup.redis.includes(val)) {
        this.cacheSchemaLessValueFormats();
      } else if (DataSourceGroup.mq.includes(val)) {
        this.mqSchemaLessValueFormats(val);
      } else if (DataSourceGroup.ck.includes(val)) {
        this.ckTableEngine();
      } else if (DataSourceGroup.starrocks.includes(val)) {
        this.srOrDorisTableModel(val);
      }
    }

  },
  watch: {
    'taskInfo.sinkType': {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.getAdvancedSettingList(newVal);
        }
      }
    },
    'taskInfo.sourceType': {
      handler(val) {
        this.getAdvancedSettingList(val);
      }
    }
  }
};
</script>
<style scoped>
.advance-setting {
  border: 1px solid #EDEDED;
  background-color: #FAFAFA;
  line-height: 26px;
  padding: 8px 20px;
  vertical-align: middle;
  width: 442px;
}
</style>
