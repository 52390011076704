<template>
  <div class="job-detail-state-container">
    <div>
      <div class="job-detail-state-progress">
        <p class="job-detail-state-progress-title"><span class="status-point"></span>数据校验
          <a @click="handleShowLog(task.dataTaskId)">查看日志</a>
        </p>
        <div class="job-detail-state-count">
          <div class="circle-background"></div>
          <i-circle :stroke-width="5" :stroke-color="getCurrentStateColor('CHECK')"
                    :percent="checkProgress.checkedPercent" :size="200">
            <span class="detail-Circle-inner"
                  style="font-size:50px">{{ checkProgress.checkedPercent }}%</span>
          </i-circle>
          <p style="margin-top: 16px">
            已校验{{ formatCount(JSON.parse(taskList['CHECK'].taskPosition).checked) }}行</p>
        </div>
      </div>
      <div class="job-detail-state-detail">
        <p class="job-detail-state-progress-title"><span class="status-point"></span>各库表校验进度</p>
        <Table stripe style="margin-top: 20px" :border="false" size="small"
               :columns="fullTransColumns" :data="showCheckData" max-height="300">
          <template slot-scope="{ row }" slot="progress">
            <div>
              <Progress :percent="row.checkPercent" :stroke-width="10"
                        :stroke-color="getProgressStatus(taskList['CHECK'].dataTaskStatus)==='normal'?['#ff9900', '#ff9900']:''"
                        :status="getProgressStatus(taskList['CHECK'].dataTaskStatus)">
                <span style="font-weight: 500">{{ row.checkPercent }}%</span>
              </Progress>
            </div>
          </template>
        </Table>
        <Page style="margin-top:20px;text-align: center" :total="checkTotal" size="small"
              show-elevator :page-size="checkSize"
              @on-change="handleCheckPageChange"/>
      </div>
    </div>
    <div class="job-detail-metric">
      <p class="job-detail-state-progress-title"><span class="status-point"></span>校验速率</p>
      <div style="margin-top:20px">
        <div class="panel">
          <div class="panel-header">
            <div style="float: right">
            </div>
            {{ metric.title }}
          </div>
          <div class="panel-body">
            <div style="height: 200px">
              <Metric ref="metric3" :model="metric" :time="time" :resourceType="resourceType"
                      :dataJobId="jobId.toString()"
                      :filters="filters" :selected="metric.selected"
                      :dataTaskId="taskList['CHECK'].dataTaskId.toString()"
                      :filterOthers="filterOthers"></Metric>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { formatCount } from '../../../util';
import Metric from '../../monitor/metric/metric';

export default {
  components: { Metric },
  props: {
    getCurrentStateColor: Function,
    checkProgress: Object,
    showCheckData: Array,
    getProgressStatus: Function,
    checkTotal: Number,
    checkSize: Number,
    handleCheckPageChange: Function,
    taskList: Object,
    resourceType: String,
    filters: Object,
    filterOthers: Object,
    metric: Object,
    time: Object,
    jobId: Number,
    handleShowLog: Function,
    task: Object
  },
  data() {
    return {
      formatCount,
      fullTransColumns: [
        {
          title: '数据库',
          key: 'schema',
          minWidth: 150
        },
        {
          title: '表名',
          key: 'table',
          minWidth: 220
        },
        {
          title: ' ',
          slot: 'progress',
          width: 160
        },
        {
          title: '校验行数',
          width: 200,
          render: (h, params) => h('div', {}, `已校验${formatCount(params.row.handledRecordCount)}行`)
        },
        {
          title: '丢失行数',
          width: 100,
          render: (h, params) => h('div', {}, `丢失${formatCount(params.row.loss)}行`)
        },
        {
          title: '不一致行数',
          width: 100,
          render: (h, params) => h('div', {}, `不一致${formatCount(params.row.diff)}行`)
        }
      ]
    };
  },
  methods: {
    handleRender() {
      const that = this;

      setTimeout(() => {
        if (that.$refs.metric3) {
          that.$refs.metric3.$emit('render');
        }
      }, 100);
    }
  }
};
</script>
