<template>
  <div class="clean-data-body">
    <FilterHeader :handleTableFilter="handleTableFilter" :filterTable="filterTable"
                  :handleShowVirtualColumn="handleShowVirtualColumn"
                  :handleSetTargetKey="handleSetTargetKey"
                  :handleShowFilterAll="handleShowFilterAll" :filterList="filterList"
                  :handleDeleteFilter="handleDeleteFilter" :handleClearFilter="handleClearFilter"
                  :handleGetColumnByTable="handleGetColumnByTable" :selectedIndex="selectedIndex"
                  :getSelectedInfo="getSelectedInfo" :getWhereCondition="getWhereCondition"
                  :handleFilter="handleFilter" :handleSetPartition="handleSetPartition"
                  theType="edit"
                  :sourceType="jobData.sourceDsVO.dataSourceType"
                  :sinkType="jobData.targetDsVO.dataSourceType"
                  :handlePageChange="handlePageChange"
                  :handleShowPartitionAll="handleShowPartitionAll"
                  :showTableList="showTableList" :selectedColumns="selectedColumns"></FilterHeader>
    <div class="table-filter-nav-container" style="position: relative;width:400px;float: left;">
      <div class="table-filter-nav table-filter-nav-column">
        <TableMapping
          v-if="DataSourceGroup.mq.indexOf(jobData.targetDsVO.dataSourceType)===-1&&DataSourceGroup.mq.indexOf(jobData.sourceDsVO.dataSourceType)===-1"
          :handleGetColumnByTable="handleGetColumnByTable" :selectedIndex="selectedIndex"
          theType="edit"
          :showTableList="showTableList" :getSelectedInfo="getSelectedInfo"
          :sourceType="jobData.sourceDsVO.dataSourceType"
          :sinkType="jobData.targetDsVO.dataSourceType"
          :handleShowVirtualColumn="handleShowVirtualColumn"
          :getWhereCondition="getWhereCondition" :handleFilter="handleFilter"
          :handleChangePk="handleChangePk"
          :handleSetDrdsPartition="handleSetDrdsPartition"
          :handleConfirmChangePk="handleConfirmChangePk"
          :selectedColumns="selectedColumns" :uniqList="uniqList"></TableMapping>
        <MqTableMapping
          v-if="DataSourceGroup.mq.indexOf(jobData.sourceDsVO.dataSourceType)>-1||DataSourceGroup.mq.indexOf(jobData.targetDsVO.dataSourceType)>-1"
          :handleSetPartition="handleSetPartition" :handleChangePk="handleChangePk" theType="edit"
          :type="DataSourceGroup.mq.includes(jobData.sourceDsVO.dataSourceType)?'source':'sink'"
          :handleShowVirtualColumn="handleShowVirtualColumn"
          :sourceType="jobData.sourceDsVO.dataSourceType"
          :handleGetColumnByTable="handleGetColumnByTable" :selectedIndex="selectedIndex"
          :showTableList="showTableList" :getSelectedInfo="getSelectedInfo"
          :getWhereCondition="getWhereCondition" :handleFilter="handleFilter"
          :selectedColumns="selectedColumns"></MqTableMapping>
      </div>
      <Page size="small" :page-size="pageSize" class="clean-data-page" :total="total" show-total
            @on-change="handlePageChange"/>
    </div>
    <div class="column-filter-container">
      <div
        :style="`${DataSourceGroup.mq.includes(jobData.targetDsVO.dataSourceType)?'margin-right: 300px':''}`">
        <TableContainer :tableHeight="tableHeight" :rowClassName="rowClassName"
                        :handleSelected="handleSelected"
                        :handleSelectCancel="handleSelectCancel"
                        :handleAllSelected="handleAllSelected"
                        :handleCancelSelectAll="handleCancelSelectAll" :jobData="jobData"
                        :handleSinkColumnChange="handleSinkColumnChange"
                        :hasTheSameColumn="hasTheSameColumn"
                        :handleChangeIndexType="handleChangeIndexType"
                        :targetCaseSensitive="JSON.stringify(targetCaseSensitive)"
                        :handleChangeAnalyzer="handleChangeAnalyzer"
                        :sourceType="jobData.sourceDsVO.dataSourceType"
                        :sinkType="jobData.targetDsVO.dataSourceType" :table="table"
                        targetCaseSensitiv="true" :taskInfo="taskInfo" :havePackage="jobData.havePackage"
                        :selectedColumns="selectedColumns" :mqSchema="mqSchema"
                        :index="index"></TableContainer>
      </div>
      <div class="mq-schema-container"
           v-if="DataSourceGroup.mq.includes(jobData.targetDsVO.dataSourceType)">
        <div class="mq-schema-content">
          <p class="mq-header">目标消息结构
            <span><Icon class="copy-icon" type="ios-photos-outline"
                        @click="handleCopyMqSchema(mqSchema)"/></span>
          </p>
          <div class="mq-schema-format" :style="`height:${tableHeight-40}px`">
            <pre>{{ mqSchema }}</pre>
          </div>
        </div>
      </div>
    </div>
    <Modal
      v-model="showFilter"
      footer-hide
      :closable="false"
      title="设置where条件">
      <div style="word-break: break-all">
        select {{ getColumns(selectedTable.sourceDb, selectedTable.sourceTable) }} from
        {{ selectedTable.sourceTable }}
        where
        <div style="margin-top: 10px">
          <Input v-model="whereCondition" placeholder="条件中字段请严格按名称匹配（如大小写）" type="textarea" :rows="4"/>
        </div>
        <a style="margin-top: 10px;display: block;width: 100px"
           @click="clearWhereCondition(selectedTable)">清除where条件</a>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleAddWhereCondition(selectedTable)">确定</pd-button>
          <pd-button @click="handleCancel">取消</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showFilterAll"
      width="740px"
      footer-hide
      :closable="false"
      title="批量添加where条件">
      <div style="word-break: break-all">
        select columns where
        <div style="margin-top: 10px">
          <Input placeholder="条件中字段请严格按名称匹配（如大小写）" v-model="whereCondition" type="textarea" :rows="4"/>
        </div>
        <div style="margin-top: 10px">
          <pd-button type="primary"
                     @click="handleFindTable(whereCondition)">查找有以上列的表
          </pd-button>
        </div>
        <p style="margin-top: 20px;margin-bottom: 10px;font-size: 14px;">应用到以下表</p>
        <div style="background-color: #FAFAFA;border: 1px solid #EDEDED;padding: 22px 12px">
          <Checkbox style="margin-bottom: 10px"
                    :value="checkAll"
                    @click.prevent.native="handleCheckAll">全选
          </Checkbox>
          <CheckboxGroup style="max-height: 200px;overflow: auto;" v-model="checkAllGroup"
                         @on-change="checkAllGroupChange">
            <Checkbox style="margin-bottom: 10px" v-for="(table,i) of showHasColumnTableList"
                      :label="`${table.db}.${table.sourceTable}`" :key="i"></Checkbox>
          </CheckboxGroup>
          <Page size="small" style="margin-top: 20px" :total="hasColumnTableList.length" show-total
                :page-size="showHasColumnTableListSize" @on-change="handleShowHasColumnPageChange"/>
        </div>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleAddAllWhereCondition(whereCondition)">确定</pd-button>
          <pd-button @click="handleCancel">取消</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showPartition"
      @on-ok="handleAddPartition(selectedTable)"
      width="600"
      title="配置分区键">
      <div style="word-break: break-all">
        <Form label-position="left" :label-width="80">
          <FormItem label="分区键">
            <Input style="width: 300px" v-model="partitionData.partition[0].key"></Input>
          </FormItem>
          <FormItem label="分区类型">
            <RadioGroup v-model="partitionData.partition[0].func" type="button" transfer>
              <Tooltip v-for="(format,key) of HivePartitionFuncs" :content="format" :key="key"
                       placement="bottom">
                <Radio :label="key">{{ key }}</Radio>
              </Tooltip>
            </RadioGroup>
          </FormItem>
          <FormItem label="文件格式">
            <Select style="width:300px" v-model="partitionData.fileFormat">
              <Option v-for="(format,key) of HdfsFileFormats" :value="key" :key="key">{{
                  key
                }}
              </Option>
            </Select>
          </FormItem>
          <FormItem label="压缩方式">
            <Select style="width:300px" v-model="partitionData.compressType">
              <Option v-for="(compress) of HdfsFileFormats[partitionData.fileFormat]"
                      :value="compress" :key="compress">
                {{ compress }}
              </Option>
            </Select>
          </FormItem>
        </Form>
      </div>
    </Modal>
    <Modal
      v-model="showPartitionAll"
      @on-ok="handleAddPartitionAll"
      width="600"
      title="批量配置分区键">
      <div style="word-break: break-all">
        <Form label-position="left" :label-width="80">
          <FormItem label="分区键">
            <Input style="width: 300px" v-model="partitionData.partition[0].key"></Input>
          </FormItem>
          <FormItem label="分区类型">
            <RadioGroup v-model="partitionData.partition[0].func" type="button" transfer @on-change="handleChangePartitionFunc">
              <Tooltip v-for="(format) of HivePartitionFuncs" :content="format.funcDesc" :key="format.function"
                       placement="bottom">
                <Radio :label="format.function">{{ format.i18nName }}</Radio>
              </Tooltip>
            </RadioGroup>
          </FormItem>
          <FormItem label="文件格式">
            <Select style="width:300px" v-model="partitionData.fileFormat">
              <Option v-for="(format,key) of HdfsFileFormats" :value="key" :key="key">{{
                  key
                }}
              </Option>
            </Select>
          </FormItem>
          <FormItem label="压缩方式">
            <Select style="width:300px" v-model="partitionData.compressType">
              <Option v-for="(compress) of HdfsFileFormats[partitionData.fileFormat]"
                      :value="compress" :key="compress">
                {{ compress }}
              </Option>
            </Select>
          </FormItem>
        </Form>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleAddPartitionAll">确定</pd-button>
          <pd-button type="default" @click="handleCancel">取消</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showMqPartition"
      width="740"
      footer-hide
      :closable="false"
      title="设置分区数">
      <div style="word-break: break-all">
        <Form label-position="left" :label-width="80">
          <FormItem label="分区数">
            <Input :disabled="!selectedTable.needAutoCreated" style="width: 132px" type="number"
                   v-model="mqPartition.count"></Input>
          </FormItem>
        </Form>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleAddMqPartition(selectedTable)">确定</pd-button>
          <pd-button @click="handleCancel">取消</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showChangePkBatch"
      width="500px"
      footer-hide
      :closable="false"
      title="批量修改目标主键">
      <div>
        <Select style="width: 340px" filterable placeholder="请选择索引列，否则会影响性能"
                @on-change="handleSelectUniq" multiple :key="uqIndex" v-model="currentCols">
          <Option v-for="uniq in getAllUniqData" :key="uniq.column" :value="uniq.column">
            <span>{{ uniq.column }}</span>
            <span v-if="uniq.isUk" style="margin-left: 8px"><Icon type="ios-key"/></span>
          </Option>
        </Select>
        <pd-button style="margin-left: 6px" type="primary" @click="handleFindTableByUq">查找有该列的表
        </pd-button>
        <p style="margin-top: 20px;margin-bottom: 10px;font-size: 14px;">应用到以下表</p>
        <div style="background-color: #FAFAFA;border: 1px solid #EDEDED;padding: 22px 12px">
          <Checkbox style="margin-bottom: 10px"
                    :value="checkAll"
                    @click.prevent.native="handleCheckAllBatch">全选
          </Checkbox>
          <CheckboxGroup style="max-height: 200px;overflow: auto;" v-model="checkAllGroup"
                         @on-change="checkAllGroupChange">
            <Checkbox style="margin-bottom: 10px" v-for="(table,i) of showHasColumnTableList"
                      :label="`${table.dbName}.${table.tableName}`" :key="i"></Checkbox>
          </CheckboxGroup>
          <Page size="small" style="margin-top: 20px" :total="hasColumnTableList.length" show-total
                :page-size="showHasColumnTableListSize" @on-change="handleShowHasColumnPageChange"/>
        </div>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmPk('batch')">确定</pd-button>
          <pd-button @click="handleCancel">取消</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showChangePk"
      width="500px"
      footer-hide
      :closable="false"
      title="修改目标主键">
      <div>
        <Select filterable placeholder="请选择索引列，否则会影响性能" @on-change="handleSelectUniq" multiple
                :key="uqIndex">
          <Option v-for="uniq in getUniqData" :key="uniq.constraintName"
                  :value="uniq.cols.join(',')">
            <span>索引名称：{{ uniq.constraintName }}</span>
            <span>列名：{{ uniq.cols.join(',') }}</span>
          </Option>
          <Option v-for="column in currentColumnList" :key="column.columnName||column.sourceColumn"
                  :value="column.columnName||column.sourceColumn">
            {{ column.columnName || column.sourceColumn }}
          </Option>
        </Select>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmPk">确定</pd-button>
          <pd-button @click="handleCancel">取消</pd-button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import { listHdfsFileFormat, listHivePartitionFunc } from '@services/api/constant';
import { getDsTypeMapping, listDbTablesUniqs } from '@services/api/datasource';
import { validExprFilter } from '@services/api/datarule';
import _ from 'lodash';
import { handleMqSchemaTool, getExampleData } from '@views/viewUtil';
import store from '../../../../store';
import Mapping from '../../../../views/util';
import DataSourceGroup from '../../../../views/dataSourceGroup';
import TableContainer from './cleanData/TableContainer';
import CleanDataMixin from '../../mixin/cleanDataMixin';
import { handleCopy } from './util';
import TableMapping from './cleanData/TableMapping';
import FilterHeader from './cleanData/FilterHeader';
import MqTableMapping from './cleanData/MqTableMapping';

export default {
  mixins: [CleanDataMixin],
  props: {
    jobData: Object,
    newData: Object,
    updateLoading: Function,
    mqSchemaTemplate: String,
    editJobData: Object
  },
  components: {
    TableContainer,
    TableMapping,
    FilterHeader,
    MqTableMapping
  },
  created() {
    if (this.editJobData.currentStep <= this.editJobData.lastStep && !this.editJobData.oneChange) {
      Object.assign(this, this.editJobData.two);
    } else {
      if (this.jobData.sourceDsVO.dataSourceType === 'Hive') {
        listHivePartitionFunc()
          .then((res) => {
            if (res.data.code === '1') {
              this.HivePartitionFuncs = res.data.data;
              this.partitionData.partition[0].key = this.HivePartitionFuncs[0].partitionKey;
              this.partitionData.partition[0].func = this.HivePartitionFuncs[0].function;
            }
          });

        listHdfsFileFormat()
          .then((res) => {
            if (res.data.code === '1') {
              this.HdfsFileFormats = res.data.data;
            }
          });
      }

      this.targetCaseSensitive = this.jobData.dstDsCaseSensitive;

      getDsTypeMapping({
        srcDsType: this.jobData.sourceDsVO.dataSourceType,
        dstDsType: this.jobData.targetDsVO.dataSourceType,
        dstDsId: this.jobData.targetDsVO.id
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.typeMapping = res.data.data;
            this.getUniqList(this.init);
          }
        });
    }
  },
  mounted() {
  },
  data() {
    return {
      typeMapping: {},
      type: 'edit',
      DataSourceGroup,
      tableHeight: 480,
      Mapping,
      table: {},
      selectedIndex: 0,
      filterList: [],
      sourceTableMetaList: {},
      sinkTableMetaList: {},
      loading: false,
      index: 0,
      HivePartitionFuncs: [],
      HdfsFileFormats: {},
      store: {},
      uniqList: {},
      total: 0,
      page: 1,
      pageSize: 20,
      showFilter: false,
      showFilterAll: false,
      showPartitionAll: false,
      checkAll: false,
      checkAllGroup: [],
      partitionCheckAll: true,
      partitionCheckAllGroup: [],
      showPartition: false,
      sinkTables: [],
      whereCondition: '',
      targetCaseSensitive: true,
      showMqPartition: false,
      showChangePkBatch: false,
      showChangePk: false,
      currentColumnList: [],
      selectedTargetKeys: [],
      currentUqs: [],
      currentCols: '',
      promiseList: [],
      uqInfo: {
        constraintName: '',
        cols: []
      },
      uqIndex: 0,
      mqPartition: {
        key: [],
        count: 4
      },
      whereConditionFilter: {
        column: '',
        symbol: '',
        value: ''
      },
      filterTable: {
        filterTableName: '',
        tableFilterType: ''
      },
      partitionData: {
        partition: [
          {
            key: '',
            func: 'DAY_FORMAT'
          }
        ]
      },
      cleanDataColumn: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: '源表字段',
          key: 'sourceColumn',
          width: 200,
          render: (h, params) => {
            if (params.row.isPk) {
              return h('div', [
                h('span', {}, params.row.sourceColumn),
                h('Icon', {
                  props: { type: 'ios-key' },
                  style: {
                    marginLeft: '5px',
                    color: '#ff9900'
                  }
                })
              ]);
            }
            return h('div', {}, params.row.sourceColumn);
          }
        },
        {
          title: '源表字段类型及长度',
          key: 'sourceType',
          width: 180
        },
        {
          title: '能否为NULL',
          key: 'sourceIsNullable',
          width: 120
        },
        {
          title: '目标表字段',
          slot: 'sink',
          width: 200
        },
        {
          title: '目标表字段类型及长度',
          key: 'sinkType',
          width: 180,
          slot: 'sinkType'
        },
        {
          title: '能否为NULL',
          key: 'sinkIsNullable',
          slot: 'sinkIsNullable',
          width: 120
        }
      ],
      cleanDataData: {},
      tableInfo: [],
      selectedColumns: {},
      sinkColumns: {},
      sinkColumnNames: [],
      tableList: [],
      showTableList: [],
      filteredTableList: [],
      selectedTable: {},
      targetColumns: [],
      hasColumnTableList: [],
      showHasColumnTableList: [],
      showHasColumnTableListPage: 1,
      showHasColumnTableListSize: 10,
      mqSchema: '',
      taskInfo: {
        sinkColumns: {}
      },
      supportPkgDs: []
    };
  },
  computed: {
    hasWhereTableClass() {
      return (table, index) => {
        if (store.state.selectedTables && store.state.selectedTables[table.sourceDb] && store.state.selectedTables[table.sourceDb][index].whereConditionFilter) {
          return 'has-where-table';
        }
        return '';
      };
    },
    getSelectedInfo() {
      return (db, index) => {
        let n = 0;
        const columnMap = [];

        if (this.selectedColumns[db][index] instanceof Array) {
          this.selectedColumns[db][index].map((item) => {
            if (item.selected) {
              n++;
              columnMap.push({
                sourceColumn: item.sourceColumn,
                sinkColumn: item.sinkColumn
              });
            }
            return null;
          });
        }
        return {
          selectedCount: n,
          columnMap
        };
      };
    },
    dbToCreateClass() {
      return (table, row) => {
        if (this.selectedColumns[table.sourceDb][table.sourceTable]
          && this.selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkColumn
          && !this.hasTheSameColumn(this.selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkColumn,
            this.targetColumns[table.targetTable])) {
          return 'db-to-create';
        }
        return '';
      };
    },
    getColumns() {
      return (db, table) => {
        const columns = [];

        if (this.selectedColumns[db] && this.selectedColumns[db][table]) {
          this.selectedColumns[db][table].map((item) => {
            if (item.selected) {
              columns.push(item.sourceColumn);
            }
            return null;
          });
        }
        return columns.join(',');
      };
    },
    getColumnList() {
      return (db, table) => {
        const columns = [];

        if (this.selectedColumns[db] && this.selectedColumns[db][table]) {
          this.selectedColumns[db][table].map((item) => {
            columns.push(item.sourceColumn);
            return null;
          });
        }
        return columns;
      };
    },
    getWhereFilterFormat() {
      return (value, type) => {
        if (type !== 'in') {
          if (Number.isNaN(value)) {
            return value.substr(0, 1) !== '\'' || value.substr(value.length - 1, 1) !== '\'';
          }
          return false;
        }
        let isIllegal = false;

        value.split(',')
          .map((item) => {
            if (Number.isNaN(item)) {
              if (item.substr(0, 1) !== '\'' || item.substr(item.length - 1, 1) !== '\'') {
                isIllegal = true;
              }
            }
            return null;
          });
        return isIllegal;
      };
    },
    getUniqData() {
      const key = {
        dbName: this.selectedTable.sourceDb,
        tableSchema: null,
        tableName: this.selectedTable.sourceTable
      };

      if (this.uniqList[JSON.stringify(key)]) {
        this.updateCurrentUqs(this.uniqList[JSON.stringify(key)]);

        return this.uniqList[JSON.stringify(key)];
      }
      this.updateCurrentUqs([]);
      return [];
    },
    getAllUniqData() {
      const list = [];

      Object.values(this.uniqList)
        .forEach((item) => {
          item.forEach((uq) => {
            let hasSame = false;

            list.forEach((column) => {
              if (column.column === uq.cols.join(',')) {
                hasSame = true;
              }
            });
            if (!hasSame) {
              list.push({
                column: uq.cols.join(','),
                isUk: true
              });
            }
          });
        });

      Object.values(this.selectedColumns)
        .forEach((db) => {
          Object.values(db)
            .forEach((table) => {
              table.forEach((column) => {
                let hasSame = false;

                list.forEach((column1) => {
                  if (column1.column === column.sourceColumn) {
                    hasSame = true;
                  }
                });
                if (!hasSame) {
                  list.push({
                    column: column.sourceColumn,
                    isUk: false
                  });
                }
              });
            });
        });

      return list;
    }
  },
  methods: {
    updateCurrentUqs(data) {
      this.currentUqs = data;
    },
    init() {
      this.updateLoading(true);
      this.promiseList = [];
      Object.keys(this.newData)
        .map((item, index) => {
          this.sourceTableMetaList[item] = [];
          this.sinkTableMetaList[this.newData[item].targetDb] = [];
          this.selectedItem = this.newData[item];
          this.selectedColumns[item] = {};

          this.dbTablesMap[item] = [];
          this.sinkDbTableMap[item] = [];
          if (this.newData[item].tableList) {
            this.newData[item].tableList.map((table) => {
              this.dbTablesMap[item].push(table.sourceTable);
              if (!table.needAutoCreated) {
                this.sinkDbTableMap[item].push(table.targetTable);
              }
              this.tableList.push({
                sourceTable: table.sourceTable,
                targetTable: table.targetTable,
                targetSchema: table.targetSchema,
                customSinkTable: table.customSinkTable,
                sourceDb: item,
                sinkDb: this.newData[item].targetDb,
                index,
                partitions: table.partitions,
                needAutoCreated: table.needAutoCreated
              });

              const list = store.state.tableInfo;

              list.push({
                sourceTable: table.sourceTable,
                targetTable: table.targetTable,
                targetSchema: table.targetSchema,
                customSinkTable: table.customSinkTable,
                sourceDb: item,
                sinkDb: this.newData[item].targetDb,
                index,
                partitions: table.partitions,
                needAutoCreated: table.needAutoCreated
              });
              store.commit('getTableInfo', list);

              this.sourceTableMetaList[item].push({
                dbName: item,
                tableName: table.sourceTable,
                schemaName: table.sourceSchema
              });
              this.sinkTableMetaList[this.newData[item].targetDb].push({
                dbName: this.newData[item].targetDb,
                tableName: table.targetTable,
                schemaName: table.targetSchema
              });
              return null;
            });
          }
          const newDataItem = _.cloneDeep(this.newData[item]);

          newDataItem.sourceDb = newDataItem.sourceDb.db || newDataItem.sourceDb;
          if (this.sinkDbTableMap[item].length > 0 && DataSourceGroup.mq.indexOf(this.jobData.sourceDsVO.dataSourceType) > -1) {
            this.getMqColumnData(newDataItem);
          } else if (this.sinkDbTableMap[item].length > 0 && DataSourceGroup.mq.indexOf(this.jobData.sourceDsVO.dataSourceType) === -1) {
            this.getSourceAndTargetColumnData(newDataItem);
          } else {
            this.getSourceColumnData(newDataItem);
          }
          // this.tableList.concat(item.tableList);
          return null;
        });
      // Promise.allSettled(this.promiseList).then(() => {
      //   this.updateLoading(false);
      //   this.loading = false;
      // });
      let showTableList = [];
      Promise.allSettled(this.promiseList)
        .then((results) => {
          // const len = results.length;
          results.forEach((res) => {
            res = res.value;
            const {
              item,
              selectedItem,
              sinkTables = {}
            } = res;
            try {
              if (item.sourceDb && this.sinkDbTableMap[item.sourceDb].length > 0 && DataSourceGroup.mq.indexOf(this.jobData.sourceDsVO.dataSourceType) > -1) {
                if (res.data.code === '1') {
                  let sinkTables2 = {};

                  sinkTables2 = res.data.data.tableMetaDataMap[selectedItem.sinkDb ? selectedItem.sinkDb : selectedItem.targetDb];
                  Object.keys(sinkTables2)
                    .forEach((key, i) => {
                      const arr = [];

                      sinkTables2[key].forEach((sinkColumn) => {
                        arr.push({
                          _checked: true,
                          _disabled: sinkColumn.pk,
                          sinkColumn: sinkColumn.columnName,
                          sinkType: sinkColumn.dataType,
                          sinkIsNullable: sinkColumn.isNullable,
                          selected: true,
                          isPk: sinkColumn.pk,
                          index: i,
                          needAutoCreated: false,
                          sinkTable: key,
                          sinkDb: selectedItem.sinkDb ? selectedItem.sinkDb : selectedItem.targetDb
                        });
                      });
                      this.selectedColumns[selectedItem.sinkDb ? selectedItem.sinkDb : selectedItem.targetDb][key] = arr;
                    });
                }
              } else if (item.sourceDb && this.sinkDbTableMap[item.sourceDb].length > 0) {
                let tables = [];

                tables = res.data.data.tableMetaDataMap[selectedItem.sourceDb];
                const noPkTableNameList = res.data.data.noPkTableNameList;

                this.tableList.forEach((table) => {
                  try {
                    const arr = [];
                    const suffixFields = [];

                    if (table.sourceDb === selectedItem.sourceDb) {
                      if (tables && tables[table.sourceTable]) {
                        tables[table.sourceTable].forEach((column, i) => {
                          if (column.pk) {
                            suffixFields.push(column.columnName);
                          }

                          let hasTheSameColumn = false;

                          if (sinkTables) {
                            if (sinkTables[table.sinkTable ? table.sinkTable : table.targetTable]) {
                              sinkTables[table.sinkTable ? table.sinkTable : table.targetTable].forEach((sinkColumn) => {
                                if (sinkColumn.columnName === column.columnName) {
                                  hasTheSameColumn = true;
                                  arr.push({
                                    _checked: Boolean(this.typeMapping[column.dataType.toUpperCase()]),
                                    _disabled: DataSourceGroup.mysql.includes(this.jobData.sourceDsVO.dataSourceType) && this.supportPkgDs.includes(this.jobData.targetDsVO.dataSourceType)
                                      ? false : (table.cols && table.cols[column.columnName]
                                        || Boolean(!this.typeMapping[column.dataType.toUpperCase()])),
                                    sourceColumn: column.columnName,
                                    sourceType: column.dataType.toUpperCase(),
                                    sinkColumn: this.typeMapping[column.dataType.toUpperCase()] ? this.getTargetValue(column.columnName) : '',
                                    commonRule: this.taskInfo ? this.taskInfo.commonRule : '',
                                    sourceIsNullable: column.isNullable,
                                    sinkType: this.typeMapping[column.dataType.toUpperCase()] || '',
                                    sinkIsNullable: sinkColumn.isNullable,
                                    selected: Boolean(this.typeMapping[column.dataType.toUpperCase()]),
                                    isPk: table.cols && table.cols[column.columnName] || column.pk,
                                    index: i,
                                    sourceDb: table.sourceDb,
                                    sourceTable: table.sourceTable,
                                    sourceSchema: table.sourceSchema,
                                    targetSchema: table.targetSchema,
                                    analyzer: sinkColumn.esAnalyzerType,
                                    jsonValue: false,
                                    isIndex: true,
                                    timeFormat: sinkColumn.timeFormat,
                                    needAutoCreated: !this.hasTheSameItem(this.getTargetValue(column.columnName), this.taskInfo.sinkColumns[selectedItem.sinkDb
                                      ? selectedItem.sinkDb : selectedItem.targetDb][table.sinkTable ? table.sinkTable : table.targetTable])
                                  });
                                }
                              });
                            }
                          }

                          if (!hasTheSameColumn) {
                            arr.push({
                              _checked: Boolean(this.typeMapping[column.dataType.toUpperCase()]),
                              _disabled: DataSourceGroup.mysql.includes(this.jobData.sourceDsVO.dataSourceType) && this.supportPkgDs.includes(this.jobData.targetDsVO.dataSourceType)
                                ? false : (table.cols && table.cols[column.columnName]
                                  || Boolean(!this.typeMapping[column.dataType.toUpperCase()])),
                              sourceColumn: column.columnName,
                              sourceType: column.dataType.toUpperCase(),
                              sinkColumn: this.typeMapping[column.dataType.toUpperCase()] ? this.getTargetValue(column.columnName) : '',
                              commonRule: this.taskInfo ? this.taskInfo.commonRule : '',
                              sourceIsNullable: column.isNullable,
                              sinkType: this.typeMapping[column.dataType.toUpperCase()] || '',
                              sinkIsNullable: column.isNullable,
                              selected: Boolean(this.typeMapping[column.dataType.toUpperCase()]),
                              isPk: table.cols && table.cols[column.columnName] || column.pk,
                              index: i,
                              sourceDb: table.sourceDb,
                              sourceTable: table.sourceTable,
                              sourceSchema: table.sourceSchema,
                              targetSchema: table.targetSchema,
                              jsonValue: false,
                              isIndex: true,
                              needAutoCreated: !this.hasTheSameItem(this.getTargetValue(column.columnName), this.taskInfo.sinkColumns[selectedItem.sinkDb
                                ? selectedItem.sinkDb : selectedItem.targetDb] && this.taskInfo.sinkColumns[selectedItem.sinkDb
                                ? selectedItem.sinkDb : selectedItem.targetDb][table.sinkTable
                                ? table.sinkTable : table.targetTable])
                            });
                          }
                          if (DataSourceGroup.mq.indexOf(this.sinkType) > -1 && column.pk) {
                            if (!table.partitionKeys) {
                              table.partitionKeys = [];
                            }
                            if (!table.partitions) {
                              table.partitions = 4;
                            }
                            table.partitionKeys.push(column.columnName);
                          }
                        });
                      }
                      this.selectedColumns[table.sourceDb][table.sourceTable] = arr;
                      if (DataSourceGroup.mq.indexOf(this.sinkType) > -1 && this.newData[table.sourceDb]) {
                        this.newData[table.sourceDb].tableList.forEach((selectTable) => {
                          if (selectTable.sourceTable === table.sourceTable) {
                            selectTable.partitionKeys = table.partitionKeys;
                          }
                        });
                      }
                      if (DataSourceGroup.redis.indexOf(this.sinkType) > -1 && this.newData[table.sourceDb]) {
                        this.newData[table.sourceDb].tableList.forEach((selectTable) => {
                          if (selectTable.sourceTable === table.sourceTable) {
                            selectTable.suffixFields = suffixFields;
                            table.suffixFields = suffixFields;
                          }
                        });
                      }
                      if (noPkTableNameList && noPkTableNameList[table.sourceDb] && noPkTableNameList[table.sourceDb].includes(table.sourceTable)) {
                        table.isNoPk = true;
                        tables[table.sourceTable].isNoPk = true;
                        const key = {
                          dbName: table.sourceDb,
                          tableSchema: null,
                          tableName: table.sourceTable
                        };
                        if (this.uniqList[JSON.stringify(key)] && this.uniqList[JSON.stringify(key)][0]) {
                          const uqInfo = this.uniqList[JSON.stringify(key)][0];

                          table.cols = this.uniqList[JSON.stringify(key)][0].cols;
                          this.newData[table.sourceDb].tableList.forEach((item1) => {
                            if (item1.sourceTable === table.sourceTable) {
                              item1.constraintName = _.cloneDeep(uqInfo.constraintName);
                              item1.cols = _.cloneDeep(uqInfo.cols);
                            }
                          });

                          if (this.selectedColumns[table.sourceDb][table.sourceTable]) {
                            this.selectedColumns[table.sourceDb][table.sourceTable].forEach((column) => {
                              if (uqInfo.cols.includes(column.sourceColumn)) {
                                column.isPk = true;
                              } else {
                                column.isPk = false;
                              }
                            });
                          }
                        } else {
                          table.cols = [];
                        }
                      }
                      // setTimeout(() => {
                      //   that.handleGetSelectedColumns(table.sourceDb);
                      //   that.selectedColumns = { ...that.selectedColumns };
                      // });
                    }
                  } catch (e) {
                    console.log('e', e);
                  }
                });
                if (DataSourceGroup.mq.indexOf(this.sinkType) > -1) {
                  this.selectedColumns[this.table.sourceDb][this.table.sourceTable].foreach((column) => {
                    if (column.selected) {
                      this.mqSchema.sqlType[column.sourceColumn] = this.Mapping.sqlType[column.sourceType] ? this.Mapping.sqlType[column.sourceType] : 1111;
                      this.mqSchema.data[0][column.sourceColumn] = getExampleData(column.sourceType);
                    }
                  });
                }
              } else {
                let tables = [];

                tables = res.data.data.tableMetaDataMap[selectedItem.sourceDb];
                const noPkTableNameList = res.data.data.noPkTableNameList;

                this.tableList.forEach((table) => {
                  try {
                    const arr = [];

                    if (table.fieldMetaList) {
                      const columns = [];

                      table.fieldMetaList.forEach((field) => {
                        columns.push({
                          columnName: field.fieldName,
                          dataTypeWithLength: field.fieldTypeName,
                          needIndex: field.needIndex,
                          analyzer: field.esAnalyzerType ? field.esAnalyzerType : field.fieldTypeName === 'TEXT' ? 'STANDARD' : null,
                          timePrecision: field.timePrecision,
                          jsonValue: field.jsonValue
                        });
                      });
                      if (!this.taskInfo.sinkColumns[selectedItem.sinkDb ? selectedItem.sinkDb : selectedItem.targetDb]) {
                        this.taskInfo.sinkColumns[selectedItem.sinkDb ? selectedItem.sinkDb : selectedItem.targetDb] = {};
                      }
                      this.taskInfo.sinkColumns[selectedItem.sinkDb ? selectedItem.sinkDb : selectedItem.targetDb][table.sinkTable ? table.sinkTable : table.targetTable] = columns;
                    } else {
                      if (!this.taskInfo.sinkColumns[selectedItem.sinkDb ? selectedItem.sinkDb : selectedItem.targetDb]) {
                        this.taskInfo.sinkColumns[selectedItem.sinkDb ? selectedItem.sinkDb : selectedItem.targetDb] = {};
                      }
                      this.taskInfo.sinkColumns[selectedItem.sinkDb ? selectedItem.sinkDb : selectedItem.targetDb][table.sinkTable ? table.sinkTable : table.targetTable] = [];
                    }
                    if (table.sourceDb === selectedItem.sourceDb) {
                      if (tables[table.sourceTable]) {
                        tables[table.sourceTable].forEach((column, i) => {
                          arr.push({
                            _checked: Boolean(this.typeMapping[column.dataType.toUpperCase()]),
                            _disabled: DataSourceGroup.mysql.includes(this.jobData.sourceDsVO.dataSourceType) && this.supportPkgDs.includes(this.jobData.targetDsVO.dataSourceType)
                              ? false : (table.cols && table.cols[column.columnName]
                                || Boolean(!this.typeMapping[column.dataType.toUpperCase()])),
                            sourceColumn: column.columnName,
                            sourceType: column.dataType.toUpperCase(),
                            sinkColumn: this.typeMapping[column.dataType.toUpperCase()] ? this.getTargetValue(column.columnName) : '',
                            commonRule: this.taskInfo ? this.taskInfo.commonRule : '',
                            sourceIsNullable: column.isNullable,
                            sinkType: this.typeMapping[column.dataType.toUpperCase()] || '',
                            sinkIsNullable: column.isNullable,
                            selected: Boolean(this.typeMapping[column.dataType.toUpperCase()]),
                            isPk: table.cols && table.cols[column.columnName] || column.pk,
                            index: i,
                            sourceDb: table.sourceDb,
                            sourceTable: table.sourceTable,
                            sourceSchema: table.sourceSchema,
                            targetSchema: table.targetSchema,
                            needAutoCreated: !this.hasTheSameItem(this.getTargetValue(column.columnName), this.taskInfo.sinkColumns[selectedItem.sinkDb
                              ? selectedItem.sinkDb : selectedItem.targetDb][table.sinkTable ? table.sinkTable : table.targetTable]),
                            isIndex: true,
                            analyzer: DataSourceGroup.es.indexOf(this.sinkType) > -1 ? column.analyzer
                              ? column.analyzer : this.typeMapping[column.dataType.toUpperCase()] === 'TEXT' ? 'STANDARD' : null : '',
                            timePrecision: column.timePrecision,
                            jsonValue: column.jsonValue
                          });

                          if (DataSourceGroup.mq.indexOf(this.sinkType) > -1 && column.pk) {
                            if (!table.partitionKeys) {
                              table.partitionKeys = [];
                              table.partitions = 4;
                            }

                            table.partitionKeys.push(column.columnName);
                          }
                        });
                      }
                      if (DataSourceGroup.mq.indexOf(this.sinkType) > -1 && this.newData[table.sourceDb]) {
                        this.newData[table.sourceDb].tableList.forEach((selectTable) => {
                          if (selectTable.sourceTable === table.sourceTable) {
                            selectTable.partitionKeys = table.partitionKeys;
                          }
                        });
                      }
                      this.selectedColumns[table.sourceDb][table.sourceTable] = arr;
                      if (noPkTableNameList && noPkTableNameList[table.sourceDb] && noPkTableNameList[table.sourceDb].includes(table.sourceTable)) {
                        table.isNoPk = true;
                        tables[table.sourceTable].isNoPk = true;
                        const key = {
                          dbName: table.sourceDb,
                          tableSchema: null,
                          tableName: table.sourceTable
                        };

                        if (this.uniqList[JSON.stringify(key)] && this.uniqList[JSON.stringify(key)][0]) {
                          const uqInfo = this.uniqList[JSON.stringify(key)][0];

                          table.cols = this.uniqList[JSON.stringify(key)][0].cols;
                          let list = this.newData[table.sourceDb].tableList;

                          if (!list) {
                            list = this.tableList;
                          }
                          if (list) {
                            list.forEach((item1) => {
                              if (item1.sourceTable === table.sourceTable) {
                                item1.constraintName = _.cloneDeep(uqInfo.constraintName);
                                item1.cols = _.cloneDeep(uqInfo.cols);
                              }
                            });
                          }

                          if (this.selectedColumns[table.sourceDb][table.sourceTable]) {
                            this.selectedColumns[table.sourceDb][table.sourceTable].forEach((column) => {
                              if (uqInfo.cols.includes(column.sourceColumn)) {
                                column.isPk = true;
                              } else {
                                column.isPk = false;
                              }
                            });
                          }
                        } else {
                          table.cols = [];
                        }
                      }
                      // setTimeout(() => {
                      //   that.handleGetSelectedColumns(table.sourceDb);
                      //   that.selectedColumns = { ...that.selectedColumns };
                      // });
                    }
                  } catch (e) {
                    console.log('e', e);
                  }
                });
                if (DataSourceGroup.mq.indexOf(this.sinkType) > -1) {
                  store.commit('getTableInfo', this.tableList);
                  this.tableList = store.state.tableInfo;
                  this.filteredTableList = _.cloneDeep(this.tableList);
                  this.total = this.filteredTableList.length;
                  this.showTableList = this.filteredTableList.slice(this.pageSize * (this.page - 1), this.pageSize * this.page);
                  this.table = this.showTableList[0];
                  if (DataSourceGroup.mq.indexOf(this.sinkType) > -1) {
                    const pkColumns = [];

                    this.taskInfo.sinkColumns[selectedItem.targetDb.db
                      ? selectedItem.targetDb.db : selectedItem.targetDb][this.table.sinkTable
                      ? this.table.sinkTable : this.table.targetTable].forEach((item1) => {
                      if (item1.isPk) {
                        pkColumns.push(item1.columnName);
                      }
                    });
                    this.mqSchema = {
                      data: [
                        {}
                      ],
                      database: selectedItem.sourceDb,
                      entryType: null,
                      es: 1608782968300,
                      id: 0,
                      isDdl: false,
                      mysqlType: {},
                      old: [],
                      pkNames: pkColumns,
                      schema: null,
                      sql: null,
                      sqlType: {},
                      table: this.table.sourceTable,
                      ts: 1608782968300,
                      type: 'INSERT'
                    };
                    this.selectedColumns[this.table.sourceDb][this.table.sourceTable].forEach((column) => {
                      if (column.selected) {
                        this.mqSchema.sqlType[column.sourceColumn] = this.Mapping.sqlType[column.sourceType] ? this.Mapping.sqlType[column.sourceType] : 1111;
                        this.mqSchema.data[0][column.sourceColumn] = getExampleData(column.sourceType);
                      }
                    });
                  }
                }
              }
            } catch (e) {
              console.log('err', e);
            }
          });

          let list = [];

          for (let i = 0; i < Object.keys(this.newData).length; i++) {
            list = list.concat(this.newData[Object.keys(this.newData)[i]].tableList);
          }
          this.tableList = list;
          this.filteredTableList = _.cloneDeep(this.tableList);
          this.total = this.filteredTableList.length;
          showTableList = this.filteredTableList.slice(this.pageSize * (this.page - 1), this.pageSize * this.page);
          this.showTableList = showTableList;
        })
        .finally(() => {
          this.updateLoading(false);
          this.loading = false;
          setTimeout(() => {
            this.handleGetColumnByTable(this.showTableList[0], 0);
            this.handleGetSelectedColumns(0);
            this.selectedColumns = { ...this.selectedColumns };
            this.$forceUpdate();
          });
        });
    },
    hasTheSameItem(column, columns) {
      let isSame = false;

      if (columns) {
        columns.forEach((item) => {
          if (item.columnName === column) {
            isSame = true;
          }
        });
      }
      return isSame;
    },
    hasTheSameColumn(columnName, columns) {
      let isSame = false;

      if (columns) {
        columns.map((item) => {
          if (item.columnName === columnName) {
            isSame = true;
          }
          return null;
        });
      }
      return isSame;
    },
    handleFilter(table) {
      this.showFilter = true;
      this.selectedTable = table;
      this.newData[table.sourceDb].tableList.map((item) => {
        if (item.sourceTable === table.sourceTable) {
          this.whereCondition = _.cloneDeep(item.whereCondition);
          if (item.whereConditionFilter) {
            this.whereConditionFilter = _.cloneDeep(item.whereConditionFilter);
          } else {
            this.whereConditionFilter = {
              column: '',
              symbol: '',
              value: ''
            };
          }
        }
        return null;
      });
    },
    handlePartition(table) {
      this.showPartition = true;
      this.selectedTable = table;
      this.newData[table.sourceDb].tableList.map((item) => {
        if (item.sourceTable === table.sourceTable) {
          if (item.partitionData) {
            this.partitionData = _.cloneDeep(item.partitionData);
          } else {
            this.partitionData = {
              partition: [
                {
                  key: '',
                  func: 'DAY_FORMAT'
                }
              ]
            };
          }
        }
        return null;
      });
    },
    handleGetSelectedColumns(dbName) {
      const commonSelectEdColumns = {};

      if (this.selectedColumns[dbName]) {
        Object.keys(this.selectedColumns[dbName])
          .map((item) => {
            if (this.selectedColumns[dbName][item] instanceof Array) {
              this.selectedColumns[dbName][item].map((column, i) => {
                if (commonSelectEdColumns[this.selectedColumns[dbName][item][i].sourceDb]) {
                  commonSelectEdColumns[this.selectedColumns[dbName][item][i].sourceDb][this.selectedColumns[dbName][item][i].sourceTable] = this.selectedColumns[dbName][item];
                } else {
                  commonSelectEdColumns[this.selectedColumns[dbName][item][i].sourceDb] = {};
                  commonSelectEdColumns[this.selectedColumns[dbName][item][i].sourceDb][this.selectedColumns[dbName][item][i].sourceTable] = this.selectedColumns[dbName][item];
                }
                return null;
              });
            }
            return null;
          });
      }
      Object.keys(this.newData)
        .map((item) => {
          if (commonSelectEdColumns[item.sourceDb]) {
            this.newData[item].selectedColumns = commonSelectEdColumns[item];
          }
          return null;
        });
      this.handleMqSchema();
    },
    handleSelected(table, index, selection) {
      selection.map((item) => {
        if (!this.selectedColumns[table.sourceDb][table.sourceTable][item.index].sinkColumn) {
          this.selectedColumns[table.sourceDb][table.sourceTable][item.index].sinkColumn = this.targetCaseSensitive ? item.sourceColumn : item.sourceColumn.toLowerCase();
          this.selectedColumns[table.sourceDb][table.sourceTable][item.index].sinkType = item.sourceType;
          this.selectedColumns[table.sourceDb][table.sourceTable][item.index].sinkIsNullable = item.sourceIsNullable;
          if (this.targetColumns[table.targetTable]) {
            this.targetColumns[table.targetTable].map((sinkColumn) => {
              if (sinkColumn.columnName === item.sourceColumn) {
                this.selectedColumns[table.sourceDb][table.sourceTable][item.index].sinkType = sinkColumn.dataType;
                this.selectedColumns[table.sourceDb][table.sourceTable][item.index].sinkIsNullable = sinkColumn.isNullable;
              }
              return null;
            });
          }
        }
        this.selectedColumns[table.sourceDb][table.sourceTable][item.index].selected = true;
        this.selectedColumns[table.sourceDb][table.sourceTable][item.index]._checked = true;
        return null;
      });
      this.forceUpdateView();
      this.handleGetSelectedColumns(index);
    },
    handleSelectCancel(table, index, selection) {
      this.selectedColumns[table.sourceDb][table.sourceTable].map((item) => {
        let alreadyHave = false;

        selection.map((row) => {
          if (row.sourceColumn === item.sourceColumn) {
            alreadyHave = true;
          }
          return null;
        });
        if (!alreadyHave) {
          item.sinkColumn = '';
          item.selected = false;
          this.forceUpdateView();
        }

        this.selectedColumns[table.sourceDb][table.sourceTable].map((column) => {
          let alreadyHaveColumn = false;

          selection.map((row) => {
            if (row.sourceColumn === column.sourceColumn) {
              alreadyHaveColumn = true;
            }
            return null;
          });
          if (!alreadyHaveColumn) {
            column._checked = false;
          }
          return null;
        });
        return null;
      });
      this.handleGetSelectedColumns(table.sourceDb);
    },
    handleAllSelected(table, index) {
      this.selectedColumns[table.sourceDb][table.sourceTable].map((item) => {
        item.sinkColumn = this.targetCaseSensitive ? item.sourceColumn : item.sourceColumn.toLowerCase();
        item.selected = true;
        item._checked = true;
        return null;
      });
      this.forceUpdateView();
      this.handleGetSelectedColumns(index);
    },
    handleCancelSelectAll(table, index) {
      this.selectedColumns[table.sourceDb][table.sourceTable].map((item) => {
        if (!item.isPk) {
          item.sinkColumn = '';
          item.selected = false;
          item._checked = false;
        }
        return null;
      });
      this.forceUpdateView();
      this.handleGetSelectedColumns(index);
    },
    handleSinkColumnChange(table, row, column) {
      if (column) {
        if (row.sourceColumn !== column) {
          this.selectedColumns[table.sourceDb][table.sourceTable][row.index].needAutoCreated = false;
        } else {
          let hasSame = false;

          this.targetColumns[table.targetTable].map((sinkColumn) => {
            if (sinkColumn.columnName === column) {
              this.selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkType = sinkColumn.dataType;
              this.selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkIsNullable = sinkColumn.isNullable;
              hasSame = true;
            }
            return null;
          });
          if (!hasSame) {
            this.selectedColumns[table.sourceDb][table.sourceTable][row.index].needAutoCreated = true;
          }
        }
        this.forceUpdateView();
      }
    },
    rowClassName(row) {
      if (row._isChecked) {
        return 'demo-table-info-row';
      }
      return '';
    },
    forceUpdateView() {
      // this.$set(this.selectedColumns, this.index, this.index);
      // this.index++;
      this.selectedColumns = { ...this.selectedColumns };
    },
    updateNextStep() {
      const noColumnList = [];
      const
        notNullList = [];

      this.tableList.map((table) => {
        let count = 0;

        if (this.selectedColumns[table.sourceDb] && this.selectedColumns[table.sourceDb][table.sourceTable]) {
          if (this.targetColumns[table.targetTable]) {
            this.targetColumns[table.targetTable].map((item) => {
              if (item.isNullable === 'NO') {
                let hasSamecolumn = false;

                this.selectedColumns[table.sourceDb][table.sourceTable].map((columns) => {
                  if (!this.targetCaseSensitive) {
                    if (columns.sourceColumn.toLowerCase() === item.columnName.toLowerCase()) {
                      hasSamecolumn = true;
                      if (!columns.selected) {
                        if (notNullList.indexOf(table.targetTable.toLowerCase()) === -1) {
                          notNullList.push(table.targetTable.toLowerCase());
                        }
                      }
                    }
                  } else if (this.taskInfo.commonRule === 'UpperCase') {
                    if (columns.sourceColumn.toUpperCase() === item.columnName.toUpperCase()) {
                      hasSamecolumn = true;
                      if (!columns.selected) {
                        if (notNullList.indexOf(table.targetTable.toUpperCase()) === -1) {
                          notNullList.push(table.targetTable.toUpperCase());
                        }
                      }
                    }
                  } else if (this.taskInfo.commonRule === 'LowerCase') {
                    if (columns.sourceColumn.toLowerCase() === item.columnName.toLowerCase()) {
                      hasSamecolumn = true;
                      if (!columns.selected) {
                        if (notNullList.indexOf(table.targetTable.toLowerCase()) === -1) {
                          notNullList.push(table.targetTable.toLowerCase());
                        }
                      }
                    }
                  } else if (columns.sourceColumn === item.columnName) {
                    hasSamecolumn = true;
                    if (!columns.selected) {
                      if (notNullList.indexOf(table.targetTable) === -1) {
                        notNullList.push(table.targetTable);
                      }
                    }
                  }
                  return null;
                });
                if (!hasSamecolumn) {
                  if (!this.targetCaseSensitive) {
                    if (notNullList.indexOf(table.targetTable.toLowerCase()) === -1) {
                      notNullList.push(table.targetTable.toLowerCase());
                    }
                  } else if (this.taskInfo.commonRule === 'LowerCase') {
                    if (notNullList.indexOf(table.targetTable.toLowerCase()) === -1) {
                      notNullList.push(table.targetTable.toLowerCase());
                    }
                  } else if (this.taskInfo.commonRule === 'UpperCase') {
                    if (notNullList.indexOf(table.targetTable.toUpperCase()) === -1) {
                      notNullList.push(table.targetTable.toUpperCase());
                    }
                  } else if (notNullList.indexOf(table.targetTable) === -1) {
                    notNullList.push(table.targetTable);
                  }
                }
              }
              return null;
            });
          }
          this.selectedColumns[table.sourceDb][table.sourceTable].map((columns) => {
            if (columns.selected) {
              count++;
            }
            if (count === 0) {
              noColumnList.push(table);
            }
            return null;
          });
        }
        return null;
      });
      store.commit('getSelectedColumns', this.selectedColumns);
      return {
        allHasColumn: noColumnList,
        notNullList
      };
    },
    handlePageChange(page) {
      this.page = page;
      this.showTableList = this.filteredTableList.slice(this.pageSize * (this.page - 1), this.pageSize * this.page);
      this.table = this.showTableList[0];
      this.selectedIndex = 0;
    },
    handleShowHasColumnPageChange(page) {
      this.showHasColumnTableListPage = page;
      this.showHasColumnTableList = this.hasColumnTableList.slice(this.showHasColumnTableListSize * (this.showHasColumnTableListPage - 1),
        this.showHasColumnTableListPage * this.showHasColumnTableListSize);
    },
    handleAddWhereCondition(table) {
      if (this.whereCondition) {
        validExprFilter({
          dataFilterType: 'SQL_WHERE',
          expression: this.whereCondition
        }).then((res) => {
          if (res.data.code === '1') {
            const columns = res.data.data;
            const ifCorrect = this.checkColumns(columns, this.selectedColumns[table.sourceDb][table.sourceTable]);
            if (ifCorrect) {
              this.showFilter = false;
              this.tableList.forEach((item) => {
                if (item.sourceTable === table.sourceTable && item.sourceDb === table.sourceDb) {
                  item.whereCondition = _.cloneDeep(this.whereCondition);
                }
              });
              this.newData[table.sourceDb].tableList.forEach((item) => {
                if (item.sourceTable === table.sourceTable) {
                  item.whereCondition = _.cloneDeep(this.whereCondition);
                }
              });
            }
          }
        });
      } else {
        this.showFilter = false;
        this.whereCondition = '';
        this.tableList.forEach((item) => {
          if (item.sourceTable === table.sourceTable && item.sourceDb === table.sourceDb) {
            item.whereCondition = '';
          }
        });
        this.newData[table.sourceDb].tableList.forEach((item) => {
          if (item.sourceTable === table.sourceTable) {
            item.whereCondition = '';
          }
        });
      }
    },
    handleAddAllWhereCondition(data) {
      this.whereCondition = data;
      this.showFilterAll = false;
      if (data) {
        this.checkAllGroup.forEach((item) => {
          this.newData[item.split('.')[0]].tableList.forEach((table) => {
            if (item.split('.')[1] === table.sourceTable) {
              table.whereCondition = _.cloneDeep(this.whereCondition);
            }
          });
          this.tableList.forEach((table) => {
            if (item.split('.')[1] === table.sourceTable && item.split('.')[0] === table.sourceDb) {
              table.whereCondition = _.cloneDeep(this.whereCondition);
            }
          });
        });
      } else {
        this.checkAllGroup = [];
      }
      this.hasColumnTableList = [];
      this.showHasColumnTableList = [];
      this.showHasColumnTableListPage = 1;
    },
    handleAddPartition(table) {
      this.newData[table.sourceDb].tableList.map((item) => {
        if (item.sourceTable === table.sourceTable) {
          item.partitionData = _.cloneDeep(this.partitionData);
        }
        return null;
      });
    },
    handleAddPartitionAll() {
      this.partitionCheckAllGroup.map((item) => {
        this.newData[item.split('.')[0]].map((table) => {
          if (item.split('.')[1] === table.sourceTable) {
            table.partitionData = _.cloneDeep(this.partitionData);
          }
          return null;
        });
        return null;
      });
      this.showPartitionAll = false;
      this.partitionData = {
        partition: [
          {
            key: this.HivePartitionFuncs[0].partitionKey,
            func: this.HivePartitionFuncs[0].function
          }
        ]
      };
    },
    handleCheckAll() {
      this.checkAll = !this.checkAll;
      this.indeterminate = false;

      if (this.checkAll) {
        this.checkAllGroup = [];
        this.hasColumnTableList.map((table) => {
          this.checkAllGroup.push(`${table.db}.${table.sourceTable}`);
          return null;
        });
      } else {
        this.checkAllGroup = [];
      }
    },
    checkAllGroupChange(data) {
      if (data.length === this.hasColumnTableList.length) {
        this.indeterminate = false;
        this.checkAll = true;
      } else if (data.length > 0) {
        this.indeterminate = true;
        this.checkAll = false;
      } else {
        this.indeterminate = false;
        this.checkAll = false;
      }
    },
    partitionCheckAllGroupChange(data) {
      if (data.length === this.tableList.length) {
        this.indeterminate = false;
        this.checkAll = true;
      } else if (data.length > 0) {
        this.indeterminate = true;
        this.checkAll = false;
      } else {
        this.indeterminate = false;
        this.checkAll = false;
      }
    },
    handleShowFilterAll() {
      this.showFilterAll = true;
      this.checkAll = false;
      this.hasColumnTableList = [];
      this.showHasColumnTableList = [];
      this.checkAllGroup = [];
      this.showHasColumnTableListPage = 1;
      this.whereCondition = '';
    },
    handleShowPartitionAll() {
      this.showPartitionAll = true;
      this.tableList.map((table) => {
        this.partitionCheckAllGroup.push(`${table.sourceDb}.${table.sourceTable}`);
        return null;
      });
      let func = '';
      func = this.partitionData.partition[0].func;
      if (func) {
        this.partitionData.partition[0].func = func;
        this.HivePartitionFuncs.forEach((item) => {
          if (item.function === func) {
            this.partitionData.partition[0].key = item.partitionKey;
          }
        });
      }
    },
    handleFindTable(data) {
      if (data) {
        validExprFilter({
          dataFilterType: 'SQL_WHERE',
          expression: data
        }).then((res) => {
          if (res.data.code === '1') {
            const columnList = res.data.data;
            this.hasColumnTableList = [];
            Object.keys(this.selectedColumns)
              .forEach((db) => {
                Object.keys(this.selectedColumns[db])
                  .forEach((table) => {
                    let hasSame = true;

                    columnList.forEach((c) => {
                      let sameColumn = false;

                      this.selectedColumns[db][table].forEach((column) => {
                        if (column.sourceColumn === c) {
                          sameColumn = true;
                        }
                      });
                      if (!sameColumn) {
                        hasSame = false;
                      }
                    });
                    if (hasSame) {
                      this.hasColumnTableList.push({
                        db,
                        sourceTable: table
                      });
                    }
                  });
              });
            this.showHasColumnTableListPage = 1;
            this.showHasColumnTableList = this.hasColumnTableList.slice(this.showHasColumnTableListSize * (this.showHasColumnTableListPage - 1),
              this.showHasColumnTableListPage * this.showHasColumnTableListSize);
          }
        });
      }
    },
    handleColumnChange() {
      this.hasColumnTableList = [];
      this.showHasColumnTableList = [];
    },
    getWhereCondition(tb) {
      let hasWhereCondition = false;

      if (this.newData[tb.sourceDb] && this.newData[tb.sourceDb].tableList) {
        this.newData[tb.sourceDb].tableList.map((table) => {
          if (tb.sourceTable === table.sourceTable) {
            if (table.whereConditionFilter && table.whereConditionFilter.column) {
              hasWhereCondition = true;
            }
          }
          return null;
        });
      }
      return hasWhereCondition;
    },
    clearWhereCondition(table) {
      this.newData[table.sourceDb].tableList.map((item) => {
        if (item.sourceTable === table.sourceTable) {
          this.whereCondition = '';
          item.whereCondition = _.cloneDeep(this.whereCondition);
        }
        return null;
      });
    },
    handleTableFilter(type) {
      if (this.filterTable.tableFilterType) {
        this.filterList.push(type);
      }
      this.filteredTableList = [];
      this.filteredTableList = this.tableList.filter((item) => (this.filterList.indexOf('hasWhere') > -1 ? Boolean(item.whereConditionFilter && item.whereConditionFilter.column) : true)
        && (this.filterList.indexOf('noWhere') > -1 ? !item.whereConditionFilter || !item.whereConditionFilter.column : true)
        && (this.filterTable.filterTableName ? item.sourceTable.toLowerCase().indexOf(this.filterTable.filterTableName.toLowerCase()) > -1 : true));
      this.total = this.filteredTableList.length;
      this.showTableList = this.filteredTableList.slice(this.pageSize * (this.page - 1), this.pageSize * this.page);
      this.table = this.showTableList[0];
      this.selectedIndex = 0;
    },
    handleRefresh() {
      this.loading = true;
      this.init();
    },
    handleGetColumnByTable(table, index) {
      this.table = table;
      this.selectedIndex = index;
      this.handleMqSchema();
    },
    handleDeleteFilter(i) {
      this.filterList.splice(i, 1);
      this.filterTable.tableFilterType = '';
      this.handleTableFilter();
    },
    handleClearFilter() {
      this.filterList = [];
      this.filterTable.tableFilterType = '';
      this.handleTableFilter();
    },
    handleCancel() {
      this.showFilter = false;
      this.showFilterAll = false;
      this.showMqPartition = false;
      this.showChangePkBatch = false; this.showChangePk = false;
      this.selectedTargetKeys = [];
    },
    handleChangeIndexType() {

    },
    handleChangeAnalyzer() {

    },
    handleCopyMqSchema(data) {
      handleCopy(data);
      this.$Message.success('复制成功！');
    },
    handleMqSchema() {
      if (DataSourceGroup.mq.includes(this.jobData.targetDsVO.dataSourceType)) {
        this.mqSchema = this.mqSchemaTemplate;
        this.mqSchema = handleMqSchemaTool(this.mqSchema, this.selectedColumns[this.table.sourceDb
        || this.table.sourceDb][this.table.sourceTable], this.table.sourceDb || this.table.sourceDb, this.table.sourceTable, 'tableMq');
      }
    },
    handleSetPartition(row) {
      this.selectedTable = row;
      this.mqPartition.count = row.partitions;
      this.mqPartition.key = row.partitionKeys;
      this.showMqPartition = true;
    },
    handleAddMqPartition(table) {
      this.showMqPartition = false;
      if (this.mqPartition.count > 32 || this.mqPartition.count < 1) {
        this.$Modal.warning({
          title: '分区数设置有误',
          content: '分区数支持范围为1~32，请设置正确的分区数。'
        });
      } else {
        this.tableList.map((item) => {
          if (item.sourceDb === table.sourceDb && item.sourceTable === table.sourceTable) {
            item.partitionKeys = this.mqPartition.key;
            item.partitions = this.mqPartition.count;
          }
          return null;
        });
        this.newData[table.sourceDb].tableList.map((item) => {
          if (item.sourceDb === table.sourceDb && item.sourceTable === table.sourceTable) {
            item.partitionKeys = this.mqPartition.key;
            item.partitions = this.mqPartition.count;
          }
          return null;
        });
        this.filteredTableList = _.cloneDeep(this.tableList);
        this.showTableList = this.filteredTableList.slice(this.pageSize * (this.page - 1), this.pageSize * this.page);
      }
    },
    handleShowVirtualColumn(table) {
      this.showVirtualColumn = true;
      this.selectedTable = table;
    },
    handleChangePk(table) {
      this.currentColumnList = this.selectedColumns[table.sourceDb][table.sourceTable]; this.showChangePk = true;
      this.selectedTable = table;
    },
    handleSetDrdsPartition() {

    },
    getUniqList(func) {
      this.updateLoading(true);

      const dbNames = [];

      Object.keys(this.newData)
        .map((db) => {
          dbNames.push(db);
          return null;
        });
      listDbTablesUniqs({
        host: this.jobData.sourceDsVO.host,
        privateHost: this.jobData.sourceDsVO.privateHost,
        publicHost: this.jobData.sourceDsVO.publicHost,
        hostType: this.jobData.sourceDsVO.hostType,
        type: this.jobData.sourceDsVO.dataSourceType,
        dbNames,
        dataSourceId: this.jobData.sourceDsVO.id,
        referenceDataJobId: this.jobData.dataJobId,
        referenceDsEndPointType: 'SOURCE'
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.uniqList = res.data.data;
          }
          if (func) {
            func();
          } else {
            this.updateLoading(false);
          }
        });
    },
    handleSetTargetKey() {
      this.showChangePkBatch = true;
    },
    handleFindTableByUq() {
      this.hasColumnTableList = [];
      if (this.currentCols) {
        const columnList = this.currentCols.join(',')
          .split(',');

        Object.keys(this.selectedColumns)
          .forEach((db) => {
            Object.keys(this.selectedColumns[db])
              .forEach((table) => {
                let hasSame = true;

                columnList.forEach((c) => {
                  let sameColumn = false;

                  this.selectedColumns[db][table].forEach((column) => {
                    if (column.sourceColumn === c) {
                      sameColumn = true;
                    }
                  });
                  if (!sameColumn) {
                    hasSame = false;
                  }
                });
                if (hasSame) {
                  this.hasColumnTableList.push({
                    dbName: db,
                    tableName: table
                  });
                }
              });
          });
      }
      this.showHasColumnTableListPage = 1;
      this.showHasColumnTableList = this.hasColumnTableList.slice(this.showHasColumnTableListSize * (this.showHasColumnTableListPage - 1),
        this.showHasColumnTableListPage * this.showHasColumnTableListSize);
    },
    handleCheckAllBatch() {
      this.checkAll = !this.checkAll;
      this.indeterminate = false;

      if (this.checkAll) {
        this.checkAllGroup = [];
        this.hasColumnTableList.map((table) => {
          this.checkAllGroup.push(`${table.dbName}.${table.tableName}`);
          return null;
        });
      } else {
        this.checkAllGroup = [];
      }
    },
    handleConfirmPk(type) {
      this.showChangePk = false;
      this.showChangePkBatch = false;
      if (type === 'batch') {
        this.handleConfirmChangePkBatch(this.selectedTable);
      } else {
        this.handleConfirmChangePk(this.selectedTable, this.selectedTargetKeys);
      }
      this.selectedTargetKeys = [];
    },
    handleConfirmChangePk(table, selectedTargetKeys) {
      this.showFilter = false;

      this.tableList.map((item) => {
        if (item.sourceTable === table.sourceTable && item.sourceDb === table.sourceDb) {
          item.cols = _.cloneDeep(selectedTargetKeys);
        }
        return null;
      });
      this.newData[table.sourceDb].tableList.map((item) => {
        if (item.sourceTable === table.sourceTable) {
          item.cols = _.cloneDeep(selectedTargetKeys);
          if (selectedTargetKeys.includes(item.sourceColumn)) {
            item.isPk = true;
          } else {
            item.isPk = false;
          }
        }
        return null;
      });

      if (this.selectedColumns[table.sourceDb][table.sourceTable]) {
        this.selectedColumns[table.sourceDb][table.sourceTable].map((column) => {
          if (selectedTargetKeys.includes(column.sourceColumn)) {
            column.isPk = true;
          } else {
            column.isPk = false;
          }
          return null;
        });
      }
      const columnsData = _.cloneDeep(this.selectedColumns);
      const
        index = this.selectedIndex;

      this.selectedIndex = -1;
      this.forceUpdateView();

      setTimeout(() => {
        this.selectedColumns = columnsData;
        this.selectedIndex = index;
      }, 200);

      this.uqIndex++;
    },
    handleConfirmChangePkBatch() {
      if (this.currentCols) {
        const cols = this.currentCols.join(',')
          .split(',');
        this.checkAllGroup.map((check) => {
          const db = check.split('.')[0];
          const
            table = check.split('.')[1];

          this.tableList.map((item) => {
            if (item.sourceTable === table && item.sourceDb === db) {
              item.cols = cols;
            }
            return null;
          });
          this.newData[db].tableList.map((item) => {
            if (item.sourceTable === table) {
              item.cols = cols;
            }
            return null;
          });

          if (this.selectedColumns[db][table]) {
            this.selectedColumns[db][table].map((column) => {
              if (cols.includes(column.sourceColumn)) {
                column.isPk = true;
              } else {
                column.isPk = false;
              }
              return null;
            });
          }
          return null;
        });
        const columnsData = _.cloneDeep(this.selectedColumns);
        const
          index = this.selectedIndex;

        this.selectedIndex = -1;
        this.forceUpdateView();

        setTimeout(() => {
          this.selectedColumns = columnsData;
          this.selectedIndex = index;
        }, 200);
      }
    },
    handleSelectUniq(data) {
      this.selectedTargetKeys = data;
    },
    checkColumns(columns, columnList) {
      const noSameColumns = [];
      columns.forEach((item) => {
        let hasSame = false;
        columnList.forEach((column) => {
          if (item === column.sourceColumn) {
            hasSame = true;
          }
        });
        if (!hasSame) {
          noSameColumns.push(item);
        }
      });
      if (noSameColumns.length > 0) {
        this.$Modal.error({
          title: '添加where条件异常提示',
          content: `当前表没有选择${noSameColumns.join(',')}列，请填写准确的where条件`
        });
        return false;
      }
      return true;
    },
    handleChangePartitionFunc(data) {
      this.HivePartitionFuncs.forEach((item) => {
        if (item.function === data) {
          this.partitionData.partition[0].key = item.partitionKey;
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.clean-data-body {
  height: 575px;
  overflow: auto;
  border: 1px solid #dadada;
}

.clean-data-table-info {
  font-size: 16px;
  color: #0BB9F8;
  margin-right: 10px;
}

.clean-data-table-arrow {
  margin: 0 3px;
}

.ivu-table .demo-table-info-row td {
  /*background-color: rgba(45, 183, 245, 0.1);*/

}

.ivu-modal-confirm-body > div {
  word-break: break-word;
}

.has-where-table {
  color: #FF6E0D;
}

.clean-data-search-container {
  padding: 14px 20px;
}

.clean-data-page {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  line-height: 58px;
  vertical-align: middle;
  background-color: #FAFAFA;
  border-top: 1px solid #DADADA;
  border-right: 1px solid #DADADA;;
  text-align: center;
}

.table-filter-nav-column {
  width: 400px;
  border-right: 1px solid #DADADA;
  border-top: 1px solid #DADADA;
}

.column-filter-container {
  margin-left: 400px;
  position: relative;
  border-top: 1px solid #DADADA;
  border-bottom: 1px solid #DADADA;
  background: #FAFAFA;

  .ivu-table-wrapper-with-border {
    border-right: none;
    border-left: none;
    border-bottom: none;
  }

  .mq-schema-container {
    width: 300px;
    position: absolute;
    right: 0;
    top: 0;
    //float: right;
    border-left: 1px solid #DADADA;
    margin-left: 10px;
    background: #ffffff;
  }
}

.table-filter-filter-span {
  background-color: #FFE1E1;
  padding: 4px 6px;
  border-radius: 4px;
  margin-right: 8px;

  .ivu-icon {
    margin-left: 6px;
    cursor: pointer;
  }
}

.table-filter-filter-clear {
  margin-left: 6px;
  cursor: pointer;
}

.table-filter-nav {
  height: 512px;
  padding-bottom: 60px;
}
</style>
