<template>
  <div>
    <div class="table-filter-container-search" style="position: relative">
      <div style="display: flex;justify-content: space-between">
        <div>
          <Select v-if="!DataSourceGroup.mq.includes(taskInfo.sourceType)" prefix="ios-search"
                  @on-change="handleChangeFilterType(db,$event)" style="width:120px;margin-right: 10px"
                  v-model="filterTable[theDb].tableFilterType">
            <Option value="autoCreated">待创建表</Option>
            <Option value="noCreate">已存在表</Option>
            <Option value="hasPk">有主键表</Option>
            <Option value="noPk">无主键表</Option>
            <Option value="hasSelected">已选择的表</Option>
            <Option value="notSelected">未选择的表</Option>
            <Option value="column">列名筛选</Option>
            <Option value="differPk">主键未对齐</Option>
          </Select>
          <div style="display: inline-block;margin-right: 10px;" v-if="filterTable[theDb].tableFilterType==='column'">
            <Input v-model="filterTable[theDb].tableFilterColumn" placeholder="请填入筛选表的列名"
                   style="width: 150px"/>
            <pd-button :loading="loading" style="margin-left: 5px" type="primary"
                       @click="handleTableFilter(theDb,db.sourceSchema,db)">确定
            </pd-button>
          </div>
          <Input prefix="ios-search" v-model="filterTable[theDb].tableFilterName" placeholder="筛选表"
                 style="width: 230px" @on-change="handleTableFilter(theDb,db.sourceSchema,db)"/>
        </div>
        <div>
          <div style="float: right;display: inline-block;margin-top: 6px"
               v-if="!tableDisabled&&!(taskInfo.type==='CHECK'||taskInfo.type==='REVISE'||taskInfo.type==='STRUCT_MIGRATION'||taskInfo.type==='MIGRATION'&&!taskInfo.mode.synchronize)">
            <span style="margin-right: 5px;font-weight: 500">action批量勾选：</span>
            <span style="display: inline-block;width: 320px;vertical-align: top">
          <Checkbox style="width: 70px" label="INSERT" @on-change="handleBatchActionFilter('INSERT', theDb, $event)" v-model="filterTable[theDb].tableFilterAction.INSERT">
            INSERT
          </Checkbox>
          <Checkbox style="width: 70px" label="UPDATE" @on-change="handleBatchActionFilter('UPDATE', theDb, $event)" v-model="filterTable[theDb].tableFilterAction.UPDATE">
            UPDATE
          </Checkbox>
          <Checkbox style="width: 70px" label="DELETE" @on-change="handleBatchActionFilter('DELETE', theDb, $event)" v-model="filterTable[theDb].tableFilterAction.DELETE">
            DELETE
          </Checkbox>
        <Checkbox v-if="!JSON.parse(taskInfo.ddl) && DataSourceGroup.ddlFilter.includes(taskInfo.sourceType)" style="width: 70px" label="CREATE"
                  @on-change="handleBatchActionFilter('CREATE', theDb, $event)" v-model="filterTable[theDb].tableFilterAction.CREATE">
          CREATE
        </Checkbox>
        <Checkbox v-if="!JSON.parse(taskInfo.ddl) && DataSourceGroup.ddlFilter.includes(taskInfo.sourceType)" style="width: 70px" label="ALTER"
                  @on-change="handleBatchActionFilter('ALTER', theDb, $event)" v-model="filterTable[theDb].tableFilterAction.ALTER">
          ALTER
        </Checkbox>
        <Checkbox v-if="!JSON.parse(taskInfo.ddl) && DataSourceGroup.ddlFilter.includes(taskInfo.sourceType)" style="width: 70px" label="RENAME"
                  @on-change="handleBatchActionFilter('RENAME', theDb, $event)" v-model="filterTable[theDb].tableFilterAction.RENAME">
          RENAME
        </Checkbox>
        <Checkbox v-if="!JSON.parse(taskInfo.ddl) && DataSourceGroup.ddlFilter.includes(taskInfo.sourceType)" style="width: 90px" label="TRUNCATE"
                  @on-change="handleBatchActionFilter('TRUNCATE', theDb, $event)" v-model="filterTable[theDb].tableFilterAction.TRUNCATE">
          TRUNCATE
        </Checkbox>
        </span>
          </div>
          <span v-if="taskInfo.schemaWhiteListLevel&&DataSourceGroup.mq.includes(taskInfo.sinkType)"
                style="margin-left: 20px;line-height: 32px">默认topic: {{ taskInfo.dstMqDefaultTopic }}  分区数: {{ taskInfo.dstMqDefaultTopicPartitions }} <a v-if="!tableDisabled"
            @click="handleSetTopic('default')">设置</a></span>
          <Button v-if="DataSourceGroup.mq.includes(taskInfo.sinkType) && !tableDisabled"
                  style="float: right;margin-right: 20px" type="primary" ghost @click="handleSetTopic">
            批量设置Topic名称
          </Button>
          <Button v-if="theMappingRule.length>1" style="float: right;margin-right: 20px" type="primary"
                  ghost @click="handleShowMappingRule">目标映射规则
          </Button>
          <pd-button v-if="DataSourceGroup.es.indexOf(taskInfo.sinkType)>-1"
                     style="float: right;margin-right: 10px" type="primary" ghost
                     @click="handleSetShards">批量设置分片数
          </pd-button>
        </div>
      </div>
      <div style="margin-top: 10px" v-if="filterList[theDb]&&filterList[theDb].length>0">
        <span>检索项：</span>
        <span class="table-filter-filter-span" v-for="(filter,i) in filterList[theDb]"
              :key="i">{{ Mapping.filterType[filter] }}<Icon type="md-close-circle"
                                                             @click="handleDeleteFilter(i)"/></span>
        <span class="table-filter-filter-clear" @click="handleClearFilter">清除</span>
      </div>
    </div>
  </div>
</template>
<script>
import DataSourceGroup from '../../../../../views/dataSourceGroup';
import Mapping from '../../../../../views/util';

export default {
  props: {
    filterTable: Object,
    db: Object,
    handleChangeFilterType: Function,
    handleTableFilter: Function,
    taskInfo: Object,
    handleBatchActionFilter: Function,
    handleSetShards: Function,
    handleSetPartitions: Function,
    filterList: Object,
    handleDeleteFilter: Function,
    handleClearFilter: Function,
    handleChangeMigrationType: Function,
    handleDefaultTopic: Function,
    handleSetTopic: Function,
    loading: Boolean,
    handleSetDefaultTopic: Function,
    handleShowMappingRule: Function,
    theMappingRule: Array,
    handleBatchSetRedisKey: Function,
    tableDisabled: Boolean
  },
  data() {
    return {
      DataSourceGroup,
      Mapping,
      migrationType: 'table',
      theDb: '',
      showUploadPkg: false,
      customPkgFile: null,
      checkAllGroup: ['INSERT', 'UPDATE', 'DELETE']
    };
  },
  created() {
    this.getTheDb();
  },
  methods: {
    setCheckAllGroup(data) {
      this.checkAllGroup = data;
    },
    getTheDb() {
      if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
        this.theDb = this.db.sinkDb;
      } else {
        this.theDb = this.db.sourceDb;
      }
    },
    handleFileChange(e) {
      const files = e.target.files;

      if (files && files[0]) {
        const file = files[0];

        if (file.size > 1024 * 1024 * 500) {
          this.$Modal.error({
            title: '文件过大',
            content: '文件大小应不超过500M'
          });

          return false;
        }
        this.customPkgFile = file;
      }
    },
    handleShowUploadPkg() {
      this.customPkgFile = this.taskInfo.customPkgFile;
      this.showUploadPkg = true;
    },
    handleConfirmUpload() {
      this.taskInfo.customPkgFile = this.customPkgFile;
      this.showUploadPkg = false;
    },
    handleCancel() {
      this.showUploadPkg = false;
    },
    handleRemovePkg() {
      this.customPkgFile = null;
    }
  },
  watch: {
    db() {
      this.getTheDb();
    }
  }
};
</script>
<style lang="less">
.clean-data-search-container{
  padding: 14px 20px;
}
</style>
