<template>
  <div>
    <Collapse>
      <Panel :name="`${index}`"
             v-for="(sourceDb,index) in DataSourceGroup.mq.includes(jobData.sourceDsVO.dataSourceType)?targetSchema:sourceSchema"
             :key="index">
        <span style="margin-right: 8px">
          <i class="iconfont iconshujuku"></i>
        </span>
        <span v-if="!DataSourceGroup.mq.includes(jobData.sourceDsVO.dataSourceType)">
          {{
            `${sourceDb.db}${(ifHasSchema(jobData.sourceDsVO.dataSourceType) === 'SCHEMA') ?
              `.${sourceDb.tableSpaces ? sourceDb.tableSpaces[0].tableSpace : sourceDb.schemas[0].schema}` : ''}`
          }}
          <span
            v-if="DataSourceGroup.mq.indexOf(jobData.targetDsVO.dataSourceType)===-1&&DataSourceGroup.es.indexOf(jobData.targetDsVO.dataSourceType)===-1&&
              DataSourceGroup.redis.indexOf(jobData.targetDsVO.dataSourceType)===-1&&DataSourceGroup.kudu.indexOf(jobData.targetDsVO.dataSourceType)===-1">
              <span>
                <Icon type="md-arrow-forward"/>
              </span>
            <i
              :class="sourceDb.targetAutoCreate?'iconfont icondaijianKU newAdded':'iconfont iconshujuku'"
              style="margin-right: 8px"
            />{{
              getTargetData(`${ifHasSchema(jobData.sourceDsVO.dataSourceType)}_${ifHasSchema(jobData.targetDsVO.dataSourceType)}`, sourceDb)
            }}
            <i v-if="ifMapping('DB_DB',sourceDb)" class="iconfont iconyingshe"/>
          </span>
        </span>
        <span v-if="DataSourceGroup.mq.includes(jobData.sourceDsVO.dataSourceType) && !DataSourceGroup.noDb.includes(jobData.targetDsVO.dataSourceType)">
          {{
            `${sourceDb.db}${(ifHasSchema(jobData.targetDsVO.dataSourceType) === 'SCHEMA') ?
              `.${sourceDb.tableSpaces ? sourceDb.tableSpaces[0].tableSpace : sourceDb.schemas[0].schema}` : ''}`
          }}
        </span>
        <div style="float: right;margin-right: 16px">
          <span style="margin-left: 10px">总共迁移 <span
            class="point-data">{{
              getTotalTables(sourceDb)
            }}</span> 张表，新建表<span
            :class="getAddedData('TABLE',sourceDb).length>0?'point-data newAdded':''">{{
              getAddedData('TABLE', sourceDb).length
            }}</span>，  存在映射<span
            :class="getMappingCount('TABLE_TABLE',sourceDb)>0?'point-data newAdded':''">{{
              getMappingCount(getMappingMethod('table'), sourceDb)
            }}</span></span>
        </div>
        <div slot="content">
          <div class="filter-table-container">
            <Input @on-change="handleFilterTable(sourceDb, index)" v-model="filterName[sourceDb.db]"
                   style="width: 280px" placeholder="筛选表"/>
          </div>
          <div class="show-table-mapping-content" style="display: flex;">
            <div style="flex: 1" class="show-table-list" v-if="filteredList[sourceDb.db]">
              <div v-for="(sourceTable,index) in filteredList[sourceDb.db].list" :key="index"
                   :class="`show-table-list-item ${index===selectedIndex?'show-table-list-item-selected':''}`"
                   @click="handleChangeTable(sourceDb,sourceTable,index)">
                <div v-if="!DataSourceGroup.mq.includes(jobData.sourceDsVO.dataSourceType)">
                  <p>
                    <i class="iconfont iconbiao"></i>
                    <span style="margin-left: 8px" class="point-data">{{ sourceTable.table || sourceTable.collection}}</span>
                  </p>
                  <img class="table-filter-nav-item-arrow" src="~@asset/jiantou01.png" alt="arrow" v-if="getTargetData(getMappingMethod('table'), sourceDb, sourceTable)">
                  <p v-if="getTargetData(getMappingMethod('table'), sourceDb, sourceTable)">
                    <i :style="`color:${sourceTable.targetAutoCreate?'#FF6E0D':'#333333'}`"
                       :class="DataSourceGroup.mq.includes(jobData.targetDsVO.dataSourceType)?'iconfont icontopic':sourceTable.targetAutoCreate?
                       'iconfont icondaijianBIAO newAdded':'iconfont iconbiao'"></i>
                    <span style="margin-left: 8px" class="point-data">{{
                        getTargetData(getMappingMethod('table'), sourceDb, sourceTable)
                      }}</span>
                    <i v-if="ifMapping(getMappingMethod('table'),sourceDb,sourceTable)"
                       class="iconfont iconyingshe"></i>
                  </p>
                </div>
                <div v-if="DataSourceGroup.mq.includes(jobData.sourceDsVO.dataSourceType)">
                  <p>
                    <i class="iconfont icontopic"></i>
                    <span style="margin-left: 8px" class="point-data">
                      {{ getTargetData('TOPIC_TABLE', sourceDb, sourceTable) }}
                    </span>
                  </p>
                  <img class="table-filter-nav-item-arrow" src="~@asset/jiantou01.png" alt="arrow">
                  <p>
                    <i class="iconfont iconbiao"></i>
                    <span style="margin-left: 8px" class="point-data">
                      {{ sourceTable.table  }}
                    </span>
                    <i v-if="ifMapping('TOPIC_TABLE',sourceDb,sourceTable)"
                       class="iconfont iconyingshe"></i>
                  </p>
                </div>
                <p v-if="!isMongoDB(jobData.sourceDsVO.dataSourceType)">
                  总共迁移 <span class="point-data">{{
                    getColumnsCount(sourceTable, sourceDb, index)
                  }} </span>列，
                  其中新建<span
                  :class="getAddedData('COLUMN',sourceDb,sourceTable).length>0?'point-data newAdded':''">{{
                    getAddedData('COLUMN', sourceDb, sourceTable).length
                  }}</span>
                  <span class="job-info-action"
                        :style="getTableActionStyle(sourceTable.actions,'i')">I</span>
                  <span class="job-info-action"
                        :style="getTableActionStyle(sourceTable.actions,'u')">U</span>
                  <span class="job-info-action"
                        :style="getTableActionStyle(sourceTable.actions,'d')">D</span>
                  <span class="job-info-action"
                        v-if="!JSON.parse(jobData.filterDdl) && DataSourceGroup.ddlFilter.includes(jobData.sourceDsVO.dataSourceType)"
                        :style="getTableActionStyle(sourceTable.actions,'c')">C</span>
                  <span class="job-info-action"
                        v-if="!JSON.parse(jobData.filterDdl) && DataSourceGroup.ddlFilter.includes(jobData.sourceDsVO.dataSourceType)"
                        :style="getTableActionStyle(sourceTable.actions,'a')">A</span>
                  <span class="job-info-action"
                        v-if="!JSON.parse(jobData.filterDdl) && DataSourceGroup.ddlFilter.includes(jobData.sourceDsVO.dataSourceType)"
                        :style="getTableActionStyle(sourceTable.actions,'r')">R</span>
                  <span class="job-info-action"
                        v-if="!JSON.parse(jobData.filterDdl) && DataSourceGroup.ddlFilter.includes(jobData.sourceDsVO.dataSourceType)"
                        :style="getTableActionStyle(sourceTable.actions,'t')">T</span>
                  <a style="margin-left: 10px;cursor: pointer"
                     @click.stop="handleShowWhereCondition(sourceTable.dataFilter,sourceTable,sourceDb)"
                     v-if="sourceTable.dataFilter">where条件</a>
                </p>
              </div>
              <div class="show-table-page">
                <Page size="small"
                      :total="filteredList[sourceDb.db].total" show-elevator
                      :page-size="filteredList[sourceDb.db].size"
                      @on-change="handlePageChange(sourceDb,index,$event)"/>
              </div>
            </div>
            <div style="flex: 1" class="mq-container-source"
                 v-if="DataSourceGroup.mq.includes(jobData.sourceDsVO.dataSourceType) && !DataSourceGroup.mongo.includes(jobData.targetDsVO.dataSourceType)">
              <p class="mq-header">源消息结构 <span class="tip">该 Topic 需符合以下结构</span>
                <Icon class="copy-icon" type="ios-photos-outline"
                      @click="handleCopyMqSchema(mqSchema[sourceDb.schemas ? `${sourceDb.db}.${sourceDb.schemas[0].schema}` : sourceDb.db])"/>
              </p>
              <div class="mq-content">
                <pre>{{
                    mqSchema[sourceDb.schemas ? `${sourceDb.db}.${sourceDb.schemas[0].schema}` : sourceDb.db]
                  }}</pre>
              </div>
            </div>
            <Table style="flex: 1" size="small"
                   :style="`${DataSourceGroup.mq.includes(jobData.targetDsVO.dataSourceType)?'marginRight:351px':''}`"
                   :columns="getTableColumn" max-height="475" class="show-mapping-table" border
                   stripe
                   v-if="!isMongoDB(jobData.sourceDsVO.dataSourceType)"
                   :data="getColumnsData(sourceDb)"></Table>
            <div style="flex: 1" class="mq-container"
                 v-if="!isMongoDB(jobData.sourceDsVO.dataSourceType)&&DataSourceGroup.mq.includes(jobData.targetDsVO.dataSourceType)">
              <p class="mq-header">目标消息结构
                <Icon class="copy-icon" type="ios-photos-outline"
                      @click="handleCopyMqSchema(mqSchema[sourceDb.schemas ? `${sourceDb.db}.${sourceDb.schemas[0].schema}` : sourceDb.db])"/>
              </p>
              <div class="mq-content">
                <pre>{{ mqSchema[sourceDb.schemas ? `${sourceDb.db}.${sourceDb.schemas[0].schema}` : sourceDb.db] }}</pre>
              </div>
            </div>
          </div>
        </div>
      </Panel>
    </Collapse>
  </div>
</template>
<script>
import _ from 'lodash';
import { isMongoDB } from '@utils';
import DataSourceGroup from '../../../../views/dataSourceGroup';
import { handleCopy } from '../createJob/util';

export default {
  props: {
    sourceSchema: Array,
    targetSchema: Array,
    getTargetData: Function,
    getAddedData: Function,
    selectedIndex: Number,
    getMappingCount: Function,
    showTableMapping: Object,
    sourceTable: Object,
    mappingDef: Array,
    handleChangeTable: Function,
    jobData: Object,
    mqSchema: Object,
    handleShowWhereCondition: Function,
    trueColumnData: Object
  },
  data() {
    return {
      beFilterList: [],
      DataSourceGroup,
      filterName: {},
      tableColumn: [
        {
          title: '源字段',
          key: 'sourceColumn'
        },
        {
          title: '目标字段',
          key: 'sinkColumn',
          render: (h, params) => h('div', {
            style: {
              color: params.row.targetAutoCreate ? '#FF6E0D' : params.row.sourceColumn !== params.row.sinkColumn ? '#0BB9F8' : ''
            }
          }, `${params.row.sinkColumn}${params.row.sourceColumn !== params.row.sinkColumn ? '（有映射）' : ''}`)
        }
      ],
      tableMqColumn: [
        {
          title: '源字段',
          key: 'sourceColumn'
        }
      ],
      mqTableColumn: [
        {
          title: '目标字段',
          key: 'sourceColumn'
        }
      ],
      filteredList: {}
    };
  },
  mounted() {
    this.filteredList = _.cloneDeep(this.showTableMapping);
    this.beFilterList = _.cloneDeep(this.sourceSchema);
  },
  computed: {
    ifHasSchema() {
      return (type) => (DataSourceGroup.hasSchema.indexOf(type) > -1 ? 'SCHEMA' : 'DB');
    },
    getTableColumn() {
      if (DataSourceGroup.mq.includes(this.jobData.targetDsVO.dataSourceType)) {
        return this.tableMqColumn;
      }
      if (DataSourceGroup.mq.includes(this.jobData.sourceDsVO.dataSourceType)) {
        return this.mqTableColumn;
      }
      return this.tableColumn;
    },
    getTotalTables() {
      return (sourceDb) => {
        if (isMongoDB(this.jobData.sourceDsVO.dataSourceType)) {
          return sourceDb.collections.length;
        }
        if (DataSourceGroup.pg.indexOf(this.jobData.sourceDsVO.dataSourceType) > -1) {
          return sourceDb.schemas[0].tables.length;
        }
        if (DataSourceGroup.oracle.indexOf(this.jobData.sourceDsVO.dataSourceType) > -1) {
          return sourceDb.tableSpaces[0].tables.length;
        }
        if (DataSourceGroup.mq.indexOf(this.jobData.sourceDsVO.dataSourceType) > -1) {
          if (DataSourceGroup.pg.indexOf(this.jobData.targetDsVO.dataSourceType) > -1) {
            return sourceDb.schemas[0].tables.length;
          }
          if (DataSourceGroup.oracle.indexOf(this.jobData.targetDsVO.dataSourceType) > -1) {
            return sourceDb.tableSpaces[0].tables.length;
          }
          return sourceDb.tables.length;
        }
        return sourceDb.tables.length;
      };
    },
    getMappingMethod() {
      return (type) => {
        if (type === 'table') {
          if (DataSourceGroup.es.indexOf(this.jobData.targetDsVO.dataSourceType) > -1) {
            return 'TABLE_INDEX';
          }
          if (DataSourceGroup.mq.indexOf(this.jobData.targetDsVO.dataSourceType) > -1) {
            return 'TABLE_TOPIC';
          }
          if (DataSourceGroup.redis.indexOf(this.jobData.targetDsVO.dataSourceType) > -1) {
            return 'TABLE_KEYPREFIX';
          }
          if (DataSourceGroup.hasSchema.includes(this.jobData.targetDsVO.dataSourceType)) {
            if (DataSourceGroup.hasSchema.includes(this.jobData.sourceDsVO.dataSourceType)) {
              return 'TABLE_TABLE_WITH_SCHEMA';
            }
          }
          return 'TABLE_TABLE';
        }
        if (type === 'column') {
          if (DataSourceGroup.hasSchema.includes(this.jobData.targetDsVO.dataSourceType)) {
            if (DataSourceGroup.hasSchema.includes(this.jobData.sourceDsVO.dataSourceType)) {
              return 'COLUMN_COLUMN_WITH_SCHEMA';
            }
          }
          return 'COLUMN_COLUMN';
        }
      };
    },
    ifMapping() {
      return (type, sourceDb, sourceTable) => {
        this.mappingDef.forEach((item) => {
          if (item.method === type) {
            if (type === 'DB_DB') {
              const mapping = {
                value: sourceDb.db
              };

              return Boolean(item.serializeMapping[JSON.stringify(mapping)]);
            }
            if (type === 'TABLE_TABLE' || type === 'TABLE_INDEX' || type === 'TABLE_TOPIC') {
              const mapping = {
                parent: {
                  value: sourceDb.db
                },
                value: sourceTable.table
              };

              return Boolean(item.serializeMapping[JSON.stringify(mapping)]);
            }
          } else if (type === 'TABLE_TABLE_WITH_SCHEMA' && item.method === 'TABLE_TABLE') {
            const mapping = {
              parent: {
                value: sourceDb.tableSpaces ? sourceDb.tableSpaces[0].tableSpace : sourceDb.schemas[0].schema,
                parent: {
                  value: sourceDb.db
                }
              },
              value: sourceTable.table
            };

            return Boolean(item.serializeMapping[JSON.stringify(mapping)]);
          }
        });
      };
    },
    getColumnsCount() {
      return (sourceTable, sourceDb, index) => {
        let count = 0;
        // if (this.jobData.filterDdl) {
        //   sourceTable.columns.forEach((column) => {
        //     if (!column.inBlackList) {
        //       count++;
        //     }
        //   });
        // } else {
        let list = [];
        list = this.filteredList[sourceDb.db] && this.filteredList[sourceDb.db].list.length > 0 ? this.filteredList[sourceDb.db].list[index].columns : [];

        if (this.trueColumnData[sourceDb.db]) {
          if (DataSourceGroup.hasSchema.indexOf(this.jobData.sourceDsVO.dataSourceType) > -1) {
            if (DataSourceGroup.oracle.indexOf(this.jobData.sourceDsVO.dataSourceType) > -1) {
              if (this.trueColumnData[sourceDb.db][this.filteredList[sourceDb.db].list[index].table]) {
                this.trueColumnData[sourceDb.db][this.filteredList[sourceDb.db].list[index].table].forEach((column) => {
                  let sameItem = null;
                  let inBlack = false;
                  list.forEach((item) => {
                    if (column.columnName === item.column) {
                      sameItem = item;
                      if (item.inBlackList) {
                        inBlack = true;
                      }
                    }
                  });
                  if (sameItem && !inBlack) {
                    count++;
                  } else if (!sameItem) {
                    count++;
                  }
                });
              } else {
                sourceTable.columns.forEach((column) => {
                  if (!column.inBlackList) {
                    count++;
                  }
                });
              }
            } else {
              if (this.trueColumnData[sourceDb.db][this.filteredList[sourceDb.db].list[index].table]) {
                this.trueColumnData[sourceDb.db][this.filteredList[sourceDb.db].list[index].table].forEach((column) => {
                  let sameItem = null;
                  let inBlack = false;
                  list.forEach((item) => {
                    if (column.columnName === item.column) {
                      sameItem = item;
                      if (item.inBlackList) {
                        inBlack = true;
                      }
                    }
                  });
                  if (sameItem && !inBlack) {
                    count++;
                  } else if (!sameItem) {
                    count++;
                  }
                });
              } else {
                sourceTable.columns.forEach((column) => {
                  if (!column.inBlackList) {
                    count++;
                  }
                });
              }
            }
          } else {
            if (this.filteredList[sourceDb.db] && this.trueColumnData[sourceDb.db][this.filteredList[sourceDb.db].list[index].table]) {
              this.trueColumnData[sourceDb.db][this.filteredList[sourceDb.db].list[index].table].forEach((column) => {
                let sameItem = null;
                let inBlack = false;
                list.forEach((item) => {
                  if (column.columnName === item.column) {
                    sameItem = item;
                    if (item.inBlackList) {
                      inBlack = true;
                    }
                  }
                });
                if (sameItem && !inBlack) {
                  count++;
                } else if (!sameItem) {
                  count++;
                }
              });
            } else {
              sourceTable.columns.forEach((column) => {
                if (!column.inBlackList) {
                  count++;
                }
              });
            }
          }
        } else {
          if (sourceTable.columns) {
            sourceTable.columns.forEach((column) => {
              if (!column.inBlackList) {
                count++;
              }
            });
          }
        }
        return count;
      };
    }
  },
  methods: {
    isMongoDB,
    getTableActionStyle(action, type) {
      let formatAction = 'color:#c5c8ce';

      if (action) {
        action.forEach((item) => {
          if (item === 'INSERT' && type === 'i') {
            formatAction = 'color:#19be6b';
          } else if (item === 'UPDATE' && type === 'u') {
            formatAction = 'color:#19be6b';
          } else if (item === 'DELETE' && type === 'd') {
            formatAction = 'color:#19be6b';
          } else if (item === 'CREATE' && type === 'c') {
            formatAction = 'color:#19be6b';
          } else if (item === 'ALTER' && type === 'a') {
            formatAction = 'color:#19be6b';
          } else if (item === 'DROP' && type === 'dr') {
            formatAction = 'color:#19be6b';
          } else if (item === 'RENAME' && type === 'r') {
            formatAction = 'color:#19be6b';
          } else if (item === 'TRUNCATE' && type === 't') {
            formatAction = 'color:#19be6b';
          }
        });
      }
      return formatAction;
    },
    getColumnsData(sourceDb) {
      let list = [];

      const data = [];

      list = this.filteredList[sourceDb.db] && this.filteredList[sourceDb.db].list.length > 0 && this.filteredList[sourceDb.db].list[this.selectedIndex]
        ? this.filteredList[sourceDb.db].list[this.selectedIndex].columns : [];

      // filterddl doesnt matter
      // if (this.jobData.filterDdl) {
      //   if (DataSourceGroup.hasSchema.indexOf(this.jobData.sourceDsVO.dataSourceType) > -1) {
      //     if (DataSourceGroup.oracle.indexOf(this.jobData.sourceDsVO.dataSourceType) > -1) {
      //       list.forEach((item) => {
      //         if (!item.inBlackList) {
      //           data.push({
      //             targetAutoCreate: item.targetAutoCreate,
      //             sourceColumn: item.column,
      //             sinkColumn: this.getTargetData(this.getMappingMethod('column'), sourceDb, sourceDb.tableSpaces[0].tables[this.selectedIndex], item),
      //             sourceTable: sourceDb.tableSpaces[0].tables[this.selectedIndex].table,
      //             sourceDb: sourceDb.db
      //           });
      //         }
      //       });
      //     } else {
      //       list.forEach((item) => {
      //         if (!item.inBlackList) {
      //           data.push({
      //             targetAutoCreate: item.targetAutoCreate,
      //             sourceColumn: item.column,
      //             sinkColumn: this.getTargetData(this.getMappingMethod('column'), sourceDb, sourceDb.schemas[0].tables[this.selectedIndex], item),
      //             sourceTable: sourceDb.schemas[0].tables[this.selectedIndex].table,
      //             sourceDb: sourceDb.db
      //           });
      //         }
      //       });
      //     }
      //   } else {
      //     list.forEach((item) => {
      //       if (!item.inBlackList) {
      //         data.push({
      //           targetAutoCreate: item.targetAutoCreate,
      //           sourceColumn: item.column,
      //           sinkColumn: this.getTargetData(this.getMappingMethod('column'), sourceDb, sourceDb.tables[this.selectedIndex], item),
      //           sourceTable: sourceDb.tables[this.selectedIndex].table,
      //           sourceDb: sourceDb.db
      //         });
      //       }
      //     });
      //   }
      // } else {
      if (this.trueColumnData[sourceDb.db]) {
        if (DataSourceGroup.hasSchema.indexOf(this.jobData.sourceDsVO.dataSourceType) > -1) {
          if (DataSourceGroup.oracle.indexOf(this.jobData.sourceDsVO.dataSourceType) > -1) {
            if (this.filteredList[sourceDb.db].list[this.selectedIndex]
              && this.trueColumnData[sourceDb.db][this.filteredList[sourceDb.db].list[this.selectedIndex].table]) {
              this.trueColumnData[sourceDb.db][this.filteredList[sourceDb.db].list[this.selectedIndex].table].forEach((column) => {
                let sameItem = null;
                let inBlack = false;
                list.forEach((item) => {
                  if (column.columnName === item.column) {
                    sameItem = item;
                    if (item.inBlackList) {
                      inBlack = true;
                    }
                  }
                });
                if (sameItem && !inBlack) {
                  data.push({
                    targetAutoCreate: sameItem.targetAutoCreate,
                    sourceColumn: sameItem.column,
                    sinkColumn: this.getTargetData(this.getMappingMethod('column'), sourceDb, sourceDb.tableSpaces[0].tables[this.selectedIndex], sameItem),
                    sourceTable: sourceDb.tableSpaces[0].tables[this.selectedIndex].table,
                    sourceDb: sourceDb.db
                  });
                } else if (!sameItem) {
                  data.push({
                    targetAutoCreate: true,
                    sourceColumn: column.columnName,
                    sinkColumn: column.columnName,
                    sourceTable: sourceDb.tableSpaces[0].tables[this.selectedIndex].table,
                    sourceDb: sourceDb.db
                  });
                }
              });
            }
          } else {
            if (this.filteredList[sourceDb.db].list[this.selectedIndex]
              && this.trueColumnData[sourceDb.db][this.filteredList[sourceDb.db].list[this.selectedIndex].table]) {
              this.trueColumnData[sourceDb.db][this.filteredList[sourceDb.db].list[this.selectedIndex].table].forEach((column) => {
                let sameItem = null;
                let inBlack = false;
                list.forEach((item) => {
                  if (column.columnName === item.column) {
                    sameItem = item;
                    if (item.inBlackList) {
                      inBlack = true;
                    }
                  }
                });
                if (sameItem && !inBlack) {
                  data.push({
                    targetAutoCreate: sameItem.targetAutoCreate,
                    sourceColumn: sameItem.column,
                    sinkColumn: this.getTargetData(this.getMappingMethod('column'), sourceDb, sourceDb.schemas[0].tables[this.selectedIndex], sameItem),
                    sourceTable: sourceDb.schemas[0].tables[this.selectedIndex].table,
                    sourceDb: sourceDb.db
                  });
                } else if (!sameItem) {
                  data.push({
                    targetAutoCreate: true,
                    sourceColumn: column.columnName,
                    sinkColumn: column.columnName,
                    sourceTable: sourceDb.schemas[0].tables[this.selectedIndex].table,
                    sourceDb: sourceDb.db
                  });
                }
              });
            }
          }
        } else {
          if (this.filteredList[sourceDb.db] && this.filteredList[sourceDb.db].list[this.selectedIndex]
            && this.trueColumnData[sourceDb.db][this.filteredList[sourceDb.db].list[this.selectedIndex].table]) {
            this.trueColumnData[sourceDb.db][this.filteredList[sourceDb.db].list[this.selectedIndex].table].forEach((column) => {
              let sameItem = null;
              let inBlack = false;
              list.forEach((item) => {
                if (column.columnName === item.column) {
                  sameItem = item;
                  if (item.inBlackList) {
                    inBlack = true;
                  }
                }
              });
              if (sameItem && !inBlack) {
                data.push({
                  targetAutoCreate: sameItem.targetAutoCreate,
                  sourceColumn: sameItem.column,
                  sinkColumn: this.getTargetData(this.getMappingMethod('column'), sourceDb,
                    sourceDb.tables ? sourceDb.tables[this.selectedIndex] : sourceDb.schemas[0].tables[this.selectedIndex], sameItem),
                  sourceTable: this.filteredList[sourceDb.db].list[this.selectedIndex].table,
                  sourceDb: sourceDb.db
                });
              } else if (!sameItem) {
                data.push({
                  targetAutoCreate: true,
                  sourceColumn: column.columnName,
                  sinkColumn: column.columnName,
                  sourceTable: this.filteredList[sourceDb.db].list[this.selectedIndex].table,
                  sourceDb: sourceDb.db
                });
              }
            });
          }
        }
      }

      // }

      return data;
    },
    handleCopyMqSchema(data) {
      handleCopy(data);
      this.$Message.success('复制成功！');
    },
    handleFilterTable(ds, index) {
      const db = ds.db;

      if (this.filterName[db]) {
        this.beFilterList[index].tables = [];
        const tables = this.sourceSchema[index].tables ? this.sourceSchema[index].tables
          : this.sourceSchema[index].collections ? this.sourceSchema[index].collections : this.sourceSchema[index].tableSpaces;
        if (tables) {
          tables.forEach((item) => {
            if (item.tableSpace) {
              item.tables.forEach((table) => {
                if (table.table.toLowerCase()
                  .indexOf(this.filterName[db].toLowerCase()) > -1) {
                  this.beFilterList[index].tables.push(table);
                }
              });
            } else if (item.collection) {
              if (item.collection.toLowerCase()
                .indexOf(this.filterName[db].toLowerCase()) > -1) {
                this.beFilterList[index].tables.push(item);
              }
            } else {
              if (item.table.toLowerCase()
                .indexOf(this.filterName[db].toLowerCase()) > -1) {
                this.beFilterList[index].tables.push(item);
              }
            }
          });
        }
        this.filteredList[db].total = this.beFilterList[index].tables.length;
        this.filteredList[db].page = 1;
        this.filteredList[db].list = this.beFilterList[index].tables.slice(0, 4);
      } else {
        this.beFilterList = _.cloneDeep(this.sourceSchema);

        this.filteredList = _.cloneDeep(this.showTableMapping);
      }
      if (this.filteredList[db].list.length > 0) {
        this.handleChangeTable(ds, this.filteredList[ds.db].list[0], 0);
      }
    },
    handlePageChange(sourceDb, index, page) {
      this.filteredList[sourceDb.db].page = page;
      if (DataSourceGroup.hasSchema.indexOf(this.jobData.sourceDsVO.dataSourceType) > -1) {
        if (DataSourceGroup.pg.indexOf(this.jobData.sourceDsVO.dataSourceType) > -1) {
          this.filteredList[sourceDb.db].list = this.beFilterList[index].schemas[0].tables.slice((this.filteredList[sourceDb.db].page - 1) * this.filteredList[sourceDb.db].size,
            this.filteredList[sourceDb.db].page * this.filteredList[sourceDb.db].size);
        } else {
          this.filteredList[sourceDb.db].list = this.beFilterList[index].tableSpaces[0].tables.slice((this.filteredList[sourceDb.db].page - 1) * this.filteredList[sourceDb.db].size,
            this.filteredList[sourceDb.db].page * this.filteredList[sourceDb.db].size);
        }
      } else if (DataSourceGroup.mq.includes(this.jobData.sourceDsVO.dataSourceType)) {
        this.filteredList[sourceDb.db].list = this.targetSchema[index].tables.slice((this.filteredList[sourceDb.db].page - 1) * this.filteredList[sourceDb.db].size,
          this.filteredList[sourceDb.db].page * this.filteredList[sourceDb.db].size);
      } else if (isMongoDB(this.jobData.sourceDsVO.dataSourceType)) {
        this.filteredList[sourceDb.db].list = this.beFilterList[index].collections.slice((this.filteredList[sourceDb.db].page - 1) * this.filteredList[sourceDb.db].size,
          this.filteredList[sourceDb.db].page * this.filteredList[sourceDb.db].size);
      } else {
        this.filteredList[sourceDb.db].list = this.beFilterList[index].tables.slice((this.filteredList[sourceDb.db].page - 1) * this.filteredList[sourceDb.db].size,
          this.filteredList[sourceDb.db].page * this.filteredList[sourceDb.db].size);
      }
      this.filteredList = { ...this.filteredList };
      this.handleChangeTable(sourceDb, this.filteredList[sourceDb.db].list[0], 0);
    }
  }
};
</script>
