<template>
  <Modal :value="visible" title="测试连接" :mask-closable="false" @on-cancel="_handleCloseModal" width="380"
         footer-hide>
    <Form :model="form">
      <FormItem label="绑定集群" prop="clusterId">
        <Select v-model="form.clusterId" style="width:280px"
                filterable @on-change="handleChangeCluster">
          <Option v-for="(cluster) in clusterList" :value="cluster.id" :key="cluster.id"
                  :label="cluster.clusterDesc?cluster.clusterDesc:cluster.clusterName" :style="`${cluster.runningCount ? '' : 'cursor: not-allowed'}`">
            <p>{{ cluster.clusterName }}</p>
            <p style="color:#ccc;margin: 5px 0">
              {{ cluster.clusterDesc }}
              <span style="margin-left: 8px">{{ cluster.runningCount }}/{{
                  cluster.workerCount
                }}</span>
            </p>
          </Option>
        </Select>
      </FormItem>
      <FormItem label="" v-if="this.currentCluster.runningCount===0">
        <span>
          <span style="color: #FF6E0C">
            <i
              style="margin-left: 10px;margin-right: 8px"
              class="iconfont iconTIP"></i>该集群无存活机器，
          </span>
          <a :href="`/#/system/resource/${form.clusterId}`">管理机器</a>
        </span>
      </FormItem>
      <FormItem label="网络类型" prop="host">
<!--        <Select v-model="form.hostType" style="width:280px">-->
<!--          <Option value="public">外网</Option>-->
<!--          <Option value="private">内网</Option>-->
<!--        </Select>-->
        <RadioGroup v-model="form.hostType" @on-change="handleChangeHostType">
          <Radio label="PRIVATE" v-if="datasource.privateHost || datasource.host">
            内网
          </Radio>
          <Radio label="PUBLIC" v-if="datasource.publicHost">
            外网
          </Radio>
        </RadioGroup>
      </FormItem>
      <FormItem>
        <Button :loading="loading" @click="testConnection" style="margin-right: 10px;">测试连接</Button>
        <span v-if="hasTest">
          <Icon :type="testSuccess ? 'ios-checkmark-circle' : 'ios-close-circle'"
                :color="testSuccess ? 'green' : 'red'"/>
          {{ testSuccess ? '测试连接成功' : '测试连接失败' }}
        </span>
      </FormItem>
    </Form>
  </Modal>
</template>

<script>
import { listClusterByCondition } from '@services/api/cluster';
import { testConnectionBeforeAdd, testConnection } from '@services/api/datasource';
import DataSourceGroup from '../../../views/dataSourceGroup';

export default {
  name: 'TestConnectionModal',
  props: {
    visible: Boolean,
    datasource: Object,
    handleCloseModal: Function,
    type: {
      default: 'addDataSource'
    }
  },
  data() {
    return {
      taskInfo: {},
      clusterList: [],
      currentCluster: {},
      form: {
        clusterId: '',
        hostType: 'PRIVATE'
      },
      hasTest: false,
      testSuccess: false,
      loading: false
    };
  },
  methods: {
    handleChangeHostType() {
      this.hasTest = false;
      this.testSuccess = false;
      this.loading = false;
    },
    _handleCloseModal() {
      this.hasTest = false;
      this.testSuccess = false;
      this.loading = false;
      this.form = {
        clusterId: '',
        hostType: 'PRIVATE'
      };
      this.handleCloseModal();
    },
    separatePort(type) {
      return !['Kudu', 'Redis', 'ClickHouse', 'ElasticSearch', 'RabbitMQ', 'RocketMQ', 'Kafka', 'MongoDB', 'PolarDbX'].includes(type);
    },
    testConnection() {
      if (this.type === 'addDataSource') {
        this.testConnectionBeforeAdd();
      } else {
        this.loading = true;
        const instance = this.datasource;
        const data = `${instance.host}|${instance.id}|${instance.autoCreateAccount}
      |${instance.hasPassword}|${instance.privateHost}|${instance.publicHost}|${instance.instanceId}|${instance.securityType}`;
        this.taskInfo.clusterId = this.form.clusterId;
        this.taskInfo.sourceHostType = this.form.hostType;
        this.taskInfo.sourceType = instance.dataSourceType;
        this.taskInfo.sourceAccount = instance.accountName;
        this.taskInfo.sourceInstance = data.split('|')[0];
        this.taskInfo.sourceDataSourceId = data.split('|')[1];
        this.taskInfo.sourceAuto = data.split('|')[2];
        this.taskInfo.hasSourcePassword = JSON.parse(data.split('|')[3]);
        this.taskInfo.sourcePrivateHost = data.split('|')[4];
        this.taskInfo.sourceInstanceId = data.split('|')[6];
        this.taskInfo.sourceSecurityType = data.split('|')[7];
        if (this.taskInfo.sourceSecurityType === 'NONE') {
          this.taskInfo.sourceAccount = null;
          this.taskInfo.sourcePasswd = null;
        }
        if (data.split('|')[5] === 'null') {
          this.taskInfo.sourcePublicHost = '';
        } else {
          this.taskInfo.sourcePublicHost = data.split('|')[5];
        }
        if (this.taskInfo.sourceAuto === 'CREATED' || this.taskInfo.hasSourcePassword) {
          this.taskInfo.sourceAutoCreateAccount = true;
          this.taskInfo.sourceSpecifiedUserPassword = false;
        } else {
          this.taskInfo.sourceAutoCreateAccount = false;
          this.taskInfo.sourceSpecifiedUserPassword = true;
        }

        testConnection({
          host: this.form.hostType === 'PUBLIC' ? this.taskInfo.sourcePublicHost : this.taskInfo.sourcePrivateHost,
          privateHost: this.taskInfo.sourcePrivateHost,
          publicHost: this.taskInfo.sourcePublicHost,
          hostType: this.taskInfo.sourceHostType,
          type: this.taskInfo.sourceType,
          userName: DataSourceGroup.oracle.indexOf(this.taskInfo.sourceType) > -1
          && this.taskInfo.sourceAccountRole ? `${this.taskInfo.sourceAccount} as SYSDBA` : this.taskInfo.sourceAccount,
          password: this.taskInfo.sourcePasswd,
          dataSourceId: this.taskInfo.sourceDataSourceId,
          autoCreateAccount: this.taskInfo.sourceAutoCreateAccount,
          hasPassword: this.taskInfo.hasSourcePassword,
          clusterId: this.taskInfo.clusterId,
          specifiedUserPassword: this.taskInfo.sourceSpecifiedUserPassword
        }).then((res) => {
          this.hasTest = true;
          if (res.data.code === '1') {
            this.testSuccess = true;
            this.msg = '测试连接成功';
          } else {
            this.testSuccess = false;
            this.msg = '测试连接失败';
          }
        }).catch((e) => {
          console.log(e);
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    testConnectionBeforeAdd() {
      const {
        asSysDba,
        host,
        port,
        publicHost,
        publicPort,
        account,
        password,
        type,
        securityType,
        connectType,
        connectTypeValue
      } = this.datasource;
      const {
        hostType,
        clusterId
      } = this.form;
      const isSeparate = this.separatePort(type);
      this.loading = true;
      testConnectionBeforeAdd({
        clusterId,
        dsType: type,
        securityType,
        connectType,
        host: hostType === 'PUBLIC'
          ? isSeparate ? `${publicHost}:${publicPort}${type === 'Oracle' ? `:${connectTypeValue}` : ''}` : publicHost
          : isSeparate ? `${host}:${port}${type === 'Oracle' ? `:${connectTypeValue}` : ''}` : host,
        userName: `${account}${asSysDba ? ' as SYSDBA' : ''}`,
        password
      })
        .then((res) => {
          this.hasTest = true;
          if (res.data.code === '1') {
            this.testSuccess = true;
            this.msg = '测试连接成功';
          } else {
            this.testSuccess = false;
            this.msg = '测试连接失败';
          }
        }).finally(() => {
          this.loading = false;
        });
    },
    handleChangeCluster() {
      this.clusterList.forEach((cluster) => {
        if (cluster.id === this.form.clusterId) {
          this.currentCluster = cluster;
        }
      });
    },
    getCluster() {
      listClusterByCondition({})
        .then((res) => {
          if (res.data.code === '1') {
            this.clusterList = res.data.data;
            for (let i = 0; i < this.clusterList.length; i++) {
              if (this.clusterList[i].runningCount > 0) {
                this.form.clusterId = this.clusterList[i].id;
                return;
              }
            }
          }
        });
    }
  },
  created() {
    this.getCluster();
  },
  watch: {
    visible(val) {
      if (val) {
        this.getCluster();
      }
    },
    datasource: {
      deep: true,
      handler(val) {
        const hostType = val.privateHost || val.host ? 'PRIVATE' : 'PUBLIC';
        this.form.hostType = hostType;
      }
    }
  }
};
</script>

<style scoped lang="less">
.footer {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
