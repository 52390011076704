import DATASOURCE_TYPE from '@consts/datasourceType';

const CREATE_TASK_STEPS = {
  ORIGINAL: 'ORIGINAL',
  FUNCTIONAL: 'FUNCTIONAL',
  TABLE_FILTER: 'TABLE_FILTER',
  CLEAN_DATA: 'CLEAN_DATA'
};

export const DATASOURCE_DEPLOY_TYPE = {
  SELF_MAINTENANCE: 'SELF_MAINTENANCE',
  ALIBABA_CLOUD_HOSTED: 'ALIBABA_CLOUD_HOSTED'
};

export const HOST_TYPE = {
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE'
};

export const DATASOURCE_LIFECYCLE_STATE = {
  CREATED: 'CREATED'
};

export const CONNECT_TYPE = {
  ORACLE: [
    { label: 'ORACLE_SID', value: 'ORACLE_SID' },
    { label: 'ORACLE_SERVICE', value: 'ORACLE_SERVICE' }
  ]
};

export const ORACLE_CONTENT_TYPE = {
  ORACLE_SID: 'ORACLE_SID',
  ORACLE_SERVICE: 'ORACLE_SERVICE'
};

export {
  DATASOURCE_TYPE,
  CREATE_TASK_STEPS
};

export const MYSQL_DEFAULT_STRATEGY = [
  {
    label: 'NOTHING',
    value: 'NOTHING'
  },
  {
    label: 'ZERO',
    value: 'ZERO'
  },
  {
    label: 'IS_NULL',
    value: 'IS_NULL'
  },
  {
    label: 'CURRENT',
    value: 'CURRENT'
  }
];

export const PG_DEFAULT_STRATEGY = [
  {
    label: 'NOTHING',
    value: 'NOTHING'
  },
  {
    label: 'IS_NULL',
    value: 'IS_NULL'
  },
  {
    label: 'CURRENT',
    value: 'CURRENT'
  },
  {
    label: 'UTC_ZERO',
    value: 'UTC_ZERO'
  }
];

export const EDITIONS_I18N = {
  COMMUNITY_VERSION: '社区版',
  POC_VERSION: 'POC版',
  ENTERPRISE_VERSION: '企业版',
  EXPERIENCE_VERSION: '体验版',
  UNLIMITED: 'SVIP',
  FLAGSHIP_VERSION: '旗舰版'
};

export const EDITIONS = {
  COMMUNITY_VERSION: 'COMMUNITY_VERSION',
  POC_VERSION: 'POC_VERSION',
  ENTERPRISE_VERSION: 'ENTERPRISE_VERSION',
  EXPERIENCE_VERSION: 'EXPERIENCE_VERSION'
};

export const AUTH_SHOW_TYPE = {
  AUTHED: 'AUTHED',
  DIFF_AUTHED: 'DIFF_AUTHED',
  NOT_AUTHED: 'NOT_AUTHED'
};
