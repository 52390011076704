<template>
    <div style="margin-bottom: 74px">
        <Breadcrumb style="margin-bottom: 14px">
            <BreadcrumbItem>操作日志</BreadcrumbItem>
        </Breadcrumb>
        <div class="page-header-container">
            <Form ref="formInline" :model="searchData" inline label-position="left"
                  style="padding-right: 300px">
                <FormItem>
                    <Select v-model="searchType" style="width:160px" @on-change="handleChangeSearchType">
                        <Option value="time" label="操作时间">
                            <span>操作时间</span>
                        </Option>
                        <Option value="user" label="操作人" v-if="(store.state.urlLabels.indexOf('/')>-1||store.state.urlLabels.indexOf('/audit/querybyuid')>-1)&&store.state.userRole!=='ORG_ADMIN'">
                            <span>操作人</span>
                        </Option>
                    </Select>
                </FormItem>
                <FormItem v-if="searchType==='time'">
                    <DatePicker :editable="false" v-model="timeRange" type="datetimerange" format="yyyy-MM-dd HH:mm" style="width: 300px"></DatePicker>
                </FormItem>
                <FormItem v-if="searchType==='user'">
                    <Input v-model="searchData.userName" style="width: 250px"/>
                </FormItem>
                <FormItem>
                    <pd-button type="primary" @click="handleSearch">查询</pd-button>
                </FormItem>
            </Form>
            <div class="page-header-function">
                <pd-button type="default" style="margin-right: 6px" @click="handleSearch"
                        :loading="refreshLoading">
                    刷新
                </pd-button>
            </div>
        </div>
        <Table style="margin-top: 20px" size="small" border :columns="logColumn" :data="logData"></Table>
        <div class="page-footer-container">
            <div class="page-footer-paging">
                <pd-button :disabled="page===1" style="font-size: 16px;padding: 0 16px 0 10px" @click="handlePre"><Icon type="ios-arrow-back" style="font-size: 16px"/>上一页</pd-button>
                <span style="margin: 0 10px">第{{page}}页</span>
                <pd-button :disabled="noMoreData" style="font-size: 16px;padding: 0 16px 0 10px;margin-left: 5px" @click="handleNext">
                  <Icon type="ios-arrow-forward" style="font-size: 16px"/>下一页</pd-button>
            </div>
        </div>
    </div>
</template>
<script>
import { queryAllLog, getLogByUserName, getLogByUid } from '@services/api/operationLog';
import fecha from 'fecha';
import store from '../../store/index';

export default {
  components: {
  },
  data() {
    return {
      searchType: 'time',
      store,
      noMoreData: false,
      refreshLoading: false,
      firstId: 0,
      lastId: 0,
      prevFirst: [],
      page: 1,
      timeRange: [new Date(new Date().getTime() - 24 * 3600 * 1000), new Date()],
      searchData: {
        uid: '',
        userName: '',
        opStart: '',
        opEnd: '',
        // securityLevel:'',
        pageData: {
          startId: 0,
          pageSize: 20
        }
      },
      logColumn: [
        {
          title: '操作者',
          key: 'userName',
          width: 150,
          render: (h, params) => {
            if ((store.state.urlLabels.indexOf('/') > -1 || store.state.urlLabels.indexOf('/audit/querybyuid') > -1) && store.state.userRole !== 'ORG_ADMIN') {
              return h('a', {
                on: {
                  click: () => {
                    this.searchData.userName = params.row.userName;
                    this.searchData.uid = params.row.uid;
                    this.handleSearch();
                  }
                }
              }, params.row.userName);
            }
            return h('div', {}, params.row.userName);
          }
        },
        {
          title: '操作时间',
          key: 'operateDate',
          width: 200,
          render: (h, params) => h('div', {}, fecha.format(new Date(params.row.operateDate), 'YYYY-MM-DD HH:mm:ss'))
        },
        {
          title: '操作地址',
          key: 'sourceIp',
          width: 150
        },
        {
          title: '资源名称',
          key: 'alias',
          width: 300
        },
        {
          title: '操作资源',
          key: 'resourceValue'
        },
        {
          title: '日志唯一信息',
          key: 'uuidKey'
        },
        {
          title: '安全等级',
          key: 'securityLevel',
          width: 120,
          render: (h, params) => h('div', {
            style: {
              color: params.row.securityLevel === 'NORMAL' ? '#19be6b' : params.row.securityLevel === 'HIGH' ? '#ed4014' : ''
            }
          }, params.row.securityLevel === 'NORMAL' ? '普通' : params.row.securityLevel === 'HIGH' ? '高风险' : ''),
          filterMultiple: false,
          filters: [
            {
              label: '普通',
              value: 'NORMAL'
            },
            {
              label: '高风险',
              value: 'HIGH'
            }
          ],
          filterRemote(value) {
            this.searchData.securityLevel = value[0];
            this.handleSearch();
          }
        }
      ],
      logData: []
    };
  },
  mounted() {
    this.handleSearch();
    this.searchData.pageData.pageSize = 20;
  },
  methods: {
    handleSearch(type) {
      this.refreshLoading = true;
      if (this.timeRange.length > 0) {
        this.searchData.opStart = fecha.format(new Date(new Date(this.timeRange[0]).getTime() - 8 * 3600 * 1000), 'YYYY-MM-DDTHH:mm:ss.SSS');
        this.searchData.opEnd = fecha.format(new Date(new Date(this.timeRange[1].getTime() - 8 * 3600 * 1000)), 'YYYY-MM-DDTHH:mm:ss.SSS');
      } else {
        this.searchData.opStart = '';
        this.searchData.opEnd = '';
      }
      this.searchData.pageData.pageSize = 20;
      if (this.searchData.uid) {
        getLogByUid(this.searchData).then((res) => {
          if (res.data.code === '1') {
            this.logData = res.data.data;
            if (type === 'next') {
              if (!this.prevFirst[this.page - 1]) {
                this.prevFirst.push(this.firstId);
              }
            }
            this.firstId = this.logData[0].id;
            this.lastId = this.logData[this.logData.length - 1].id;
          }
          this.refreshLoading = false;
          this.noMoreData = res.data.data.length < this.searchData.pageData.pageSize;
        }).catch(() => {
          this.refreshLoading = false;
        });
      } else if (this.searchData.userName) {
        getLogByUserName(this.searchData).then((res) => {
          if (res.data.code === '1') {
            this.logData = res.data.data;
            if (type === 'next') {
              if (!this.prevFirst[this.page - 1]) {
                this.prevFirst.push(this.firstId);
              }
            }
            this.firstId = this.logData[0].id;
            this.lastId = this.logData[this.logData.length - 1].id;
          }
          this.refreshLoading = false;
          this.noMoreData = res.data.data.length < this.searchData.pageData.pageSize;
        }).catch(() => {
          this.refreshLoading = false;
        });
      } else {
        queryAllLog(this.searchData).then((res) => {
          if (res.data.code === '1') {
            this.logData = res.data.data;
            if (type === 'next') {
              if (!this.prevFirst[this.page - 1]) {
                this.prevFirst.push(this.firstId);
              }
            }
            this.firstId = this.logData[0].id;
            this.lastId = this.logData[this.logData.length - 1].id;
          }
          this.refreshLoading = false;
          this.noMoreData = res.data.data.length < this.searchData.pageData.pageSize;
        }).catch(() => {
          this.refreshLoading = false;
        });
      }
    },
    handlePre() {
      this.page--;
      let startId = this.prevFirst[this.page - 1] + 1;

      if (startId < 0) {
        startId = 0;
      }
      this.searchData.pageData.startId = startId;
      this.handleSearch('prev');
    },
    handleNext() {
      this.searchData.pageData.startId = this.lastId;
      this.handleSearch('next');
      this.page++;
    },
    handleChangeSize() {
      this.handleSearch('next');
    },
    handleChangeSearchType() {
      // 切换查询类型的时候，重置所有搜索的值
      this.searchData = {
        uid: '',
        userName: '',
        opStart: '',
        opEnd: '',
        // securityLevel:'',
        pageData: {
          startId: 0,
          pageSize: 20
        }
      };
    }
  }
};
</script>
