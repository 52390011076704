export function formatSeconds(value) {
  let theTime = parseInt(value, 10); // 秒
  let theTime1 = 0; // 分
  let theTime2 = 0; // 小时
  let theTime3 = 0;// 天

  if (theTime > 60) {
    theTime1 = parseInt(theTime / 60, 10);
    theTime = parseInt(theTime % 60, 10);
    if (theTime1 > 60) {
      theTime2 = parseInt(theTime1 / 60, 10);
      theTime1 = parseInt(theTime1 % 60, 10);
    }
    if (theTime2 > 24) {
      theTime3 = parseInt(theTime2 / 24, 10);
      theTime2 = parseInt(theTime2 % 24, 10);
    }
  }

  let result;// 秒

  if (theTime < 10 > 0) {
    result = `0${parseInt(theTime, 10)}秒`;// 秒
  } else {
    result = `${String(parseInt(theTime, 10))}秒`;// 秒
  }

  if (theTime1 < 10 > 0) {
    result = `0${parseInt(theTime1, 10)}分${result}`;// 分，不足两位数，首位补充0，
  } else {
    result = `${String(parseInt(theTime1, 10))}分${result}`;// 分
  }
  if (theTime2 > 0) {
    result = `${String(parseInt(theTime2, 10))}小时${result}`;// 时
  }
  if (theTime3 > 0) {
    result = `${String(parseInt(theTime3, 10))}天${result}`;// 天
  }

  return result;
}

export function formatCount(value) {
  let value1 = parseInt(value, 10);
  let value2 = 0; // 万
  let value3 = 0; // 亿

  let result = parseInt(value1, 10);

  if (value1 > 10000) {
    value2 = parseInt(value1 / 10000, 10);
    value1 = parseInt(value1 % 10000, 10);
    if (value2 > 10000) {
      value3 = parseInt(value2 / 10000, 10);
      value2 = parseInt(value2 % 10000, 10);
    }
  }
  if (value2 > 0) {
    result = `${String(parseInt(value2, 10))}万${String(parseInt(value1, 10)) > 0 ? String(parseInt(value1, 10)) : ''}`;
  }
  if (value3 > 0) {
    result = `${String(parseInt(value3, 10))}亿${String(parseInt(value2, 10)) > 0 ? `${String(parseInt(value2, 10))}万` : ''}${String(parseInt(value1, 10)) > 0 ? String(parseInt(value1, 10)) : ''}`;//
  }
  return result;
}

export function formatHour(value) {
  let theTime = parseInt(value, 10); // 小时
  let theTime3 = 0;// 天

  if (theTime > 24) {
    theTime3 = parseInt(theTime / 24, 10);
    theTime = parseInt(theTime % 24, 10);
  }

  let result = '';// 小时

  if (theTime > 0) {
    result = `${String(parseInt(theTime, 10))}小时${result}`;// 时
  }
  if (theTime3 > 0) {
    if (theTime3 > 100) {
      result = `${String(parseInt(theTime3, 10))}天`;// 天
    } else {
      result = `${String(parseInt(theTime3, 10))}天${result}`;// 天
    }
  }

  return result;
}
