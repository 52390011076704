<template>
  <Modal
    title="重启历史记录"
    :width="1200"
    v-model="visible"
  >
      <Table :columns="columns" :data="showRestartList"/>
    <Page @on-change="handlePageChange" :total="restartList.length" show-total :current="page"
          :page-size="pageSize" style="margin-top: 10px;text-align: right"/>
    <div slot="footer" class="modal-footer" style="margin-top: 20px">
      <pd-button @click="handleCloseModal">关闭</pd-button>
    </div>
  </Modal>
</template>

<script>
import dayjs from 'dayjs';
import { formatSeconds } from '@components/util';

export default {
  name: 'RestartListModal',
  props: {
    visible: Boolean,
    handleCloseModal: Function,
    restartList: Array
  },
  data() {
    return {
      page: 1,
      pageSize: 10,
      showRestartList: [],
      columns: [
        {
          title: '机器IP',
          key: 'scheduleWorkerIp'
        },
        {
          title: '首次绑定时间',
          render: (h, params) => h('div', dayjs(params.row.firstBindTime).locale('zh-cn').format('YYYY-MM-DD HH:mm:ss'))
        },
        {
          title: '当前时间距离首次绑定时间',
          render: (h, params) => h('div', `${formatSeconds(params.row.elapsedTimeMsAfterFirstBind / 1000)}前`)
        },
        {
          title: '当前时间距离上次启动时间',
          render: (h, params) => h('div', `${formatSeconds(params.row.elapsedTimeMsAfterLastStart / 1000)}`)
        },
        {
          title: '小时重启累计次数',
          key: 'hourStartCount'
        },
        {
          title: '天累计重启次数(24小时)',
          key: 'dayStartCount'
        },
        {
          title: '周累计重启次数(168小时)',
          key: 'weekStartCount'
        }
      ]
    };
  },
  mounted() {
    // console.log('restart');
    this.showRestartList = this.restartList.slice(this.pageSize * (this.page - 1), this.pageSize * this.page);
  },
  methods: {
    handlePageChange(page) {
      // console.log(page);
      this.page = page;
      this.showRestartList = this.restartList.slice(this.pageSize * (this.page - 1), this.pageSize * this.page);
    }
  },
  watch: {
    restartList() {
      // console.log('restartList');
      this.showRestartList = this.restartList.slice(this.pageSize * (this.page - 1), this.pageSize * this.page);
    }
  }
};
</script>

<style scoped>

</style>
