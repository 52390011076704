<template>
  <div>
    <Breadcrumb>
      <BreadcrumbItem to="/data/dataSource">数据源管理</BreadcrumbItem>
      <BreadcrumbItem>新增数据源</BreadcrumbItem>
    </Breadcrumb>
    <div class="add-datasource-wrapper">
      <div class="add-datasource-content">
        <DataSourceInfo :addDataSourceForm="addDataSourceForm" :deployMode="deployMode"
                        v-if="currentStep===0" ref="dataSourceInfo" :versionMap="versionMap"
                        :set-security-setting="setSecuritySetting"></DataSourceInfo>
        <AddWhiteList :clusters="clusters" :rdsList="addDataSourceForm.rdsList" ref="addwhiteList"
                      :addDataSourceForm="addDataSourceForm" :versionMap="versionMap"
                      v-if="currentStep===1&&addDataSourceForm.instanceType==='ALIBABA_CLOUD_HOSTED'"></AddWhiteList>
        <SetAccount :securitySetting="securitySetting" :addDataSourceForm="addDataSourceForm"
                    v-if="(currentStep===2&&addDataSourceForm.instanceType==='ALIBABA_CLOUD_HOSTED')
                            ||currentStep===1&&addDataSourceForm.instanceType!=='ALIBABA_CLOUD_HOSTED'"></SetAccount>
        <AddDataSourceInfo :addDataSourceForm="addDataSourceForm"
                           v-if="(currentStep===3&&addDataSourceForm.instanceType==='ALIBABA_CLOUD_HOSTED')
                                   ||(currentStep===2&&addDataSourceForm.instanceType!=='ALIBABA_CLOUD_HOSTED')"></AddDataSourceInfo>
        <SuccessAdd v-if="currentStep>3"></SuccessAdd>
      </div>
    </div>
    <div>
      <div class="add-dataSource-tools">
        <pd-button v-if="currentStep===0" @click="handleReturn">返回数据源管理</pd-button>
        <pd-button type="primary" v-if="currentStep!==0&&currentStep<4" @click="handleStep('pre')">
          上一步
        </pd-button>
        <pd-button type="primary"
                   :disabled="addDataSourceForm.instanceType==='ALIBABA_CLOUD_HOSTED'&&addDataSourceForm.rdsList.length===0"
                   @click="handleStep('next')" v-if="((currentStep!==2&&addDataSourceForm.instanceType==='ALIBABA_CLOUD_HOSTED')||
                           (currentStep!==0&&addDataSourceForm.instanceType!=='ALIBABA_CLOUD_HOSTED'))&&currentStep<4">
          下一步
        </pd-button>
        <pd-button type="primary" @click="handleAddDataSource"
                   v-if="(currentStep===2&&addDataSourceForm.instanceType==='ALIBABA_CLOUD_HOSTED')||
                           (currentStep===0&&addDataSourceForm.instanceType!=='ALIBABA_CLOUD_HOSTED')">
          新增数据源
        </pd-button>
      </div>
    </div>
    <Modal
      v-model="akskConfirm"
      title="提示"
      footer-hide
    >
      <div>
        <Alert type="error" show-icon>数据源没有填写aksk</Alert>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button @click="akskConfirm=false">关闭</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showAddConfirm"
      title="新增数据源确认"
      @on-ok="handleAdd"
      footer-hide
    >
      <div>
        <p v-if="DataSourceGroup.es.indexOf(addDataSourceForm.type)===-1&&dontAuto.length>0"
           style="font-weight: 500">
          您选择的某些数据源没有选择自动创建账号密码并且没有手动输入账号密码。如选择自动创建账号密码后cloudCanal会自动去对应RDS的数据源创建账号，
          创建数据任务时无需填写相关信息。如没有选择自动创建账号密码，则后续需要您亲自到RDS进行创建。</p>
        <p style="margin-top: 10px">确定要新增这些数据源吗？</p>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleAdd">确认</pd-button>
          <pd-button @click="handleCancel">取消</pd-button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import { queryDeployMode, dsMainVersions } from '@services/api/constant';
import { addDataSource } from '@services/api/upload';
import DataSourceInfo from '@components/function/addDataSource/DataSourceInfo';
import AddDataSourceInfo from '@components/function/addDataSource/AddDataSourceInfo';
import AddWhiteList from '@components/function/addDataSource/AddWhiteList';
import SetAccount from '@components/function/addDataSource/SetAccount';
import SuccessAdd from '@components/function/addDataSource/SuccessAdd';
import { separatePort } from '@utils';
import DataSourceGroup from '../dataSourceGroup';
import store from '../../store/index';

export default {
  components: {
    DataSourceInfo,
    AddDataSourceInfo,
    AddWhiteList,
    SetAccount,
    SuccessAdd
  },
  data() {
    return {
      akskConfirm: false,
      DataSourceGroup,
      store,
      currentStep: 0,
      showAddConfirm: false,
      deployMode: '',
      dontAuto: [],
      clusters: [],
      versionMap: {},
      addDataSourceForm: {
        dsKvConfigs: [],
        hostList: [{
          type: 'private', display: true, host: '', port: '3306'
        }, {
          type: 'public', display: false, host: '', port: '3306'
        }],
        // 额外的接口信息
        clientHostList: [{
          type: 'private', display: true, host: '', port: '8030'
        }, {
          type: 'public', display: false, host: '', port: '8030'
        }],
        oceanBaseRpcPortList: [{
          type: 'private', display: true, port: '2882'
        }, {
          type: 'public', display: false, port: '2882'
        }],
        connectType: null,
        connectTypeValue: '',
        host: '',
        type: 'MySQL',
        region: 'hangzhou',
        instanceType: 'ALIBABA_CLOUD_HOSTED',
        rdsList: [],
        aliyunAk: '',
        aliyunSk: '',
        instanceDesc: '',
        ifAkSK: 'true',
        port: '',
        publicHost: '',
        publicPort: '',
        hdfsSecurityType: 'NONE',
        account: '',
        password: '',
        hdfsPort: '8020',
        securityType: 'KERBEROS',
        hdfsDwDir: '/user/hive/warehouse',
        sid: '',
        service: '',
        accountRole: '',
        asSysDba: false,
        securityFile: ''
      },
      securitySetting: []
    };
  },
  mounted() {
    this.queryDeployMode();
    this.getMainVersion();
  },
  beforeDestroy() {
    store.state.rdsData = [];
    store.state.addedRdsList = [];
    store.state.firstAddDataSource = true;
    store.state.selectedCluster = {};
    store.state.clusterList = [];
  },
  methods: {
    separatePort,
    setSecuritySetting(setting) {
      this.securitySetting = setting;
    },
    getMainVersion() {
      dsMainVersions().then((res) => {
        this.versionMap = res.data.data;
      });
    },
    handleStep(type) {
      if (type === 'pre') {
        this.currentStep--;
        if (this.currentStep === 1) {
          this.addDataSourceForm.rdsList.map((item) => {
            if (item.clusters) {
              this.clusters[item.instanceId] = item.clusters;
            }
            return null;
          });
        }
      } else if (this.currentStep === 0) {
        this.securitySetting = this.$refs.dataSourceInfo.securitySetting;
        this.currentStep++;
      } else if (this.currentStep === 1) {
        if (this.addDataSourceForm.instanceType === 'SELF_MAINTENANCE' || this.addDataSourceForm.ifAkSK === 'false') {
          if (!this.addDataSourceForm.host) {
            this.$Modal.warning({
              title: '数据源添加失败',
              content: '请填写数据源信息'
            });
          } else if (this.addDataSourceForm.type === 'Hive'
            && (!this.addDataSourceForm.hdfsIp
              || !this.addDataSourceForm.hdfsPort
              || !this.addDataSourceForm.hdfsDwDir
              || !this.addDataSourceForm.hdfsSecurityType
              || this.addDataSourceForm.securityType === 'NONE'
              && !this.addDataSourceForm.account
              || this.addDataSourceForm.securityType === 'KERBEROS'
              && !this.addDataSourceForm.hdfsPrincipal)) {
            this.$Modal.warning({
              title: '数据源添加失败',
              content: '请填写完整的数据源信息'
            });
          } else {
            this.currentStep++;
          }
        } else if (this.addDataSourceForm.rdsList.length > 0) {
          const noClusterDataSource = [];

          this.addDataSourceForm.rdsList.map((item) => {
            if (!item.clusters || item.clusters.length < 1) {
              noClusterDataSource.push(item);
            }
            return null;
          });
          if (noClusterDataSource.length > 0) {
            this.$Modal.confirm({
              title: '数据源添加提示',
              content: '您当前已有添加机器，确认当前所选数据源不对此添加白名单？如不添加，后续请至数据源管理添加。',
              onOk: () => {
                this.currentStep++;
              }
            });
          } else {
            this.currentStep++;
          }
        } else {
          this.$Modal.warning({
            title: '数据源添加失败',
            content: '请选择至少一个数据源'
          });
        }
      } else {
        this.currentStep++;
      }
    },
    handleAddDataSource() {
      if (this.addDataSourceForm.instanceType === 'ALIBABA_CLOUD_HOSTED') {
        this.dontAuto = [];
        let aksk = true;
        this.addDataSourceForm.rdsList.map((rds) => {
          if (!rds.autoCreate && (!rds.account || !rds.password)) {
            this.dontAuto.push(rds.instanceId);
          }
          if (this.securitySetting[0] && this.securitySetting[0].needExtraAliyunAkSk && (!rds.accessKey || !rds.secretKey)) {
            aksk = false;
          }
          return null;
        });
        if (!aksk) {
          this.akskConfirm = true;
        } else if (this.dontAuto.length > 0) {
          this.showAddConfirm = true;
        } else {
          this.handleAdd();
        }
      } else {
        this.$refs.dataSourceInfo.$refs.addLocalDs.validate((val) => {
          console.log('local ds', val);
          if (val) {
            const { hostList } = this.addDataSourceForm;
            if (hostList[0].type === 'public') {
              this.addDataSourceForm.publicHost = hostList[0].host;
              this.addDataSourceForm.publicPort = hostList[0].port;
              this.addDataSourceForm.host = hostList[1].host;
              this.addDataSourceForm.port = hostList[1].port;
            } else {
              this.addDataSourceForm.publicHost = hostList[1].host;
              this.addDataSourceForm.publicPort = hostList[1].port;
              this.addDataSourceForm.host = hostList[0].host;
              this.addDataSourceForm.port = hostList[0].port;
            }
            this.dontAuto = [];
            this.addDataSourceForm.rdsList.map((rds) => {
              if (!rds.autoCreate && (!rds.account || !rds.password)) {
                this.dontAuto.push(rds.instanceId);
              }
              return null;
            });
            this.handleAdd();
          }
        });
      }
    },
    queryDeployMode() {
      queryDeployMode()
        .then((res) => {
          if (res.data.code === '1') {
            this.deployMode = res.data.data;
          }
        });
    },
    handleAdd() {
      let privateExtraHost = '';
      let publicExtraHost = '';
      let privateOceanBaseRpcPort = '';
      let publicOceanBaseRpcPort = '';

      const { clientHostList, oceanBaseRpcPortList } = this.addDataSourceForm;
      if (clientHostList[0].type === 'public') {
        if (clientHostList[0].host) {
          publicExtraHost = this.separatePort(this.addDataSourceForm.type) ? `${clientHostList[0].host}:${clientHostList[0].port}` : `${clientHostList[0].host}`;
        }
        if (clientHostList[1].host) {
          privateExtraHost = this.separatePort(this.addDataSourceForm.type) ? `${clientHostList[1].host}:${clientHostList[1].port}` : `${clientHostList[1].host}`;
        }
      } else {
        if (clientHostList[0].host) {
          privateExtraHost = this.separatePort(this.addDataSourceForm.type) ? `${clientHostList[0].host}:${clientHostList[0].port}` : `${clientHostList[0].host}`;
        }
        if (clientHostList[1].host) {
          publicExtraHost = this.separatePort(this.addDataSourceForm.type) ? `${clientHostList[1].host}:${clientHostList[1].port}` : `${clientHostList[1].host}`;
        }
      }

      if (oceanBaseRpcPortList[0].type === 'public') {
        if (oceanBaseRpcPortList[0].display) {
          publicOceanBaseRpcPort = oceanBaseRpcPortList[0].port;
        }
        if (oceanBaseRpcPortList[1].display) {
          privateOceanBaseRpcPort = oceanBaseRpcPortList[1].port;
        }
      } else {
        if (oceanBaseRpcPortList[0].display) {
          privateOceanBaseRpcPort = oceanBaseRpcPortList[0].port;
        }
        if (oceanBaseRpcPortList[1].display) {
          publicOceanBaseRpcPort = oceanBaseRpcPortList[1].port;
        }
      }

      this.showAddConfirm = false;
      if (this.addDataSourceForm.instanceType === 'SELF_MAINTENANCE' && !this.addDataSourceForm.host && !this.addDataSourceForm.publicHost) {
        this.$Modal.warning({
          title: '添加数据源提示',
          content: '请填写完整且正确的数据源地址'
        });
      } else if (this.addDataSourceForm.instanceType === 'SELF_MAINTENANCE') {
        const { connectTypeValue, dsKvConfigs } = this.addDataSourceForm;
        const formData = new FormData();
        const isSeparate = this.separatePort(this.addDataSourceForm.type);
        const host = isSeparate ? (this.addDataSourceForm.host && this.addDataSourceForm.port) ? `${this.addDataSourceForm.host}:${this.addDataSourceForm.port}` : ''
          : this.addDataSourceForm.host;
        const publicHost = isSeparate ? (this.addDataSourceForm.publicHost && this.addDataSourceForm.publicPort) ? `${this.addDataSourceForm.publicHost}:${this.addDataSourceForm.publicPort}` : ''
          : this.addDataSourceForm.publicHost;

        const kvConfigs = [];
        if (dsKvConfigs.length) {
          dsKvConfigs.forEach((config) => {
            const { configName, currentCount, defaultValue } = config;
            kvConfigs.push({
              configName,
              configValue: currentCount || defaultValue
            });
          });
        }
        const DataSourceAddData = {
          host: host && this.addDataSourceForm.type === 'Oracle' ? `${host}:${connectTypeValue}` : host,
          privateHost: host && this.addDataSourceForm.type === 'Oracle' ? `${host}:${connectTypeValue}` : host,
          publicHost: publicHost && this.addDataSourceForm.type === 'Oracle'
            ? `${publicHost}:${connectTypeValue}` : publicHost,
          region: this.addDataSourceForm.region,
          type: this.addDataSourceForm.type,
          connectType: this.addDataSourceForm.connectType,
          deployType: this.addDataSourceForm.instanceType,
          instanceDesc: this.addDataSourceForm.instanceDesc,
          hostType: 'PRIVATE',
          account: DataSourceGroup.oracle.indexOf(this.addDataSourceForm.type) > -1
            ? this.addDataSourceForm.asSysDba
              ? `${this.addDataSourceForm.account} as SYSDBA` : this.addDataSourceForm.account : this.addDataSourceForm.account,
          instanceId: this.addDataSourceForm.instanceId,
          password: this.addDataSourceForm.password,
          securityType: this.addDataSourceForm.securityType,
          clientTrustStorePassword: this.addDataSourceForm.clientTrustStorePassword,
          dsKvConfigs: kvConfigs,
          extraData: {
            hdfsIp: this.addDataSourceForm.hdfsIp,
            hdfsPort: this.addDataSourceForm.hdfsPort,
            hdfsDwDir: this.addDataSourceForm.hdfsDwDir,
            hdfsPrincipal: this.addDataSourceForm.hdfsPrincipal,
            privateStarRocksHttpHost: privateExtraHost,
            publicStarRocksHttpHost: publicExtraHost,
            privateOceanBaseLogProxyHost: privateExtraHost,
            publicOceanBaseLogProxyHost: publicExtraHost,
            privateOceanBaseRpcPort,
            publicOceanBaseRpcPort
          },
          version: this.addDataSourceForm.version
        };

        formData.append('secretFile', this.addDataSourceForm.secretFile);
        formData.append('securityFile', this.addDataSourceForm.securityFile);
        formData.append('DataSourceAddData', JSON.stringify(DataSourceAddData));
        addDataSource(formData)
          .then((res) => {
            if (res.data.code === '1') {
              this.currentStep = 4;
            }
          });
      } else if (this.addDataSourceForm.ifAkSK === 'true') {
        if (this.getSecurity(this.addDataSourceForm.rdsList[0].securityType).needExtraAliyunAkSk) {
          let ifHasAKSK = true;

          this.addDataSourceForm.rdsList.map((rds) => {
            if (!rds.accessKey || !rds.secretKey) {
              ifHasAKSK = false;
            }
            return null;
          });
          if (!ifHasAKSK) {
            console.log('ifhasaksk', ifHasAKSK);
            this.$Modal.warning({
              title: '添加数据源提示',
              content: '有数据源没有填写完整的阿里云AK/SK，请填写完整后再添加'
            });
            return;
          }
        }
        this.addDataSourceForm.rdsList.map((rds) => {
          const list = [];

          if (rds.clusters) {
            rds.clusters[rds.instanceId].map((item) => {
              list.push(item.id);
              return null;
            });
          }
          // eslint-disable-next-line one-var
          const formData = new FormData(),
            DataSourceAddData = {
              instanceId: rds.instanceId,
              host: rds.host,
              instanceDesc: rds.instanceDesc,
              hostType: rds.hostType,
              autoCreateAccount: rds.autoCreate,
              password: rds.password,
              account: rds.account,
              clusterIds: list,
              whiteListAddType: rds.whiteListAddType,
              privateHost: rds.privateHost,
              publicHost: rds.publicHost,
              type: rds.dataSourceType,
              securityType: rds.securityType,
              region: this.addDataSourceForm.region,
              deployType: this.addDataSourceForm.instanceType,
              accessKey: rds.accessKey,
              secretKey: rds.secretKey,
              clientTrustStorePassword: rds.clientTrustStorePassword,
              version: rds.version || rds.selectedVersion
            };

          formData.append('secretFile', rds.secretFile);
          formData.append('securityFile', rds.securityFile);
          formData.append('DataSourceAddData', JSON.stringify(DataSourceAddData));
          addDataSource(formData)
            .then((res) => {
              if (res.data.code === '1') {
                this.currentStep = 4;
              }
            });
          return null;
        });
      } else {
        const formData = new FormData();
        const
          DataSourceAddData = {
            host: this.addDataSourceForm.host,
            privateHost: this.addDataSourceForm.host,
            publicHost: `${this.addDataSourceForm.publicHost}:${this.addDataSourceForm.publicPort}`,
            hostType: 'PUBLIC',
            region: this.addDataSourceForm.region,
            type: this.addDataSourceForm.type,
            deployType: this.addDataSourceForm.instanceType,
            instanceDesc: this.addDataSourceForm.instanceDesc,
            account: this.addDataSourceForm.account,
            password: this.addDataSourceForm.password,
            instanceId: this.addDataSourceForm.instanceId
          };

        formData.append('secretFile', this.addDataSourceForm.secretFile);
        formData.append('securityFile', this.addDataSourceForm.securityFile);
        formData.append('DataSourceAddData', JSON.stringify(DataSourceAddData));
        addDataSource(formData)
          .then((res) => {
            if (res.data.code === '1') {
              this.currentStep = 4;
            }
          });
      }
    },
    handleReturn() {
      this.$router.push({ path: '/data/dataSource' });
    },
    handleReset() {
      this.addDataSourceForm = {
        host: '',
        publicHost: '',
        publicPort: '',
        type: 'MySQL',
        region: 'hangzhou',
        instanceType: 'ALIBABA_CLOUD_HOSTED',
        rdsList: [],
        aliyunAk: '',
        aliyunSk: '',
        instanceDesc: '',
        ifAkSK: 'true',
        port: '3306',
        hdfsSecurityType: 'NONE',
        account: '',
        hdfsPort: '8020',
        securityType: 'KERBEROS',
        securityFile: '',
        hdfsDwDir: '/user/hive/warehouse'
      };
    },
    handleCancel() {
      this.showAddConfirm = false;
    },
    getSecurity(type) {
      let security = {};

      this.securitySetting.map((item) => {
        if (item.securityType === type) {
          security = item;
        }
        return null;
      });
      return security;
    }
  }
};
</script>
<style lang="less">
.add-datasource-wrapper {
  background: #ffffff;
  margin-top: 16px;
  border: 1px solid #DADADA;

  .add-datasource-content {
    /*padding: 20px;*/
    margin-bottom: 60px;
  }
}

.add-dataSource-step {
  padding: 30px 380px;
  border-bottom: 1px solid #DADADA;
}

.add-dataSource-tools {
  /*margin-top: 20px;*/
  position: fixed;
  bottom: 0;
  left: 0;
  text-align: center;
  background: #ffffff;
  width: 100%;
  line-height: 60px;
  height: 60px;
  z-index: 99;
  box-shadow: 6px 2px 23px 0 rgba(197, 197, 197, 0.5);

  button {
    margin: 0 8px;
  }
}
</style>
