import { instance } from '../instance';

export const listAlertConfig = (param) => instance.post('alert/list/config', param);

export const listAlertIndicators = (param) => instance.post('alert/list/indicators', param);

export const updateAlertConfigs = (param) => instance.post('alert/updates', param);

export const listAlertEventLogs = (param) => instance.post('alert/event/list', param);

export const listAlertAlarmLevel = (param) => instance.post('alert/list/alarmlevel', param);

export const modifyJobAlertReceiver = (param) => instance.post('alert/modifyjobalertreceiver', param);
