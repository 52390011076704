// eslint-disable-next-line func-style,max-statements
export const getTargetName = (rule, db, item, taskInfo, type) => {
  db = db.db ? db.db : db;
  let sinkTable = item.tableName || item.sourceTable;

  if (rule) {
    if (type === 'edit') {
      const theDb = db.db || db;

      if (rule.rule === 'SOURCE_INS_DB_BY_DOT') {
        sinkTable = `${taskInfo.instanceId}.${theDb}`;
      } else if (rule.rule === 'SOURCE_INS_DB_TABLE_BY_DOT') {
        sinkTable = `${taskInfo.instanceId}.${theDb}.${item.tableName}`;
      } else if (rule.rule === 'SOURCE_INS_DB_SCHEMA_TABLE_BY_DOT') {
        sinkTable = `${taskInfo.instanceId}.${theDb}.${item.sourceSchema}` || `${item.schemaName}.${item.tableName}`;
      } else if (rule.rule === 'SOURCE_INS_DB_BY_PERCENT') {
        sinkTable = `${taskInfo.instanceId}%${theDb}`;
      } else if (rule.rule === 'SOURCE_INS_DB_TABLE_BY_PERCENT') {
        sinkTable = `${taskInfo.instanceId}%${theDb}%${item.tableName}`;
      } else if (rule.rule === 'SOURCE_INS_DB_SCHEMA_TABLE_BY_PERCENT') {
        sinkTable = `${taskInfo.instanceId}%${theDb}%${item.sourceSchema}%${item.tableName}`;
      } else if (rule.rule === 'SOURCE_INS_DB') {
        sinkTable = `${taskInfo.instanceId}_${theDb}`;
      } else if (rule.rule === 'SOURCE_INS_DB_SCHEMA_TABLE') {
        sinkTable = `${taskInfo.instanceId}_${theDb}_${item.sourceSchema}_${item.tableName}`;
      } else if (rule.rule === 'SOURCE_INS_DB_TABLE') {
        sinkTable = `${taskInfo.instanceId}_${theDb}_${item.tableName}`;
      } else if (rule.rule === 'SOURCE_LAST_ITEM_BY_DOT') {
        if (item.sourceTable) {
          const itemList = item.sourceTable.split('.');

          sinkTable = itemList[item.sourceTable.split('.').length - 1];
        } else if (item.tableName) {
          const itemList = item.tableName.split('.');

          sinkTable = itemList[item.tableName.split('.').length - 1];
        } else {
          sinkTable = '';
        }
      } else if (rule.rule === 'SOURCE_LAST_ITEM_BY_PERCENT') {
        const sourceTable = item.sourceTable || item.tableName;
        if (sourceTable) {
          const itemList = sourceTable.split('%');

          sinkTable = itemList[sourceTable.split('%').length - 1];
        }
      } else if (rule.rule === 'TO_LOWER_CASE') {
        sinkTable = sinkTable.toLowerCase();
      } else if (rule.rule === 'TO_UPPER_CASE') {
        sinkTable = sinkTable.toUpperCase();
      } else if (rule.rule === 'SOURCE_DB_TABLE_BY_COLON') {
        sinkTable = `${theDb}:${item.tableName}`;
      }
    } else {
      if (rule.rule === 'SOURCE_INS_DB_BY_DOT') {
        sinkTable = `${taskInfo.sourceInstanceId}.${db}`;
      } else if (rule.rule === 'SOURCE_INS_DB_TABLE_BY_DOT') {
        sinkTable = `${taskInfo.sourceInstanceId}.${db}.${sinkTable}`;
      } else if (rule.rule === 'SOURCE_INS_DB_SCHEMA_TABLE_BY_DOT') {
        let schema = item.sourceSchema;

        if (!schema) {
          schema = item.schemaName;
        }

        sinkTable = `${taskInfo.sourceInstanceId}.${db}.${schema}.${sinkTable}`;
      } else if (rule.rule === 'SOURCE_INS_DB_BY_PERCENT') {
        sinkTable = `${taskInfo.sourceInstanceId}%${db}`;
      } else if (rule.rule === 'SOURCE_INS_DB_TABLE_BY_PERCENT') {
        sinkTable = `${taskInfo.sourceInstanceId}%${db}%${sinkTable}`;
      } else if (rule.rule === 'SOURCE_INS_DB_SCHEMA_TABLE_BY_PERCENT') {
        sinkTable = `${taskInfo.sourceInstanceId}%${db}%${item.sourceSchema}%${sinkTable}`;
      } else if (rule.rule === 'SOURCE_INS_DB') {
        sinkTable = `${taskInfo.sourceInstanceId}_${db}`;
      } else if (rule.rule === 'SOURCE_INS_DB_SCHEMA_TABLE') {
        sinkTable = `${taskInfo.sourceInstanceId}_${db}_${item.sourceSchema}_${sinkTable}`;
      } else if (rule.rule === 'SOURCE_INS_DB_TABLE') {
        sinkTable = `${taskInfo.sourceInstanceId}_${db}_${sinkTable}`;
      } else if (rule.rule === 'SOURCE_LAST_ITEM_BY_DOT') {
        if (item.sourceTable || item.tableName) {
          if (item.tableName) {
            const itemList = item.tableName.split('.');

            sinkTable = itemList[item.tableName.split('.').length - 1];
          } else {
            const itemList = item.sourceTable.split('.');

            sinkTable = itemList[item.sourceTable.split('.').length - 1];
          }
        } else {
          sinkTable = '';
        }
      } else if (rule.rule === 'SOURCE_LAST_ITEM_BY_PERCENT') {
        const sourceTable = item.sourceTable || item.tableName;
        if (sourceTable) {
          const itemList = sourceTable.split('%');

          sinkTable = itemList[sourceTable.split('%').length - 1];
        }
      } else if (rule.rule === 'TO_LOWER_CASE') {
        sinkTable = sinkTable.toLowerCase();
      } else if (rule.rule === 'TO_UPPER_CASE') {
        sinkTable = sinkTable.toUpperCase();
      } else if (rule.rule === 'NUMBER_SUFFIX_REG_EXPRESSION') {
        if (sinkTable.match(new RegExp(rule.expr))) {
          sinkTable = sinkTable.match(new RegExp(rule.expr))[1];
        }
      } else if (rule.rule === 'KUDU_PRESTO_FORMAT') {
        sinkTable = `presto.${db}.${item.sourceSchema ? `${item.sourceSchema}.` : ''}${sinkTable}`;
      } else if (rule.rule === 'KUDU_IMPALA_FORMAT') {
        sinkTable = `impala::${db}.${item.sourceSchema ? `${item.sourceSchema}.` : ''}${sinkTable}`;
      } else if (rule.rule === 'SOURCE_INS_DB_SCHEMA_TABLE_BY_UNDERLINE') {
        sinkTable = (`cc_${taskInfo.sourceInstanceId}_${db}_${item.sourceTable || item.tableName}`).toLowerCase();
      } else if (rule.rule === 'SOURCE_DB_TABLE_BY_COLON') {
        sinkTable = `${db}:${sinkTable}`;
      }
    }
    return sinkTable || '';
  }
  return sinkTable || '';
};

// eslint-disable-next-line one-var
export const handleCopy = (data) => {
  const input = document.createElement('textarea');

  input.value = data;
  document.body.appendChild(input);
  input.select();
  document.execCommand('Copy');
  document.body.removeChild(input);
};
