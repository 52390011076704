<template>
  <Modal
    v-model="showAkSk"
    width="720px"
    footer-hide
    title="阿里云临时访问权限">
    <div>
      <Alert type="warning" show-icon>
        <p>阿里云AK/SK需要赋予必要的权限。<a href="https://doc-cloudcanal.clougence.com/operation/aliyun_ram_sts_auth"
                                target="_blank">如何赋权？</a></p>
        <p style="margin-top: 8px">阿里云AK/SK仅用来申请阿里云临时访问权限，CloudCanal不会存储。</p>
      </Alert>
      <Form style="margin-top: 12px" label-position="right" :label-width="130">
        <FormItem label="AccessKey ID" prop="aliyunAk">
          <Input v-model="aliyunAk" style="width: 280px"/>
        </FormItem>
        <FormItem label="AccessKey Secret" prop="aliyunSk">
          <Input v-model="aliyunSk" type="password" password style="width: 280px"/>
          <a style="margin-left: 10px;color: #535C70"
             href="https://doc-cloudcanal.clougence.com/operation/aliyun_ram_sts_auth"
             target="_blank">如何获取AK/SK？</a>
        </FormItem>
      </Form>
      <div slot="footer" class="modal-footer" style="margin-top: 20px">
        <pd-button type="primary" @click="handleApplyStToken">确定</pd-button>
        <pd-button @click="handleCancelEdit">取消</pd-button>
      </div>
    </div>
  </Modal>
</template>
<script>
import { applyStsToken } from '@services/api/stToken';

export default {
  props: {
    // nextStep:Function
  },
  data() {
    return {
      aliyunAk: '',
      aliyunSk: '',
      showAkSk: false
    };
  },
  methods: {
    handleApplyStToken() {
      this.showAkSk = false;
      applyStsToken({
        userAk: this.aliyunAk,
        userSk: this.aliyunSk
      })
        .then((res) => {
          if (res.data.code === '1') {
            // if (this.nextStep){
            //     this.nextStep();
            // }
          }
        });
    },
    handleShowAkSk() {
      this.showAkSk = true;
    },
    handleCancelEdit() {
      this.showAkSk = false;
    }
  }
};
</script>
