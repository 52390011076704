import TaskList from '../../views/data/JobList';
import TaskDetail from '../../views/data/JobDetail';
import Process from '../../views/data/Process';
import ParamsEdit from '../../views/data/ParamsEdit';
import DataSource from '../../views/dataSource/DataSource';
import AddDataSouce from '../../views/dataSource/AddDataSouce';
import TaskGroup from '../../views/data/TaskGroup';

export default [
  {
    path: '/data/job/list',
    name: 'taskList',
    component: TaskList,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/data/job/create/process',
    name: 'taskCreate/process',
    component: Process
  },
  {
    path: '/data/job/edit/process',
    name: 'taskEdit/process',
    component: Process
  },
  {
    path: '/data/job/:id',
    name: 'task/id',
    component: TaskDetail
  },
  {
    path: '/data/job/:id/params',
    name: 'task/id/params',
    component: ParamsEdit
  },
  {
    path: '/data/dataSource',
    name: 'dataSource',
    component: DataSource
  },
  {
    path: '/data/dataSource/add',
    name: 'dataSource/add',
    component: AddDataSouce
  },
  {
    path: '/data/job/group/:id',
    name: 'data/job/group/id',
    component: TaskGroup
  }
];
