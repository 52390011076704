<template>
  <div>
    <div class="job-detail-container">
      <Breadcrumb>
        <BreadcrumbItem to="/data/job/list">任务管理</BreadcrumbItem>
        <BreadcrumbItem>任务详情</BreadcrumbItem>
      </Breadcrumb>
      <div class="refresh-btns">
        <Checkbox @on-change="handleInterval">10秒定时刷新</Checkbox>
        <pd-button @click="handleRefresh" :loading="refreshLoading">刷新</pd-button>
      </div>
      <div class="job-detail-content">
        <div class="job-header">
                    <span class="job-header-db">
                        <i class="iconfont iconshujuku"></i>
                    </span>
          <div class="job-header-name">
            <p>
              <span class="job-header-name-main">{{ jobData.dataJobName }}</span>
              <svg class="icon job-info-status job-info-status-running" aria-hidden="true">
                <use :xlink:href="'#'+getStatusIcon(jobData)"></use>
              </svg>
              <span
                :style="`font-family:PingFangSC-Semibold;color:${getFontStepColor}`">{{
                  getStatusName(jobData)
                }}</span>
            </p>
            <span class="job-header-name-desc">{{
                jobData.dataJobDesc ? jobData.dataJobDesc : '暂无描述'
              }}<i
                style="margin-left: 4px" class="iconfont iconbianji" @click="handleEditJobDesc"></i></span>
          </div>
          <div class="task-detail-step" v-if="ifShowStep">
            <DataJobStep :currentStep="currentStep" :showStep="showStep" :jobData="jobData"
                         :currentStatus="currentStatus"></DataJobStep>
          </div>
          <div class="job-header-buttons">
            <Dropdown style="margin-right: 8px">
              <pd-button type="default">
                功能列表
                <Icon style="font-size: 15px" type="md-apps" />
              </pd-button>
              <DropdownMenu slot="list">
                <DropdownItem
                  :disabled="!jobData.childJobs||jobData.childJobs.length===0">
                  <div @click="handleGoTaskGroup">关联任务</div>
                </DropdownItem>
                <DropdownItem v-if="jobData.havePackage">
                  <div @click="handleShowPackageList">代码包管理</div>
                </DropdownItem>
                <DropdownItem v-if="!store.state.urlLabels.indexOf('/')>-1||store.state.urlLabels.indexOf('/datajob/delete')>-1">
                  <div @click="handleParamsEdit">
                    参数修改
                  </div>
                </DropdownItem>
                <DropdownItem :disabled="Boolean(parentDataJobId) || (jobData.dataTaskState!=='INCRE'&&jobData.dataTaskState!=='CATCH_UP'||
                         !DataSourceGroup.editSource.includes(jobData.sourceDsVO.dataSourceType)||
                         DataSourceGroup.noEditTarget.includes(jobData.targetDsVO.dataSourceType)
                         ||(store.state.urlLabels.indexOf('/')===-1&&
                         store.state.urlLabels.indexOf('/datajob/start')===-1))">
                  <div v-if="!Boolean(parentDataJobId) && !(jobData.dataTaskState!=='INCRE'&&jobData.dataTaskState!=='CATCH_UP'||
                         !DataSourceGroup.editSource.includes(jobData.sourceDsVO.dataSourceType)||
                         DataSourceGroup.noEditTarget.includes(jobData.targetDsVO.dataSourceType)
                         ||(store.state.urlLabels.indexOf('/')===-1&&
                         store.state.urlLabels.indexOf('/datajob/start')===-1))" @click="handleEditJob">
                    修改订阅
                  </div>
                  <Tooltip v-else content="只有支持的数据源类型并且在增量同步阶段的主任务可以进行订阅修改" transfer placement="right">
                    <p>修改订阅</p>
                  </Tooltip>
                </DropdownItem>
                <!--                <DropdownItem-->
                <!--                    v-if="store.state.urlLabels.indexOf('/')>-1||store.state.urlLabels.indexOf('/datajob/delete')>-1">-->
                <!--                  <div @click="handleShowLog">查看日志</div>-->
                <!--                </DropdownItem>-->
                <DropdownItem
                  v-if="store.state.urlLabels.indexOf('/')>-1||store.state.urlLabels.indexOf('/datajob/delete')>-1">
                  <div @click="handleAlarmConfig">告警配置</div>
                </DropdownItem>
                <DropdownItem
                  v-if="store.state.urlLabels.indexOf('/')>-1||store.state.urlLabels.indexOf('/datajob/delete')>-1">
                  <div @click="handlePDeleteJob">删除</div>
                </DropdownItem>
                <DropdownItem
                  v-if="(store.state.urlLabels.indexOf('/')>-1||store.state.urlLabels.indexOf('/datajob/delete')>-1)
                  &&!DataSourceGroup.noDb.includes(jobData.sourceDsVO.dataSourceType)">
                  <div @click="handleCreateSimilarJob">创建相似任务</div>
                </DropdownItem>
                <DropdownItem>
                  <div @click="handleShowRestartList">重启历史记录</div>
                </DropdownItem>
                <DropdownItem v-if="jobData.dataJobType==='CHECK'&&jobTypeList.REVISE">
                  <div @click="handleCreateSimilarJob('revise')">创建数据订正任务</div>
                </DropdownItem>
                <DropdownItem>
                  <div @click="handleRebuildIndex">更新元数据索引</div>
                </DropdownItem>
                <!--                                <DropdownItem @click="handleGoFsm"  v-if="store.state.urlLabels.indexOf('/')>-1||store.state.urlLabels.indexOf('fsm_list')>-1">状态机</DropdownItem>-->
              </DropdownMenu>
            </Dropdown>
            <pd-button v-if="!disableBtn('startfsm')" style="margin-right: 5px" type="primary"
                       @click="handleStartFsm"
                       :disabled="disableBtn('startfsm')">
              {{ starting ? '启动中' : '启动' }}
            </pd-button>
            <pd-button v-if="disableBtn('startfsm')" style="margin-right: 5px" type="primary"
                       @click="handleStartJob"
                       :disabled="disableBtn('start')">
              {{ starting ? '启动中' : '启动' }}
            </pd-button>
            <pd-button style="margin-right: 5px" type="warning" @click="handleRestartJob"
                       :disabled="disableBtn('restart')">
              {{ restarting ? '重启中' : '重启' }}
            </pd-button>
            <pd-button style="margin-right: 5px" type="error" @click="handleStopJob"
                       :disabled="disableBtn('stop')"
            >
              {{ stopping ? '停止中' : '停止' }}
            </pd-button>
          </div>
        </div>
      </div>
      <div class="job-body">
        <DataConfigInfo :jobData="jobData" :taskList="taskList"
                        :handleShowMapping="handleShowMapping" :handleShowPackageList="handleShowPackageList"
                        :getDataSourceDetail="getDataSourceDetail" :activePackage="activePackage"
                        :getDataSinkDetail="getDataSinkDetail" :sourceDetail="sourceDetail"
                        :sinkDetail="sinkDetail"></DataConfigInfo>
        <div class="job-detail-state">
          <div class="worker-ip-btn"
               v-if="currentState==='FULL'&&taskList['FULL']&&taskList['FULL'].workerIp&&taskList['FULL'].clusterId">
            <i style="margin-right: 6px;font-size: 14px" class="iconfont iconmachine"></i><a
            style="color: #333333"
            @click="handleGoWorker(taskList['FULL'])">{{
              taskList['FULL'].workerIp
            }}</a>
            <Icon class="copy-icon" type="ios-photos-outline"
                  @click="handleCopyWorkerIp(taskList['FULL'].workerIp)"/>
          </div>
          <div class="worker-ip-btn"
               v-if="currentState==='REVISE'&&taskList['REVISE']&&taskList['REVISE'].workerIp&&taskList['REVISE'].clusterId">
            <i style="margin-right: 6px;font-size: 14px" class="iconfont iconmachine"></i><a
            style="color: #333333"
            @click="handleGoWorker(taskList['REVISE'])">{{
              taskList['REVISE'].workerIp
            }}</a>
            <Icon class="copy-icon" type="ios-photos-outline"
                  @click="handleCopyWorkerIp(taskList['REVISE'].workerIp)"/>
          </div>
          <div class="worker-ip-btn"
               v-if="currentState==='INCREMENT'&&taskList['INCREMENT']&&taskList['INCREMENT'].workerIp&&taskList['INCREMENT'].clusterId">
            <i style="margin-right: 6px;font-size: 14px" class="iconfont iconmachine"></i><a
            style="color: #333333"
            @click="handleGoWorker(taskList['INCREMENT'])">{{
              taskList['INCREMENT'].workerIp
            }}</a>
            <Icon class="copy-icon" type="ios-photos-outline"
                  @click="handleCopyWorkerIp(taskList['INCREMENT'].workerIp)"/>
          </div>
          <div class="worker-ip-btn"
               v-if="(currentState==='CHECK'||currentState==='COMPLETE')&&taskList['CHECK']&&taskList['CHECK'].workerIp&&taskList['CHECK'].clusterId">
            <i style="margin-right: 6px;font-size: 14px" class="iconfont iconmachine"></i><a
            style="color: #333333"
            @click="handleGoWorker(taskList['CHECK'])">{{
              taskList['CHECK'].workerIp
            }}</a>
            <Icon class="copy-icon" type="ios-photos-outline"
                  @click="handleCopyWorkerIp(taskList['CHECK'].workerIp)"/>
          </div>
          <Tabs type="card" v-model="currentState" @on-click="handleTabChange">
            <TabPane :label="buildStructLabel" name="BUILD_STRUCT" v-if="taskList['BUILD_STRUCT']">
              <StructBuild :jobData="jobData" :getCurrentStateColor="getCurrentStateColor"
                           :taskList="taskList" :task="taskList['BUILD_STRUCT']"
                           :showStructData="showStructData" :structData="structData"
                           :handleStructPageChange="handleStructPageChange"
                           :handleShowLog="handleShowLog"></StructBuild>
            </TabPane>
            <TabPane :label="fullLabel" name="FULL" v-if="taskList['FULL']">
              <Full :getCurrentStateColor="getCurrentStateColor" :fullProgress="fullProgress"
                    :handleFilterFullTable="handleFilterFullTable" :task="taskList['FULL']"
                    :showFullTransData="showFullTransData" :getProgressStatus="getProgressStatus"
                    :taskList="taskList"
                    :fullTotal="fullTotal"
                    :full-page="fullPage"
                    :handleShowLog="handleShowLog"
                    :fullSize="fullSize" :handleFullPageChange="handleFullPageChange"
                    :handleMoreMonitor="handleMoreMonitor" :resourceType="resourceType"
                    :filters="filters" :filterOthers="filterOthers" :metric="metric" :time="time"
                    :jobId="jobId"
                    :filterJobStatus="filterJobStatus"
                    :filterTableName="filterTableName" ref="fullDetail"
              ></Full>
            </TabPane>
            <TabPane :label="increLabel" name="INCREMENT" v-if="taskList['INCREMENT']">
              <Increment :getCurrentStateColor="getCurrentStateColor" :getStepColor="getStepColor"
                         :taskPosition="taskPosition" :taskList="taskList"
                         :getDelayColor="getDelayColor" :handleShowConfirm="handleShowConfirm"
                         :handleClearBinlog="handleClearBinlog" :handleShowLog="handleShowLog"
                         :task="taskList['INCREMENT']"
                         :handleMoreMonitor="handleMoreMonitor" :incrementMetric="incrementMetric"
                         :filters="filters" :posTypeList="posTypeList"
                         :resourceType="resourceType" :jobData="jobData"
                         :filterOthers="filterOthers" :time="time" :jobId="jobId"
                         ref="incrementDetail"
              ></Increment>
            </TabPane>
            <TabPane :label="checkLabel" name="CHECK" v-if="taskList['CHECK']">
              <Check :getCurrentStateColor="getCurrentStateColor" :checkProgress="checkProgress"
                     :showCheckData="showCheckData" :getProgressStatus="getProgressStatus"
                     :checkTotal="checkTotal" :checkSize="checkSize"
                     :handleCheckPageChange="handleCheckPageChange"
                     :taskList="taskList" :resourceType="resourceType"
                     :filters="filters" :filterOthers="filterOthers" :metric="checkMetric"
                     :time="time" :jobId="jobId"
                     :filterJobStatus="filterJobStatus" :handleShowLog="handleShowLog"
                     :task="taskList['CHECK']"
                     :filterTableName="filterTableName" ref="checkDetail"
              ></Check>
            </TabPane>
            <TabPane :label="reverseLabel" name="REVISE" v-if="taskList['REVISE']">
              <Revise :getCurrentStateColor="getCurrentStateColor" :reviseProgress="reviseProgress"
                      :handleFilterFullTable="handleFilterFullTable"
                      :showFullTransData="showFullTransData" :getProgressStatus="getProgressStatus"
                      :taskList="taskList"
                      :fullTotal="fullTotal"
                      :fullSize="fullSize" :handleFullPageChange="handleFullPageChange"
                      :handleMoreMonitor="handleMoreMonitor" :resourceType="resourceType"
                      :filters="filters" :filterOthers="filterOthers" :metric="reviseMetricConfig" :time="time"
                      :jobId="jobId"
                      :filterJobStatus="filterJobStatus" :handleShowLog="handleShowLog"
                      :task="taskList['REVISE']"
                      :filterTableName="filterTableName" ref="reviseDetail"
              ></Revise>
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
    <DataJobDetailModal :showMapping="showMapping" :jobData="jobData" :getAddedData="getAddedData"
                        :selectedIndex="selectedIndex" :mappingDef="mappingDef"
                        :updateSourceSchema="updateSourceSchema"
                        :sourceSchema="sourceSchema" :getMappingCount="getMappingCount"
                        :showTableMapping="showTableMapping" :sourceTable="sourceTable"
                        :showResetPosition="showResetPosition" :positionLoading="positionLoading"
                        :handleResetPosition="handleResetPosition"
                        :handleChangeBinlogCancel="handleChangeBinlogCancel"
                        :showPartition="showPartition" :partition="partition"
                        :showWhereCondition="showWhereCondition"
                        :showEditJobDesc="showEditJobDesc" :selectedJob="selectedJob"
                        :handleConfirmEdit="handleConfirmEdit" :showConfirm="showConfirm"
                        :handleCancelDelete="handleCancelDelete" :showLog="showLog"
                        :updateLogFileName="updateLogFileName"
                        :handleShowWhereCondition="handleShowWhereCondition"
                        :handleSelectLog="handleSelectLog" :logLoading="logLoading"
                        :handleShowLog="handleShowLog" :trueColumnData="trueColumnData"
                        :logData="logData" :showEditJob="showEditJob"
                        :handleCancelEdit="handleCancelEdit"
                        :currentWhereCondition="currentWhereCondition" :taskPosition="taskPosition"
                        :handleChangeTable="handleChangeTable" :mqSchema="mqSchema"
                        :mqSchemaTemplate="mqSchemaTemplate"
                        :selectedLog="selectedLog" :handlePageChange="handlePageChange"
                        :queryJobSchema="queryJobSchema" :posTypeList="posTypeList"
                        :updatePositionInfo="updatePositionInfo" :targetSchema="targetSchema"
    ></DataJobDetailModal>
    <restart-list-modal :visible="showRestartListModal" :handle-close-modal="handleCloseRestartListModal" :restart-list="restartList"/>
    <alarm-rule-modal :visible="showEditAlarmRule" :handle-close-modal="handleCloseAlarmRuleModal"/>
    <verify-code-modal
      :visible="showClearPosition"
      title="清除位点"
      verify-code-type="DELETE_POSITION"
      :handle-close-modal="handleChangeBinlogCancel"
      :handle-confirm-callback="handleResetPosition"
      ref="clear-position-modal"
      :width="580"
    >
      <div slot="content">
        <Alert type="warning" style="margin-bottom: 20px">
          <div style="color: #333;line-height: 22px">
            <ul>
              <li style="list-style:none;"><span class="warn-point"></span>清除位点操作为<span
                class="warn-font">高危</span>操作，不可逆，请谨慎操作。
              </li>
              <li style="list-style:none;"><span class="warn-point"></span>清除位点后将自动重启任务。</li>
              <li style="list-style:none;"><span class="warn-point"></span>请输入验证码以确认要将<span
                class="warn-font">{{ jobData.dataJobName }}</span>任务的位点清除并设置为当前时间<span
                class="warn-font">{{ positionInfo.binlogTime }} </span>。
              </li>
            </ul>
          </div>
        </Alert>
      </div>
    </verify-code-modal>
    <verify-code-modal
      :visible="showConfirm"
      title="删除确认"
      :width="580"
      :handle-close-modal="handleCancelDelete"
      :handle-confirm-callback="handleConfirmDelete"
      verify-code-type="DELETE_JOB"
      ref="delete-job"
    >
      <Alert type="warning" style="margin-bottom: 20px" slot="content">
        <div style="color: #333;line-height: 22px">
          <ul>
            <li style="list-style:none;"><span class="warn-point"></span>请输入短信验证码以确认要删除实例ID为<span
              class="warn-font">{{ jobData.dataJobName }}</span>的任务。
            </li>
            <li style="list-style:none;"><span class="warn-point"></span>短信验证码默认发送给任务创建者。</li>
          </ul>
        </div>
      </Alert>
    </verify-code-modal>
    <Modal
      v-model="showPackage"
      title="代码包管理"
      footer-hide
      width="1200"
      :closable="false"
    >
      <div class="pkg-manager">
        <div>
          <Button type="primary" style="margin-bottom: 10px;" @click="handleUpload">上传新包</Button>
          <Table stripe border size="small" :columns="packageColumns" :data="packageData" height="400" :loading="activePkgLoading">
            <template slot-scope="{ row }" slot="desc">
              <div>
                {{row.description}}
                <Icon class="edit-icon" type="md-create" @click="handleShowUpdatePkg(row)"/>
              </div>
            </template>
            <template slot-scope="{ row }" slot="status">
              <div>
                <div v-if="row.active">已激活
                  <Icon style="color: rgb(82, 196, 26);font-size: 18px" type="md-star" />
                </div>
                <div v-if="!row.active">未激活</div>
              </div>
            </template>
            <template slot-scope="{ row }" slot="action">
              <div>
                <Poptip  v-if="!row.active" transfer trigger="hover">
                  <a style="margin-right: 6px" @click="activePackages(row)">激活</a>
                  <div slot="content">
                    激活后请手动重启任务，否则代码包将不会生效
                  </div>
                </Poptip>
                <a style="margin-right: 6px" @click="downloadPackage(row)">下载</a>
                <a v-if="!row.active" @click="handleDeletePackage(row)">删除</a>
              </div>
            </template>
          </Table>
        </div>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button @click="handleCancel">关闭</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showUploadPkg"
      title="上传新包"
      footer-hide
    >
      <div>
        <Form label-position="left" :label-width="60">
          <FormItem label="上传插件">
        <span class="upload-btn" v-if="!customPkgFile">
                  <input style="margin-left: 10px" @change="handleFileChange" type = "file" name = "uploadfile" id = "uploadfile" />
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-LocalUpload"></use>
                  </svg>
                  点击这里上传文件
          </span>
            <span v-if="customPkgFile" class="file-name-span">
            {{customPkgFile.name}}
            ({{(customPkgFile.size/1024).toFixed(1)}}KB)
            <Icon type="md-close" @click="handleRemovePkg"/>
          </span>
          </FormItem>
          <FormItem label="描述">
            <Input style="width: 280px" v-model="addedPackage.pkgDescription"/>
          </FormItem>
        </Form>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmUpload">确定</pd-button>
          <pd-button @click="handleCancelUpload">取消</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showUpdatePkg"
      title="修改描述"
      footer-hide
    >
      <div>
        <Form label-position="left">
          <FormItem label="描述">
            <Input style="width: 380px" v-model="pkgDescription"/>
          </FormItem>
        </Form>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmUpdate">确定</pd-button>
          <pd-button @click="handleCancelUpdate">取消</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showUpdatePkgProgress"
      title="上传新包进度"
      footer-hide
    >
      <div>
        <Progress :percent="currentPercentage" :stroke-width="20" status="active"
                  text-inside/>
        <p style="padding-left:10px;text-align: center;margin-top: 16px;font-weight:500">上传中...</p>
      </div>
    </Modal>
    <Modal v-model="showDeletePkg" title="删除自定义代码包" footer-hide>
      <div style="padding: 20px">
        <Progress :percent="currentPercentage" :stroke-width="20"
                  :status="currentDeletetStatus==='SUCCESS'?'success':(currentPkgDeleteStatus==='FAILED'?'wrong':'active')"
                  text-inside/>
        <p style="padding-left:10px;text-align: center;margin-top: 16px;font-weight:500">
          {{
            currentPkgDeleteStatus === 'SUCCESS' ? '删除成功' : currentPkgDeleteStatus === 'FAILED' ? '删除失败' : '删除自定义代码包中...'
          }}</p>
      </div>
    </Modal>
  </div>
</template>
<script>
import {
  activeFsm,
  deleteJob,
  queryJobById,
  queryJobSchemaById,
  resetPosTocurrent,
  restartJob,
  startJob,
  stopJob,
  tailTaskMainLog,
  updateJob,
  updateIncrePos,
  listPackages,
  deletePackages,
  activePackages,
  updatePkgInfo, getRestartList,
  preFirstCheck
} from '@services/api/job';
import { downloadPackage } from '@services/api/download';
import {
  schemaLessValueFormatTemplate,
  dsPosTypes,
  supportedsourcedstype, supportedtargetdstype, transformJobType, rebuildIndex
} from '@services/api/constant';
import {
  listAlertAlarmLevel,
  listAlertConfig,
  listAlertIndicators,
  updateAlertConfigs
} from '@services/api/alert';
import { queryById, batchListColumns } from '@services/api/datasource';
import { queryTaskDetailMonitorConfig } from '@services/api/monitor';
import { uploadPackage } from '@services/api/upload';
import { Config } from '@components/function/monitor/config';
import { queryConsoleJob } from '@services/api/consoleJob';
import _ from 'lodash';
import { sendCodeInLoginState } from '@services/api/verify';
import fecha from 'fecha';
import Time from '@components/function/monitor/utils/time';
import DataJobDetailMix from '@components/function/mixin/dataJobDetailMixin';
import DataJobDetailModal from '@components/function/dataJob/jobDetail/modal/DataJobDetailModal';
import '@components/function/dataJob/jobDetail/jobDetail.less';
import DataJobStep from '@components/function/dataJob/jobDetail/DataJobStep';
import DataConfigInfo from '@components/function/dataJob/jobDetail/DataConfigInfo';
import StructBuild from '@components/function/dataJob/jobDetail/StructBuild';
import Full from '@components/function/dataJob/jobDetail/Full';
import Increment from '@components/function/dataJob/jobDetail/Increment';
import Check from '@components/function/dataJob/jobDetail/Check';
import Revise from '@components/function/dataJob/jobDetail/Revise';
import RestartListModal from '@views/data/modal/RestartListModal';
import {
  isMongoDB, isMySQL, isSQLServer
} from '@utils';
import AlarmRuleModal from '@views/data/modal/AlarmRuleModal';
import { Modal } from 'view-design';
import DataSourceGroup from '../dataSourceGroup';
import { handleMqSchemaTool } from '../viewUtil';
import MappingUtil from '../util';
import store from '../../store/index';
import VerifyCodeModal from '../../components/modal/VerifyCodeModal';

export default {
  mixins: [DataJobDetailMix],
  components: {
    AlarmRuleModal,
    RestartListModal,
    VerifyCodeModal,
    DataJobDetailModal,
    DataJobStep,
    DataConfigInfo,
    StructBuild,
    Full,
    Increment,
    Check,
    Revise
  },
  created() {
    this.jobId = Number(this.$route.params.id);
    this.parentDataJobId = this.$route.query.parentDataJobId;
    this.queryData('init');
  },
  mounted() {
    const totalHeight = window.innerHeight;

    // eslint-disable-next-line no-undef
    $('.job-body')
      .css('height', `${totalHeight - 200}px`);
  },
  beforeDestroy() {
    clearInterval(this.myInterval);
    clearInterval(this.sendCodeAgain);
    clearInterval(this.startingInterval);
    clearInterval(this.restartingInterval);
    clearInterval(this.stoppingInterval);
    clearInterval(this.queryDeletePkgInterval);
    clearInterval(this.getPercantage);
  },
  computed: {
    specificDataSource() {
      const { sourceType, sinkType } = this.taskInfo;
      return (isSQLServer(sourceType) && isMySQL(sinkType));
    },
    getFontStepColor() {
      const type = MappingUtil.taskSateToTaskType[this.jobData.dataTaskState];

      if (type) {
        if (!this.getDataTask(this.jobData.dataTasks)[type]) {
          return '#CCCCCC';
        }
        if (this.getDataTask(this.jobData.dataTasks)[type].dataTaskStatus === 'STOP') {
          return '#FFA30E';
        }
        if (this.getDataTask(this.jobData.dataTasks)[type].healthLevel === 'Health') {
          return '#52C41A';
        }
        return '#FF1815';
      }
      if (this.jobData.dataTaskState === 'COMPLETE') {
        return '#808695';
      }
      return '#52C41A';
    },
    getStepColor() {
      if (this.jobData.currentStatus === 'COMPLETE') {
        return '#9EE6AA';
      }
      if (this.jobData.currentStatus === 'STOP') {
        return '#FFBE56';
      }
      if (this.jobData.currentStatus === 'ABNORMAL') {
        return '#FF7674';
      }
      return '#6ED0FF';
    }
  },
  data() {
    return {
      jobTypeList: {},
      activePkgLoading: false,
      alarmLevels: [],
      restartList: [],
      showRestartListModal: false,
      parentDataJobId: '',
      positionLoading: false,
      mqSchemaTemplate: '',
      mqSchema: {},
      fecha,
      DataSourceGroup,
      posTypeList: [],
      schemaLessValueFormat: 'CLOUDCANAL_JSON_FOR_MQ',
      currentPkgDeleteStatus: '',
      verifyCode: '',
      showClearPosition: false,
      showDeletePkg: false,
      currentDeletetStatus: '',
      sendcodeDisabled: true,
      showPackage: false,
      sendCodeAgainTime: 69,
      showEditJob: false,
      logFileName: '',
      logLoading: false,
      showLog: false,
      showConfirm: false,
      selectedIndex: 0,
      ifShowStep: false,
      showUploadPkg: false,
      showUpdatePkgProgress: false,
      currentPercentage: 0,
      showUpdatePkg: false,
      customPkgFile: null,
      pkgDescription: '',
      selectedPkg: '',
      sourceTable: {},
      sourceDetail: {},
      sinkDetail: {},
      currentState: '',
      queryJobSchema: {},
      resourceType: 'DATA_JOB',
      store,
      sendCodeAgain: '',
      startingInterval: '',
      restartingInterval: '',
      stoppingInterval: '',
      sinkDbSchemaRelation: {},
      sourceDbSchemaRelation: {},
      selectedJob: {},
      showEditJobDesc: false,
      showWhereCondition: false,
      currentWhereCondition: '',
      tableFilter: {},
      MappingUtil,
      showEditAlarmRule: false,
      indicators: [],
      trueColumnData: {},
      showPartition: false,
      activePackage: {},
      logData: [],
      selectedLog: {},
      partition: { partition: [] },
      sourceSchema: [],
      targetSchema: [],
      mappingDef: [],
      addRule: {
        indicators: [{
          indicator: '',
          value: '',
          symbol: ''
        }],
        methods: ['sms', 'dingding'],
        sendAdmin: false
      },
      taskExceptionMethods: [],
      taskExceptionSendAdmin: false,
      taskExceptionExpress: '',
      exceptionConfig: {},
      delayAlertOn: false,
      transTime: 0,
      jobInfoColumn: [
        {
          title: '任务信息',
          slot: 'jobInfo'
        }
      ],
      jobInfoData: [
        {}
      ],
      dataSourceInfoColumn: [
        {
          title: '源数据源信息',
          slot: 'dataSourceInfo'
        }
      ],
      dataSourceInfoData: [{}],
      targetInfoColumn: [
        {
          title: '目标数据源信息',
          slot: 'targetInfo'
        }
      ],
      targetInfoData: [{}],
      showTableMapping: {},
      showFullTableList: [],
      filterTableName: '',
      filterJobStatus: ['running', 'wait', 'finish'],
      jobPanelCount: {
        BUILD_STRUCT: '1',
        FULL: '2',
        INCRE: '3',
        CATCH_UP: '3',
        CHECK: '4'
      },
      jobStateMapping: {
        BUILD_STRUCT: 'BUILD_STRUCT',
        FULL: 'FULL',
        INCRE: 'INCREMENT',
        CATCH_UP: 'INCREMENT',
        CHECK: 'CHECK',
        REVISE: 'REVISE',
        COMPLETE: 'COMPLETE'
      },
      starting: false,
      restarting: false,
      stopping: false,
      showResetPosition: false,
      hasInterval: false,
      hasFullInterval: false,
      positionInfo: {
        binlogTime: new Date(),
        gtid: ''
      },
      refreshLoading: false,
      showEditBinlogTime: false,
      showDataJobDetailInterval: {},
      jobId: 0,
      mappingPageSize: 4,
      taskMap: {},
      taskList: {},
      showMapping: false,
      showJobDetail: '-1',
      currentStep: 0,
      currentStatus: 'process',
      showStep: 1,
      fullSize: 5,
      fullPage: 1,
      fullTotal: 0,
      checkTotal: 0,
      filters: {
        instance: 'all',
        job: 'all'
      },
      filterOthers: {
        filterInstances: [],
        dbOnInstance: {},
        database: '全部'
      },
      panelType: 'job',
      metrics: [],
      metric: {},
      incrementMetric: {},
      checkMetric: {},
      reviseMetricConfig: {},
      time: new Time(),
      configFile: Config.mysql,
      fullProgress: {
        trans: 0,
        transPercent: 0
      },
      checkProgress: {
        checked: 6000,
        checkedPercent: 20
      },
      reviseProgress: {
      },
      structProgress: {
        progressPercent: 50,
        builded: 10,
        total: 20,
        currentSchema: 'db2',
        currentTable: 'table3'
      },
      taskPosition: {},
      jobData: {
        sourceDsVO: {},
        targetDsVO: {},
        gmtCreated: '2020-02-24T06:16:34.000+0000'
      },
      schemaData: null,
      fullTransData: [],
      showFullTransData: [],
      checkColumns: [
        {
          title: '数据库',
          key: 'schema',
          width: 150
        },
        {
          title: '表名',
          key: 'table',
          width: 200
        },
        {
          title: '丢失行数',
          key: 'loss',
          width: 150
        },
        {
          title: '不一致行数',
          key: 'diff',
          width: 150
        },
        {
          title: '进度',
          slot: 'checkProgress'
        }
      ],
      checkData: [],
      showCheckData: [],
      checkPage: 1,
      checkSize: 10,
      structData: [],
      showStructData: [],
      structPage: 1,
      commonSelectColumns: {},
      fullMetricConfig: {},
      incrementMetricConfig: {},
      addedPackage: {},
      buildStructLabel: (h) => h('div', [
        h('span', {
          style: {
            marginLeft: '18px'
          }
        }, '结构迁移'),
        h('span', {
          style: {
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            marginLeft: '8px',
            display: 'inline-block',
            background: this.getTabColor('BUILD_STRUCT')
          }
        })
      ]),
      fullLabel: (h) => h('div', [
        h('span', {
          style: {
            marginLeft: '18px'
          }
        }, '全量迁移'),
        h('span', {
          style: {
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            marginLeft: '8px',
            display: 'inline-block',
            background: this.getTabColor('FULL')
          }
        })
      ]),
      increLabel: (h) => h('div', [
        h('span', {
          style: {
            marginLeft: '18px'
          }
        }, '增量同步'),
        h('span', {
          style: {
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            marginLeft: '8px',
            display: 'inline-block',
            background: this.getTabColor('INCREMENT')
          }
        })
      ]),
      checkLabel: (h) => h('div', [
        h('span', {
          style: {
            marginLeft: '18px'
          }
        }, '数据校验'),
        h('span', {
          style: {
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            marginLeft: '8px',
            display: 'inline-block',
            background: this.getTabColor('CHECK')
          }
        })]),
      reverseLabel: (h) => h('div', [
        h('span', {
          style: {
            marginLeft: '18px'
          }
        }, '数据订正'),
        h('span', {
          style: {
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            marginLeft: '8px',
            display: 'inline-block',
            background: this.getTabColor('REVISE')
          }
        })
      ]),
      packageColumns: [
        {
          title: '包名',
          key: 'fileName'
        },
        {
          title: '描述',
          slot: 'desc'
        },
        {
          title: '包大小（MB）',
          key: 'fileSizeMb',
          maxWidth: 120
        },
        {
          title: '创建时间',
          key: 'gmtCreate',
          width: 180
        },
        {
          title: '上传用户',
          key: 'uploadUserName',
          width: 100
        },
        {
          title: '状态',
          slot: 'status',
          width: 120
        },
        {
          title: '操作',
          slot: 'action',
          width: 160
        }
      ],
      packageData: []
    };
  },
  methods: {
    getJobTypeList() {
      transformJobType({
        sourceType: this.jobData.sourceDsVO.dataSourceType,
        targetType: this.jobData.targetDsVO.dataSourceType
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.jobTypeList = res.data.data.optionType;
          }
        });
    },
    handleCloseAlarmRuleModal() {
      this.showEditAlarmRule = false;
    },
    getAddedData(type, sourceDb, sourceTable) {
      const data = [];

      if (type === 'TABLE') {
        if (DataSourceGroup.pg.indexOf(this.jobData.sourceDsVO.dataSourceType) > -1) {
          sourceDb.schemas[0].tables.map((item) => {
            if (item.targetAutoCreate) {
              data.push(item);
            }
            return null;
          });
        } else if (DataSourceGroup.oracle.indexOf(this.jobData.sourceDsVO.dataSourceType) > -1) {
          sourceDb.tableSpaces[0].tables.map((item) => {
            if (item.targetAutoCreate) {
              data.push(item);
            }
            return null;
          });
        } else if (DataSourceGroup.mq.indexOf(this.jobData.sourceDsVO.dataSourceType) > -1) {
          if (DataSourceGroup.pg.indexOf(this.jobData.targetDsVO.dataSourceType) > -1) {
            sourceDb.schemas[0].tables.map((item) => {
              if (item.targetAutoCreate) {
                data.push(item);
              }
              return null;
            });
          } else if (DataSourceGroup.oracle.indexOf(this.jobData.targetDsVO.dataSourceType) > -1) {
            sourceDb.tableSpaces[0].tables.map((item) => {
              if (item.targetAutoCreate) {
                data.push(item);
              }
              return null;
            });
          }
        } else if (isMongoDB(this.jobData.sourceDsVO.dataSourceType)) {
          sourceDb.collections.forEach((item) => {
            if (item.targetAutoCreate) {
              data.push(item);
            }
          });
        } else {
          sourceDb.tables.forEach((item) => {
            if (item.targetAutoCreate) {
              data.push(item);
            }
          });
        }
      }
      if (type === 'COLUMN') {
        if (sourceTable.columns) {
          sourceTable.columns.map((column) => {
            if (column.targetAutoCreate) {
              data.push(column);
            }
            return null;
          });
        }
      }
      return data;
    },
    handleCloseRestartListModal() {
      this.showRestartListModal = false;
    },
    handleTabChange(name) {
      if (name === 'BUILD_STRUCT') {
        this.initSchema();
      }
    },
    handleRefresh() {
      console.log('Refreshing');
      this.refreshLoading = true;
      this.queryData('refresh');
      if (this.$refs.incrementDetail) {
        this.$refs.incrementDetail.handleRender();
      }
      if (this.$refs.fullDetail) {
        this.$refs.fullDetail.handleRender();
      }
      if (this.$refs.checkDetail) {
        this.$refs.checkDetail.handleRender();
      }
      if (this.$refs.reviseDetail) {
        this.$refs.reviseDetail.handleRender();
      }
    },
    queryData(type) {
      queryJobById({ jobId: this.jobId })
        .then((res) => {
          try {
            this.refreshLoading = false;
            this.jobData = res.data.data;
            if (this.jobData.targetDsVO.dbSchemaRelation) {
              this.sinkDbSchemaRelation = JSON.parse(this.jobData.targetDsVO.dbSchemaRelation.configValue);
            }
            if (this.jobData.sourceDsVO.dbSchemaRelation) {
              this.sourceDbSchemaRelation = JSON.parse(this.jobData.sourceDsVO.dbSchemaRelation.configValue);
            }
            if (this.jobData.state === 'INIT') {
              this.currentStep = 0;
            }
            if (this.jobData.dataTaskState === 'COMPLETE') {
              this.jobData.currentStatus = 'COMPLETE';
            } else {
              this.jobData.dataTasks.map((task) => {
                if ((this.jobData.dataTaskState === 'CATCH_UP' || this.jobData.dataTaskState === 'INCRE') && task.dataTaskType === 'INCREMENT') {
                  this.jobData.currentStatus = task.dataTaskStatus;
                } else if (task.dataTaskType === this.jobData.dataTaskState) {
                  this.jobData.currentStatus = task.dataTaskStatus;
                }
                return null;
              });
            }
            this.showJobDetail = this.jobPanelCount[this.jobData.dataTaskState];
            if (type !== 'refresh') {
              let state = this.jobStateMapping[this.jobData.dataTaskState];

              if (state === 'COMPLETE') {
                const lastState = this.jobData.dataTasks[this.jobData.dataTasks.length - 1].dataTaskType;

                if (lastState === 'CATCH_UP') {
                  this.currentState = 'INCREMENT';
                } else {
                  this.currentState = lastState;
                }
              } else {
                if (!state && this.jobData.dataTasks && this.jobData.dataTasks.length) {
                  state = this.jobData.dataTasks[0].dataTaskType;
                }

                this.currentState = state;
              }
            }
            this.jobData.dataTasks.map((item) => {
              this.taskList[item.dataTaskType] = item;
              if (item.dataTaskType === 'FULL') {
                this.fullProgress = JSON.parse(item.taskPosition);
                if (this.fullProgress.transTableDetail) {
                  const transTableDetail = JSON.parse(this.fullProgress.transTableDetail);

                  this.fullTransData = [];
                  Object.keys(transTableDetail)
                    .map((table) => {
                      if (DataSourceGroup.hasSchema.includes(this.jobData.sourceDsVO.dataSourceType)) {
                        this.fullTransData.push({
                          dbName: transTableDetail[table].dbName,
                          schema: transTableDetail[table].schema,
                          table: transTableDetail[table].tableName,
                          trans: transTableDetail[table].handledRecordCount,
                          transPercent: transTableDetail[table].percentage
                        });
                      } else {
                        this.fullTransData.push({
                          schema: transTableDetail[table].schema || transTableDetail[table].dbName,
                          table: transTableDetail[table].tableName,
                          trans: transTableDetail[table].handledRecordCount,
                          transPercent: transTableDetail[table].percentage
                        });
                      }
                      return null;
                    });
                  this.showFullTableList = _.cloneDeep(this.fullTransData);
                  this.showFullTransData = this.showFullTableList.slice((this.fullPage - 1) * this.fullSize, this.fullPage * this.fullSize);
                  this.fullTotal = this.showFullTableList.length;
                }
              } else if (item.dataTaskType === 'INCREMENT') {
                this.taskPosition = JSON.parse(item.taskPosition);
                this.delayAlertOn = item.delayAlertOn;
              } else if (item.dataTaskType === 'CHECK') {
                this.checkProgress = JSON.parse(item.taskPosition);
                if (this.checkProgress.checkTableDetail) {
                  const checkTableDetail = JSON.parse(this.checkProgress.checkTableDetail);

                  this.checkData = [];
                  Object.keys(checkTableDetail)
                    .map((table) => {
                      this.checkData.push({
                        schema: checkTableDetail[table].schema,
                        table: checkTableDetail[table].tableName,
                        loss: checkTableDetail[table].loss,
                        diff: checkTableDetail[table].diff,
                        handledRecordCount: checkTableDetail[table].handledRecordCount,
                        checkPercent: checkTableDetail[table].percentage
                      });
                      return null;
                    });
                  this.checkTotal = this.checkData.length; this.showCheckData = this.checkData.slice((this.checkPage - 1) * this.checkSize, this.checkPage * this.checkSize);
                }
              } else if (item.dataTaskType === 'REVISE') {
                this.reviseProgress = JSON.parse(item.taskPosition);
                if (this.reviseProgress.revisePosDetail) {
                  this.reviseProgress.revisePosDetailObj = JSON.parse(this.reviseProgress.revisePosDetail);
                }
              } else if (item.dataTaskType === 'BUILD_STRUCT') {
                this.initSchema();
              }
              if (this.taskList.BUILD_STRUCT && this.taskList.FULL && this.taskList.INCREMENT) {
                this.showStep = 1;
                if (this.jobData.dataTaskState === 'BUILD_STRUCT') {
                  this.currentStep = 1;
                } else if (this.jobData.dataTaskState === 'FULL') {
                  this.currentStep = 2;
                } else if (this.jobData.dataTaskState === 'INCRE' || this.jobData.dataTaskState === 'CATCH_UP') {
                  this.currentStep = 3;
                } else if (this.jobData.dataTaskState === 'COMPLETE') {
                  this.currentStep = 5;
                }
              } else if (!this.taskList.BUILD_STRUCT && this.taskList.FULL && this.taskList.INCREMENT) {
                this.showStep = 4;
                if (this.jobData.dataTaskState === 'FULL') {
                  this.currentStep = 1;
                } else if (this.jobData.dataTaskState === 'INCRE' || this.jobData.dataTaskState === 'CATCH_UP') {
                  this.currentStep = 2;
                } else if (this.jobData.dataTaskState === 'COMPLETE') {
                  this.currentStep = 4;
                }
              } else if (!this.taskList.BUILD_STRUCT && this.taskList.FULL && !this.taskList.INCREMENT) {
                this.showStep = 3;
                if (this.jobData.dataTaskState === 'FULL') {
                  this.currentStep = 1;
                } else if (this.jobData.dataTaskState === 'COMPLETE') {
                  this.currentStep = 3;
                }
              } else if (this.taskList.BUILD_STRUCT && this.taskList.FULL && !this.taskList.INCREMENT) {
                this.showStep = 2;
                if (this.jobData.dataTaskState === 'BUILD_STRUCT') {
                  this.currentStep = 1;
                } else if (this.jobData.dataTaskState === 'FULL') {
                  this.currentStep = 2;
                } else if (this.jobData.dataTaskState === 'COMPLETE') {
                  this.currentStep = 4;
                }
              } else if (this.taskList.BUILD_STRUCT && !this.taskList.FULL && this.taskList.INCREMENT) {
                this.showStep = 6;
                if (this.jobData.dataTaskState === 'BUILD_STRUCT') {
                  this.currentStep = 1;
                } else if (this.jobData.dataTaskState === 'INCRE' || this.jobData.dataTaskState === 'CATCH_UP') {
                  this.currentStep = 2;
                } else if (this.jobData.dataTaskState === 'COMPLETE') {
                  this.currentStep = 4;
                }
              } else if (!this.taskList.BUILD_STRUCT && !this.taskList.FULL && this.taskList.INCREMENT) {
                this.showStep = 5;
                if (this.jobData.dataTaskState === 'INCRE' || this.jobData.dataTaskState === 'CATCH_UP') {
                  this.currentStep = 1;
                } else if (this.jobData.dataTaskState === 'COMPLETE') {
                  this.currentStep = 3;
                }
              } else if (!this.taskList.BUILD_STRUCT && !this.taskList.FULL && !this.taskList.INCREMENT && this.taskList.CHECK) {
                this.showStep = 7;
                if (this.jobData.dataTaskState === 'CHECK') {
                  this.currentStep = 1;
                } else if (this.jobData.dataTaskState === 'COMPLETE') {
                  this.currentStep = 2;
                }
              } else if (this.taskList.BUILD_STRUCT && !this.taskList.FULL && !this.taskList.INCREMENT && !this.taskList.CHECK) {
                this.showStep = 8;
                if (this.jobData.dataTaskState === 'STRUCT_MIGRATION') {
                  this.currentStep = 1;
                } else if (this.jobData.dataTaskState === 'COMPLETE') {
                  this.currentStep = 2;
                }
              } else if (this.taskList.REVISE) {
                this.showStep = 9;
                if (this.jobData.dataTaskState === 'REVISE') {
                  this.currentStep = 1;
                } else if (this.jobData.dataTaskState === 'COMPLETE') {
                  this.currentStep = 2;
                }
              }

              this.jobData.dataTasks.map((task) => {
                this.taskMap[task.dataJobType] = task;
                return null;
              });
              return null;
            });
            if (this.currentState === 'BUILD_STRUCT') {
              this.initSchema();
            }

            if (this.taskList.FULL) {
              queryTaskDetailMonitorConfig({
                dataJobId: this.jobId,
                dataTaskId: this.taskList.FULL.dataTaskId
              })
                .then((res1) => {
                  if (res1.data.code === '1') {
                    this.fullMetricConfig = res1.data.data;

                    this.formatFullMetric(this.fullMetricConfig);
                  }
                });
            }
            if (this.taskList.REVISE) {
              queryTaskDetailMonitorConfig({
                dataJobId: this.jobId,
                dataTaskId: this.taskList.REVISE.dataTaskId
              })
                .then((res1) => {
                  if (res1.data.code === '1') {
                    this.reviseMetricConfig = res1.data.data;

                    this.formatReviseMetric(this.reviseMetricConfig);
                  }
                });
            }
            if (this.taskList.INCREMENT) {
              queryTaskDetailMonitorConfig({
                dataJobId: this.jobId,
                dataTaskId: this.taskList.INCREMENT.dataTaskId
              })
                .then((res1) => {
                  if (res1.data.code === '1') {
                    this.incrementMetric = res1.data.data;

                    this.formatIncrMetric(this.incrementMetric);
                  }
                });
            }
            if (this.taskList.CHECK) {
              queryTaskDetailMonitorConfig({
                dataJobId: this.jobId,
                dataTaskId: this.taskList.CHECK.dataTaskId
              })
                .then((res1) => {
                  if (res1.data.code === '1') {
                    this.checkMetric = res1.data.data;
                    this.formatCheckMetric(this.checkMetric);
                  }
                });
            }
            if (this.jobData.havePackage) {
              this.listPackages();
            }
            if (type === 'init') {
              const that = this;

              setTimeout(() => {
                that.init();
                that.ifShowStep = true;
              }, 1000);
            }

            if (type === 'refresh' && this.$refs.fullDetail) {
              const { theFilterJobStatus, theFilterTableName } = this.$refs.fullDetail;
              this.handleFilterFullTable(theFilterJobStatus, theFilterTableName);
            }

            this.getJobTypeList();
          } catch (e) {
            console.log('e', e);
          }
          this.dsPosTypes();
        })
        .catch((e) => {
          console.log(e);
          this.refreshLoading = false;
        });
    },
    formatFullMetric(data) {
      data.config[0].options.queries.map((query) => {
        if (query.metricNamePattern.indexOf('sum(') > -1) {
          let a = _.cloneDeep(query.metricNamePattern.split('{')[0]);
          const b = _.cloneDeep(query.metricNamePattern.split('by')[1]);

          a = a.replace('\'', '');

          query.metricNamePattern = `${a}{job_id="${this.jobId}",task_id="${this.taskList.FULL.dataTaskId}"}) by${b}`;
        } else if (query.metricNamePattern.indexOf('increase') > -1) {
          let a = query.metricNamePattern.split('[')[0].split('{')[0];

          a = a.replace('\'', '');
          query.metricNamePattern = `${a}'{'job_id="{0}",task_id="{1}"'}'[${query.metricNamePattern.split('[')[1]}`;
        } else {
          let a = query.metricNamePattern.split('{')[0];

          a = a.replace('\'', '');
          query.metricNamePattern = a;
          query.metricNamePattern += '\'{\'job_id="{0}",task_id="{1}"\'}\'';
        }
        return null;
      });
    },
    formatReviseMetric(data) {
      data.config[0].options.queries.map((query) => {
        if (query.metricNamePattern.indexOf('sum(') > -1) {
          let a = _.cloneDeep(query.metricNamePattern.split('{')[0]);
          const b = _.cloneDeep(query.metricNamePattern.split('by')[1]);

          a = a.replace('\'', '');

          query.metricNamePattern = `${a}{job_id="${this.jobId}",task_id="${this.taskList.REVISE.dataTaskId}"}) by${b}`;
        } else if (query.metricNamePattern.indexOf('increase') > -1) {
          let a = query.metricNamePattern.split('[')[0].split('{')[0];

          a = a.replace('\'', '');
          query.metricNamePattern = `${a}'{'job_id="{0}",task_id="{1}"'}'[${query.metricNamePattern.split('[')[1]}`;
        } else {
          let a = query.metricNamePattern.split('{')[0];
          a = a.replace('\'', '');
          query.metricNamePattern = a;
          query.metricNamePattern += '\'{\'job_id="{0}",task_id="{1}"\'}\'';
        }
        return null;
      });
    },
    formatCheckMetric(data) {
      data.config[0].options.queries.map((query) => {
        if (query.metricNamePattern.indexOf('sum(') > -1) {
          let a = _.cloneDeep(query.metricNamePattern.split('{')[0]);
          const b = _.cloneDeep(query.metricNamePattern.split('by')[1]);

          a = a.replace('\'', '');

          query.metricNamePattern = `${a}{job_id="${this.jobId}",task_id="${this.taskList.REVISE.dataTaskId}"}) by${b}`;
        } else if (query.metricNamePattern.indexOf('increase') > -1) {
          let a = query.metricNamePattern.split('[')[0].split('{')[0];

          a = a.replace('\'', '');
          query.metricNamePattern = `${a}'{'job_id="{0}",task_id="{1}"'}'[${query.metricNamePattern.split('[')[1]}`;
        } else {
          let a = query.metricNamePattern.split('{')[0];
          a = a.replace('\'', '');
          query.metricNamePattern = a;
          query.metricNamePattern += '\'{\'job_id="{0}",task_id="{1}"\'}\'';
        }
        return null;
      });
    },
    formatIncrMetric(data) {
      data.config[0].options.queries.map((query) => {
        if (query.metricNamePattern.indexOf('sum(') > -1) {
          let a = _.cloneDeep(query.metricNamePattern.split('{')[0]);
          const b = _.cloneDeep(query.metricNamePattern.split('by')[1]);

          a = a.replace('\'', '');
          query.metricNamePattern = `${a}{job_id="${this.jobId}",task_id="${this.taskList.INCREMENT.dataTaskId}"}) by${b}`;
        } else if (query.metricNamePattern.indexOf('increase') > -1) {
          let a = query.metricNamePattern.split('[')[0].split('{')[0];

          a = a.replace('\'', '');
          query.metricNamePattern = `${a}'{'job_id="{0}",task_id="{1}"'}'[${query.metricNamePattern.split('[')[1]}`;
        } else {
          let a = query.metricNamePattern.split('{')[0];

          a = a.replace('\'', '');
          query.metricNamePattern = a;
          query.metricNamePattern += '\'{\'job_id="{0}",task_id="{1}"\'}\'';
        }
        return null;
      });
    },
    handleParamsEdit() {
      this.$router.push({
        path: `/data/job/${this.jobId}/params`
      });
    },
    handleMoreMonitor(id, type) {
      if (type === 'FULL') {
        this.$router.push({
          path: `/monitor/job/full/graph/${this.jobId}/${id}`
        });
      } else {
        this.$router.push({
          path: `/monitor/job/graph/${this.jobId}/${id}`
        });
      }
    },
    handleShowMapping() {
      this.$Spin.show();

      queryJobSchemaById({
        jobId: this.jobId,
        srcDsType: this.jobData.sourceDsVO.dataSourceType,
        dstDsType: this.jobData.targetDsVO.dataSourceType
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.schemaData = res.data.data;
            this.schemaLessValueFormat = res.data.data.dstSchemaLessFormat || res.data.data.srcSchemaLessFormat;

            this.sourceSchema = JSON.parse(res.data.data.sourceSchema);
            this.mappingDef = JSON.parse(res.data.data.mappingConfig);
            if (res.data.data.targetSchema) {
              this.targetSchema = JSON.parse(res.data.data.targetSchema);
            }
            if (this.schemaLessValueFormat && (DataSourceGroup.mq.includes(this.jobData.sourceDsVO.dataSourceType) || DataSourceGroup.mq.includes(this.jobData.targetDsVO.dataSourceType))) {
              schemaLessValueFormatTemplate({
                schemaLessValueFormat: this.schemaLessValueFormat
              })
                .then((res1) => {
                  this.mqSchemaTemplate = res1.data.data;
                });
            }

            this.getConfigMapping();

            let db = this.sourceSchema[0];

            if (DataSourceGroup.pg.indexOf(this.jobData.sourceDsVO.dataSourceType) > -1) {
              this.sourceTable = db.schemas[0].tables[0];
            } else if (DataSourceGroup.oracle.indexOf(this.jobData.sourceDsVO.dataSourceType) > -1) {
              this.sourceTable = db.tableSpaces[0].tables[0];
            } else if (DataSourceGroup.mq.includes(this.jobData.sourceDsVO.dataSourceType)) {
              db = this.targetSchema[0];
              if (DataSourceGroup.pg.includes(this.jobData.targetDsVO.dataSourceType)) {
                this.sourceTable = db.schemas[0].tables[0];
              } else {
                this.sourceTable = db.tables[0];
              }
            } else if (isMongoDB(this.jobData.sourceDsVO.dataSourceType)) {
              this.sourceTable = db.collections[0];
            } else {
              this.sourceTable = db.tables[0];
            }
            this.getColumnData(db, this.sourceTable);
            this.showMapping = true;
          }
          this.$Spin.hide();
        })
        .catch((e) => {
          console.error(e);
          this.$Spin.hide();
        });
    },
    getConfigMapping() {
      const sourceType = this.jobData.sourceDsVO.dataSourceType;
      this.structData = [];
      this.sourceSchema.forEach((db) => {
        this.tableFilter[db.db] = '';
        if (isMongoDB(sourceType)) {
          this.showTableMapping[db.db] = {
            list: [],
            page: 1,
            size: 4,
            total: db.collections.length
          };
          this.showTableMapping[db.db].list = db.collections.slice(0, this.mappingPageSize);
        } else if (DataSourceGroup.pg.indexOf(this.jobData.sourceDsVO.dataSourceType) > -1) {
          this.showTableMapping[db.db] = {
            list: [],
            page: 1,
            size: 4,
            total: db.schemas[0].tables.length
          };
          this.showTableMapping[db.db].list = db.schemas[0].tables.slice(0, this.mappingPageSize);
        } else if (DataSourceGroup.oracle.indexOf(this.jobData.sourceDsVO.dataSourceType) > -1) {
          this.showTableMapping[db.db] = {
            list: [],
            page: 1,
            size: 4,
            total: db.tableSpaces[0].tables.length
          };
          this.showTableMapping[db.db].list = db.tableSpaces[0].tables.slice(0, this.mappingPageSize);
        } else if (DataSourceGroup.mq.indexOf(this.jobData.sourceDsVO.dataSourceType) === -1) {
          this.showTableMapping[db.db] = {
            list: [],
            page: 1,
            size: 4,
            total: db.tables.length
          };
          this.showTableMapping[db.db].list = db.tables.slice(0, this.mappingPageSize);
        }
        if (DataSourceGroup.mq.indexOf(this.jobData.sourceDsVO.dataSourceType) === -1) {
          if (this.getAddedData('TABLE', db).length > 0) {
            this.getAddedData('TABLE', db)
              .map((table) => {
                this.structData.push({
                  schema: db.db,
                  table: table.table
                });
                return null;
              });
          } else {
            this.getAddedData('TABLE', db)
              .map((table) => {
                if (this.getAddedData('Column', db, table).length > 0) {
                  this.structData.push({
                    schema: db.db,
                    table: table.table
                  });
                }
                return null;
              });
          }
          this.showStructData = this.structData.slice((this.structPage - 1) * 10, this.structPage * 10);
        }
        this.handleMqSchema(db);
      });
      if (DataSourceGroup.mq.includes(this.jobData.sourceDsVO.dataSourceType)) {
        if (DataSourceGroup.noDb.includes(this.jobData.targetDsVO.dataSourceType)) {
          const tables = [];
          this.targetSchema.forEach((index) => {
            const table = {
              ...index,
              columns: [],
              table: index.indexName
            };
            index.fields.forEach((field) => {
              table.columns.push({
                ...field,
                column: field.fieldName
              });
            });
            tables.push(table);
          });
          this.targetSchema = [{
            db: 'empty',
            tables
          }];
        }
        this.targetSchema.forEach((db) => {
          this.tableFilter[db.db] = '';
          if (DataSourceGroup.mysql.includes(this.jobData.targetDsVO.dataSourceType)
            || (DataSourceGroup.kafka.includes(this.jobData.sourceDsVO.dataSourceType) && DataSourceGroup.mongo.includes(this.jobData.targetDsVO.dataSourceType))) {
            this.showTableMapping[db.db] = {
              list: [],
              page: 1,
              size: 4,
              total: db.tables.length
            };
            this.showTableMapping[db.db].list = db.tables.slice(0, 4);
          } else if (DataSourceGroup.pg.indexOf(this.jobData.targetDsVO.dataSourceType) > -1) {
            this.showTableMapping[db.db] = {
              list: [],
              page: 1,
              size: 4,
              total: db.schemas[0].tables.length
            };
            this.showTableMapping[db.db].list = db.schemas[0].tables.slice(0, this.mappingPageSize);
          } else if (DataSourceGroup.es.includes(this.jobData.targetDsVO.dataSourceType)) {
            this.showTableMapping[db.db] = {
              list: [],
              page: 1,
              size: 4,
              total: db.tables.length
            };
            this.showTableMapping[db.db].list = db.tables.slice(0, this.mappingPageSize);
          }

          if (this.getAddedData('TABLE', db).length > 0) {
            this.getAddedData('TABLE', db)
              .map((table) => {
                this.structData.push({
                  schema: db.db,
                  table: table.table
                });
                return null;
              });
          } else {
            this.getAddedData('TABLE', db)
              .map((table) => {
                if (this.getAddedData('Column', db, table).length > 0) {
                  this.structData.push({
                    schema: db.db,
                    table: table.table
                  });
                }
                return null;
              });
          }
          this.showStructData = this.structData.slice((this.structPage - 1) * 10, this.structPage * 10);
          this.handleMqSchema(db);
        });
      }
    },
    handleShowDetail() {
      this.handleRender();
    },
    initSchema() {
      queryJobSchemaById({
        jobId: this.jobId,
        srcDsType: this.jobData.sourceDsVO.dataSourceType,
        dstDsType: this.jobData.targetDsVO.dataSourceType
      })
        .then((response) => {
          if (response.data.code === '1') {
            this.schemaLessValueFormat = response.data.data.dstSchemaLessFormat || response.data.data.srcSchemaLessFormat;
            this.sourceSchema = JSON.parse(response.data.data.sourceSchema);
            this.mappingDef = JSON.parse(response.data.data.mappingConfig);
            if (response.data.data.targetSchema) {
              this.targetSchema = JSON.parse(response.data.data.targetSchema);
            }
            if (this.schemaLessValueFormat && (DataSourceGroup.mq.includes(this.jobData.sourceDsVO.dataSourceType) || DataSourceGroup.mq.includes(this.jobData.targetDsVO.dataSourceType))) {
              schemaLessValueFormatTemplate({
                schemaLessValueFormat: this.schemaLessValueFormat
              })
                .then((res1) => {
                  this.mqSchemaTemplate = res1.data.data;
                });
            }

            this.structData = [];
            this.getConfigMapping();
          }
        });
    },
    handleRestartJob() {
      this.$Modal.confirm({
        title: '重启任务确认',
        content: '请确认是否要重启该任务，重启中将出现任务中断',
        onOk: () => {
          restartJob({
            jobId: this.jobId,
            dataJobName: this.jobData.dataJobName
          })
            .then((res) => {
              if (res.data.code === '1') {
                this.restarting = true;
                const that = this;

                setTimeout(() => {
                  that.restarting = false;
                  that.handleRefresh();
                }, 1000);
              }
            });
        }
      });
    },
    handleStopJob() {
      this.$Modal.confirm({
        title: '停止任务确认',
        content: '请确认是否要停止该任务，停止将使任务中断',
        onOk: () => {
          stopJob({
            jobId: this.jobId,
            dataJobName: this.jobData.dataJobName
          })
            .then((res) => {
              if (res.data.code === '1') {
                this.stopping = true;
                const that = this;
                setTimeout(() => {
                  that.handleRefresh();
                  that.stopping = false;
                }, 1000);
              }
            });
        }
      });
    },
    handleStartJob() {
      startJob({
        jobId: this.jobId,
        dataJobName: this.jobData.dataJobName
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.starting = true;
            const that = this;

            setTimeout(() => {
              that.handleRefresh();
              that.starting = false;
            }, 1000);
          }
        });
    },
    handleStartFsm() {
      activeFsm({
        jobId: this.jobId,
        dataJobName: this.jobData.dataJobName
      })
        .then((res) => {
          if (res.data.code === '1') {
            const that = this;

            setTimeout(() => {
              that.queryData();
            }, 2000);
            this.$Message.success('启动成功！');
          }
        });
    },
    init() {
      try {
        const metrics1 = this.incrementMetric;
        const metrics2 = this.fullMetricConfig;
        const metrics3 = this.checkMetric;
        const metrics4 = this.reviseMetricConfig;

        this.metric = {};
        this.incrementMetric = {};
        this.metrics = metrics1;
        if (this.taskList.INCREMENT) {
          if (metrics1.config) {
            metrics1.queries = metrics1.config[0].options.queries;
            this.formatIncrMetric(metrics1);
            this.incrementMetric = metrics1;
          }
        }
        if (this.taskList.FULL) {
          if (metrics2.config) {
            metrics2.queries = metrics2.config[0].options.queries;
            this.formatFullMetric(metrics2);
            this.metric = metrics2;
          }
        }

        if (this.taskList.REVISE) {
          if (metrics4.config) {
            metrics4.queries = metrics4.config[0].options.queries;
            this.formatReviseMetric(metrics4);
            this.reviseMetric = metrics4;
          }
        }
        if (this.taskList.CHECK) {
          if (metrics3.config) {
            metrics3.queries = metrics3.config[0].options.queries;
            metrics3.config[0].options.queries.map((query) => {
              if (query.metricNamePattern.indexOf('sum(') > -1) {
                let a = _.cloneDeep(query.metricNamePattern.split('{')[0]);
                const b = _.cloneDeep(query.metricNamePattern.split('by')[1]);

                a = a.replace('\'', '');

                query.metricNamePattern = `${a}{job_id="${this.jobId}",task_id="${this.taskList.CHECK.dataTaskId}"}) by${b}`;
              } else if (query.metricNamePattern.indexOf('increase') > -1) {
                let a = query.metricNamePattern.split('[')[0].split('{')[0];

                a = a.replace('\'', '');
                query.metricNamePattern = `${a}'{'job_id="{0}",task_id="{1}"'}'[${query.metricNamePattern.split('[')[1]}`;
              } else {
                let a = query.metricNamePattern.split('{')[0];

                a = a.replace('\'', '');
                query.metricNamePattern = a;
                query.metricNamePattern += '\'{\'job_id="{0}",task_id="{1}"\'}\'';
              }
              return null;
            });
            this.checkMetric = metrics3;
          }
        }

        this.handleRefresh();
      } catch (e) {
        console.log('e', e);
      }
    },
    handleRender() {
      const that = this;

      setTimeout(() => {
        if (that.$refs.metric1) {
          that.$refs.metric1.$emit('render');
        } else {
          _.forEach(that.$refs.metrics, (m) => {
            m.$emit('render');
          });
        }
        if (that.$refs.metric2) {
          that.$refs.metric2.$emit('render');
        }
        if (that.$refs.metric3) {
          that.$refs.metric3.$emit('render');
        }
      }, 100);
    },
    handleRenderFull() {
      const that = this;

      setTimeout(() => {
        if (that.$refs.metric1) {
          that.$refs.metric1.$emit('render');
        } else {
          _.forEach(that.$refs.metrics, (m) => {
            m.$emit('render');
          });
        }
      }, 100);
    },
    handleRenderIncrement() {
      const that = this;

      setTimeout(() => {
        if (that.$refs.metric2) {
          that.$refs.metric2.$emit('render');
        }
      }, 100);
    },
    handleFullPageChange(page) {
      this.fullPage = page;
      this.showFullTransData = this.showFullTableList.slice((this.fullPage - 1) * this.fullSize, this.fullPage * this.fullSize);
    },
    handleClearBinlog() {
      if (this.jobData.currentStatus === 'RUNNING') {
        this.$Modal.warning({
          title: '清除位点提醒',
          content: '当前任务还在运行中，请先停止任务再进行位点清除'
        });
      } else {
        this.positionInfo.binlogTime = new Date();
        this.showClearPosition = true;
      }
    },
    handleChangeBinlogCancel() {
      this.showEditBinlogTime = false;
      this.showResetPosition = false;
      this.showClearPosition = false;
      this.showEditAlarmRule = false;
      this.showMapping = false;
    },
    handleGoFsm() {
      this.$router.push({ path: `/system/fsm/${this.jobData.fsmId}/${this.jobId}` });
    },
    handleGoWorker(task) {
      this.$router.push({ path: `/system/resource/${task.clusterId}?ip=${task.workerIp}&task=${task.dataTaskId}` });
    },
    handleShowConfirm() {
      if (this.jobData.currentStatus === 'RUNNING') {
        this.$Modal.warning({
          title: '回溯位点提醒',
          content: '当前任务还在运行中，请先停止任务再进行位点回溯'
        });
      } else {
        this.showEditBinlogTime = false;
        this.showResetPosition = true;
      }
    },
    handleResetPosition(verifyCode, clearPosition, posType) {
      if (this.jobData.currentStatus === 'RUNNING') {
        this.$Modal.warning({
          title: '回溯位点提醒',
          content: '当前任务还在运行中，请先停止任务再进行位点回溯'
        });
        this.showClearPosition = false;
        this.showResetPosition = false;
      } else {
        this.positionLoading = true;
        this.showEditBinlogTime = false;
        if (clearPosition) {
          resetPosTocurrent({
            taskId: this.taskList.INCREMENT.dataTaskId,
            posTypes: this.posTypeList,
            verifyCode
          })
            .then((res) => {
              if (res.data.code === '1') {
                this.$Message.success('回溯位点成功');
                this.queryData();
                this.init();
                const that = this;

                setTimeout(() => {
                  that.handleRender();
                  that.showJobDetail = that.jobPanelCount[that.jobData.dataTaskState];
                }, 200);

                this.positionLoading = false;
                this.showClearPosition = false;
                this.showResetPosition = false;
                this.$refs['clear-position-modal'].handleEmptyVerifyCodeModalData();
              }
            });
        } else {
          updateIncrePos({
            taskId: this.taskList.INCREMENT.dataTaskId,
            posType,
            positionTimestamp: this.positionInfo.binlogTime.getTime(),
            journalFile: this.positionInfo.journalFile,
            filePosition: this.positionInfo.filePosition,
            gtidPosition: this.positionInfo.gtid,
            lsn: this.positionInfo.lsn,
            scn: this.positionInfo.scn
          }).then((res) => {
            if (res.data.code === '1') {
              this.$Message.success('回溯位点成功');
              this.queryData();
              this.init();
              const that = this;

              setTimeout(() => {
                that.handleRender();
                that.showJobDetail = that.jobPanelCount[that.jobData.dataTaskState];
              }, 200);

              this.positionLoading = false;
              this.showClearPosition = false;
              this.showResetPosition = false;
            }
          });
        }
      }
    },
    handleInterval() {
      const that = this;

      if (this.hasInterval) {
        this.hasInterval = false;
        clearInterval(this.myInterval);
      } else {
        this.hasInterval = true;
        this.myInterval = setInterval(() => {
          that.queryData('refresh');
          that.handleRender();
          // that.getTransTime();
        }, 10000);
      }
    },
    handleFullInterval() {
      const that = this;

      if (this.hasFullInterval) {
        this.hasFullInterval = false;
        clearInterval(this.myInterval);
      } else {
        this.hasFullInterval = true;
        this.myInterval = setInterval(() => {
          that.handleRenderFull();
        }, 10000);
      }
    },
    handleGoDataSource(id) {
      this.$router.push({ path: `/data/dataSource/${id}` });
    },
    getMappingCount(type, sourceDb) {
      let count = 0;

      this.mappingDef.forEach((item) => {
        if (item.method === type) {
          if (type === 'TABLE_TABLE') {
            if (DataSourceGroup.pg.indexOf(this.jobData.sourceDsVO.dataSourceType) > -1) {
              sourceDb.schemas[0].tables.map((table) => {
                const mapping = {
                  parent: {
                    value: sourceDb.schemas[0].schema,
                    parent: {
                      value: sourceDb.db
                    }
                  },
                  value: table.table
                };

                if (item.serializeMapping[JSON.stringify(mapping)]) {
                  count++;
                }
                return null;
              });
            } else if (DataSourceGroup.oracle.indexOf(this.jobData.sourceDsVO.dataSourceType) > -1) {
              sourceDb.tableSpaces[0].tables.map((table) => {
                const mapping = {
                  parent: {
                    value: sourceDb.tableSpaces[0].tableSpace,
                    parent: {
                      value: sourceDb.db
                    }
                  },
                  value: table.table
                };

                if (item.serializeMapping[JSON.stringify(mapping)]) {
                  count++;
                }
                return null;
              });
            } else if (isMongoDB(this.jobData.sourceDsVO.dataSourceType)) {
              sourceDb.collections.map((table) => {
                const mapping = {
                  parent: sourceDb.db,
                  value: table.table
                };

                if (item.serializeMapping[JSON.stringify(mapping)]) {
                  count++;
                }
                return null;
              });
            } else {
              sourceDb.tables.map((table) => {
                const mapping = {
                  parent: sourceDb.db,
                  value: table.table
                };

                if (item.serializeMapping[JSON.stringify(mapping)]) {
                  count++;
                }
                return null;
              });
            }
          }
        } else if (type === 'TABLE_TABLE_WITH_SCHEMA' && item.method === 'TABLE_TABLE') {
          if (sourceDb.tableSpaces) {
            sourceDb.tableSpaces[0].tables.forEach((table) => {
              const mapping = {
                parent: {
                  value: sourceDb.tableSpaces[0].tableSpace,
                  parent: {
                    value: sourceDb.db
                  }
                },
                value: table.table
              };

              if (item.serializeMapping[JSON.stringify(mapping)]) {
                count++;
              }
            });
          } else {
            sourceDb.schemas[0].tables.forEach((table) => {
              const mapping = {
                parent: {
                  value: sourceDb.schemas[0].schema,
                  parent: {
                    value: sourceDb.db
                  }
                },
                value: table.table
              };

              if (item.serializeMapping[JSON.stringify(mapping)]) {
                count++;
              }
            });
          }
        }
      });
      return count;
    },
    handleAlarmConfig() {
      listAlertAlarmLevel().then((res) => {
        if (res.data.success) {
          this.alarmLevels = res.data;
        }
      });
      listAlertIndicators().then((res) => {
        if (res.data.code === '1') {
          this.indicators = res.data.data;
        }
      });
      listAlertConfig({ dataJobId: this.jobId }).then((res) => {
        if (res.data.code === '1') {
          this.selectedRule = res.data.data[0];
          this.showEditAlarmRule = true;
          const expressionForm = this.verfifyExpression(this.selectedRule.expression);

          this.addRule.indicators[0] = {
            indicator: expressionForm[0],
            value: expressionForm[2].split('*')[0],
            symbol: expressionForm[1]
          };
          this.addRule.sendAdmin = this.selectedRule.sendAdmin;
          this.addRule.methods = [];
          if (this.selectedRule.dingding) {
            this.addRule.methods.push('dingding');
          }
          if (this.selectedRule.sms) {
            this.addRule.methods.push('sms');
          }
          this.taskExceptionMethods = [];
          this.exceptionConfig = res.data.data[1];
          if (res.data.data[1].dingding) {
            this.taskExceptionMethods.push('dingding');
          }
          if (res.data.data[1].sms) {
            this.taskExceptionMethods.push('sms');
          }
          this.taskExceptionExpress = res.data.data[1].expression;
          this.taskExceptionSendAdmin = res.data.data[1].sendAdmin;
        }
      });
    },
    verfifyExpression(expression) {
      const expressionForm = [];

      if (expression.indexOf('>=') > -1) {
        expressionForm[0] = expression.split('>=')[0];
        expressionForm[1] = '>=';
        expressionForm[2] = expression.split('>=')[1];
      } else if (expression.indexOf('<=') > -1) {
        expressionForm[0] = expression.split('<=')[0];
        expressionForm[1] = '<=';
        expressionForm[2] = expression.split('<=')[1];
      } else if (expression.indexOf('!=') > -1) {
        expressionForm[0] = expression.split('!=')[0];
        expressionForm[1] = '!=';
        expressionForm[2] = expression.split('!=')[1];
      } else if (expression.indexOf('=') > -1) {
        expressionForm[0] = expression.split('=')[0];
        expressionForm[1] = '=';
        expressionForm[2] = expression.split('=')[1];
      } else if (expression.indexOf('>') > -1) {
        expressionForm[0] = expression.split('>')[0];
        expressionForm[1] = '>';
        expressionForm[2] = expression.split('>')[1];
      } else if (expression.indexOf('<') > -1) {
        expressionForm[0] = expression.split('<')[0];
        expressionForm[1] = '<';
        expressionForm[2] = expression.split('<')[1];
      }

      return expressionForm;
    },
    handleFilterFullTable(data1, data2) {
      this.filterJobStatus = data1;
      this.showFullTableList = [];
      this.filterTableName = data2;
      this.fullTransData.map((item) => {
        if (this.filterTableName) {
          if (item.table.indexOf(this.filterTableName) > -1) {
            if (this.filterJobStatus.length < 3) {
              this.filterJobStatus.map((status) => {
                if (status === 'running') {
                  if (item.transPercent < 100 && item.transPercent > 0) {
                    this.showFullTableList.push(item);
                  }
                } else if (status === 'wait') {
                  if (item.transPercent === 0) {
                    this.showFullTableList.push(item);
                  }
                } else if (status === 'finish') {
                  if (item.transPercent === 100) {
                    this.showFullTableList.push(item);
                  }
                }
                return null;
              });
            } else {
              this.showFullTableList.push(item);
            }
          }
        } else if (this.filterJobStatus.length < 3) {
          this.filterJobStatus.map((status) => {
            if (status === 'running') {
              if (item.transPercent < 100 && item.transPercent > 0) {
                this.showFullTableList.push(item);
              }
            } else if (status === 'wait') {
              if (item.transPercent === 0) {
                this.showFullTableList.push(item);
              }
            } else if (status === 'finish') {
              if (item.transPercent === 100) {
                this.showFullTableList.push(item);
              }
            }
            return null;
          });
        } else {
          this.showFullTableList.push(item);
        }
        return null;
      });
      this.fullPage = 1;
      this.showFullTransData = this.showFullTableList.slice((this.fullPage - 1) * this.fullSize, this.fullPage * this.fullSize);
      this.fullTotal = this.showFullTableList.length;
    },
    handleShowWhereCondition(tableInfo, table, db) {
      if (tableInfo.type === 'SQL_WHERE') {
        let content = 'select ';

        table.columns.map((column, index) => {
          if (index < table.columns.length - 1) {
            content += `${column.column},`;
          } else {
            content += column.column;
          }
          return null;
        });

        content += ` from ${db.db}.${table.table} where ${tableInfo.expression};`;
        this.currentWhereCondition = content;
        this.showWhereCondition = true;
      }
    },
    handlePartition(targetDbTableConfig, db, table) {
      this.partition = targetDbTableConfig[db].tableConfig[table];
      this.showPartition = true;
    },
    getCheckExpression() {
      const weekMap = {
        1: '日',
        2: '一',
        3: '二',
        4: '三',
        5: '四',
        6: '五',
        7: '六'
      };
      const expressionArr = this.taskList.CHECK.checkPeriodCronExpr.split(' ');

      if (expressionArr[5] === '?') {
        if (expressionArr[2] === '*') {
          return `每小时 ${expressionArr[1] < 10 ? `0${expressionArr[1]}` : expressionArr[1]} 分执行一次`;
        }
        return `每天 ${expressionArr[2]}:${expressionArr[1] < 10 ? `0${expressionArr[1]}` : expressionArr[1]} 执行一次`;
      }
      return `每周 ${weekMap[expressionArr[5]]}${expressionArr[2]}:${expressionArr[1] < 10 ? `0${expressionArr[1]}` : expressionArr[1]} 执行一次`;
    },
    handleEditJobDesc() {
      this.selectedJob = _.cloneDeep(this.jobData);
      this.showEditJobDesc = true;
    },
    handleConfirmEdit() {
      this.showEditJobDesc = false;
      updateJob({
        jobId: this.jobId,
        dataJobDesc: this.selectedJob.dataJobDesc
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.queryData();
          }
        });
    },
    handleCheckPageChange(page) {
      this.checkPage = page;
      this.showCheckData = this.checkData.slice((this.checkPage - 1) * this.checkSize, this.checkPage * this.checkSize);
    },
    sameItem(sink, source) {
      let hasSame = false;

      if (this.jobData.targetCaseSensitive === 'false') {
        if (sink.toUpperCase() === source.toUpperCase()) {
          hasSame = true;
        }
      } else if (this.jobData.commonRule === 'UpperCase') {
        if (sink.toUpperCase() === source.toUpperCase()) {
          hasSame = true;
        }
      } else if (this.jobData.commonRule === 'LowerCase') {
        if (sink.toLowerCase() === source.toLowerCase()) {
          hasSame = true;
        }
      } else if (sink === source) {
        hasSame = true;
      }
      return hasSame;
    },
    handleStructPageChange(page) {
      this.structPage = page;
      this.showStructData = this.structData.slice((this.structPage - 1) * 10, this.structPage * 10);
    },
    getStatusName(row) {
      const type = MappingUtil.taskSateToTaskType[row.dataTaskState];

      if (type) {
        if (!this.getDataTask(row.dataTasks)[type]) {
          return '初始化';
        }
        if (this.getDataTask(row.dataTasks)[type].dataTaskStatus === 'STOP') {
          return '已停止';
        }
        if (this.getDataTask(row.dataTasks)[type].healthLevel === 'Health') {
          return '正常';
        }
        return '异常';
      }
      if (row.dataTaskState === 'COMPLETE') {
        return '已完成';
      }
      return '正常';
    },
    getStatusIcon(row) {
      const type = MappingUtil.taskSateToTaskType[row.dataTaskState];

      if (type) {
        if (!this.getDataTask(row.dataTasks)[type]) {
          return 'icon-chongqi';
        }
        if (this.getDataTask(row.dataTasks)[type].dataTaskStatus === 'STOP') {
          return 'icon-zanting';
        }
        if (this.getDataTask(row.dataTasks)[type].healthLevel === 'Health') {
          return 'icon-zhengchang';
        }
        return 'icon-yichang';
      }
      if (row.dataTaskState === 'COMPLETE') {
        return 'icon-wancheng';
      }
      return 'icon-zhengchang';
    },
    getDataTask(taskList) {
      const dataTasks = {};

      if (taskList) {
        taskList.map((item) => {
          dataTasks[item.dataTaskType] = item;
          return null;
        });
      }
      return dataTasks;
    },
    getCurrentStateColor(state) {
      const status = this.taskList[state].dataTaskStatus;

      switch (status) {
        case 'STOP':
          return '#FFBE56';
        case 'ABNORMAL':
          return '#FF7674';
        case 'RUNNING':
          return '#52C41A';
        default:
          return '#52C41A';
      }
    },
    handleCopyWorkerIp(data) {
      const aux = document.createElement('input');

      aux.setAttribute('value', data);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand('copy');
      document.body.removeChild(aux);

      this.$Message.success('复制成功！');
    },
    getDataSourceDetail() {
      queryById({ dataSourceId: this.jobData.sourceDsVO.id })
        .then((res) => {
          if (res.data.code === '1') {
            this.sourceDetail = res.data.data;
          }
        });
    },
    getDataSinkDetail() {
      queryById({ dataSourceId: this.jobData.targetDsVO.id })
        .then((res) => {
          if (res.data.code === '1') {
            this.sinkDetail = res.data.data;
          }
        });
    },
    handleChangeTable(sourceDb, sourceTable, index) {
      this.sourceTable = sourceTable;
      this.selectedIndex = index;
      this.handleMqSchema(sourceDb);
      this.getColumnData(sourceDb, sourceTable);
    },
    isTarget() {
      return DataSourceGroup.mq.includes(this.jobData.sourceDsVO.dataSourceType);
    },
    getColumnData(sourceDb, sourceTable) {
      if (sourceTable.columns) {
        const dbTablesMap = {};
        dbTablesMap[sourceDb.db] = sourceTable;
        const tableMetas = [];
        tableMetas.push({
          dbName: sourceDb.db,
          tableName: sourceTable.table,
          schemaName: sourceDb.schemas ? sourceDb.schemas[0].schema : sourceDb.tableSpaces ? sourceDb.tableSpaces[0].tableSpace : ''
        });
        const dsVO = this.isTarget() ? this.jobData.targetDsVO : this.jobData.sourceDsVO;
        batchListColumns({
          host: dsVO.host,
          privateHost: dsVO.privateHost,
          publicHost: dsVO.publicHost,
          hostType: dsVO.hostType,
          type: dsVO.dataSourceType,
          dbName: sourceDb.db,
          dataSourceId: dsVO.id,
          referenceDataJobId: this.jobData.dataJobId,
          referenceDsEndPointType: this.isTarget() ? 'TARGET' : 'SOURCE',
          dbTablesMap,
          tableSchema: sourceDb.schemas ? sourceDb.schemas[0].schema : '',
          tableMetas
        })
          .then((res) => {
            if (res.data.code === '1') {
              this.trueColumnData = res.data.data.tableMetaDataMap;
            }
          });
      }
    },
    getTabColor(type) {
      if (this.taskList[type] && this.taskList[type].dataTaskStatus !== 'COMPLETE') {
        if (this.taskList[type].dataTaskStatus === 'RUNNING' && this.taskList[type].healthLevel === 'Unhealthy') {
          return '#FF1815';
        }
        return this.getCurrentStateColor(type);
      }
    },
    handleCancelDelete() {
      this.verifyCode = '';
      this.showEditAlarmRule = false;
      this.showLog = false;
      this.showConfirm = false;
      this.showWhereCondition = false;
    },
    handlePDeleteJob() {
      this.showConfirm = true;
    },
    handleShowLog(taskId) {
      let id = taskId;

      if (typeof taskId !== 'number') {
        id = this.taskList[this.currentState] ? this.taskList[this.currentState].dataTaskId : 0;
      }

      this.logLoading = true;
      tailTaskMainLog({
        taskId: id,
        dataJobId: this.jobId

      })
        .then((res) => {
          this.logLoading = false;
          if (res.data.code === '1') {
            this.logData = res.data.data;
            if (this.selectedLog && this.selectedLog.fileName) {
              this.logData.map((item) => {
                if (item.fileName === this.selectedLog.fileName) {
                  this.selectedLog = item;
                }
                return null;
              });
            } else if (this.logData.length > 0) {
              this.selectedLog = this.logData[0];
              this.logFileName = this.selectedLog.fileName;
            }
            if (this.logData.length > 0) {
              this.showLog = true;
            } else {
              this.$Modal.info({
                title: '查看日志提醒',
                content: '该任务当前阶段未开始或已完成，无法获取日志。'
              });
            }
            const ele = document.getElementsByClassName('log-content');

            ele.scrollTop = ele.scrollHeight;
          }
        });
    },
    // handleShowLog () {
    //
    //     this.logLoading = true;
    //     let taskId = 0;
    //
    //     if (this.currentState && this.currentState !== 'INIT' && this.currentState !== 'COMPLETE') {
    //
    //         taskId = this.taskList[this.currentState].dataTaskId;
    //         tailTaskMainLog({
    //             taskId,
    //             dataJobId: this.jobId
    //         }).then((res) => {
    //
    //             this.logLoading = false;
    //             if (res.data.code === '1') {
    //
    //                 this.logData = res.data.data;
    //                 if (this.selectedLog && this.selectedLog.fileName) {
    //
    //                     this.logData.map((item) => {
    //
    //                         if (item.fileName === this.selectedLog.fileName) {
    //
    //                             this.selectedLog = item;
    //
    //                         }
    //
    //                     });
    //
    //                 } else if (this.logData.length > 0) {
    //
    //                     this.selectedLog = this.logData[0];
    //                     this.logFileName = this.selectedLog.fileName;
    //
    //                 }
    //                 if (this.logData.length > 0) {
    //
    //                     this.showLog = true;
    //
    //                 } else {
    //
    //                     this.$Modal.info({
    //                         title: '查看日志提醒',
    //                         content: '该任务当前阶段已完成，无法获取日志。'
    //                     });
    //
    //                 }
    //                 const ele = document.getElementsByClassName('log-content');
    //
    //                 ele.scrollTop = ele.scrollHeight;
    //
    //             }
    //
    //         });
    //
    //     } else if (this.currentState === 'INIT') {
    //
    //         this.$Modal.info({
    //             title: '查看日志提醒',
    //             content: '任务初始化中，无法获取日志。'
    //         });
    //
    //     } else if (this.currentState === 'COMPELETE') {
    //
    //         this.$Modal.info({
    //             title: '查看日志提醒',
    //             content: '任务已完成，无法获取日志。'
    //         });
    //
    //     } else if (!this.currentState) {
    //
    //         this.$Modal.info({
    //             title: '查看日志提醒',
    //             content: '任务正在初始化中，无法获取日志。'
    //         });
    //
    //     }
    //
    // },
    handleSelectLog(logName) {
      this.logData.forEach((item) => {
        if (item.fileName === logName) {
          this.selectedLog = item;
        }
      });
    },
    handleEditJob() {
      this.$Spin.show();

      queryJobSchemaById({
        jobId: this.jobId,
        srcDsType: this.jobData.sourceDsVO.dataSourceType,
        dstDsType: this.jobData.targetDsVO.dataSourceType
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.sourceSchema = JSON.parse(res.data.data.sourceSchema);
            this.mappingDef = JSON.parse(res.data.data.mappingConfig);
            this.queryJobSchema = res.data.data;
            // if (this.queryJobSchema.schemaWhiteListLevel === 'DB') {
            //   this.$Modal.warning({
            //     title: '修改订阅提示',
            //     content: '该任务非白名单模式，不支持修改订阅。如需加库，请新建任务。'
            //   });
            //   this.$Spin.hide();
            //   return;
            // }

            this.$router.push({
              path: '/data/job/edit/process',
              query: { jobId: this.jobId }
            });
          }
          this.$Spin.hide();
        })
        .catch((e) => {
          console.log(e);
          this.$Spin.hide();
        });
    },
    handleCancelEdit() {
      this.showEditJob = false;
      this.showEditJobDesc = false;
    },
    updatePositionInfo(data, posType, type) {
      if (posType.includes('GTID_POS')) {
        this.positionInfo.gtid = data;
      } else if (posType.includes('TIMESTAMP_POS')) {
        this.positionInfo.binlogTime = data;
      } else if (posType.includes('LOG_FILE_POS')) {
        if (type === 'file') {
          this.positionInfo.journalFile = data;
        } else if (type === 'position') {
          this.positionInfo.filePosition = data;
        }
      } else if (posType.includes('LSN_POS')) {
        this.positionInfo.lsn = data;
      } else if (posType.includes('SCN_POS')) {
        this.positionInfo.scn = data;
      }
    },
    handleEditRule() {
      this.showEditAlarmRule = false;
      if (this.addRule.indicators[0].value < 1) {
        this.$Modal.warning({
          title: '告警配置失败',
          content: '延迟时间最小设置1分钟'
        });
      } else {
        const data1 = {};
        const data2 = {};
        const
          alertConfigVOList = [];

        data1.expression = this.addRule.indicators[0].indicator + this.addRule.indicators[0].symbol + this.addRule.indicators[0].value;
        data1.phone = this.addRule.methods.indexOf('phone') > -1;
        data1.email = this.addRule.methods.indexOf('email') > -1;
        data1.sms = this.addRule.methods.indexOf('sms') > -1;
        data1.dingding = this.addRule.methods.indexOf('dingding') > -1;
        data1.sendAdmin = this.addRule.sendAdmin;
        data1.id = this.selectedRule.id;
        alertConfigVOList.push(data1);
        data2.id = this.exceptionConfig.id;
        data2.sendAdmin = this.taskExceptionSendAdmin;
        data2.dingding = this.taskExceptionMethods.indexOf('dingding') > -1;
        data2.sms = this.taskExceptionMethods.indexOf('sms') > -1;
        data2.phone = this.taskExceptionMethods.indexOf('phone') > -1;
        data2.email = this.taskExceptionMethods.indexOf('email') > -1;
        data2.expression = this.taskExceptionExpress;
        alertConfigVOList.push(data2);
        updateAlertConfigs(alertConfigVOList)
          .then((res) => {
            if (res.data.code === '1') {
              this.$Message.success('修改告警配置成功');
            }
          });
      }
    },
    handlePageChange(sourceDb, index, page) {
      this.showTableMapping[sourceDb.db].page = page;
      if (DataSourceGroup.hasSchema.indexOf(this.jobData.sourceDsVO.dataSourceType) > -1) {
        if (DataSourceGroup.pg.indexOf(this.jobData.sourceDsVO.dataSourceType) > -1) {
          this.showTableMapping[sourceDb.db].list = this.sourceSchema[index].schemas[0].tables.slice((this.showTableMapping[sourceDb.db].page - 1) * this.showTableMapping[sourceDb.db].size,
            this.showTableMapping[sourceDb.db].page * this.showTableMapping[sourceDb.db].size);
        } else {
          this.showTableMapping[sourceDb.db].list = this.sourceSchema[index].tableSpaces[0].tables.slice((this.showTableMapping[sourceDb.db].page - 1) * this.showTableMapping[sourceDb.db].size,
            this.showTableMapping[sourceDb.db].page * this.showTableMapping[sourceDb.db].size);
        }
      } else if (DataSourceGroup.mq.includes(this.jobData.sourceDsVO.dataSourceType)) {
        this.showTableMapping[sourceDb.db].list = this.targetSchema[index].tables.slice((this.showTableMapping[sourceDb.db].page - 1) * this.showTableMapping[sourceDb.db].size,
          this.showTableMapping[sourceDb.db].page * this.showTableMapping[sourceDb.db].size);
      } else {
        this.showTableMapping[sourceDb.db].list = this.sourceSchema[index].tables.slice((this.showTableMapping[sourceDb.db].page - 1) * this.showTableMapping[sourceDb.db].size,
          this.showTableMapping[sourceDb.db].page * this.showTableMapping[sourceDb.db].size);
      }
      this.showTableMapping = { ...this.showTableMapping };
      this.selectedIndex = 0;
      this.sourceTable = this.showTableMapping[sourceDb.db].list[0];
    },
    updateLogFileName(name) {
      this.logFileName = name;
    },
    handleVerify(type) {
      this.sendcodeDisabled = false;
      this.sendCodeAgainTime = 60;
      const that = this;

      let verifyType = '';

      this.sendCodeAgain = setInterval(() => {
        if (that.sendCodeAgainTime > 0) {
          that.sendCodeAgainTime--;
        } else {
          clearInterval(that.sendCodeAgain);
          that.sendcodeDisabled = true;
        }
      }, 1000);

      if (type === 'delete') {
        verifyType = 'DELETE_JOB';
      } else {
        verifyType = 'DELETE_POSITION';
      }
      sendCodeInLoginState({
        verifyType: 'SMS_VERIFY_CODE',
        verifyCodeType: verifyType
      })
        .then((res) => {
          if (res.data.code !== '1') {
            this.sendcodeDisabled = true;
            this.sendCodeAgainTime = 60;
            clearInterval(this.sendCodeAgain);
          }
        })
        .catch(() => {
          this.sendcodeDisabled = true;
          this.sendCodeAgainTime = 60;
          clearInterval(this.sendCodeAgain);
        });
    },
    handleConfirmDelete(verifyCode) {
      deleteJob({
        jobId: this.jobData.dataJobId,
        verifyCode
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.$router.push({ path: '/data/job/list' });
            this.handleCancelDelete();
          }
        });
    },
    updateSourceSchema() {
      this.sourceSchema = _.cloneDeep(JSON.parse(this.jobData.sourceSchema));
    },
    handleGoTaskGroup() {
      this.$router.push({ path: `/data/job/group/${this.jobId}` });
    },
    handleMqSchema(db) {
      if (!this.sourceTable || !this.sourceTable.table) {
        setTimeout(() => {
          if (this.showTableMapping[db.db]) {
            this.sourceTable = this.showTableMapping[db.db].list[0];
            this.currentIndex = 0;
            this.handleMqSchema(db);
          }
        }, 1000);
      }
      if (db.tables) {
        let currentTable = {};

        db.tables.map((table) => {
          if (table.table === this.sourceTable.table) {
            currentTable = table;
          }
          return null;
        });
        this.mqSchema[db.db] = this.mqSchemaTemplate;
        this.mqSchema[db.db] = handleMqSchemaTool(this.mqSchema[db.db], currentTable.columns, db.db, '', currentTable.table, 'detail');
      }

      if (db.schemas) {
        let currentTable = {};

        db.schemas[0].tables.map((table) => {
          if (table.table === this.sourceTable.table) {
            currentTable = table;
          }
          return null;
        });
        this.mqSchema[`${db.db}.${db.schemas[0].schema}`] = this.mqSchemaTemplate;
        this.mqSchema[`${db.db}.${db.schemas[0].schema}`] = handleMqSchemaTool(this.mqSchema[`${db.db}.${db.schemas[0].schema}`],
          currentTable.columns, db.db, db.schemas[0].schema, currentTable.table, 'detail');
      }
      this.mqSchema = { ...this.mqSchema };
    },
    disableBtn(type) {
      if (type === 'start') {
        return !((store.state.urlLabels.indexOf('/') > -1 || store.state.urlLabels.indexOf('/datajob/start') > -1)
          && this.jobData.currentStatus === 'STOP') || this.starting;
      }
      if (type === 'restart') {
        return !((store.state.urlLabels.indexOf('/') > -1 || store.state.urlLabels.indexOf('/datajob/restart') > -1)
          && this.jobData.currentStatus === 'RUNNING') || this.restarting || this.stopping;
      }
      if (type === 'stop') {
        return !((store.state.urlLabels.indexOf('/') > -1 || store.state.urlLabels.indexOf('/datajob/stop') > -1)
          && (this.jobData.currentStatus === 'RUNNING' || this.jobData.currentStatus === 'WAIT_START')) || this.stopping || this.restarting;
      }
      if (type === 'startfsm') {
        return !(this.getDataTask(this.jobData.dataTasks).BUILD_STRUCT
          ? this.getDataTask(this.jobData.dataTasks).BUILD_STRUCT.dataTaskStatus === 'COMPLETE' && (!this.jobData.fsmActive && this.jobData.dataTaskState === 'INIT')
          : !this.jobData.fsmActive && this.jobData.dataTaskState === 'INIT');
      }
      return false;
    },
    dsPosTypes() {
      dsPosTypes({ dsType: this.jobData.sourceDsVO.dataSourceType }).then((res) => {
        if (res.data.code === '1') {
          this.posTypeList = res.data.data;
        }
      });
    },
    handleShowPackageList() {
      this.listPackages();
      this.showPackage = true;
    },
    handleCancel() {
      this.showPackage = false;
    },
    handleCancelUpload() {
      this.showUploadPkg = false;
      this.handleRemovePkg();
    },
    handleCancelUpdate() {
      this.showUpdatePkg = false;
    },
    handleDeletePackage(row) {
      this.$Modal.confirm({
        title: '删除确认',
        content: `<p>确认要删除${row.description}这个包吗？</p>`,
        onOk: () => {
          this.$Spin.show();
          deletePackages({
            pkgId: row.id,
            dataJobId: this.jobId
          }).then((res) => {
            this.currentPercentage = 0;
            if (res.data.code === '1') {
              if (res.data.data) {
                this.showDeletePkg = true;
                const that = this;
                this.queryDeletePkgInterval = setInterval(() => {
                  queryConsoleJob({ consoleJobId: res.data.data })
                    .then((response) => {
                      if (res.data.code === '1') {
                        that.currentPkgDeleteStatus = response.data.data.taskState;
                        if (response.data.data.taskState === 'SUCCESS') {
                          clearInterval(that.queryDeletePkgInterval);
                          // that.sourceDisabled = false;
                          setTimeout(() => {
                            that.listPackages();
                          }, 500);
                        } else if (response.data.data.taskState === 'FAILED') {
                          clearInterval(that.queryDeletePkgInterval);
                          // that.sourceDisabled = false;
                        }
                      }
                    })
                    .catch((e) => {
                      console.log(e);
                      // that.sourceDisabled = false;
                    });
                }, 2000);
                this.getPercantage = setInterval(() => {
                  if (that.currentPkgDeleteStatus === 'SUCCESS') {
                    that.currentPercentage = 100;
                    clearInterval(that.getPercantage);
                    setTimeout(() => {
                      that.showDeletePkg = false;
                    }, 500);
                  } else if (that.currentPkgDeleteStatus === 'FAILED') {
                    clearInterval(that.getPercantage);
                  } else if (that.currentPercentage < 90) {
                    that.currentPercentage += Math.floor(Math.random() * 3);
                  } else if (that.currentPercentage < 97 && that.currentPercentage >= 90) {
                    that.currentPercentage += Math.floor(Math.random() * 1.5);
                  } else if (that.currentPercentage >= 97) {
                    that.currentPercentage = 99;
                  }
                }, 200);
              }
              this.listPackages();
            }
          }).finally(() => {
            this.$Spin.hide();
          });
        },
        onCancel: () => {
          this.$Message.info('Clicked cancel');
        }
      });
    },
    listPackages() {
      listPackages({
        dataJobId: this.jobData.dataJobId
      }).then((res) => {
        if (res.data.code === '1') {
          this.packageData = res.data.data;
          this.activePackage = {};
          this.packageData.forEach((item) => {
            if (item.active) {
              this.activePackage = item;
            }
          });
        }
      });
    },
    downloadPackage(row) {
      this.$Spin.show();
      downloadPackage({
        pkgId: row.id,
        dataJobId: this.jobData.dataJobId
      }).then((res) => {
        const a = document.createElement('a');
        const binaryData = [];
        binaryData.push(res.data);
        const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/jar' }));
        const filename = row.fileName;
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
        this.$Spin.hide();
      });
    },
    handleUpload() {
      this.showUploadPkg = true;
    },
    handleFileChange(e) {
      const files = e.target.files;

      if (files && files[0]) {
        const file = files[0];

        if (file.size > 1024 * 1024 * 500) {
          this.$Modal.error({
            title: '文件过大',
            content: '文件大小应不超过500M'
          });

          return false;
        }
        this.customPkgFile = file;
      }
    },
    handleConfirmUpload() {
      this.addedPackage.customPkgFile = this.customPkgFile;
      this.showUploadPkg = false;
      const data = {
        description: this.addedPackage.pkgDescription,
        dataJobId: this.jobId
      };
      this.showUpdatePkgProgress = true;
      this.currentPercentage = 0;
      this.getPercantage = setInterval(() => {
        if (this.currentPercentage < 90) {
          this.currentPercentage += Math.floor(Math.random() * 10);
        } else if (this.currentPercentage < 97 && this.currentPercentage >= 90) {
          this.currentPercentage += Math.floor(Math.random() * 3);
        } else if (this.currentPercentage >= 97) {
          this.currentPercentage = 99;
        }
      }, 200);
      const stringData = JSON.stringify(data); const
        formData = new FormData();

      if (this.addedPackage.customPkgFile) {
        formData.append('customPkgFile', this.addedPackage.customPkgFile);
      }
      formData.append('uploadPkgFO', new Blob([stringData], { type: 'application/json' }));
      uploadPackage(formData).then((res) => {
        if (res.data.code === '1') {
          this.currentPercentage = 100;
          clearInterval(this.getPercantage);
          setTimeout(() => {
            this.showUpdatePkgProgress = false;
            this.listPackages();
          }, 200);
        }
      }).finally(() => {
        this.handleRemovePkg();
        this.showUpdatePkgProgress = false;
      });
    },
    handleConfirmUpdate() {
      updatePkgInfo({
        dataJobId: this.jobId,
        pkgId: this.selectedPkg.id,
        description: this.pkgDescription
      }).then((res) => {
        if (res.data.code === '1') {
          this.listPackages();
          this.showUpdatePkg = false;
        }
      });
    },
    handleRemovePkg() {
      this.customPkgFile = null;
      this.addedPackage.pkgDescription = '';
    },
    activePackages(row) {
      this.activePkgLoading = true;
      activePackages({
        activePkgId: row.id,
        dataJobId: this.jobId
      }).then((res) => {
        if (res.data.code === '1') {
          this.activePkgLoading = false;
          this.listPackages();
        }
      });
    },
    handleShowUpdatePkg(row) {
      this.showUpdatePkg = true;
      this.pkgDescription = row.description;
      this.selectedPkg = row;
    },
    async handleRebuildIndex() {
      const res = await rebuildIndex({
        dataJobId: this.jobId
      });

      if (res.data.success) {
        this.$Message.success('更新元数据索引成功');
      } else {
        this.$Message.error('更新元数据索引失败');
      }
    },
    handleShowRestartList() {
      const task = this.taskList[this.currentState];
      getRestartList({
        dataJobId: this.jobId,
        dataTaskId: task.dataTaskId
      }).then((res) => {
        if (res.data.success) {
          this.restartList = res.data.data;
        }
      });
      this.showRestartListModal = true;
    },
    async handleCreateSimilarJob(type = 'revise') {
      await preFirstCheck()
        .then((res2) => {
          if (res2.data.code === '1') {
            this.handleCreateSimilarJobFunc(type);
          }
        });
    },
    async handleCreateSimilarJobFunc(type) {
      const { sourceDsVO, targetDsVO } = this.jobData;
      const sourceRes = await supportedsourcedstype({ deployType: sourceDsVO.deployType });
      let unsupportedDsType = false;
      let msg = '';
      if (sourceRes.data.success) {
        if (sourceRes.data.data[sourceDsVO.dataSourceType]) {
          const sinkRes = await supportedtargetdstype({
            deployType: targetDsVO.deployType,
            sourceType: sourceDsVO.dataSourceType
          });

          if (sinkRes.data.success) {
            if (!sinkRes.data.data[targetDsVO.dataSourceType]) {
              unsupportedDsType = true;
              msg = `${targetDsVO.dataSourceType}不支持`;
            }
          }
        } else {
          unsupportedDsType = true;
          msg = `${sourceDsVO.dataSourceType}不支持`;
        }
      }

      if (!unsupportedDsType) {
        if (!this.schemaData) {
          this.$Spin.show();

          queryJobSchemaById({
            jobId: this.jobId,
            srcDsType: this.jobData.sourceDsVO.dataSourceType,
            dstDsType: this.jobData.targetDsVO.dataSourceType
          })
            .then((res) => {
              if (res.data.code === '1') {
                this.schemaData = res.data.data;
                this.jobData.schemaData = res.data.data;
                if (type === 'revise') {
                  this.jobData.createReviseTask = true;
                  this.jobData.checkTaskId = this.taskList.CHECK.dataTaskId;
                }
                store.commit('getJobDataForSimilarJob', this.jobData);
                this.$router.push({ path: '/data/job/create/process' });
              }
            }).finally(() => {
              this.$Spin.hide();
            });
        } else {
          this.jobData.schemaData = this.schemaData;
          store.commit('getJobDataForSimilarJob', this.jobData);
          this.$router.push({ path: '/data/job/create/process' });
        }
      } else {
        Modal.error({
          title: '许可过期',
          content: msg
        });
      }
    }
  }
};
</script>
<style lang="less">

.job-info-status {
  font-size: 14px;
}
.pkg-manager {
  .edit-icon {
    cursor: pointer;
    margin-left: 6px;
  }
}

.ivu-progress-normal .ivu-progress-bg {
  background: #9EE6AA;
}
</style>
