<template>
  <div>
    <h4 style="margin-top: 30px">数据库映射
      <!--            <Tooltip content="请确认数据库已对您的账号授权" placement="right" style="font-family: PingFangSC-Regular,serif">-->
      <!--                <a style="color: #888888;font-size: 12px;margin-left: 10px;font-weight: 400">查询不到库？</a>-->
      <!--            </Tooltip>-->
      <RadioGroup v-if="!DataSourceGroup.pg.includes(taskInfo.sourceType)&&!DataSourceGroup.oracle.includes(taskInfo.sourceType)&&
          !DataSourceGroup.pg.includes(taskInfo.sinkType)&&!DataSourceGroup.oracle.includes(taskInfo.sinkType)"
                  v-model="taskInfo.mappingMode"
                  @on-change="handleChangeMode"
                  style="margin-left: 10px;font-weight: 400;font-size: 12px">
        <Radio label="advanced">默认模式</Radio>
        <Radio label="easy" v-if="!DataSourceGroup.mongo.includes(taskInfo.sourceType)">树形模式</Radio>
      </RadioGroup>
    </h4>
    <div v-if="taskInfo.mappingMode === 'advanced'">
      <div v-for="(db,index) in taskInfo.dbMap" :key="index" style="margin-bottom: 20px">
        <FormItem prop="hasDb">
          <Select filterable :disabled="!taskInfo.sourceConnection" v-model="db.sourceDb"
                  placeholder="选择源数据库"
                  :transfer="true"
                  style="width: 280px" @on-change="handleDbChange('source',index,$event)">
            <Option :disabled="(hasTheDb(item) || hasSelectedTheDb(item.dbName, 'source')) && !(isMySQL(taskInfo.sourceType) && isGP(taskInfo.sinkType))"
                    v-for="(item,i) in taskInfo.sourceDbList" :value="item.dbName"
                    :key="i">{{ item.dbName }}
            </Option>
          </Select>
          <Select filterable :disabled="!taskInfo.sourceConnection"
                  v-if="DataSourceGroup.pg.indexOf(taskInfo.sourceType)>-1"
                  v-model="db.sourceSchema"
                  placeholder="选择源端schema"
                  ref="source-schema-select"
                  :transfer="true" style="width: 180px;margin: 0 5px" @on-open-change="handleEmptySourceSchemaQuery(index, $event)">
            <Option v-for="(item,i) in getSchemaList(taskInfo.sourceDbList,db.sourceDb)"
                    :value="item" :key="i">{{ item }}
            </Option>
          </Select>
          <Select filterable :disabled="!taskInfo.sourceConnection"
                  v-if="DataSourceGroup.oracle.indexOf(taskInfo.sourceType)>-1"
                  v-model="db.sourceSchema"
                  placeholder="表空间" @on-change="handleChangeSchema(db,$event)"
                  :transfer="true" style="width: 100px;margin: 0 5px">
            <Option v-for="(item,i) in getSchemaList(taskInfo.sourceDbList,db.sourceDb)"
                    :value="item" :key="i">{{ item }}
            </Option>
          </Select>
          <Icon type="md-arrow-forward"/>
          <Select filterable :disabled="!taskInfo.sinkConnection" v-model="db.sinkDb"
                  :class="!containsDb(taskInfo.sinkDbList,db.sinkDb)?'db-to-create':''"
                  placeholder="选择目标数据库"
                  :transfer="true"
                  style="width: 280px" ref="sink-db-select"
                  @on-open-change="handleEmptySinkQuery(index, $event)">
            <Option v-for="(item) in taskInfo.sinkDbList" :value="item.dbName"
                    :key="item.dbName">{{ item.dbName }}
            </Option>
            <Option v-if="(!isMongoDB(taskInfo.sourceType) || (isMongoDB(taskInfo.sourceType)&&isMongoDB(taskInfo.sinkType)))&&((taskInfo.sinkInstanceType!=='ALIBABA_CLOUD_HOSTED'&&
              DataSourceGroup.oracle.indexOf(taskInfo.sinkType)===-1)||(taskInfo.sinkInstanceType==='ALIBABA_CLOUD_HOSTED'&&
              (DataSourceGroup.mysql.indexOf(taskInfo.sourceType)>-1||DataSourceGroup.polar.indexOf(taskInfo.sourceType)>-1||DataSourceGroup.pg.indexOf(taskInfo.sourceType)>-1)))&&
              !isTunnel(taskInfo.sourceType)&&
              db.sourceDb&&!containsDb(taskInfo.sinkDbList,db.sourceDb)"
                    :value="taskInfo.targetCaseSensitive==='false'?db.sourceDb.toLowerCase():db.sourceDb"
                    :key="999">
              {{
                taskInfo.targetCaseSensitive === 'false' ? db.sourceDb.toLowerCase() : db.sourceDb
              }}（待创建）
            </Option>
            <Option v-if="((taskInfo.sinkInstanceType!=='ALIBABA_CLOUD_HOSTED'&&
              DataSourceGroup.oracle.indexOf(taskInfo.sourceType)>-1)||(DataSourceGroup.oracle.includes(taskInfo.sourceType)&&DataSourceGroup.ck.includes(taskInfo.sinkType)))&&db.sourceDb&&
              !containsDb(taskInfo.sinkDbList,db.sourceSchema)"
                    :value="db.sourceSchema" :key="9999">{{ db.sourceSchema }}（待创建）
            </Option>
          </Select>
          <Select filterable :disabled="!taskInfo.sinkConnection"
                  v-if="DataSourceGroup.pg.indexOf(taskInfo.sinkType)>-1" v-model="db.targetSchema"
                  placeholder="选择对端schema"
                  :class="getSchemaList(taskInfo.sinkDbList,db.sinkDb)&&getSchemaList(taskInfo.sinkDbList,db.sinkDb).indexOf(db.targetSchema)===-1?'db-to-create':''"
                  :transfer="true" style="width: 180px;margin: 0 5px"
                  ref="sink-schema-select"
                  @on-change="handleSchemaChange(db,index,$event)"  @on-open-change="handleEmptySinkSchemaQuery(index, $event)">
            <Option v-for="(item,i) in getSchemaList(taskInfo.sinkDbList,db.sinkDb)"
                    :value="item" :key="i">{{ item }}
            </Option>
            <Option :value="targetSchema(db)"
                    v-if="db.sourceSchema&&
                      (taskInfo.sinkInstanceType!=='ALIBABA_CLOUD_HOSTED'||(taskInfo.sinkInstanceType==='ALIBABA_CLOUD_HOSTED'&&
                      (DataSourceGroup.mysql.indexOf(taskInfo.sourceType)>-1||DataSourceGroup.polar.indexOf(taskInfo.sourceType)>-1||DataSourceGroup.pg.indexOf(taskInfo.sourceType)>-1)))&&
                      db.sourceDb&&
                      !containsSchema(taskInfo.sinkDbList,db.sinkDb,db.sourceSchema)">
              {{ targetSchema(db) }}（待创建）
            </Option>
          </Select>
          <Select filterable :disabled="!taskInfo.sinkConnection"
                  v-if="DataSourceGroup.oracle.indexOf(taskInfo.sinkType)>-1"
                  v-model="db.targetSchema"
                  placeholder="表空间" @on-change="handleChangeSchema(db,$event)"
                  :transfer="true" style="width: 100px;margin: 0 5px">
            <Option v-for="(item,i) in getSchemaList(taskInfo.sinkDbList,db.sinkDb)"
                    :value="item" :key="i">{{ item }}
            </Option>
          </Select>
          <Icon class="add-item-btn add-item-btn-remove" @click="handleRemoveMap(index)"
                v-if="taskInfo.dbMap.length>1" type="ios-remove-circle-outline"/>
          <Icon class="add-item-btn add-item-btn-add" @click="handleAddMap"
                v-if="index===taskInfo.dbMap.length-1&&!isOracle(taskInfo.sinkType)&&!isOracle(taskInfo.sourceType)
                &&!isSQLServer(taskInfo.sourceType)"
                type="ios-add-circle-outline"/>
        </FormItem>
      </div>
    </div>
    <div v-if="taskInfo.mappingMode === 'easy'">
      <div>
        <Row>
          <Col :span="8">
            <Input v-model="filterkey" @on-change="handleFilterDb" suffix="ios-search"
                   placeholder="搜索库名" style="width: 100%"/>
            <div class="db-tree-container">
              <div class="select-all">
                <Checkbox @on-change="handleSelectAll">全选</Checkbox>
                <a style="float: right" @click="handleEditMapping">批量修改目标映射</a>
              </div>
              <div class="db-tree-body">
                <Tree :data="filteredData" show-checkbox
                      @on-check-change="handleChangeCheck"></Tree>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
    <Modal v-model="showEditMapping"
           :title="batchlyEdit?'批量修改目标映射':'修改目标映射'"
           footer-hide
    >
      <div>
        <Select filterable v-model="currentSinkDb">
          <Option v-for="sink in taskInfo.sinkDbList" :value="sink.dbName" :key="sink.dbName">
            {{ sink.dbName }}
          </Option>
        </Select>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleChangeMapping">确定</pd-button>
          <pd-button @click="handleCancel">取消</pd-button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import _ from 'lodash';
import {
  isGP,
  isMongoDB, isMySQL, isOracle, isSQLServer, isTunnel
} from '@utils';
import DataSourceGroup from '../../../../../views/dataSourceGroup';

export default {
  props: {
    dbMap: Array,
    handleDbChange: Function,
    hasTheDb: Function,
    hasSelectedTheDb: Function,
    taskInfo: Object,
    getSchemaList: Function,
    store: Object,
    handleRemoveMap: Function,
    handleAddMap: Function,
    containsDb: Function,
    handleChangeSchema: Function,
    handleSchemaChange: Function,
    containsSchema: Function,
    showEditMapping: Boolean,
    updateShowEditMapping: Function,
    currentSourceDb: String
  },
  data() {
    return {
      DataSourceGroup,
      hasChecked: false,
      needCancelList: [],
      treeData: [],
      filteredData: [],
      filterkey: '',

      currentSinkDb: '',

      batchlyEdit: false
    };
  },
  computed: {
    targetSchema() {
      return (db) => (DataSourceGroup.oracle.indexOf(this.taskInfo.sourceType) > -1
      && DataSourceGroup.pg.indexOf(this.taskInfo.sinkType) > -1
      && this.taskInfo.targetCaseSensitive === 'false' ? db.sourceSchema.toLowerCase()
          .trim() : db.sourceSchema);
    }
  },
  created() {
    this.filteredData = _.cloneDeep(this.taskInfo.treeData);
    this.taskInfo.mappingMode = 'advanced';
  },
  methods: {
    isMySQL,
    isTunnel,
    isOracle,
    isSQLServer,
    isMongoDB,
    isGP,
    handleEmptySinkQuery(index, type) {
      if (!type) {
        const sinkDb = this.taskInfo.dbMap[index].sinkDb;
        this.$refs['sink-db-select'][index].setQuery(null);
        this.$nextTick(() => {
          this.taskInfo.dbMap[index].sinkDb = sinkDb;
          this.handleDbChange('sink', index, sinkDb);
        });
      }
    },
    handleEmptySinkSchemaQuery(index, type) {
      if (!type) {
        const targetSchema = this.taskInfo.dbMap[index].targetSchema;
        this.$refs['sink-schema-select'][index].setQuery(null);
        this.$nextTick(() => {
          this.taskInfo.dbMap[index].targetSchema = targetSchema;
          this.handleSchemaChange(this.taskInfo.dbMap[index], index, type);
        });
      }
    },
    handleEmptySourceSchemaQuery(index, type) {
      if (!type) {
        const sourceSchema = this.taskInfo.dbMap[index].sourceSchema;
        this.$refs['source-schema-select'][index].setQuery(null);
        this.$nextTick(() => {
          this.taskInfo.dbMap[index].sourceSchema = sourceSchema;
        });
      }
    },
    handleSelectDb() {

    },
    handleCancelDb() {

    },
    handleFilterDb() {
      if (this.filterkey) {
        this.filteredData = [];
        this.taskInfo.treeData.forEach((db) => {
          if (db.title.indexOf(this.filterkey) > -1) {
            this.filteredData.push(db);
          }
        });
      } else {
        this.getTreeData(this.taskInfo.treeData);
      }
    },
    handleSelectAll(data) {
      this.filteredData.forEach((db) => {
        db.checked = data;
        this.taskInfo.treeData.forEach((treedata) => {
          if (treedata.title === db.title) {
            treedata.checked = data;
          }
        });

        if (data) {
          let needAuto = false;

          if (this.taskInfo.sinkDbList) {
            needAuto = true;
            this.taskInfo.sinkDbList.forEach((theDb) => {
              if (theDb.dbName === db.title) {
                needAuto = false;
              }
            });
          }

          if (DataSourceGroup.sqlServer.includes(this.taskInfo.sinkType)) {
            this.taskInfo.dbMap.push({
              sourceDb: db.title,
              sinkDb: db.title,
              targetSchema: 'dbo',
              sourceSchema: 'dbo',
              needAutoCreated: needAuto
            });
          } else {
            this.taskInfo.dbMap.push({
              sourceDb: db.title,
              sinkDb: db.title,
              targetSchema: 'public',
              sourceSchema: 'public',
              needAutoCreated: needAuto
            });
          }
        }
      });
      if (!data) {
        this.taskInfo.dbMap.splice(0, this.taskInfo.dbMap.length);
        this.taskInfo.treeData.forEach((db) => {
          db.sinkDb = '';
        });
        this.taskInfo = { ...this.taskInfo };
      }
    },
    handleChangeCheck(selection, current) {
      this.filteredData.forEach((db) => {
        if (current.title === db.title) {
          db.checked = current.checked;
          this.taskInfo.treeData.forEach((treedata) => {
            if (treedata.title === db.title) {
              treedata.checked = current.checked;

              if (!treedata.checked) {
                treedata.sinkDb = '';
              }
            }
          });
          let hasSame = false;

          this.taskInfo.sinkDbList.forEach((sink) => {
            if (sink.dbName === current.title) {
              hasSame = true;
            }
          });
          if (current.checked) {
            if (DataSourceGroup.sqlServer.includes(this.taskInfo.sinkType)) {
              this.taskInfo.dbMap.push({
                sourceDb: current.title,
                sinkDb: current.title,
                targetSchema: 'dbo',
                sourceSchema: 'dbo',
                needAutoCreated: !hasSame
              });
            } else {
              this.taskInfo.dbMap.push({
                sourceDb: current.title,
                sinkDb: current.title,
                targetSchema: 'public',
                sourceSchema: 'public',
                needAutoCreated: !hasSame
              });
            }
          } else {
            db.sinkDb = '';

            this.taskInfo.dbMap.forEach((item, i) => {
              if (item.sourceDb === current.title) {
                this.taskInfo.dbMap.splice(i, 1);
              }
            });
          }
        }
      });
    },
    getTreeData(data) {
      this.filteredData = [];
      data.forEach((db) => {
        this.filteredData.push(db);
      });
    },
    handleChangeMode(data) {
      if (data === 'easy') {
        this.taskInfo.treeData.forEach((treeData) => {
          treeData.checked = false;
        });

        this.taskInfo.dbMap.forEach((item, i) => {
          if (!item.sourceDb) {
            this.taskInfo.dbMap.splice(i, 1);
          } else {
            this.taskInfo.treeData.forEach((treeData) => {
              if (treeData.title === item.sourceDb) {
                treeData.checked = true;
                if (item.sourceDb !== item.sinkDb) {
                  treeData.sinkDb = item.sinkDb;
                }
              }
            });
            this.filteredData = _.cloneDeep(this.taskInfo.treeData);
            this.filterkey = '';
          }
        });
      } else {
        if (this.taskInfo.dbMap.length < 1) {
          if (DataSourceGroup.sqlServer.includes(this.taskInfo.sinkType)) {
            this.taskInfo.dbMap.push({
              sourceDb: '',
              sinkDb: '',
              targetSchema: 'dbo',
              sourceSchema: 'dbo'
            });
          } else {
            this.taskInfo.dbMap.push({
              sourceDb: '',
              sinkDb: '',
              targetSchema: 'public',
              sourceSchema: 'public'
            });
          }
        }
      }
    },
    handleChangeMapping() {
      this.updateShowEditMapping(false);

      if (this.batchlyEdit) {
        this.taskInfo.treeData.forEach((db) => {
          if (db.checked) {
            db.sinkDb = this.currentSinkDb;
          }
        });

        this.filteredData.forEach((db) => {
          if (db.checked) {
            db.sinkDb = this.currentSinkDb;
          }
        });
        this.taskInfo.dbMap.forEach((db) => {
          db.needAutoCreated = false;
          db.sinkDb = this.currentSinkDb;
        });
      } else {
        this.taskInfo.treeData.forEach((db) => {
          if (db.title === this.currentSourceDb) {
            db.sinkDb = this.currentSinkDb;
          }
        });

        this.filteredData.forEach((db) => {
          console.log('db', db, this.currentSourceDb, this.currentSourceDb);
          if (db.title === this.currentSourceDb) {
            db.sinkDb = this.currentSinkDb;
          }
        });
        this.taskInfo.dbMap.forEach((db) => {
          if (db.sourceDb === this.currentSourceDb) {
            db.needAutoCreated = false;
            db.sinkDb = this.currentSinkDb;
          }
        });
      }
      this.filteredData.push('');
      this.filteredData.pop();
    },
    handleCancel() {
      this.updateShowEditMapping(false);
    },
    handleEditMapping() {
      this.updateShowEditMapping(true);
      this.batchlyEdit = true;
    }
  },
  watch: {
    'taskInfo.treeData': {
      handler() {
        this.handleFilterDb();
      },
      deep: true
    }
  }
};
</script>
<style lang="less">
.db-tree-container {
  width: 100%;
  height: 300px;
  border-left: 1px solid #DADADA;
  border-right: 1px solid #DADADA;
  border-bottom: 1px solid #DADADA;

  .select-all {
    background: #F4F4F4;
    padding: 6px 20px;
  }

  .db-tree-body {
    height: 270px;
    overflow: auto;

    .ivu-tree ul {
      font-size: 12px;
    }
  }

  .edit-mapping-icon {
    //display: none;
  }

  .db-mapping-item:hover {
    .edit-mapping-icon {
      display: inline-block;
    }
  }
}
</style>
