<template>
  <div class="app-wrapper">
    <router-view/>
    <Modal v-model="showAgreement" footer-hide title="用户协议" :closable="false">
      123123
      <div class="footer">
        <Checkbox v-model="agree">同意</Checkbox>
        <pd-button :disabled="!agree" type="primary" @click="handleAgree">关闭</pd-button>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      showAgreement: false,
      agree: false
    };
  },
  created() {
    const hideAgreement = localStorage.getItem('hideAgreement');
    if (hideAgreement) {
      this.showAgreement = !hideAgreement;
    }
  },
  methods: {
    handleAgree() {
      this.showAgreement = false;
      localStorage.setItem('hideAgreement', 'true');
    }
  }
};
</script>
<style lang="less">
.content-wrapper {
  padding: 64px 16px 0 16px;
}
</style>
