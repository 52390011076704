<template>
  <div>
    <Steps :current="currentStep" v-if="showStep===1" :status="currentStatus">
      <Step title="任务初始化"
            :icon="currentStep===0&&jobData.currentStatus!=='STOP'&&jobData.currentStatus!=='ABNORMAL'?'':''"></Step>
      <Step title="结构迁移"
            :icon="currentStep===1&&jobData.currentStatus!=='STOP'&&jsobData.currentStatus!=='ABNORMAL'?'':''"></Step>
      <Step :title="jobData.dataJobType==='MIGRATION'?'全量迁移':'数据初始化'"
            :icon="currentStep===2&&jobData.currentStatus!=='STOP'&&jobData.currentStatus!=='ABNORMAL'?'':''"></Step>
      <Step
        :title="jobData.dataJobType==='MIGRATION'?'增量同步':jobData.dataJobType==='SYNC'?'数据同步':'数据订阅'"
        :icon="currentStep===3&&jobData.currentStatus!=='STOP'&&jobData.currentStatus!=='ABNORMAL'?'':''"></Step>
      <Step title="完成"></Step>
    </Steps>
    <Steps :current="currentStep" v-if="showStep===2" :status="currentStatus">
      <Step title="任务初始化"
            :icon="currentStep===0&&jobData.currentStatus!=='STOP'&&jobData.currentStatus!=='ABNORMAL'?'':''"></Step>
      <Step title="结构迁移"
            :icon="currentStep===1&&jobData.currentStatus!=='STOP'&&jobData.currentStatus!=='ABNORMAL'?'':''"></Step>
      <Step :title="jobData.dataJobType==='MIGRATION'?'全量迁移':'数据初始化'"
            :icon="currentStep===2?'':''"></Step>
      <Step title="完成"></Step>
    </Steps>
    <Steps :current="currentStep" v-if="showStep===3" :status="currentStatus">
      <Step title="任务初始化"
            :icon="currentStep===0&&jobData.currentStatus!=='STOP'&&jobData.currentStatus!=='ABNORMAL'?'':''"></Step>
      <Step :title="jobData.dataJobType==='MIGRATION'?'全量迁移':'数据初始化'"
            :icon="currentStep===1&&jobData.currentStatus!=='STOP'&&jobData.currentStatus!=='ABNORMAL'?'':''"></Step>
      <Step title="完成"></Step>
    </Steps>
    <Steps :current="currentStep" v-if="showStep===4" :status="currentStatus">
      <Step title="任务初始化"
            :icon="currentStep===0&&jobData.currentStatus!=='STOP'&&jobData.currentStatus!=='ABNORMAL'?'':''"></Step>
      <Step :title="jobData.dataJobType==='MIGRATION'?'全量迁移':'数据初始化'"
            :icon="currentStep===1&&jobData.currentStatus!=='STOP'&&jobData.currentStatus!=='ABNORMAL'?'':''"></Step>
      <Step
        :title="jobData.dataJobType==='MIGRATION'?'增量同步':jobData.dataJobType==='SYNC'?'数据同步':'数据订阅'"
        :icon="currentStep===2&&jobData.currentStatus!=='STOP'&&jobData.currentStatus!=='ABNORMAL'?'':''"></Step>
      <Step title="完成"></Step>
    </Steps>
    <Steps :current="currentStep" v-if="showStep===5" :status="currentStatus">
      <Step title="任务初始化"
            :icon="currentStep===0&&jobData.currentStatus!=='STOP'&&jobData.currentStatus!=='ABNORMAL'?'':''"></Step>
      <Step
        :title="jobData.dataJobType==='MIGRATION'?'增量同步':jobData.dataJobType==='SYNC'?'数据同步':'数据订阅'"
        :icon="currentStep===1&&jobData.currentStatus!=='STOP'&&jobData.currentStatus!=='ABNORMAL'?'':''"></Step>
      <Step title="完成"></Step>
    </Steps>
    <Steps :current="currentStep" v-if="showStep===6" :status="currentStatus">
      <Step title="任务初始化"
            :icon="currentStep===0&&jobData.currentStatus!=='STOP'&&jobData.currentStatus!=='ABNORMAL'?'':''"></Step>
      <Step title="结构迁移"
            :icon="currentStep===1&&jobData.currentStatus!=='STOP'&&jobData.currentStatus!=='ABNORMAL'?'':''"></Step>
      <Step
        :title="jobData.dataJobType==='MIGRATION'?'增量同步':jobData.dataJobType==='SYNC'?'数据同步':'数据订阅'"
        :icon="currentStep===2&&jobData.currentStatus!=='STOP'&&jobData.currentStatus!=='ABNORMAL'?'':''"></Step>
      <Step title="完成"></Step>
    </Steps>
    <Steps :current="currentStep" v-if="showStep===7" :status="currentStatus">
      <Step title="任务初始化"
            :icon="currentStep===0&&jobData.currentStatus!=='STOP'&&jobData.currentStatus!=='ABNORMAL'?'':''"></Step>
      <Step title="数据校验"
            :icon="currentStep===1&&jobData.currentStatus!=='STOP'&&jobData.currentStatus!=='ABNORMAL'?'':''"></Step>
      <Step title="完成"></Step>
    </Steps>
    <Steps :current="currentStep" v-if="showStep===8" :status="currentStatus">
      <Step title="任务初始化"
            :icon="currentStep===0&&jobData.currentStatus!=='STOP'&&jobData.currentStatus!=='ABNORMAL'?'':''"></Step>
      <Step title="结构迁移"
            :icon="currentStep===1&&jobData.currentStatus!=='STOP'&&jobData.currentStatus!=='ABNORMAL'?'':''"></Step>
      <Step title="完成"></Step>
    </Steps>
  <Steps :current="currentStep" v-if="showStep===9" :status="currentStatus">
        <Step title="任务初始化"
              :icon="currentStep===0&&jobData.currentStatus!=='STOP'&&jobData.currentStatus!=='ABNORMAL'?'':''"></Step>
        <Step title="数据订正"
              :icon="currentStep===1&&jobData.currentStatus!=='STOP'&&jobData.currentStatus!=='ABNORMAL'?'':''"></Step>
        <Step title="完成"></Step>
      </Steps>
    </div>
</template>
<script>
export default {
  props: {
    currentStep: Number,
    showStep: Number,
    jobData: Object,
    currentStatus: String
  }
};
</script>
<style lang="less">
.job-detail-container .job-header .task-detail-step .ivu-steps .ivu-steps-title {
  margin-top: 4px;
}
</style>
