<template>
  <div class="app-wrapper">
    <div>
      <Header>

      </Header>
      <div class="content-wrapper">
        <Content>
          <router-view :key="new Date().getTime()"/>
        </Content>
      </div>

    </div>
  </div>
</template>

<script>
import { queryLoginUser } from '@services/api/user';
import { listPureUrl } from '@services/api/authority';
import Header from '@components/function/Header';
import { getGlobalSystemConfig, listBlackUri } from '../services/api/constant';

export default {
  name: 'App',
  components: {
    Header
  },
  data() {
    return {
      minHeight: 0,
      routeName: '',
      role: ''
    };
  },
  created() {
    window.onunload = () => {
      sessionStorage.clear();
    };
    listBlackUri()
      .then((res) => {
        if (res.data.code === '1') {
          this.$store.commit('getBlackUri', res.data.data);
        }
      });
    getGlobalSystemConfig()
      .then((res2) => {
        if (res2.data.code === '1') {
          this.globalSystemConfig = res2.data.data;
          this.$store.commit('getGlobalConfig', res2.data.data);
        }
      });
    queryLoginUser()
      .then((res) => {
        if (res.data.code === '1') {
          this.role = res.data.data.role;
          this.$store.commit('getUserRole', this.role);
          localStorage.setItem('userInfo', JSON.stringify(res.data.data));
          listPureUrl()
            .then((response) => {
              if (response.data.code === '1') {
                this.$store.commit('getUrlLabels', response.data.data);
              }
            });
        }
      });
    console.log(' ______  __        ______    __    __    _______  _______ .__   __.   ______  _______\n'
      + ' /      ||  |      /  __  \\  |  |  |  |  /  _____||   ____||  \\ |  |  /      ||   ____|\n'
      + '|  ,----\'|  |     |  |  |  | |  |  |  | |  |  __  |  |__   |   \\|  | |  ,----\'|  |__\n'
      + '|  |     |  |     |  |  |  | |  |  |  | |  | |_ | |   __|  |  . `  | |  |     |   __|\n'
      + '|  `----.|  `----.|  `--\'  | |  `--\'  | |  |__| | |  |____ |  |\\   | |  `----.|  |____\n'
      + '\\______||_______| \\______/   \\______/   \\______| |_______||__| \\__|  \\______||_______|');
    // eslint-disable-next-line no-template-curly-in-string
    console.log(`查看版本信息，请打开${window.location.origin}/web-version.json`);
  },
  mounted() {
    this.minHeight = window.innerHeight;
    this.routeName = this.$route.name;
  },
  watch: {
    $route(val) {
      if (val.name !== 'taskCreate/process') {
        this.$store.commit('getJobDataForSimilarJob', null);
      }
    }
  }
};
</script>
<style lang="less">
.content-wrapper {
  padding: 64px 16px 0 16px;
}
</style>
