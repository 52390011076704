<template>
  <div>
    <Modal
      v-model="showFilter"
      footer-hide
      :closable="false">
      <div slot="header">
        数据过滤条件
        <Tooltip placement="right">
          <Icon type="ios-help-circle" />
          <div slot="content">
            {{sourceWhereTip}}
          </div>
        </Tooltip>
      </div>
      <div style="word-break: break-all">
        select {{ getColumns(selectedTable.sourceDb, selectedTable.sourceTable) }} from
        {{ selectedTable.sourceTable }}
        where
        <div style="margin-top: 10px">
          <Input v-model="currentData" placeholder="条件中字段请严格按名称匹配（如大小写）" type="textarea" :rows="4" @on-change="handleUpdateWhereCondition"/>
        </div>
        <a style="margin-top: 10px;display: block;width: 100px"
           @click="clearWhereCondition(selectedTable)">清除数据过滤条件</a>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleAddWhereCondition(selectedTable)">确定</pd-button>
          <pd-button @click="handleCancel">取消</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showTargetFilter"
      footer-hide
      :closable="false"
    >
      <div slot="header">

        对端更新条件
        <Tooltip placement="right">
          <Icon type="ios-help-circle" />
          <div slot="content">c
            {{targetWhereTip}}
          </div>
        </Tooltip>
      </div>
      <div>
        <div style="word-break: break-all">
          update {{ selectedTable.sourceTable }}
          set ...
          where
          pk = xxx and
          <div style="margin-top: 10px">
            <Input v-model="currentTargetData" placeholder="条件中字段请严格按名称匹配（如大小写）" type="textarea" :rows="4" @on-change="handleUpdateTargetWhereCondition"/>
          </div>
          <a style="margin-top: 10px;display: block;width: 100px"
             @click="clearTargetWhereCondition(selectedTable)">清除对端更新条件</a>
          <div slot="footer" class="modal-footer" style="margin-top: 20px">
            <pd-button type="primary" @click="handleAddTargetWhereCondition(selectedTable)">确定</pd-button>
            <pd-button @click="handleCancel">取消</pd-button>
          </div>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showVirtualColumn"
      footer-hide
      width="1120px"
      :closable="false"
      title="添加虚拟列">
      <div>
        <Table size="small" border :columns="virtualColumns" :data="virtualData" max-height="400"
               :key="selectedTable.sinkTable">
          <template slot="targetVirtualName" slot-scope="{row,index}">
            <Input placeholder="请输入列名，已存在列自动匹配" v-model="row.customName"
                   @on-change="handleChangeColumnRow(row,index,'customName',$event)"/>
          </template>
          <template slot="targetVirtualType" slot-scope="{row,index}">
            <span v-if="!row.isCustom">{{ row.targetVirtualType }}</span>
            <Select v-if="row.isCustom" @on-change="handleChangeTargetType(row,index,$event)" v-model="row.typeData"
                    transfer filterable>
              <Option v-for="type in typeMeta" :key="type.typeName" :value="JSON.stringify(type)">
                {{ type.typeName }}
              </Option>
            </Select>
          </template>
          <template slot="targetVirtualLength" slot-scope="{row,index}">
            <span v-if="!row.isCustom">{{
                row.targetVirtualLength && row.targetVirtualLength.match(/\((.+)\)/g) &&
                row.targetVirtualLength.match(/\((.+)\)/g)[0] &&
                row.targetVirtualLength.match(/\((.+)\)/g)[0].substr(1, row.targetVirtualLength.match(/\((.+)\)/g)[0].length - 2)
              }}</span>
            <Input v-if="row.isCustom" v-model="row.columnLength"
                   @on-change="handleChangeInputRow(row,index,'columnLength',$event)"/>
          </template>
          <template slot="columnValue" slot-scope="{row,index}">
            <Input v-model="row.columnValue"
                   @on-change="handleChangeInputRow(row,index,'columnValue',$event)"/>
          </template>
          <template slot="action" slot-scope="{row,index}">
            <i style="color: #51D300;cursor: pointer" @click="handleAddVirtualColumn"
               v-if="index===0"
               class="iconfont iconadd-g"></i>
            <i style="color: #FF1815;cursor: pointer" v-if="index!==0" class="iconfont iconDEL-R"
               @click="handleDeleteVirtual(index)"></i>
          </template>
        </Table>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleVirtualColumn(selectedTable,virtualData)">确定
          </pd-button>
          <pd-button @click="handleCancel">取消</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showPartitionAll"
      footer-hide
      width="600"
      :closable="false"
      title="配置分区键">
      <div style="word-break: break-all">
        <Form label-position="left" :label-width="80">
          <FormItem label="分区键">
            <Input style="width: 300px" v-model="partitionData.partition[0].key"/>
          </FormItem>
          <FormItem label="分区类型">
            <RadioGroup v-model="partitionData.partition[0].func" type="button" transfer @on-change="handleChangePartitionFunc">
              <Tooltip v-for="(format) of HivePartitionFuncs" :content="format.funcDesc" :key="format.function"
                       placement="bottom">
                <Radio :label="format.function">{{ format.i18nName }}</Radio>
              </Tooltip>
            </RadioGroup>
          </FormItem>
          <FormItem label="文件格式">
            <Select style="width:300px" v-model="partitionData.fileFormat">
              <Option v-for="(format,key) of HdfsFileFormats" :value="key" :key="key">{{
                  key
                }}
              </Option>
            </Select>
          </FormItem>
          <FormItem label="压缩方式">
            <Select style="width:300px" v-model="partitionData.compressType">
              <Option v-for="(compress) of HdfsFileFormats[partitionData.fileFormat]"
                      :value="compress" :key="compress">
                {{ compress }}
              </Option>
            </Select>
          </FormItem>
        </Form>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleAddPartitionAll">确定</pd-button>
          <pd-button type="default" @click="handleCancel">取消</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showMqPartition"
      width="740"
      footer-hide
      :closable="false"
      title="设置分区数">
      <div style="word-break: break-all">
        <Form label-position="left" :label-width="80">
          <!--          <FormItem label="分区键">-->
          <!--            <Select v-model="mqPartition.key" style="width: 600px" filterable multiple>-->
          <!--              <Option v-for="(column,i) in getColumnList(selectedTable.sourceDb,selectedTable.sourceTable)"-->
          <!--                      :value="column" :key="i">{{ column }}-->
          <!--              </Option>-->
          <!--            </Select>-->
          <!--          </FormItem>-->
          <FormItem label="分区数">
            <Input style="width: 132px" type="number"
                   v-model="mqPartition.count"></Input>
          </FormItem>
        </Form>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleAddMqPartition(selectedTable)">确定</pd-button>
          <pd-button @click="handleCancel">取消</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showRedisKey"
      width="740"
      footer-hide
      :closable="false"
      title="设置 Redis KEY">
      <div style="word-break: break-all">
        <Form label-position="left" :label-width="80">
          <FormItem label="Redis KEY">
            <Select v-model="suffixFields" style="width: 600px" filterable multiple>
              <Option
                v-for="(column,i) in getColumnList(selectedTable.sourceDb,selectedTable.sourceTable)"
                :value="column" :key="i">{{ column }}
              </Option>
            </Select>
          </FormItem>
        </Form>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmSetRedisKey(selectedTable, suffixFields)">
            确定
          </pd-button>
          <pd-button @click="handleCancel">取消</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showBatchSetRedisKey"
      width="740px"
      footer-hide
      :closable="false"
      title="批量配置 Redis KEY">
      <div style="word-break: break-all">
        <div style="margin-top: 10px">
          <Input placeholder="字段名称" @on-change="handleColumnChange" v-model="searchColumnKey"
                 style="width: 180px"/>
          <pd-button style="margin-left: 6px" type="primary"
                     @click="handleFindTable(searchColumnKey)">查找有该列的表
          </pd-button>
        </div>
        <p style="margin-top: 20px;margin-bottom: 10px;font-size: 14px;">应用到以下表</p>
        <div style="background-color: #FAFAFA;border: 1px solid #EDEDED;padding: 22px 12px">
          <Checkbox style="margin-bottom: 10px"
                    :value="checkAll"
                    @click.prevent.native="handleCheckAll">全选
          </Checkbox>
          <CheckboxGroup style="max-height: 200px;overflow: auto;" v-model="checkAllGroup"
                         @on-change="checkAllGroupChange">
            <Checkbox style="margin-bottom: 10px" v-for="(table,i) of showHasColumnTableList"
                      :label="`${table.db}.${table.sourceTable}`" :key="i"></Checkbox>
          </CheckboxGroup>
          <Page size="small" style="margin-top: 20px" :total="hasColumnTableList.length" show-total
                :page-size="showHasColumnTableListSize" @on-change="handleShowHasColumnPageChange"/>
        </div>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmBatchSetRedisKey(searchColumnKey)">确定
          </pd-button>
          <pd-button @click="handleCancel">取消</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showDrdsPartition"
      width="500px"
      footer-hide
      :closable="false"
      title="设置分区键">
      <div style="word-break: break-all">
        <Form label-position="right" :label-width="70" v-if="drdsPartitionSetting.dbPartition">
          <FormItem label="拆分方式">
            <Select v-model="partitionType" style="width: 280px">
              <Option v-for="type in drdsPartitionSetting.types" :value="type" :key="type">{{
                  type
                }}
              </Option>
            </Select>
          </FormItem>
          <FormItem label="分库算法" v-if="partitionType!=='NONE'">
            {{ drdsPartitionSetting.dbPartition.function }}
          </FormItem>
          <FormItem label="分库键"
                    v-if="partitionType!=='NONE'&&drdsPartitionSetting.dbPartition.needCols">
            <Select v-model="drdsPartition.dbpartition" filterable style="width: 280px"
                    :multiple="drdsPartitionSetting.dbPartition.multiCols">
              <Option
                v-for="(column,i) in getColumnList(selectedTable.sourceDb,selectedTable.sourceTable)"
                :value="column" :key="i">{{ column }}
              </Option>
            </Select>
          </FormItem>
          <FormItem label="分表算法" v-if="partitionType==='DB_TAB'" @on-change="handleChangeTbFuncs">
            <Select v-model="drdsPartition.tbFuncs" style="width: 280px">
              <Option v-for="func in drdsPartitionSetting.tbPartition" :value="func.function"
                      :key="func.function">
                {{ func.function }}
              </Option>
            </Select>
          </FormItem>
          <FormItem label="分表键" v-if="partitionType==='DB_TAB'&&getTbPartitionData().needCols">
            <Select v-model="drdsPartition.tbpartition" filterable style="width: 280px"
                    :multiple="getTbPartitionData().multiCols">
              <Option
                v-for="(column,i) in getColumnList(selectedTable.sourceDb,selectedTable.sourceTable)"
                :value="column" :key="i">{{ column }}
              </Option>
            </Select>
          </FormItem>
          <FormItem label="拆分数" v-if="partitionType==='DB_TAB'" prop="tbpartitions">
            <Input style="width: 132px" v-model="drdsPartition.tbpartitions"
                   @on-change="formatPartionSize(getTbPartitionData())"
                   :placeholder="partitionRange"></Input>
          </FormItem>
        </Form>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleAddDrdsPartitions(selectedTable)">确定</pd-button>
          <pd-button @click="handleCancel">取消</pd-button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import store from '../../../../../store/index';

export default {
  props: {
    showTargetFilter: Boolean,
    showFilter: Boolean,
    whereConditionFilter: Object,
    selectedTable: Object,
    clearWhereCondition: Function,
    clearTargetWhereCondition: Function,
    handleAddWhereCondition: Function,
    handleAddTargetWhereCondition: Function,
    handleCancel: Function,
    showFilterAll: Boolean,
    showVirtualColumn: Boolean,
    handleColumnChange: Function,
    handleFindTable: Function,
    checkAll: Boolean,
    handleCheckAll: Function,
    checkAllGroup: Array,
    checkAllGroupChange: Function,
    showHasColumnTableList: Array,
    hasColumnTableList: Array,
    showHasColumnTableListSize: Number,
    handleShowHasColumnPageChange: Function,
    handleAddAllWhereCondition: Function,
    showPartition: Boolean,
    taskInfo: Object,
    HivePartitionFuncs: Array,
    HdfsFileFormats: Object,
    showPartitionAll: Boolean,
    partitionCheckAll: Boolean,
    partitionCheckAllGroup: Array,
    partitionCheckAllGroupChange: Function,
    handleAddPartitionAll: Function,
    showMqPartition: Boolean,
    mqPartition: Object,
    handleAddMqPartition: Function,
    selectedColumns: Object,
    tableList: Array,
    showDrdsPartition: Boolean,
    drdsPartition: Object,
    handleAddDrdsPartitions: Function,
    drdsPartitionSetting: Object,
    handleVirtualColumn: Function,
    hasTheSameColumn: Function,
    virtualColumnData: Array,
    typeMeta: Array,
    showOperateColumns: Boolean,
    handleOperateColumns: Function,
    showRedisKey: Boolean,
    handleConfirmSetRedisKey: Function,
    showBatchSetRedisKey: Boolean,
    handleConfirmBatchSetRedisKey: Function,
    whereCondition: String,
    targetWhereCondition: String,
    updateWhereCondition: Function,
    updateTargetWhereCondition: Function,
    partitionData: Object
  },
  data() {
    return {
      sourceWhereTip: '当前支持 SQL 92 数据删选语句(无子查询，无join,支持 and、or、=、>、<、>=、<=、in 等逻辑和算术表达式)',
      targetWhereTip: ' 当前支持 xxx > ? 类似 SQL 92 数据删选语句（and、or、=、>、<、>=、<=）',
      store,
      partitionType: 'NONE',
      partitionRange: '1',
      searchColumnKey: '',
      operateType: 'cancel',
      targetFilterColumns: [
        {
          title: '列名',
          slot: 'columnName'
        },
        {
          title: '表达式',
          slot: 'expression'
        },
        {
          title: '值',
          slot: 'value'
        }
      ],
      targetFilterList: [{
        columnName: '',
        expression: '',
        value: ''
      }],
      virtualColumns: [
        {
          title: '*虚拟列名称',
          slot: 'targetVirtualName',
          width: 300
        },
        {
          title: '*虚拟列类型',
          slot: 'targetVirtualType'
        },
        {
          title: '虚拟列长度',
          slot: 'targetVirtualLength'
        },
        {
          title: '*列值',
          slot: 'columnValue'
        },
        {
          title: '操作',
          slot: 'action'
        }
      ],
      virtualData: [{
        targetVirtualName: '',
        targetVirtualType: '',
        targetVirtualLength: 0,
        columnValue: '',
        sqlType: 0,
        columnLength: ''
      }],
      suffixFields: [],
      currentData: '',
      currentTargetData: '',
      currentColumns: ''
    };
  },
  computed: {
    getColumns() {
      return (db, table) => {
        const columns = [];

        if (this.selectedColumns[db] && this.selectedColumns[db][table]) {
          this.selectedColumns[db][table].forEach((item) => {
            if (item.selected) {
              columns.push(item.sourceColumn);
            }
          });
        }
        return columns.join(',');
      };
    },
    getColumnList() {
      return (db, table) => {
        const columns = [];

        if (this.selectedColumns[db] && this.selectedColumns[db][table]) {
          this.selectedColumns[db][table].forEach((item) => {
            columns.push(item.sourceColumn);
          });
        }
        return columns;
      };
    },
    getWhereFilterFormat() {
      return (value, type) => {
        if (type !== 'in') {
          if (Number.isNaN(value)) {
            return value.substr(0, 1) !== '\'' || value.substr(value.length - 1, 1) !== '\'';
          }
          return false;
        }
        let isIllegal = false;

        value.split(',')
          .forEach((item) => {
            if (Number.isNaN(item)) {
              if (item.substr(0, 1) !== '\'' || item.substr(item.length - 1, 1) !== '\'') {
                isIllegal = true;
              }
            }
          });
        return isIllegal;
      };
    }
  },
  mounted() {
    this.currentData = this.whereCondition;
    this.currentTargetData = this.targetWhereCondition;
  },
  methods: {
    handleAddTargetFilter() {
      this.targetFilterList.push({
        columnName: '',
        expression: '',
        value: ''
      });
    },
    formatPartionSize(data) {
      if (this.drdsPartition.tbpartitions > data.maxPartitionSize) {
        this.$set(this.drdsPartition, 'tbpartitions', data.maxPartitionSize);
      }
      if (this.drdsPartition.tbpartitions < data.minPartitionSize) {
        this.$set(this.drdsPartition, 'tbpartitions', data.minPartitionSize);
      }
    },
    getTbPartitionData() {
      let data = {};

      this.drdsPartitionSetting.tbPartition.forEach((item) => {
        if (this.drdsPartition.tbFuncs === item.function) {
          data = item;
        }
      });
      return data;
    },
    handleChangeTbFuncs() {
      this.drdsPartition.tbpartitions = this.getTbPartitionData().minPartitionSize;
      if (this.getTbPartitionData().maxPartitionSize > this.getTbPartitionData().minPartitionSize) {
        this.taskInfo.partitionRange = `${this.getTbPartitionData().minPartitionSize}-${this.getTbPartitionData().maxPartitionSize}`;
      } else {
        this.taskInfo.partitionRange = this.getTbPartitionData()
          .minPartitionSize
          .toString();
      }
    },
    handleAddVirtualColumn() {
      this.virtualData.push({
        targetVirtualName: '',
        targetVirtualType: '',
        targetVirtualLength: 0,
        columnValue: '',
        sqlType: 0,
        columnLength: ''
      });
    },
    handleDeleteVirtual(index) {
      this.virtualData.splice(index, 1);
    },
    handleChangeRow(row, index, key, data) {
      if (data === 'cc-custom') {
        row.isCustom = true;
        this.virtualData[index].isCustom = true;
        this.virtualData[index].targetVirtualName = data;
      } else {
        row.isCustom = false;
        this.virtualData[index].isCustom = false;
        const columnList = this.taskInfo.sinkColumns[this.selectedTable.sinkDb ? this.selectedTable.sinkDb : this.selectedTable.targetDb]
          ? this.taskInfo.sinkColumns[this.selectedTable.sinkDb ? this.selectedTable.sinkDb : this.selectedTable.targetDb][this.selectedTable.sinkTable
            ? this.selectedTable.sinkTable : this.selectedTable.targetTable] : [];

        columnList.forEach((item) => {
          if (item.columnName === data) {
            this.virtualData[index].targetVirtualName = item.columnName;
            this.virtualData[index].targetVirtualType = item.dataType;
            this.virtualData[index].targetVirtualLength = item.dataTypeWithLength;
            this.virtualData[index].sqlType = item.sqlTypeIntValue;
          }
        });
      }
    },
    handleChangeColumnRow(row, index, key) {
      // 判断是是否已存在
      const sinkColumns = this.taskInfo.sinkColumns[this.selectedTable.sinkDb ? this.selectedTable.sinkDb : this.selectedTable.targetDb];

      let hasSame = false;

      if (sinkColumns && sinkColumns[this.selectedTable.sinkTable]) {
        sinkColumns[this.selectedTable.sinkTable].forEach((item) => {
          if (item.columnName === row.customName) {
            hasSame = true;
            row.isCustom = false;
            this.virtualData[index].isCustom = false;
            this.virtualData[index].customName = item.columnName;
            this.virtualData[index].targetVirtualName = item.columnName;
            this.virtualData[index].targetVirtualType = item.dataType;
            this.virtualData[index].targetVirtualLength = item.dataTypeWithLength;
            this.virtualData[index].sqlType = item.sqlTypeIntValue;
          }
        });
      }
      if (!hasSame) {
        row.isCustom = true;
        this.virtualData[index].isCustom = true;
        this.virtualData[index][key] = row[key];
      }
    },
    handleChangeInputRow(row, index, key) {
      this.virtualData[index][key] = row[key];
    },
    handleChangeTargetType(row, index, data) {
      const theData = JSON.parse(data);

      this.virtualData[index].targetVirtualType = theData.typeName;
      this.virtualData[index].sqlType = theData.sqlTypeIntVal;
      this.virtualData[index].typeData = data;
    },
    handleUpdateWhereCondition() {
      this.updateWhereCondition(this.currentData);
    },
    handleUpdateTargetWhereCondition() {
      this.updateTargetWhereCondition(this.currentTargetData);
    },
    handleChangePartitionFunc(data) {
      this.HivePartitionFuncs.forEach((item) => {
        if (item.function === data) {
          this.partitionData.partition[0].key = item.partitionKey;
        }
      });
    }
  },
  watch: {
    virtualColumnData(val) {
      if (val.length > 0) {
        this.virtualData = val;
      } else {
        this.virtualData = [];
        this.virtualData.push({
          targetVirtualName: '',
          targetVirtualType: '',
          targetVirtualLength: 0,
          columnValue: '',
          sqlType: 0,
          typeData: '',
          columnLength: ''
        });
      }
    },
    showOperateColumns(val) {
      if (val) {
        this.searchColumnKey = '';
      }
    },
    showRedisKey(val) {
      if (val) {
        this.suffixFields = this.selectedTable.suffixFields || [];
      }
    },
    whereCondition(val) {
      this.currentData = val;
    },
    targetWhereCondition(val) {
      this.currentTargetData = val;
    }
  }
};
</script>
