import { instance } from '../instance';

export const getUserList = (param) => instance.post('user/list', param);

export const queryLoginUser = (param) => instance.post('user/queryloginuser', param);

export const deleteUser = (param) => instance.post('user/deleteuser', param);

export const updatePassword = (param) => instance.post('user/updatepassword', param);

export const updateAliyunAkSk = (param) => instance.post('user/updatealiyunaksk', param);

export const cleanAliyunAkSk = (param) => instance.post('user/cleanaliyunaksk', param);

export const takeoverUserRes = (param) => instance.post('user/takeoveruserres', param);

export const updateUserInfo = (param) => instance.post('user/updateuserinfo', param);

export const updateUserPhone = (param) => instance.post('user/updateuserphone', param);

export const updateUserEmail = (param) => instance.post('user/updateuseremail', param);

export const queryUserAkSk = (param) => instance.post('user/queryuseraksk', param);

export const getCurrUserConfigs = (param) => instance.post('user/config/getcurruserconfigs', param);

export const updateUserConfigs = (param) => instance.post('user/config/upsertuserconfigs', param);

export const addUserForPremise = (param) => instance.post('user//adduserforpremise', param);
