<template>
    <div class="user-center">
        <p class="user-center-title">告警配置</p>
        <div>
            <div v-if="ifEdit">
                <div class="container-wrapper system-setting">
                    <Form :model="systemForm" label-position="right" :label-width="130">
                        <FormItem label="告警接收邮箱">
                            <Input v-model="alarmSetting.emailAddress" style="width: 280px" disabled></Input>
                        </FormItem>
                        <FormItem label="告警接收手机">
                            <Input v-model="alarmSetting.phoneNumber" style="width: 280px" disabled></Input>
                        </FormItem>
                        <p class="system-setting-title">告警邮箱服务器 <span style="color: #808CA7;margin-left: 8px;font-weight: 400;font-family: PingFangSC-Regular,serif">CloudCanal将会使用该邮箱来发送告警信息</span></p>
                        <div v-if="!editEmail">
                            <FormItem label="发件人邮箱地址">
                                <AutoComplete
                                        v-model="systemForm.EMAIL_USERNAME_KEY"
                                        :data="emailList"
                                        @on-search="handleFillEmail"
                                        @on-blur="handleShowStmp"
                                        disabled
                                        style="width:280px"></AutoComplete>
                                <pd-button style="margin-left: 6px" type="primary" ghost @click="handleEditEmail">设置</pd-button>
                            </FormItem>
                            <FormItem label="SMTP服务器地址" v-if="showSmtp">
                                <Input disabled v-model="systemForm.EMAIL_HOST_KEY" style="width: 280px" placeholder="请填写IP或域名"></Input>
                                <span>:</span>
                                <Input disabled v-model="systemForm.EMAIL_PORT_KEY" style="width: 60px" placeholder="端口"></Input>
                            </FormItem>
                        </div>
                        <div v-if="editEmail">
                            <FormItem label="发件人邮箱地址">
                                <AutoComplete
                                        v-model="systemForm.EMAIL_USERNAME_KEY"
                                        :data="emailList"
                                        @on-search="handleFillEmail"
                                        @on-blur="handleShowStmp"
                                        style="width:280px"></AutoComplete>
                            </FormItem>
                            <FormItem label="客户端授权密码">
                                <Input v-model="systemForm.EMAIL_PASSWORD_KEY" style="width: 280px" password type="password"></Input>
                                <Tooltip>
                                    <Icon type="ios-help-circle-outline" size="16"/>
                                    <div slot="content">
                                        <p>阿里邮箱填写邮箱密码</p>
                                        <p>QQ,163等邮箱填写客户端许可证</p>
                                    </div>
                                </Tooltip>

                                <pd-button :loading="verifyEmail" style="margin-left: 6px" type="primary" ghost @click="handleVerifyEmail">验证邮箱有效</pd-button>
                                <span style="margin-left: 10px" v-if="showTest">
                    <Icon :type="connection?'ios-checkmark-circle':'ios-close-circle'"
                          :style="connection?'font-size: 14px;margin-right: 5px;color: #52C41A;':'font-size: 18px;margin-right: 5px;color:#FF1815'"/>验证{{connection?"成功":"失败"}}！
                </span>
                            </FormItem>
                            <FormItem label="SMTP服务器地址" v-if="showSmtp">
                                <Input v-model="systemForm.EMAIL_HOST_KEY" style="width: 280px" placeholder="请填写IP或域名"></Input>
                                <span>:</span>
                                <Input v-model="systemForm.EMAIL_PORT_KEY" style="width: 60px" placeholder="端口"></Input>
                                <Tooltip content="仅支持启用加密的SSL端口">
                                    <Icon type="ios-help-circle-outline" size="16"/>
                                </Tooltip>
                            </FormItem>
                        </div>
                        <p class="system-setting-title">钉钉告警<span style="color: #808CA7;margin-left: 8px;font-weight: 400;font-family: PingFangSC-Regular,serif">CloudCanal将会通过钉钉群机器人来发送告警信息</span></p>
                        <FormItem label="webhook">
                            <Input v-model="systemForm.DINGDING_URL_TOKEN_KEY" style="width: 280px"></Input>
                            <a style="margin-left: 6px;color: #535C70" href="https://doc.clougence.com/docs/en/create_dingding_group" target="_blank">怎么获取webhook?</a>
                        </FormItem>
                    </Form>
                </div>
                <div class="modal-footer" style="margin-top: 20px">
                    <pd-button type="primary" @click="updateDingDingConfigs">保存</pd-button>
                    <pd-button @click="handleCancelEdit">取消</pd-button>
                </div>
            </div>
            <div v-if="!ifEdit">
                <div class="container-wrapper system-setting">
                    <p class="system-setting-title">告警邮箱服务器</p>
                    <Form :model="systemForm" label-position="right" :label-width="150">
                        <FormItem label="发件人邮箱地址">
                            {{systemForm.EMAIL_USERNAME_KEY?systemForm.EMAIL_USERNAME_KEY:'暂无'}}
                        </FormItem>
                        <FormItem label="邮箱SMTP服务器地址">
                            {{systemForm.EMAIL_HOST_KEY?systemForm.EMAIL_HOST_KEY+':'+systemForm.EMAIL_PORT_KEY:'暂无'}}
                        </FormItem>
                        <p class="system-setting-title">钉钉告警</p>
                        <FormItem label="webhook">
                            {{systemForm.DINGDING_URL_TOKEN_KEY?systemForm.DINGDING_URL_TOKEN_KEY:'暂无'}}
                        </FormItem>
                    </Form>
                    <pd-button @click="handleShowEdit" style="margin-top: 20px" type="primary">修改</pd-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { listAllConfigs, updateDingDingConfigs, verifyMail } from '@services/api/systemConfig';

export default {
  components: {
  },
  data() {
    return {
      editEmail: false,
      verifyEmail: false,
      loading: false,
      applyCode: '',
      ifEdit: true,
      showTest: false,
      connection: false,
      showSmtp: false,
      systemForm: {
        EMAIL_HOST_KEY: '',
        EMAIL_PORT_KEY: '465',
        EMAIL_USERNAME_KEY: '',
        EMAIL_PASSWORD_KEY: '',
        DINGDING_URL_TOKEN_KEY: '',
        EMAIL_FROM_KEY: ''
      },
      alarmSetting: {

      },
      emailList: [],
      emailSuffix: ['qq.com', 'sina.com', '163.com', 'sohu.com', '126.com'],
      smtpList: {
        'qq.com': 'smtp.qq.com',
        'sina.com': 'smtp.sina.com.cn',
        '163.com': 'smtp.163.com',
        'sohu.com': 'smtp.sohu.com',
        '126.com': 'smtp.126.com'
      },
      smtpPort: {
        'qq.com': '465',
        'sina.com': '25',
        '163.com': '465',
        'sohu.com': '110',
        '126.com': '25'
      },
      configKeyMap: {
        EMAIL_HOST_KEY: 'spring.mail.host',
        EMAIL_PORT_KEY: 'spring.mail.port',
        EMAIL_USERNAME_KEY: 'spring.mail.username',
        EMAIL_PASSWORD_KEY: 'spring.mail.password',
        EMAIL_FROM_KEY: 'spring.mail.properties.from',
        DINGDING_URL_TOKEN_KEY: 'console.config.alert.dingtalk.alerturl'
      }
    };
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
  },
  mounted() {
    this.listAllConfigs();
  },
  computed: {
  },
  methods: {
    handleSearch() {
      this.listAllConfigs();
    },
    listAllConfigs() {
      this.loading = true;
      listAllConfigs().then((res) => {
        this.loading = false;
        if (res.data.code === '1') {
          this.alarmSetting.emailAddress = res.data.data.emailAddress;
          this.alarmSetting.phoneNumber = res.data.data.phoneNumber;
          res.data.data.systemConfigVOList.map((item) => {
            if (item.configName === 'spring.mail.host') {
              this.systemForm.EMAIL_HOST_KEY = item.configValue;
            }
            if (item.configName === 'spring.mail.port') {
              this.systemForm.EMAIL_PORT_KEY = item.configValue;
            }
            if (item.configName === 'spring.mail.username') {
              this.systemForm.EMAIL_USERNAME_KEY = item.configValue;
            }
            if (item.configName === 'spring.mail.password') {
              // this.systemForm.EMAIL_PASSWORD_KEY = item.configValue;
            }
            if (item.configName === 'console.config.alert.dingtalk.alerturl') {
              this.systemForm.DINGDING_URL_TOKEN_KEY = item.configValue;
            }
            if (item.configName === 'spring.mail.properties.from') {
              this.systemForm.EMAIL_FROM_KEY = item.configValue;
            }
            if (this.systemForm.EMAIL_FROM_KEY) {
              this.systemForm.EMAIL_USERNAME_KEY = this.systemForm.EMAIL_FROM_KEY;
            } else if (this.systemForm.EMAIL_USERNAME_KEY) {
              this.systemForm.EMAIL_FROM_KEY = this.systemForm.EMAIL_USERNAME_KEY;
            }
            return null;
          });
          this.handleShowStmp();
        }
      });
    },
    updateDingDingConfigs() {
      const list = [];

      for (const key in this.systemForm) {
        if (key !== 'EMAIL_PASSWORD_KEY') {
          list.push({
            configName: this.configKeyMap[key],
            configValue: this.systemForm[key]
          });
        } else if (this.systemForm[key]) {
          list.push({
            configName: this.configKeyMap[key],
            configValue: this.systemForm[key]
          });
        }
      }
      list.push({
        configName: this.configKeyMap.EMAIL_FROM_KEY,
        configValue: this.systemForm.EMAIL_USERNAME_KEY
      });
      updateDingDingConfigs(list).then((res) => {
        if (res.data.code === '1') {
          this.listAllConfigs();
          this.$Message.success('修改成功！');
          this.editEmail = false;
        }
        // this.ifEdit = false;
      });
    },
    handleFillEmail(value) {
      this.emailList = [];
      if (value.indexOf('@') < 0) {
        this.emailSuffix.map((item) => {
          this.emailList.push(`${value}@${item}`);
          return null;
        });
      }
      this.handleShowStmp();
    },
    handleShowStmp() {
      if (this.systemForm.EMAIL_USERNAME_KEY) {
        const list = this.systemForm.EMAIL_USERNAME_KEY.split('@');

        if (list.length > 1) {
          if (this.emailSuffix.indexOf(list[1]) < 0) {
            this.showSmtp = true;
          } else {
            this.showSmtp = false;
            this.systemForm.EMAIL_HOST_KEY = this.smtpList[list[1]];
            this.systemForm.EMAIL_PORT_KEY = this.smtpPort[list[1]];
          }
        } else {
          this.showSmtp = false;
        }
      } else {
        this.showSmtp = false;
      }
    },
    handleVerifyEmail() {
      this.verifyEmail = true;
      verifyMail({
        emailAddress: this.systemForm.EMAIL_USERNAME_KEY,
        password: this.systemForm.EMAIL_PASSWORD_KEY,
        smtpHost: this.systemForm.EMAIL_HOST_KEY,
        smtpPort: parseInt(this.systemForm.EMAIL_PORT_KEY, 10)
      }).then((res) => {
        this.verifyEmail = false;
        if (res.data.code === '1') {
          this.showTest = true;
          this.connection = true;
        } else if (res.data.code === '0014') {
          this.showTest = true;
          this.connection = false;
        }
      });
    },
    handleShowEdit() {
      this.ifEdit = true;
    },
    handleEditEmail() {
      this.editEmail = true;
    },
    handleCancelEdit() {
      this.editEmail = false;
    }
  }
};
</script>
<style lang="less">
    .user-center-title{
        font-size: 16px;
        line-height: 16px;
        padding-left: 8px;
        color: #333333;
        font-family: PingFangSC-Semibold,serif;
        font-weight: 500;
        border-left: 3px solid #535C70;
        margin-bottom: 6px;
    }
    .user-center-wrapper{
        margin-top: 16px;
        padding: 20px 40px;
        background-color: #ffffff;
        border: 1px solid #DADADA;
        &>p{
            line-height: 36px;
            font-size: 16px;
            padding: 12px 0;
            border-bottom: 1px solid #DADADA;
            .user-center-label{
                font-family: PingFangSC-Semibold,serif;
                font-weight: 500;
                display: inline-block;
                width: 104px;
            }
        }
        .ivu-table td, .ivu-table th{
            height: 40px;
        }
    }
    .user-center-wrapper-sp{
        background-color: #ECECEC;
        margin-bottom: 20px;
        .ivu-input{
            height: 40px;
            line-height: 40px;
        }
        .user-center-wrapper-sp-btn{
            background-color: #FFA30E;
            color: #ffffff;
            margin-top: 16px;
            background-image: none;
            border: none;
            width: 100%;
            height: 50px;
            line-height: 50px;
            font-size: 16px;
            font-family: PingFangSC-Semibold,serif;
            font-weight: 500;
            &:hover{
                background-color: #FFA30E!important;
                background-image:none;
            }
        }
    }
    /*.ivu-form-item:last-child{*/
    /*    margin-bottom: 0;*/
    /*}*/
    .system-setting-title{
        font-family: PingFangSC-Semibold,serif;
        font-weight: 500;
        margin-bottom: 20px;
    }
    .user-center{
        position: relative;
        .ivu-tabs-nav .ivu-tabs-tab-active{
            color: #0BB9F8;
            font-family: PingFangSC-Semibold,serif;
        }
        .ivu-table th{
            background-color: #F5F5F5;
        }
    }
</style>
