import { instance } from '../instance';

export const activateLicense = (param) => instance.post('authcode/activate', param);

export const queryValidity = (param) => instance.post('authcode/queryvalidity', param);

export const offRemind = (param) => instance.post('authcode/offremind', param);

export const getApplyCode = (param) => instance.post('authcode/getapplycode', param);

export const getConsoleMac = (param) => instance.post('authcode/getconsolemac', param);

export const getInactiveResult = (param) => instance.post('authcode/getinactiveresult', param);

export const getAuthedResourceInfo = (param) => instance.post('authcode/getauthedresourceinfo', param);

export const getCurAuthCode = (param) => instance.post('authcode/getcurauthcode', param);
