<template>
  <Modal
    title="添加用户"
    :value="visible"
    @on-cancel="handleClose"
  >
    <div class="home-signin-container">
      <Alert type="error" show-icon v-if="errormsg">{{ errormsg }}</Alert>
      <div class="home-signin-content">
        <Form ref="registerForm" :model="login" label-position="right" :label-width="100"
              :rules="ruleValidate">
          <FormItem label="手机号码" prop="phone">
            <Input style="width: 380px" type="text" v-model="login.phone" placeholder="请输入手机号"/>
          </FormItem>
          <FormItem label="邮箱" prop="email">
            <Input style="width: 380px" type="text" v-model="login.email" placeholder="请输入邮箱" autocomplete="new-password"/>
          </FormItem>
          <FormItem label="密码" prop="password">
            <Poptip trigger="focus" placement="right-start">
              <Input style="width: 380px" type="password" v-model="login.password" password
                     placeholder="请设置密码" @on-change="handleCheckPassword" autocomplete="new-password"/>
              <div slot="content">
                <ul class="password-check">
                  <li>
                    <Icon
                      :type="pwLength?'ios-checkmark-circle-outline':'ios-close-circle-outline'"/>
                    <span>长度为8-32个字符</span></li>
                  <li>
                    <Icon
                      :type="pwContain?'ios-checkmark-circle-outline':'ios-close-circle-outline'"/>
                    <span>密码不能包含手机号</span></li>
                  <li>
                    <Icon
                      :type="pwFormat?'ios-checkmark-circle-outline':'ios-close-circle-outline'"/>
                    <span>必须包含英文和数字</span></li>
                </ul>
              </div>
            </Poptip>
          </FormItem>
          <FormItem label="确认密码" prop="passwordAgain">
            <Poptip trigger="focus" placement="right-start">
              <Input style="width: 380px" type="password" v-model="login.passwordAgain" password
                     placeholder="请再次输入密码" @on-change="handleCheckPasswordAgain"/>
              <div slot="content">
                <div v-if="!pwConfirm" style="color: #ed4014;font-size: 12px;line-height: 20px">
                  请再次输入相同的密码
                </div>
                <div v-if="pwConfirm" style="color: #19be6b;font-size: 12px;">
                  <Icon type="md-checkmark" style="margin-right: 5px"/>
                  请再次输入相同的密码
                </div>
              </div>
            </Poptip>
          </FormItem>
          <FormItem label="姓名" prop="username">
            <Input style="width: 380px" type="text" v-model="login.username" placeholder="请输入姓名"/>
          </FormItem>
          <FormItem label="角色" prop="role">
            <Select style="width: 380px" type="text" v-model="login.role" placeholder="请输入姓名">
              <Option v-for="role in roleList" :value="role" :key="role">{{role}}</Option>
            </Select>
          </FormItem>
        </Form>
      </div>
    </div>
    <div slot="footer" class="modal-footer" style="margin-top: 20px">
      <pd-button type="primary" @click="handleSigninConfirm">保存</pd-button>
      <pd-button @click="handleClose">取消</pd-button>
    </div>
  </Modal>
</template>

<script>
import { addUserForPremise } from '@services/api/user';

export default {
  name: 'AddPremiseModal',
  props: {
    visible: Boolean,
    handleClose: Function
  },
  data() {
    return {
      errormsg: '',
      roleList: ['SYSTEM', 'ADMIN_READONLY'],
      login: {
        email: '',
        password: '',
        phone: '',
        username: '',
        passwordAgain: '',
        role: '',
        noModal: true
      },
      ruleValidate: {
        password: [
          {
            required: true,
            message: '密码不能为空',
            trigger: 'blur'
          },
          {
            min: 8,
            message: '密码长度为8-32',
            trigger: 'blur'
          },
          {
            max: 32,
            message: '密码长度为8-32',
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              if (value === '') {
                return callback(new Error('请再次输入你的密码'));
              } if (!this.pwContain || !this.pwFormat) {
                return callback(new Error('格式有误'));
              }
              return callback();
            },
            trigger: 'blur'
          }
        ],
        passwordAgain: [
          {
            required: true,
            message: '确认密码不能为空',
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              if (value !== this.login.password) {
                return callback(new Error('密码输入有误'));
              }
              return callback();
            },
            trigger: 'blur'
          }
        ],
        email: [
          { required: true, message: '邮箱不能为空', trigger: 'blur' },
          { type: 'email', message: '不正确的邮箱格式', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '手机号不能为空', trigger: 'blur' }
        ],
        username: [
          { required: true, message: '用户名不能为空', trigger: 'blur' }
        ],
        role: [
          { required: true, message: '角色不能为空', trigger: 'blur' }
        ]
      },
      loading: false,
      pwLength: false,
      pwContain: false,
      pwFormat: false,
      pwConfirm: false
    };
  },
  methods: {
    handleCheckPassword() {
      this.pwLength = this.login.password.length >= 8 && this.login.password.length <= 32;
      this.pwContain = this.login.password.indexOf(this.login.phone) === -1;

      const pattern = /(?=.*[0-9])(?=.*[a-zA-Z])/;

      this.pwFormat = pattern.test(this.login.password);
    },
    handleCheckPasswordAgain() {
      this.pwConfirm = Boolean(this.login.password && this.login.password === this.login.passwordAgain);
    },
    handleSigninConfirm() {
      this.$refs.registerForm.validate((val) => {
        if (val) {
          this.loading = true;
          addUserForPremise(this.login)
            .then((res) => {
              this.loading = false;
              if (res.data.code === '1') {
                this.$Message.success('添加用户成功');
                this.errormsg = '';
                this.handleClose();
              } else {
                this.errormsg = res.data.msg;
              }
            });
        }
        // 表示校验成功或失败
      });
    }
  }
};
</script>

<style scoped lang="less">
.home-signin-container {
  background: #ffffff;
  //width: 1000px;
  margin: 0 auto;
  position: relative;

  .ivu-form-item {
    margin-bottom: 24px;

    .ivu-form-item-content {
      overflow: visible;
      white-space: nowrap;
    }
  }

  .home-signin-hasAccount {
    position: absolute;
    right: -200px;
    top: 6px;
  }

  .home-signin-content {
    margin: 0 auto;
    width: 500px;
    position: relative;

    .welcome-title {
      font-size: 20px;
      text-align: center;
      margin-bottom: 36px;
    }

    .ivu-alert {
      position: absolute;
      top: 28px;
      width: 480px;
    }
  }
}
</style>
